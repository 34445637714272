import {
  FORGOTPASSWORD_FAILURE,
  FORGOTPASSWORD_PENDING,
  FORGOTPASSWORD_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: ''
};

export default function ForgotPasswordReducer(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case FORGOTPASSWORD_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        isSuccess: false
      });
    case FORGOTPASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: action.message,
      });
    case FORGOTPASSWORD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.message,
        isError: true,
        isSuccess: false
      });
    default:
      return state;
  }
}
