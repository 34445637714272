import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

const StarDueCollapse = (props) => {



  const [isActive, setActive] = useState(props.active);
  const [activeHeight, setActiveHeight] = useState(0);
  const inputRef = useRef(null);
  useLayoutEffect(() => {
    /**collapse for mobile***/
    const innerWidth = window ? window.innerWidth : 0;
    if (innerWidth < 1024) {
      setActive(false);
    }
  }, [])
  useLayoutEffect(() => {
    setActiveHeight(inputRef.current.scrollHeight);
  }, [isActive]);
  return (
    <>
      <div
        className={"stardue-collapse step-item step-parent"}
        style={props.style}
      >
        <div
          className={
            isActive
              ? "collapse-title step-header active"
              : "collapse-title step-header"
          }
          onClick={() => {
            setActive(!isActive);
          }}
        >
          <div className="label">
            <div className="step-header-title">
              <span className="number-step">
                <svg
                  id="Group_205"
                  data-name="Group 205"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36.363"
                  height="27.635"
                  viewBox="0 0 36.363 27.635"
                >
                  <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M726.68,212.887,717.7,240.522h25.856l10.507-27.635Z"
                    transform="translate(-717.705 -212.887)"
                    fill="#ff9c00"
                  ></path>
                </svg>
                <span>{props.stepId}</span>
              </span>
              <div className="step-title-wrap d-flex flex-column">
                <h2>
                  <div dangerouslySetInnerHTML={{ __html: props.title }} />
                </h2>
                <div className="option-selected">{props.value}</div>
              </div>
            </div>
          </div>
          <div className="arrow-step">
            <div className="arrow-step--inner">
              <svg
                id="Livello_1"
                data-name="Livello 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 72.05 38.03"
              >
                <path
                  d="M15.78,31A2,2,0,0,0,14,33.15a2.06,2.06,0,0,0,.61,1.26l34,34a2,2,0,0,0,2.82,0l34-34a2,2,0,0,0-2.76-2.9l-.06,0L50,64.16,17.41,31.56A2,2,0,0,0,15.78,31Z"
                  transform="translate(-13.97 -30.96)"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div
          ref={inputRef}
          className={isActive ? "collapse-body active" : "collapse-body "}
          style={{ maxHeight: isActive ? 'initial' : 0 }}
        >
          {props.children}
        </div>
      </div>
    </>
  );
};

export default StarDueCollapse;
