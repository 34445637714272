import {
    AREAFTP_PENDING,
    AREAFTP_SUCCESS,
    AREAFTP_FAILURE,
  } from "../actions/actionTypes";

  export default function AreaFTPReducer(state, action) {
    if (!state) state = {};
    switch (action.type) {
      case AREAFTP_PENDING:
        return Object.assign({}, state, {
          isLoading: true
        });
      case AREAFTP_SUCCESS:
        return Object.assign({}, state, {
          documents: action.payload,
          isLoading: false,
        });
      case AREAFTP_FAILURE:
        return Object.assign({}, state, {
          error: action.data,
          isLoading: false,
        });
      default:
        return state;
    }
  }