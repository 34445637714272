import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getContentPage } from "../../actions/contentActions";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import { ReactTitle } from 'react-meta-tags';
import i18next from "i18next";
const TermsCondition = (props) => {
  const dispatch = useDispatch();
  const { isLoading, content, isError, message } = useSelector((state) => {
    return state.content;
  });
  useEffect(() => {
    dispatch(getContentPage('terms-conditions'));
  }, []);

  return (
    <>
      <ReactTitle title="Dashboard" />
      <Header />
      {isError && <>{message}</>}
      <div className="dashboard-content best-news normal-scroll">
        <div className="wrap">
          <div className="dashboard-content-wrapper">
            <div className="page-title">
              <h3>{i18next.t('Footer.TermsConditions')}</h3>
            </div>
            {isLoading && <>
              <div className="loader-news">
                <span className="loader"></span>
                <div className="loader-text">{i18next.t('Pages.News.Loader')}</div>
              </div>
              </>}
              {content != '' && (
              <div className="page-content">
                <div className="page-normal-content-wrapper">
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </div>
              )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsCondition;
