import {
  RESETPASSWORD_FAILURE,
  RESETPASSWORD_PENDING,
  RESETPASSWORD_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: ''
};

export default function ResetPasswordReducer(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case RESETPASSWORD_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isSuccess: false,
        message: '',
        isError: false,
      });
    case RESETPASSWORD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: true,
        message: action.message,
        isError: false,
      });
    case RESETPASSWORD_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccess: false,
        message: action.message,
        isError: true,
      });
    default:
      return state;
  }
}
