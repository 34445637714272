import React from "react";
import StarDueCollapse from "../../components/StarDueCollapse/StarDueCollapse";
import { Space, Radio } from "antd";
import i18next from "i18next";

const Color = ({
  setColor,
  color,
  stepId,
  configuratorData,
  setGroupLoader,
  defaultColor,
  setColorName,
}) => {
  const renderName = (id) => {
    let data = configuratorData.color["items"].filter(
      (item) => item.id === color
    );
    if (data && data[0]) {
      return data[0].name;
    }
  };

  return (
    <>
      <StarDueCollapse
        title={i18next.t("Pages.Configurator.Step.Header.Color")}
        stepId={stepId}
        active={true}
        value={renderName(color)}
      >
        <div className="step-body">
          <Radio.Group
            onChange={(e) => {
              setColor(e.target.value);
              setColorName(e.target.colorName);
            }}
            value={color}
            className="Radio-img ColorRadio"
          >
            <Space direction="vertical">
              {configuratorData.color["items"].map((colorData) => {
                if (colorData.id !== defaultColor) {
                  return <></>;
                }
                return (
                  <Radio
                    key={"color-" + colorData.id}
                    colorName={colorData.name}
                    value={colorData.id}
                  >
                    {colorData.name}
                    <div className="Radio-custom-img">
                      <img src={colorData.preview_image} alt="color image" />
                    </div>
                    <div className="Radio-custom-img-text">
                      <p>{colorData.style}</p>
                    </div>
                  </Radio>
                );
              })}
              {configuratorData.color["items"].map((colorData) => {
                if (colorData.id === defaultColor) {
                  return <></>;
                }
                return (
                  <Radio
                    colorName={colorData.name}
                    value={colorData.id}
                    key={"color-item-"+colorData.id}
                  >
                    {colorData.name}
                    <div className="Radio-custom-img">
                      <img src={colorData.preview_image} alt="color image" />
                    </div>
                    <div className="Radio-custom-img-text">
                      <p>{colorData.style}</p>
                    </div>
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </div>
      </StarDueCollapse>
    </>
  );
};

export default Color;
