import { request } from "../services/apiService";
import {
  CREATE_DEALERS_SHOP_PENDING,
  CREATE_DEALERS_SHOP_SUCCESS,
  CREATE_DEALERS_SHOP_FAILURE,
  DEALERS_SHOP_LIST_PENDING,
  DEALERS_SHOP_LIST_SUCCESS,
  DEALERS_SHOP_LIST_FAILURE,
  GET_DEALER_SHOP_PENDING,
  GET_DEALER_SHOP_SUCCESS,
  GET_DEALER_SHOP_FAILURE,
  UPDATE_DEALERS_SHOP_PENDING,
  UPDATE_DEALERS_SHOP_SUCCESS,
  UPDATE_DEALERS_SHOP_FAILURE,
  DELETE_DEALERS_SHOP_PENDING,
  DELETE_DEALERS_SHOP_SUCCESS,
  DELETE_DEALERS_SHOP_FAILURE,
} from "./actionTypes";

export const getDealersShopList = (page, size, status) => (dispatch) => {
  dispatch({
    type: DEALERS_SHOP_LIST_PENDING,
  });
  const url = `v1/dealer_shop/list/`;
  request("get", url, "", true).then(
    (res) => {
      dispatch({
        type: DEALERS_SHOP_LIST_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: DEALERS_SHOP_LIST_FAILURE,
        error: err.response,
      });
    }
  );
};

export const createDealersShop = (data) => (dispatch) => {
  dispatch({
    type: CREATE_DEALERS_SHOP_PENDING,
  });
  const url = `v1/dealer_shop/create/`;
  request("post", url, data, true).then(
    (res) => {
      dispatch({
        type: CREATE_DEALERS_SHOP_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: CREATE_DEALERS_SHOP_FAILURE,
        error: err.response,
      });
    }
  );
};

export const getDealerShop = (id) => (dispatch) => {
  dispatch({
    type: GET_DEALER_SHOP_PENDING,
  });
  const url = `v1/dealer_shop/${id}/`;
  request("get", url, "", true).then(
    (res) => {
      dispatch({
        type: GET_DEALER_SHOP_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: GET_DEALER_SHOP_FAILURE,
        error: err.response,
      });
    }
  );
};

export const updateDealersShop = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_DEALERS_SHOP_PENDING,
  });
  const url = `v1/dealer_shop/update/`;
  request("post", url, data, true).then(
    (res) => {
      dispatch({
        type: UPDATE_DEALERS_SHOP_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: UPDATE_DEALERS_SHOP_FAILURE,
        error: err.response,
      });
    }
  );
};

export const deleteDealerShop = (id) => (dispatch) => {
  dispatch({
    type: DELETE_DEALERS_SHOP_PENDING,
  });
  const url = `v1/dealer_shop/delete/${id}/`;
  request("post", url, "", true).then(
    (res) => {
      dispatch({
        type: DELETE_DEALERS_SHOP_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: DELETE_DEALERS_SHOP_FAILURE,
        error: err.response,
      });
    }
  );
};
