import { request } from "../services/apiService";
import {
  FETCH_BACKORDER_LIST_PENDING,
  FETCH_BACKORDER_LIST_SUCCESS,
  FETCH_BACKORDER_LIST_FAILURE
} from "./actionTypes";

export const getBackOrderList = (page, size, search, status, startDate, endDate) => dispatch => {
  dispatch({ type: FETCH_BACKORDER_LIST_PENDING });
  let userData = {};
  if (localStorage.getItem("user")) {
    try {
      userData = JSON.parse(localStorage.getItem('agent-selected-user'))
  
    } catch (e) {

    }

  }


  var url = "v1/db/orders/backorder?page=" + page + "&size=" + size + "&search=" + search + "&status=" + status + "&start_date=" + startDate + "&end_date=" + endDate + (userData && userData.dealer_code ? "&dealer_code=" + userData.dealer_code : "")
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: FETCH_BACKORDER_LIST_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: FETCH_BACKORDER_LIST_FAILURE,
        data: error.response,
      });
    }
  );
}
