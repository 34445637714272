import React, { useEffect, useState } from "react";
import { Button } from "antd";
import i18next from "i18next";
import { Link} from "react-router-dom";
import {getProductSize} from "../../actions/productListActions";
import { useDispatch, useSelector } from "react-redux";

const FindYourSize = ({ modalId }) => {

  const dispatch = useDispatch();
  const [size, setSize] = useState("");
  const [formDataForSize, setFormDataForSize] = useState({
    horse: "",
    height: "",
  });
  const { isSizeLoading,sizeList ,sizeNotFound} = useSelector((state) => {
    return state.productList;
  });
  const handleFormDataForSize = (e) => {
    
    let data = { ...formDataForSize };
    data[e.target.name] = e.target.value;
    setFormDataForSize({});
    setFormDataForSize(data);

  };

  const onClickSubmit = async () => {
    let data = { ...formDataForSize };
    data.slug = modalId;
    dispatch(getProductSize(data));
  };
  
  useEffect(() => {
    if (sizeList) {
      setSize(sizeList.size);
      // setFormDataForSize({
      //   horse: "",
      //   height: "",
      // });
    }
  },[sizeList])
  return (
    <>
      <div className="findYourSize__wrapper">

        {size == '' && sizeNotFound == false  && <div className="findYourSize__wrapperInner">
          <div className="findYourSize_heading">
            <h5>{i18next.t('Components.FindYourSize.Header.Title')}</h5>
            <p>{i18next.t('Components.FindYourSize.Header.Subtitle')}</p>
          </div>
          <div className="findYourSize__content">
            <div className="findYourSize_stepContainers">
              <div className="findYourSize_stepHeading">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="82.3px"
                  height="41px"
                  viewBox="0 0 82.3 41"
                  style={{
                    overflow: "visible",
                    enableBackground: "new 0 0 82.3 41",
                  }}
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M80.8,0H1.5C0.7,0,0,0.7,0,1.5v38C0,40.3,0.7,41,1.5,41h79.3c0.8,0,1.5-0.7,1.5-1.5v-38C82.3,0.7,81.7,0,80.8,0z M79.3,38
                          h-9V26.2c0-0.6-0.4-1-1-1s-1,0.4-1,1V38h-9.8V16.7c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2V38H42.9V27c0-0.5-0.5-1-1-1c-0.6,0-1,0.5-1,1
                          v11h-9.7V16.7c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2V38H15.5V27c0-0.5-0.5-1-1-1c-0.6,0-1,0.5-1,1v11H3V3h76.3V38z"
                    />
                  </g>
                </svg>
                <p>{i18next.t('Components.FindYourSize.Step.step1.title')}</p>
              </div>
              <div className="findYourSize_stepContainer findYourSize_step1">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-between">
                    <div className="input-wrapper">
                      <label>{i18next.t('Components.FindYourSize.Step.step1.inseam')}</label>
                      <input
                        className="cavallo"
                        value={formDataForSize.horse}
                        name="horse"
                        onChange={(e) => handleFormDataForSize(e)}
                        placeholder="cm"
                      />
                    </div>
                    <div className="help-input">
                      <img src="/assets/img/help-leg.png" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-between">
                    <div className="input-wrapper">
                      <label>{i18next.t('Components.FindYourSize.Step.step1.height')}</label>
                      <input
                        className="cavallo"
                        value={formDataForSize.height}
                        name="height"
                        onChange={(e) => handleFormDataForSize(e)}
                        placeholder="cm"
                      />
                    </div>
                    <div className="help-input">
                      <img src="/assets/img/help-height.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="findYourSize_confirmStep">
              <div className="findYourSize-ctaStep">

                <Button id="confirmStepFindYourSize" onClick={onClickSubmit} loading={isSizeLoading} className='btn-modal'>
                {i18next.t('Components.FindYourSize.Step.step1.ButtonLabel')}
                </Button>
                {/* {size} */}
              </div>
              <Link href="#">
                <a 
                  target="_blank" 
                  className="helpSize"> {i18next.t('Components.FindYourSize.Step.step1.HelpText')}
                </a>
              </Link>
            </div>
          </div>

        </div>}
        {size != '' && <div className="findYourSize__wrapperInner">
          <div className="findYourSize_heading">
            <h5>{i18next.t('Components.FindYourSize.Header.Title')}</h5>
            <p>{i18next.t('Components.FindYourSize.Step.step2.resultText')}</p>
            <div className="size-result">{size}</div>
            <div class="text-center">
              <div class="note">
                <strong>{i18next.t('Components.FindYourSize.Step.step2.Note')}</strong><br/>
                {i18next.t('Components.FindYourSize.Step.step2.NoteInfo')}
              </div>
            </div>
            <div className="text-center">
              <a className="back measure_again" onClick={() => { setSize('') }}>{i18next.t('Components.FindYourSize.Step.step2.ButtonLabel')}</a>
            </div>
          </div>
        </div>}
        {sizeNotFound == true && <div className="findYourSize__wrapperInner">
          <div className="findYourSize_heading">
            <h5>{i18next.t('Components.FindYourSize.Header.Title')}</h5>
            <p>{i18next.t('Components.FindYourSize.Step.step2.resultText')}</p>
            <div className="size-result">{i18next.t('Components.FindYourSize.Step.step2.NoSizeFound')}</div>
            <div class="text-center">
              <div class="note">
                <strong>{i18next.t('Components.FindYourSize.Step.step2.Note')}</strong><br/>
                {i18next.t('Components.FindYourSize.Step.step2.NoteInfo')}
              </div>
            </div>
            <div className="text-center">
              <a className="back measure_again" onClick={() => {setSize('');}}>{i18next.t('Components.FindYourSize.Step.step2.ButtonLabel')}</a>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
}
export default FindYourSize;