import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ItemsDetail from "../../components/ItemsDetail";
import {
  getOrderDetail,
  orderReturn,
} from "../../actions/orderReceivedDetailActions";
import { createTicket } from "../../actions/ticketActions";
import {
  Skeleton,
  notification,
  Col,
  Button,
  Row,
  Collapse,
  Modal,
  Input,
} from "antd";
import { ReactTitle } from "react-meta-tags";
import { withRouter } from "react-router-dom";
import OrderReturnTrackingPopup from "../../components/OrderReturnTrackingPopup";
import PriceFormat from "../../components/PriceFormat";
import DateFormat from "../../components/DateFormat";
import { saveAs } from "file-saver";
import countryData from "../../utils/country";
import i18next from "i18next";
const { Panel } = Collapse;

const OrderReceviedDetail = (props) => {
  const dispatch = useDispatch();

  const [isShippingDetail, setShippingDetail] = useState(false);
  const [isReturnPopup, setIsReturnPopup] = useState(false);
  const {
    isLoading,
    order,
    message,
    isError,
    isReturnLoading,
    isReturnError,
    isReturnSuccess,
  } = useSelector((state) => {
    return state.orderReceivedDetail;
  });
  const { isCreateLoading, ticketCreated } = useSelector((state) => {
    return state.ticket;
  });

  useEffect(() => {
    if (ticketCreated) {
      setIsReturnPopup(false);
      notification["success"]({
        message: "Order Request",
        description: "Your request submitted successfully.",
      });
    }
  }, [ticketCreated]);

  useEffect(() => {
    if (props.match && props.match.params && props.match.params.id)
      dispatch(getOrderDetail(props.match.params.id));
  }, [props.match]);
  const returnOrder = (productIds, reason, file) => {
    let data = {
      type: "order_return",
      description: reason,
      orderid: order.id,
      products: productIds,
      file: file,
      request: "e_commerce_assistance",
      topic: "return_replacement",
    };
    dispatch(createTicket(data));
  };

  const orderTrackingTicket = (orderTrackingText) => {
    dispatch(
      createTicket({
        type: "ticket",
        request: "e_commerce_assistance",
        description: orderTrackingText,
        orderid: order.id,
        topic: "shipment_tracking",
      })
    );
  };
  const htmlDecode = (input) => {
    var e = document.createElement("textarea");
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

  return (
    <>
      <ReactTitle
        title={"#" + i18next.t("PageTitle.YourOrders", { orderId: order.id })}
      />
      <Header />
      <div className="dashboard-content normal-scroll Order-Dashboard Order-detail-page ">
        <div className="wrap">
          <div className="page-title">
            <h3>
              {i18next.t("Pages.Orders.Single.Order")}{" "}
              {isLoading && (
                <Skeleton.Input style={{ width: 100 }} active={isLoading} />
              )}{" "}
              {order && order.id && <>#</>}
              {order && order.id}
            </h3>
          </div>

          {isError && <>{message}</>}

          <div className="page-content flex">
            <div className="left-side">
              <div className="order-details-side">
                <div className="order-details">
                  <div className="title">
                    {i18next.t("Pages.Orders.Single.OrderSummary")}
                  </div>
                  <div className="box-order-details">
                    <div className="label">
                      {i18next.t("Pages.Orders.Single.OrderDate")}
                    </div>
                    {isLoading && (
                      <Skeleton.Input
                        style={{ width: 150 }}
                        active={isLoading}
                      />
                    )}
                    <div className="value">
                      {order && order.items && (
                        <DateFormat date={order.created_at} />
                      )}
                    </div>
                  </div>
                  <div className="box-order-details">
                    <div className="label">
                      {i18next.t("Pages.Orders.Single.EstimatedDate")}
                    </div>
                    {isLoading && (
                      <Skeleton.Input
                        style={{ width: 150 }}
                        active={isLoading}
                      />
                    )}
                    {order && order.items && order.estimated_date && (
                      <div className="value">
                        <DateFormat date={order.estimated_date} />
                      </div>
                    )}
                    {order &&
                      order.items &&
                      (order.estimated_date == "" ||
                        order.estimated_date == null) && (
                        <div className="value">
                          {i18next.t(
                            "Pages.Orders.Single.NotAvailableEstimateDate"
                          )}
                        </div>
                      )}
                  </div>
                  <div className="box-order-details">
                    <h3>
                      {order && order.items && (
                        <>
                          {order.billing_address.firstname}{" "}
                          {order.billing_address.lastname}
                        </>
                      )}
                    </h3>
                    <div className="label">
                      {i18next.t("Pages.Orders.Single.ShippingData")}
                    </div>
                    {isLoading && (
                      <Skeleton.Input
                        style={{ width: 150 }}
                        active={isLoading}
                      />
                    )}
                    {order && order.items && (
                      <>
                        {order.shipping_address && (
                          <div className="value">
                            {order.shipping_address.address_1} -{" "}
                            {order.shipping_address.postcode}
                            <br></br>
                            {order.shipping_address.city} -{" "}
                            {order.shipping_address.state}{" "}
                            {countryData[order.shipping_address.country]}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="box-order-details">
                    <div className="label">
                      {i18next.t("Pages.Orders.Single.BillingInformation")}
                    </div>
                    {isLoading && (
                      <Skeleton.Input
                        style={{ width: 150 }}
                        active={isLoading}
                      />
                    )}

                    {order && order.items && (
                      <>
                        <div className="value">
                          <span>
                            {order.billing_address.address_1} -{" "}
                            {order.billing_address.postcode}
                            <br></br>
                            {order.billing_address.city} -{" "}
                            {order.billing_address.state} -{" "}
                            {countryData[order.billing_address.country]}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {order.is_order_for_customer && (
                    <div className="box-order-details">
                      <div className="label">
                        {i18next.t("Pages.Orders.Single.OrderForCustomer")}
                      </div>
                      {isLoading && (
                        <Skeleton.Input
                          style={{ width: 150 }}
                          active={isLoading}
                        />
                      )}

                      {order && order.is_order_for_customer && (
                        <>
                          <div className="value">
                            <span>
                              {order.order_created_for_customer_firstname}{" "}
                              {order.order_created_for_customer_lastname}
                              <br></br>
                              {order.order_created_for_customer_email}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <Button
                  className="btn btn-primary btn-submit"
                  onClick={() => setIsReturnPopup(true)}
                >
                  {i18next.t("Pages.Orders.Single.OpenTicket")}
                </Button>
                {/* {order.marca_shipped_track_detail && <Button className="btn btn-primary btn-submit" onClick={() => setShippingDetail(true)}>
                  Order Shipping Detail
                </Button>} */}
                {order && order.return && order.return.length > 0 && (
                  <Col span={24}>
                    <div className="Filter-col Flex-box space-between">
                      <h2>{i18next.t("Pages.Orders.Single.ReturnRequests")}</h2>
                    </div>
                    {order.return.length > 0 &&
                      order.return.map((item, index) => {
                        return (
                          <>
                            <div className="Order-content order-request-item">
                              <p>
                                {i18next.t(
                                  "Pages.Dashboard.Orders.Content.ReturnRequestsInfo"
                                )}{" "}
                                #{item.ID}
                              </p>
                            </div>
                          </>
                        );
                      })}
                  </Col>
                )}
              </div>
            </div>
            <div className="right-side">
              <div className="Order-content">
                {isLoading && (
                  <div className="skeleton-container">
                    <Skeleton avatar paragraph={{ rows: 4 }} />
                  </div>
                )}
                {order &&
                  order.items &&
                  order.items.length > 0 &&
                  order.items.map((item, idx) => (
                    <>
                      <Row gutter={[36, 36]} className="Order-details">
                        <Col
                          span={8}
                          xs={{ span: 24 }}
                          sm={{ span: 10 }}
                          lg={{ span: 10 }}
                          xl={{ span: 4 }}
                        >
                          <div className="Order-img">
                            <img src={item.item_metas.preview_image ? item.item_metas.preview_image : item.image} alt="" />
                          </div>
                        </Col>
                        <Col
                          span={8}
                          xs={{ span: 24 }}
                          sm={{ span: 12 }}
                          lg={{ span: 12 }}
                          xl={{ span: 12 }}
                        >
                          <div className="Order-product-detail">
                            <h4>
                              {item.item_metas.type !== "other_products"
                                ? item.item_metas.model_name
                                : item.name}
                            </h4>
                            {item.item_metas.type !== "other_products" && (
                              <div className="product-meta">
                                <div>
                                  <span>
                                    {i18next.t(
                                      "Pages.Orders.Single.ConfiguratorID"
                                    )}
                                    :{" "}
                                  </span>
                                  <span>{item.item_metas.configurator_id}</span>
                                </div>
                                <div>
                                  <span>
                                    {i18next.t("Pages.Orders.Single.Sku")}:{" "}
                                  </span>
                                  <span>{item.item_metas.bike_sku}</span>
                                </div>
                                <div>
                                  <span>
                                    {i18next.t("Pages.Orders.Single.Groupset")}:
                                  </span>
                                  <span>{item.item_metas.groupset_name}</span>
                                </div>
                                <div>
                                  <span>
                                    {i18next.t("Pages.Orders.Single.Size")}:{" "}
                                  </span>
                                  <span>{item.item_metas.size_name}</span>
                                </div>
                                <div>
                                  <span>
                                    {i18next.t("Pages.Orders.Single.Color")}:{" "}
                                  </span>
                                  <span>{item.item_metas.color_name}</span>
                                </div>
                                <div>
                                  <span>
                                    {i18next.t("Pages.Orders.Single.Wheels")}:{" "}
                                  </span>
                                  <span>{item.item_metas.wheel_name}</span>
                                </div>
                                {/* <div>
                                      <span>
                                        <Link href={item.item_metas.link}>
                                          <a>
                                            Configurator Link
                                          </a>
                                        </Link>
                                      </span>
                                    </div> */}
                                <div>
                                  <span>
                                    <a
                                      className="download-configuration"
                                      onClick={() => {
                                        saveAs(
                                          item.item_metas.pdf,
                                          "your-new-basso-bike-" +
                                            item.item_metas.configurator_id +
                                            ".pdf"
                                        );
                                      }}
                                    >
                                      {i18next.t(
                                        "Pages.Orders.Single.DownloarConf"
                                      )}
                                    </a>
                                  </span>
                                </div>
                                <Collapse defaultActiveKey={[]}>
                                  <Panel
                                    header={i18next.t(
                                      "Pages.Orders.Single.IncludeOption"
                                    )}
                                    key="1"
                                  >
                                    {item.item_metas.include_product_format &&
                                      item.item_metas.include_product_format.map(
                                        (textData, index) => {
                                          return (
                                            <p key={index}>
                                              {htmlDecode(textData)}
                                            </p>
                                          );
                                        }
                                      )}
                                  </Panel>
                                  {item.item_metas.upgrade_product_format
                                    .lenght && (
                                    <Panel
                                      header={i18next.t(
                                        "Pages.Orders.Single.Upgrade"
                                      )}
                                      key="2"
                                    >
                                      {item.item_metas.upgrade_product_format &&
                                        item.item_metas.upgrade_product_format.map(
                                          (textData, index) => {
                                            return (
                                              <p key={index}>{textData}</p>
                                            );
                                          }
                                        )}
                                    </Panel>
                                  )}
                                </Collapse>
                              </div>
                            )}
                            {item.item_metas.type != "bike" && (
                              <div className="variation-other-product">
                                {item.variation_data &&
                                  item.variation_data.length > 0 &&
                                  item.variation_data.map(
                                    (variation, index) => {
                                      return (
                                        <>
                                          <div className="variation-info">
                                            <span className="label">
                                              {variation.label}
                                            </span>{" "}
                                            :
                                            <span className="value">
                                              {variation.name}
                                            </span>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                <div className="price">
                                  <span>
                                    <PriceFormat
                                      price={item.item_metas.regular_price}
                                      salePrice={item.item_metas.sale_price}
                                      propsCurrency={order.currency}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col
                          span={8}
                          xs={{ span: 24 }}
                          sm={{ span: 4 }}
                          lg={{ span: 4 }}
                          xl={{ span: 4 }}
                        >
                          <div className="Order-product-detail Last-child">
                            <h4>{i18next.t("Pages.Orders.Single.Quantity")}</h4>
                            <div className="Order-price">{item.quantity}</div>
                          </div>
                        </Col>
                        <Col
                          span={8}
                          xs={{ span: 24 }}
                          sm={{ span: 4 }}
                          lg={{ span: 4 }}
                          xl={{ span: 4 }}
                        >
                          <div className="Order-product-detail Last-child">
                            <h4>{i18next.t("Pages.Orders.Single.Price")}</h4>
                            <div className="Order-price">
                              <PriceFormat
                                price={item.price}
                                propsCurrency={order.currency}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ))}

                <div className="Order-detail-row">
                  <h4>{i18next.t("Pages.Orders.Single.Subtotal")}</h4>

                  <div className="Order-price">
                    {isLoading && (
                      <Skeleton.Input
                        style={{ width: 200 }}
                        active={isLoading}
                      />
                    )}
                    <PriceFormat
                      price={order.subtotal}
                      propsCurrency={order.currency}
                    />
                  </div>
                </div>
                <div className="Order-detail-row">
                  <h4>{i18next.t("Pages.Orders.Single.Shipment")}</h4>

                  <div className="Order-price">
                    {isLoading && (
                      <Skeleton.Input
                        style={{ width: 200 }}
                        active={isLoading}
                      />
                    )}
                    <PriceFormat
                      price={order.shipping}
                      propsCurrency={order.currency}
                    />
                  </div>
                </div>
                <div className="Order-detail-row">
                  <h4>{i18next.t("Pages.Orders.Single.PaymentMethod")}</h4>
                  {isLoading && (
                    <Skeleton.Input style={{ width: 200 }} active={isLoading} />
                  )}
                  {order.payment_method == "basc" && (
                    <div className="Order-price">
                      {i18next.t("Pages.Orders.Single.Bacs")}
                    </div>
                  )}
                </div>
                <div className="Order-detail-row">
                  <h4>
                    <strong>{i18next.t("Pages.Orders.Single.Total")}</strong>
                  </h4>

                  <div className="Order-price Final-price">
                    {isLoading && (
                      <Skeleton.Input
                        style={{ width: 200 }}
                        active={isLoading}
                      />
                    )}
                    <strong>
                      <PriceFormat
                        price={order.total}
                        propsCurrency={order.currency}
                      />
                    </strong>
                  </div>
                </div>
                {/* <div className="Order-detail-row">
                  <h4></h4>

                  <div className="Order-price Final-price">
                    <Button
                      className="btn btn-secondary btn-hover-outline btn-submit"
                      onClick={() => saveAs(order.invoice_link)}
                    >
                      {i18next.t("Pages.Orders.Single.DownloadReceipt")}
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* {!isError && <Skeleton loading={isLoading}>
          </Skeleton>} */}
        </div>
      </div>
      {order.marca_shipped_track_detail && (
        <Modal
          title={"Order Shipping Detail For #" + order.id}
          visible={isShippingDetail}
          width={1200}
          footer={null}
          centered
          onCancel={() => {
            setShippingDetail(false);
          }}
        >
          <Row gutter={[24, 24]}>
            <Col span={3} xs={{ span: 3 }} sm={{ span: 3 }} lg={{ span: 3 }}>
              <div className="table-header-item">Data Evento</div>
            </Col>
            <Col span={3} xs={{ span: 3 }} sm={{ span: 3 }} lg={{ span: 5 }}>
              <div className="table-header-item">Codice</div>
            </Col>
            <Col span={4} xs={{ span: 6 }} sm={{ span: 6 }} lg={{ span: 6 }}>
              <div className="table-header-item">Descrizione</div>
            </Col>
            <Col span={4} xs={{ span: 4 }} sm={{ span: 4 }} lg={{ span: 7 }}>
              <div className="table-header-item">Luogo</div>
            </Col>
          </Row>
          {order.marca_shipped_track_detail
            .sort((a, b) => (a.Ordine > b.Ordine ? 1 : -1))
            .map((item, i) => (
              <Row gutter={[24, 24]}>
                <Col
                  span={3}
                  xs={{ span: 3 }}
                  sm={{ span: 3 }}
                  lg={{ span: 3 }}
                >
                  <div className="table-item__content">
                    <DateFormat date={item.DataEvento} />
                  </div>
                </Col>
                <Col
                  span={3}
                  xs={{ span: 3 }}
                  sm={{ span: 3 }}
                  lg={{ span: 5 }}
                >
                  <div className="table-item__content">{item.Codice}</div>
                </Col>
                <Col
                  span={4}
                  xs={{ span: 6 }}
                  sm={{ span: 6 }}
                  lg={{ span: 6 }}
                >
                  <div className="table-item__content">{item.Descrizione}</div>
                </Col>
                <Col
                  span={4}
                  xs={{ span: 4 }}
                  sm={{ span: 4 }}
                  lg={{ span: 7 }}
                >
                  <div className="table-item__content">{item.Luogo}</div>
                </Col>
              </Row>
            ))}
        </Modal>
      )}

      <Footer />
      {isReturnPopup && (
        <OrderReturnTrackingPopup
          isReturnPopup={isReturnPopup}
          setIsReturnPopup={setIsReturnPopup}
          returnOrder={returnOrder}
          products={order.items}
          isLoading={isCreateLoading}
          isSuccess={isReturnSuccess}
          returnData={order.return}
          orderId={order.id}
          orderTrackingTicket={orderTrackingTicket}
        />
      )}
    </>
  );
};

export default withRouter(OrderReceviedDetail);
