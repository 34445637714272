import React from "react";
import { Space, InputNumber, Checkbox } from "antd";
import Price from "../../components/Price";
import { findPrice } from "../../utils/commons";
import PriceFormat from "../../components/PriceFormat";
import i18next from "i18next";
const OptionalStep = ({
  onChangeOtherSteps,
  other,
  otherStep,
  disbaleProductId,
  onChangeOtherOptions,
  otherIndex,
  productIndex,
  productQuantity,
  options,
  optionalQuantity,
  onChangesOptionalQuantity,
  onChangesQantityForOptions,
  onChangesQantity,
  userData,
  priceList,
}) => {
  return (
    <div className="step-body optional-step">
      {other.descriptions !== '' && (
        <div className="other-step-description">{other.descriptions}</div>
      )}
      <Space direction="vertical">
        {other.allowed_products &&
          other.allowed_products.length > 0 &&
          other.allowed_products.map((allowed_product, index) => {
            let isChecked = false;
            if (Array.isArray(otherStep[other.step_id])) {
              isChecked = otherStep[other.step_id].includes(allowed_product.id) && (allowed_product['stock'] > 0)
                ? true
                : false;
            }
            return allowed_product && disbaleProductId[allowed_product.id] ? (
              <>
                <div className="checkbox-wrapper">
                  <Checkbox
                    className="checkbox-stardue with-image"
                    onChange={(e) => {
                      onChangeOtherSteps(
                        e,
                        other.step_id,
                        other.affect_preview,
                        otherIndex
                      );
                    }}
                    checked={isChecked}
                    key={allowed_product.title + "_" + index}
                    value={allowed_product.id}
                    product={allowed_product}
                    indexValue={index}
                    data-id={allowed_product.id}
                    disabled={(allowed_product['stock'] < 1)}
                  >
                    <div className="Pro-checkbox-img">
                      <img src={allowed_product.image} />
                    </div>
                    <div className="product-data-checkbox">
                      <span>{allowed_product.title}</span>
                      {/* {allowed_product.id} */}
                      <p className="price-checkbox">{allowed_product.type == 'simple' ? <Price price={allowed_product.prices} /> : <Price price={allowed_product.options[0] ? allowed_product.options[0].prices : 0} />}</p>
                    </div>
                    {(allowed_product['stock'] < 1) && <>
                    <div className="out-of-stock">
                    {i18next.t('Pages.Configurator.OutOfStock')}
                    </div></>}
                  </Checkbox>
                </div>

                {otherStep &&
                  otherStep[other.step_id] &&
                  Array.isArray(otherStep[other.step_id]) &&
                  otherStep[other.step_id].includes(allowed_product.id) &&
                  allowed_product.type == 'variable' && (
                    <div className="additional-step-required">
                      <div className="step-child-container">
                        <Space direction="vertical">
                          {allowed_product.options.map((option, index) => {
                            const qunatity = optionalQuantity[option.id]
                              ? optionalQuantity[option.id]
                              : 0;
                            const regular_price = findPrice(
                              option.prices,
                              "regular_price",
                              priceList,
                              userData
                            );
                            return (
                              <>
                                <Checkbox
                                  onChange={(e) =>
                                    onChangeOtherOptions(e, other.step_id)
                                  }
                                  checked={
                                    Array.isArray(options[other.step_id]) &&
                                    options[other.step_id].includes(option.id)
                                  }
                                  disabled={true}
                                  key={option.id}
                                  value={option.id}
                                  data-id={option.id}
                                >
                                  <img
                                    src={option.image}
                                    style={{ width: 30 }}
                                  />
                                  {
                                    allowed_product.type == 'variable' && option.stock < 0 ?
                                      <div className="variation-data not-available">
                                        <span className="variationName">{option.name}</span>
                                        <div className="not-available">{i18next.t("Pages.SingleProduct.OutOfStock")}</div>
                                      </div> :
                                      <div>
                                        <div className="variation-data available">
                                          <span className="variationName">{option.name}</span>
                                          <div className="quantity-box">
                                            {" "}
                                            <button
                                              onClick={() =>
                                                onChangesOptionalQuantity(
                                                  option.id,
                                                  false,
                                                  other.step_id,
                                                  option.stock
                                                )
                                              }
                                              className="btn-qty minus"
                                            >
                                              <svg
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                width="32.9px"
                                                height="4px"
                                                viewBox="0 0 32.9 4"
                                                style={{
                                                  overflow: "visible",
                                                  enableBackground: "new 0 0 32.9 4",
                                                }}
                                                xmlSpace="preserve"
                                              >
                                                <path d="M1.9,0C0.8,0.1,0,1,0,2.1s1,2,2.1,1.9h28.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c0,0,0,0-0.1,0H2.1C2,0,2,0,1.9,0z" />
                                              </svg>
                                            </button>
                                            <div className="qty-wrapper">
                                              <InputNumber
                                                min={1}
                                                max={option.stock}
                                                value={
                                                  optionalQuantity[option.id]
                                                    ? optionalQuantity[option.id]
                                                    : 0
                                                }
                                              />
                                            </div>
                                            <button
                                              className="btn-qty plus"
                                              onClick={() => {

                                                onChangesOptionalQuantity(
                                                  option.id,
                                                  true,
                                                  other.step_id,
                                                  option.stock
                                                )

                                              }

                                              }
                                              disabled={option.stock <= (optionalQuantity[option.id]
                                                ? optionalQuantity[option.id]
                                                : 0) ? true : false}
                                            >
                                              <svg
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                width="32.9px"
                                                height="33.3px"
                                                viewBox="0 0 32.9 33.3"
                                                style={{
                                                  overflow: "visible",
                                                  enableBackground: "new 0 0 32.9 33.3",
                                                }}
                                                xmlSpace="preserve"
                                              >
                                                <path
                                                  d="M16.3,0c-1,0.1-1.8,1-1.8,2v12.6H2.1c-0.1,0-0.1,0-0.2,0c-1.1,0.1-2,1-1.9,2.1s1,2,2.1,1.9h12.4v12.6c0,1.1,0.9,2,2,2
                                                        c1.1,0,2-0.9,2-2c0,0,0,0,0-0.1V18.6h12.4c1.1,0,2-0.9,2-2s-0.9-2-2-2c0,0,0,0-0.1,0H18.5V2c0-1.1-0.9-2-2-2C16.4,0,16.3,0,16.3,0z"
                                                />
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                        {qunatity > 0 && (
                                          <>
                                            <div className="total-price-variations">
                                              <div className="label">+ </div>
                                              <div className="value">
                                                {" "}
                                                <PriceFormat
                                                  price={qunatity * regular_price}
                                                />
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                  }
                                </Checkbox>
                              </>
                            );
                          })}
                        </Space>
                      </div>
                    </div>
                  )}
                {otherStep &&
                  otherStep[other.step_id] &&
                  Array.isArray(otherStep[other.step_id]) &&
                  otherStep[other.step_id].includes(allowed_product.id) &&
                  (allowed_product['stock'] > 0) &&
                  allowed_product.have_quantity === "1" &&
                  allowed_product.type == 'simple' && (
                    <div className="step-inner">
                      <div className="step-item step-child">
                        <div className="ant-space-item">
                          <div className="ant-checkbox-wrapper">
                            <div className="Select-quntity">
                              <span>{i18next.t("Pages.Configurator.Quantity")}</span>
                              {
                                allowed_product.type == 'simple' && allowed_product.stock < 0 ? <div className="not-available"> {i18next.t("Pages.SingleProduct.OutOfStock")}</div> :

                                  <div className="quantity-box">
                                    {" "}
                                    <button
                                      onClick={() =>
                                        onChangesQantity(
                                          allowed_product.id,
                                          false,
                                          allowed_product.stock
                                        )
                                      }
                                      className="btn-qty minus"
                                    >
                                      <svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        width="32.9px"
                                        height="4px"
                                        viewBox="0 0 32.9 4"
                                        style={{
                                          overflow: "visible",
                                          enableBackground: "new 0 0 32.9 4",
                                        }}
                                        xmlSpace="preserve"
                                      >
                                        <path d="M1.9,0C0.8,0.1,0,1,0,2.1s1,2,2.1,1.9h28.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c0,0,0,0-0.1,0H2.1C2,0,2,0,1.9,0z" />
                                      </svg>
                                    </button>
                                    <div className="qty-wrapper">
                                      <InputNumber
                                        min={0}
                                        max={allowed_product.stock ? allowed_product.stock : 0}
                                        value={
                                          productQuantity[allowed_product.id
                                          ]
                                        }
                                      />
                                    </div>
                                    <button
                                      className="btn-qty plus"
                                      onClick={() =>
                                        onChangesQantity(
                                          allowed_product.id,
                                          true,
                                          allowed_product.stock
                                        )
                                      }
                                      disabled={allowed_product.stock <= productQuantity[allowed_product.id] ? true : false}
                                    >
                                      <svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        width="32.9px"
                                        height="33.3px"
                                        viewBox="0 0 32.9 33.3"
                                        style={{
                                          overflow: "visible",
                                          enableBackground: "new 0 0 32.9 33.3",
                                        }}
                                        xmlSpace="preserve"
                                      >
                                        <path
                                          d="M16.3,0c-1,0.1-1.8,1-1.8,2v12.6H2.1c-0.1,0-0.1,0-0.2,0c-1.1,0.1-2,1-1.9,2.1s1,2,2.1,1.9h12.4v12.6c0,1.1,0.9,2,2,2
                                                c1.1,0,2-0.9,2-2c0,0,0,0,0-0.1V18.6h12.4c1.1,0,2-0.9,2-2s-0.9-2-2-2c0,0,0,0-0.1,0H18.5V2c0-1.1-0.9-2-2-2C16.4,0,16.3,0,16.3,0z"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                              }
                            </div>
                          </div>
                        </div>

                        {/* <Space direction="vertical">
                            <InputNumber
                              min={1}
                              max={10}
                              onChange={(e) =>
                                onChangesQantityForOptions(
                                  e,
                                  other.allowed_products[
                                    productIndex[other.step_id]
                                  ].id
                                )
                              }
                              defaultValue={
                                productQuantity[
                                  other.allowed_products[
                                    productIndex[other.step_id]
                                  ].id
                                ]
                              }
                            />
                          </Space> */}
                      </div>
                    </div>
                  )}
              </>
            ) : (
              <></>
            );
          })}
      </Space>
    </div>
  );
};
export default OptionalStep;
