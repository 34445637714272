import { request } from "../services/apiService";
import {
  FETCH_CONFIGURATOR_CATEGORY_PENDING,
  FETCH_CONFIGURATOR_CATEGORY_SUCCESS,
  FETCH_CONFIGURATOR_CATEGORY_FAILURE,
  FETCH_MODELS_PENDING,
  FETCH_MODELS_SUCCESS,
  FETCH_MODELS_FAILURE,
  FETCH_OTHER_STEP_PENDING,
  FETCH_OTHER_STEP_SUCCESS,
  FETCH_OTHER_STEP_FAILURE

} from "./actionTypes";

export const getConfiguratorCategory = () => (dispatch) => {
  dispatch({ type: FETCH_CONFIGURATOR_CATEGORY_PENDING });
  const url = `v1/configurator/category-list/`;
  request("get", url, '', false).then(
    (res) => {
      dispatch({
        type: FETCH_CONFIGURATOR_CATEGORY_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: FETCH_CONFIGURATOR_CATEGORY_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};

export const getConfiguratorModelData = (model) => (dispatch) => {
  dispatch({ type: FETCH_MODELS_PENDING });
  const url = `v1/configurator/models-list/?models=${model}`;
  request("get", url, '', false).then(
    (res) => {
      dispatch({
        type: FETCH_MODELS_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: FETCH_MODELS_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};

export const getBikeData = (configuratorType, slug, groupsetSlug) => (dispatch) => {
  dispatch({ type: FETCH_OTHER_STEP_PENDING });
  const url = `v1/configurator/product?model=${slug}&groupset=${groupsetSlug}&configurator_type=${configuratorType}`;
  request("get", url, '', false).then(
    (res) => {
      dispatch({
        type: FETCH_OTHER_STEP_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: FETCH_OTHER_STEP_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};

