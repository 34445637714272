import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Select,
  Input,
  Upload,
  notification,
  Steps,
  Pagination,
  Radio,
} from "antd";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DealerShopPopup from "../../components/DealersShopPopup";
import {
  getDealersShopList,
  createDealersShop,
  updateDealersShop,
  getDealerShop,
  deleteDealerShop
} from "../../actions/dealersShopActions";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import paginationLabel from "../../components/PaginationRender";
import DateFormat from "../../components/DateFormat";
import { ReactTitle } from "react-meta-tags";
import Cookies from "js-cookie";

const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;
const { Dragger } = Upload;

const DealersShop = () => {
  const dispatch = useDispatch();
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(12);
  const [currentStepSize, setCurrentSizeStep] = useState(0);
  const {
    isLoading,
    isError,
    message,
    dealersShop,
    dealerShop,
    isLoadingDealerShop,
    isErrorDealerShop, 
    isCreate,
    isCreateError,
    isUpdate,
    isUpdateError,
    isPopupButtonLoading,
    isDeletePopupButtonLoading,
    isDelete,
    isDeleteError
  } = useSelector((state) => {
    return state.dealersShop;
  });

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [popupOpen, setPopupOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [deleteShopId, setDeleteShopId] = useState('');
  const [formData, setFormData] = useState({
    company_name: "",
    street: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    country_code: "",
    phone: "",
    vat: "",
    coordinates_latitude: "22.307156",
    coordinates_longitude: "73.181218",
    dealer_id: userData.id,
    dealer_code: "",
  });

  useEffect(() => {
    dispatch(getDealersShopList(current, pageSize));
  }, [current]);

  useEffect(() => {
    if(isCreate){ 
      notification["success"]({
        message: 'Dealer Shop Created successfully'
      });
    }
    if(isCreateError && !isCreate){
      notification["error"]({
        message: 'Dealer Shop Not Created'
      });
    }
    if(isUpdate){ 
      notification["success"]({
        message: 'Dealer Shop Update successfully'
      });
    }
    if(isUpdateError && !isUpdate){
      notification["error"]({
        message: 'Dealer Shop Not Updated'
      });
    }
    if(isDelete){ 
      notification["success"]({
        message: 'Dealer Shop Deleted successfully'
      });
    }
    if(isDeleteError && !isDelete){
      notification["error"]({
        message: 'Dealer Shop Not Deleted'
      });
    }
    setPopupOpen(false)
    setDeletePopupOpen(false)
    dispatch(getDealersShopList(current, pageSize));
  }, [isCreate,isUpdate,isDelete]);

  useEffect(() => {
    if (dealerShop && Object.keys(dealerShop).length > 0) {
   
      setFormData(dealerShop);
    }
  }, [dealerShop]);

  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };

  const onCreate = (formData) => {
    let add_formdata = {
      "company_name": formData.company_name,
      "street": formData.address_1,
      "postal_code": formData.postcode,
      "city": formData.city,
      "state": formData.state,
      "country": formData.country,
      "phone": formData.phone,
      "vat": formData.vat,
      "coordinates_latitude": "22.307156",
      "coordinates_longitude": "73.181218",
      "dealer_id": userData.id
    }
    dispatch(createDealersShop(add_formdata));
  };

  const onUpdate = (formData) => {
    let update_formdata = {
      "ID": formData.ID,
      "company_name": formData.company_name,
      "street": formData.address_1,
      "postal_code": formData.postcode,
      "city": formData.city,
      "state": formData.state,
      "country": formData.country,
      "phone": formData.phone,
      "vat": formData.vat,
      "coordinates_latitude": "22.307156",
      "coordinates_longitude": "73.181218",
      "dealer_id": userData.id
    }
    dispatch(updateDealersShop(update_formdata));
  };

  const deleteDealerShopdispatch = (id) => {
    dispatch(deleteDealerShop(id));
  }

  const onClickEdit = (id) => {
    dispatch(getDealerShop(id));
    handleClickOpen();
  };

  return (
    <>
      <ReactTitle title={i18next.t("PageTitle.DealersShop")} />
      <Header />
      <div className="dashboard-content prd-list">
        <div className="wrap">
          <div className="page-content flex">
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t("Pages.DealersShop.PageTitle")}</h3>
              </div>
              <div className="table rel with-pagination">
                <div className="hdr sticky">
                  <Row gutter={[24, 24]}>
                    <Col
                      span={2}
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      lg={{ span: 2 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.DealersShop.Table.ID")}
                      </div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.DealersShop.Table.CompanyName")}
                      </div>
                    </Col>
                    <Col
                      span={7}
                      xs={{ span: 7 }}
                      sm={{ span: 7 }}
                      lg={{ span: 7 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.DealersShop.Table.Vat")}
                      </div>
                    </Col>
                    <Col
                      span={8}
                      xs={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.DealersShop.Table.Phone")}
                      </div>
                    </Col>
                    <Col
                      span={2}
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      lg={{ span: 2 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.DealersShop.Table.Action")}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="table-container-wrapper">
                  <div
                    className={
                      isLoading
                        ? "loader-container"
                        : "loader-container is-loaded"
                    }
                  >
                    <span className="loader"></span>
                    <div className="loader-text">
                      {i18next.t("Pages.DealersShop.Loader")}
                    </div>
                  </div>
                  <div className="table-wrap">
                    {/* {total == 0 && <>No ticket</>} */}
                    {dealersShop &&
                      dealersShop.length > 0 &&
                      dealersShop.map((item, idx) => (
                        <div className="table-item">
                          <Row gutter={[24, 24]}>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content">
                                {item.ID}
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              <div className="table-item__content">
                                {item.company_name}
                              </div>
                            </Col>
                            <Col
                              span={7}
                              xs={{ span: 7 }}
                              sm={{ span: 7 }}
                              lg={{ span: 7 }}
                            >
                              <div className="table-item__content">
                                {item.vat}
                              </div>
                            </Col>
                            <Col
                              span={8}
                              xs={{ span: 8 }}
                              sm={{ span: 8 }}
                              lg={{ span: 8 }}
                            >
                              <div className="table-item__content">
                                {item.phone}
                              </div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content">
                                <Button type="primary" onClick={() => {setFormData({});onClickEdit(item.ID);}}>
                                  Edit
                                </Button>
                                <Button type="primary" onClick={() => {setDeleteShopId(item.ID);setDeletePopupOpen(true);}}>
                                  Delete
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="pagination-wrapper">
                  {/* <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} di ${total} tickets`
                    }
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button type="primary" onClick={() => {setFormData({});handleClickOpen()}}>
          Add New Dealer Shop
        </Button>
      </div>
      {popupOpen && (
        <DealerShopPopup
          popupOpen={popupOpen}
          formData={formData}
          setFormData={setFormData}
          onCreate={onCreate}
          onUpdate={onUpdate}
          setPopupOpen={handleClose}
          isLoadingDealerShop={isLoadingDealerShop}
          isPopupButtonLoading={isPopupButtonLoading}
        />
      )}
      {deletePopupOpen && (
        <Modal
        className="modal-s2 modal-address"
        centered
        width={1000}
        footer={null}
        visible={deletePopupOpen}
        onCancel={() => {
          setDeletePopupOpen();
        }}
      >
        <div>
            Are You Sure Want to Delete this Shop ?
        </div>
        
        <Button loading={isDeletePopupButtonLoading} type="primary" onClick={() => {deleteDealerShopdispatch(deleteShopId);}}>
            Delete
        </Button>
        <Button type="primary" onClick={() => {setDeletePopupOpen(false);}}>
            Close
        </Button>
      </Modal>
      )}
      <Footer />
    </>
  );
};

export default DealersShop;
