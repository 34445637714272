import { request } from "../services/apiService";
import {
    GET_MODEL_FE_PENDING,
    GET_MODEL_FE_SUCCESS,
    GET_MODEL_FE_FAILURE,
    GET_SEARCH_USER_PENDING,
    GET_SEARCH_USER_SUCCESS,
    GET_SEARCH_USER_FAILURE,
    CREATE_REGISTER_BIKE_PENDING,
    CREATE_REGISTER_BIKE_SUCESS,
    CREATE_REGISTER_BIKE_FAILURE,
    GET_DEALER_REGISTER_BIKE_PENDING,
    GET_DEALER_REGISTER_BIKE_SUCCESS,
    GET_DEALER_REGISTER_BIKE_FAILURE
} from "./actionTypes";

export const getModelFE = () => dispatch => {
  dispatch({ type: GET_MODEL_FE_PENDING });
  var url = "v1/models/list-fe"
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: GET_MODEL_FE_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: GET_MODEL_FE_FAILURE,
        data: error.response,
      });
    }
  );
}

export const getSearchUser = (email) => dispatch => {
    dispatch({ type: GET_SEARCH_USER_PENDING });
    var url = "v1/user/search/?email="+email
    request("get", url, '', true).then(
      (res) => {
        dispatch({
          type: GET_SEARCH_USER_SUCCESS,
          data: res,
        });
      },
      (error) => {
        dispatch({
          type: GET_SEARCH_USER_FAILURE,
          data: error.response,
        });
      }
    );
  }

export const createRegisterBike = (data) => dispatch => {
    dispatch({ type: CREATE_REGISTER_BIKE_PENDING });
    var url = "v1/dealer/register-bike"
    request("post", url, data, true).then(
      (res) => {
        dispatch({
          type: CREATE_REGISTER_BIKE_SUCESS,
          data: res,
        });
      },
      (error) => {
        dispatch({
          type: CREATE_REGISTER_BIKE_FAILURE,
          data: error.response,
        });
      }
    );
  }

export const getDealerRegisterBike = (page,size,search,model) => dispatch => {
  dispatch({ type: GET_DEALER_REGISTER_BIKE_PENDING });
  var url = "v1/dealer/registered-bike?page="+page+"&size="+size+"&model="+model+"&search="+search
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: GET_DEALER_REGISTER_BIKE_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: GET_DEALER_REGISTER_BIKE_FAILURE,
        data: error.response,
      });
    }
  );
}