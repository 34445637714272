import React, { useState, useEffect } from "react";
import PriceFormat from "../PriceFormat";
import { removeCart, updateQuantity } from "../../actions/cartActions";
import { Row, Col, Button, Dropdown, Menu } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import BikeSummary from "../BikeSummary";
import {
  downloadPDF,
} from "../../actions/configuratorActions";
import { saveAs } from "file-saver";
import ConfiguratorSummary from "../../components/ConfiguratorPopup";
import { Link } from "react-router-dom";
import i18next from "i18next";

const ItemsDetail = ({ item }) => {

  const dispatch = useDispatch();
  const [configMenu, setconfigMenu] = useState(false);
  const [isSummaryPopup, setisSummaryPopup] = useState(false)

  const { cofiguratorPDF, isPdfLoading } = useSelector((state) => {
    return state.configure;
  });

  const makeRemoveItem = () => {
    dispatch(removeCart({ 'key': item.key }));
  }
  const setItemQuantity = (qty) => {
    dispatch(updateQuantity({ 'key': item.key, 'quantity': qty }));
  }

  useEffect(() => {
    if (cofiguratorPDF && Object.keys(cofiguratorPDF).length > 0) {
      downLoadConfiguratorPDF(cofiguratorPDF);
    }
  }, [cofiguratorPDF]);

  const downLoadConfiguratorPDF = (data) => {
    if (item && item.item_metas.configurator_id) {
      saveAs(
        data.url,
        "your-new-basso-bike-" + item.item_metas.configurator_id + ".pdf"
      );
    }
  };


  if (!item) { return <></> }
  return (
    <div className="table-item">
      <div className="items-list">
        <Row gutter={[24, 24]}>
          <Col
            span={3}
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="table-item__content">
              <img src={(item.item_metas.type == 'other_products') ? item.image : item.item_metas.preview_image} className="img" />
            </div>
          </Col>
          <Col
            span={5}
            xs={{ span: 5 }}
            sm={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <div className="table-item__content">
              {item.item_metas.type !== "other_products" && <>
                {item.item_metas.model_name}
                <BikeSummary item={item} />
              </>}
              {(item.item_metas.type == "other_products") && item.name}
              {(item.item_metas.type == "other_products" && item.attribute && item.attribute.length > 0) && <>

                <div className="variation-other-product">
                  {item.attribute.map((attr, index) => {
                    return <>
                      <div className="variation-info">
                        <span className="label">{attr.name}:</span><span className="value">{attr.value}</span>
                      </div> </>
                  })}
                </div>
              </>}
            </div>
          </Col>
          <Col
            span={4}
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            lg={{ span: 4 }}
          >
            <div className="table-item__content">
              {(item.item_metas.type == "other_products") && item.sku}
              {(item.item_metas.type != "other_products") && item.item_metas.bike_sku}
            </div>
          </Col>
          <Col
            span={3}
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="table-item__content">
              {(item.quantity > 0 && item.item_metas.type === "other_products") && <button className="btn-qty btn-minus" htmlType="button" disabled={item.quantity == 1} onClick={() => { setItemQuantity(item.quantity - 1) }}>
                -
              </button>}
              {item.quantity}
              {(item.stock > item.quantity && item.item_metas.type === "other_products") && <button className="btn-qty btn-plus  " htmlType="button" onClick={() => { setItemQuantity(item.quantity + 1) }}>
                +
              </button>}
            </div>
          </Col>
          <Col
            span={3}
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="table-item__content">
              <PriceFormat price={item.price} />
            </div>
          </Col>
          <Col
            span={3}
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="table-item__content">
              <div className="table-item__content">
                <PriceFormat price={item.subtotal} />
              </div>
            </div>
          </Col>
          <Col
            span={3}
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="table-item__content align-end">
              <Dropdown overlay={
                <Menu>
                  <Menu.Item onClick={() => makeRemoveItem()}>
                    {i18next.t('Pages.Checkout.Item.RemoveProduct')}
                  </Menu.Item>
                  {item.item_metas.type !== "other_products" && <>
                    <Menu.Item>
                      <Link to={`/${i18next.language}` + "/" + i18next.t('routes.Configuration') + '/?configurator_id=' + item.item_metas.configurator_id}>

                        {i18next.t('Pages.Checkout.Item.EditConfiguration')}
                      </Link>
                    </Menu.Item>
                    <Menu.Item onClick={() => setisSummaryPopup(true)}>
                      {i18next.t('Pages.Checkout.Item.viewConfiguration')}
                    </Menu.Item>
                    <Menu.Item onClick={() => {
                      dispatch(downloadPDF(item.item_metas.configurator_id));
                    }}>{i18next.t('Pages.Configurator.BottomBar.DownloadPDF')}</Menu.Item>
                  </>}
                </Menu>
              } placement="bottomLeft" arrow>
                <Button>
                  <svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 56"><path d="M350,308a28,28,0,1,0-28-28A28,28,0,0,0,350,308Zm112,0a28,28,0,1,0-28-28A28,28,0,0,0,462,308Zm-224,0a28,28,0,1,0-28-28A28,28,0,0,0,238,308Z" transform="translate(-210 -252)" style={{ fillRule: 'evenodd' }} /></svg>
                </Button>
              </Dropdown>
              {/* <div
                onClick={() => setconfigMenu(!configMenu)}
                className="ico font s18"
              >
                •••
              </div> */}
              {/* {configMenu && <div>
                <Button className={`ant-btn btn-primary  `} onClick={() => makeRemoveItem()}>Remove From Cart</Button>
              </div>} */}

            </div>
          </Col>
        </Row>
      </div>
      {isSummaryPopup && <ConfiguratorSummary isSummaryPopup={isSummaryPopup} configurator_id={item.item_metas.configurator_id} setisSummaryPopup={setisSummaryPopup} />}
    </div>
  );
};

export default ItemsDetail;
