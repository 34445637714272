import LoginContainer from "../containers/Login";
import DashboardContainer from "../containers/Dashboard";
import ConfiguratorContainer from "../containers/Configurator";
import AreaFtpContainer from "../containers/AreaFtp";
import AreaTrainingContainer from "../containers/AreaTraining";
import AreaAssistenzaContainer from "../containers/AreaAssistenza";
import OrderReceivedContainer from "../containers/OrderReceived";
import OrderReceivedDetailContainer from "../containers/OrderReceivedDetail";
import BestNewsContainer from "../containers/BestNews";
import NewsDetail from "../containers/NewsDetail";
import CheckoutPageContainer from "../containers/CheckoutPage";
import UserManagementContainer from "../containers/UserManagement";
import TicketContainer from "../containers/Ticket";
import ProductListContainer from "../containers/ProductList";
import ConfigSearch from "../containers/ConfigSearch";
import PlatformSelectionContainer from "../containers/PlatformSelectionContainer";
import ForgotPasswordContainer from "../containers/ForgotPassword";
import ResetPasswordContainer from "../containers/ResetPassword";
import DealerSelectionContainer from "../containers/DealerSelectionContainer";
import OrderBackorder from "../containers/OrderBackorder";
import RegisterBike from "../containers/RegisterBike";
import SupportDealerOrder from "../containers/SupportDealerOrder";
import DealerRegisterBike from "../containers/DealerRegisterBike";
import ConfiguratorList from "../containers/ConfiguratorList";
import TermsCondition from "../containers/TermsCondtion";
import PrivacyPolicy from "../containers/PrivacyPolicy";
import DealersShop from "../containers/DealersShop";
import ProductStock from "../containers/ProductStock";

const routes = [
  // {
  //   path: "/",
  //   exact: true,
  //   isProtected: false,
  //   component: LoginContainer,
  // },
  {
    path: "/login",
    exact: true,
    isProtected: false,
    component: LoginContainer,
  },
  {
    path: "/dashboard",
    exact: true,
    isProtected: true,
    component: DashboardContainer,
  },
  {
    path:
      "/(download-area|area-download|download-bereich|zone-telechargement|area-de-descargas)",
    exact: true,
    isProtected: true,
    component: AreaFtpContainer,
  },
  {
    path:
      "/(area-training|training-area|zone-entrainement|trainingsbereich|espace-formation|entrenamiento-area)",
    exact: true,
    isProtected: true,
    component: AreaTrainingContainer,
  },
  {
    path:
      "/(your-orders|i-tuoi-ordini|deine-bestellungen|vos-commandes|tus-pedidos)/:id",
    exact: true,
    isProtected: true,
    component: OrderReceivedDetailContainer,
  },
  {
    path:
      "/(your-orders|i-tuoi-ordini|deine-bestellungen|vos-commandes|tus-pedidos)",
    exact: true,
    isProtected: true,
    component: OrderReceivedContainer,
  },
  {
    path: "/(news|nouvelles|noticias|notizie|nachrichten)",
    exact: true,
    isProtected: true,
    component: BestNewsContainer,
  },
  {
    path: "/(news|notizie|vouvelles|noticias|nachrichten)/:id",
    exact: true,
    isProtected: true,
    component: NewsDetail,
  },
  {
    path: "/(checkout|kasse|commander|pagar)",
    exact: true,
    isProtected: true,
    component: CheckoutPageContainer,
  },
  {
    path:
      "/(account-data|dati-account|kontodaten|datos-cuenta|donnees-de-compte)",
    exact: true,
    isProtected: true,
    component: UserManagementContainer,
  },
  {
    path:
      "/(support-area|area-assistenza|area-supporto|zone-assistance|soporte-area|area-soporte|support-bereich)",
    exact: true,
    isProtected: true,
    component: TicketContainer,
  },
  {
    path:
      "/(new-equipment-order|nuovo-ordine-attrezzatura|nouvelle-commande-de-materiel|nuevo-pedido-equipo|neue-ausrustung-bestellen|ordini-nuova-attrezzatura)",
    exact: true,
    isProtected: true,
    component: ProductListContainer,
  },
  {
    path:
      "/(upload-configuration|upload-configurazioni|telecharger-la-configuration|upload-configuraciones|upload-konfiguration)",
    exact: true,
    isProtected: true,
    component: ConfigSearch,
  },
  {
    path:
      "/(switch-brand|cambia-brand|changer-la-marque|cambiar-marca|schaltermarke)",
    exact: true,
    isProtected: true,
    component: PlatformSelectionContainer,
  },

  {
    path: "/(configura|configure|konfigurieren|configurer|configurar)",
    exact: true,
    isProtected: true,
    component: ConfiguratorContainer,
  },
  {
    path:
      "/(confirmed-orders|ordini-confermati|ordini-passati|bestatigte-bestellungen|commandes-confirmees|pedidos-confirmados)",
    exact: true,
    isProtected: true,
    component: OrderBackorder,
  },
  {
    path:
      "/(localizzatore-negozi|shop-selector|selecteur-de-magasin|selector-tienda|shop-selektor)",
    exact: true,
    isProtected: true,
    component: DealerSelectionContainer,
  },
  {
    path:
      "/(reimposta-password|reset-password|passwort-zurucksetzen|reinitialiser-mot-de-passe|restablecer-contrasena)",
    exact: true,
    isProtected: false,
    component: ForgotPasswordContainer,
  },
  {
    path:
      "/(aggiorna-password|actualiza-password|mettre-a-jour-le-password|password-aktualisieren|update-password|new-password|nuova-password|neues-passwort|nouveau-mot-de-passe|nueva-contrasena)",
    exact: true,
    isProtected: false,
    component: ResetPasswordContainer,
  },
  {
    path: "/support-dealer-order",
    exact: true,
    isProtected: true,
    component: SupportDealerOrder,
  },
  {
    path:
      "/(bici-registrate-cliente|registrar-bicicleta-cliente|register-customer-bike|registrazione-bicicletta|kundenrad-registrieren|enregistrer-le-velo-dun-client)",
    exact: true,
    isProtected: true,
    component: DealerRegisterBike,
  },
  {
    path:
      "/(vos-configurations|lista-configurazioni|ihre-konfigurationen|sus-configuraciones|le-tue-configurazioni|your-configurations)",
    exact: true,
    isProtected: true,
    component: ConfiguratorList,
  },
  {
    path: "/(privacy-policy|datenschutz|confidentialite-et-politique|privacy)",
    exact: true,
    isProtected: true,
    component: PrivacyPolicy,
  },
  {
    path:
      "/(terms-conditions|termini-condizioni|geschaftsbedingungen|termes-et-conditions|terminos-condiciones)",
    exact: true,
    isProtected: true,
    component: TermsCondition,
  },
  {
    path: "/(dealers-shop)",
    exact: true,
    isProtected: true,
    component: DealersShop,
  },
  {
    path:
      "/(products-in-stock|merce-a-stock|versandfertige-produkte|productos-listos-para-entrega|produits-prets-a-etre-livres)",
    exact: true,
    isProtected: true,
    component: ProductStock,
  },
];

export default routes;
