import React, { useState } from "react";
import { Link } from "react-router-dom";
import i18next from 'i18next';
import { i18n } from "../../translations/i18n";

const Footer = () => {


    return (
        <>
            <footer>
                <div className="wrap">
                    <div className="footer-wrapper">
                        <div className="footer-info">
                            <p>{i18next.t('Footer.Coopyright')}</p>
                        </div>
                        <div className="footer-link">
                            <nav>
                                <ul>
                                    <li>
                                        <Link to={"/" + i18n.language + "/" + i18next.t('routes.TermsConditions')}>
                                        {i18next.t('Footer.TermsConditions')}
                                        </Link>
                                    </li>
                                    <li>
                                    <Link to={"/" + i18n.language + "/" + i18next.t ('routes.PrivacyPolicy')}>
                                            {i18next.t('Footer.PrivacyPolicy')}
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
