import {
  FETCH_CONFIGURATOR_DETAILS_PENDING,
  FETCH_CONFIGURATOR_DETAILS_SUCCESS,
  FETCH_CONFIGURATOR_DETAILS_FAILURE,
  DOWNLOAD_CONFIGURATOR_PDF_PENDING,
  DOWNLOAD_CONFIGURATOR_PDF_SUCCESS,
  DOWNLOAD_CONFIGURATOR_PDF_FAILURE,
  CONFIGURATOR_SUMMARY_PENDING,
  CONFIGURATOR_SUMMARY_SUCCESS,
  CONFIGURATOR_SUMMARY_FAILURE,
  CONFIGURATOR_LIST_PENDING,
  CONFIGURATOR_LIST_SUCCESS,
  CONFIGURATOR_LIST_FAILURE,
  GET_GROUPSET_FE_PENDING,
  GET_GROUPSET_FE_SUCCESS,
  GET_GROUPSET_FE_FAILURE,
  RESET_CONFIGURATOR_DETAILS
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  message: "",
  configuratorDetail: {},
  isAddedTocart: false,
  cofiguratorSummary: {},
  isSummaryLoading: false,
  isPdfLoading: false,
  configuratorListLoading: false,
  configuratorList: {},
  totalConfigurators: 0,
  groupsetList: [],
  isGropsetListError: false,
  isGroupsetListLoading: false,
  cofiguratorPDF: {},
};

export default function ConfiguratorReducer(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case RESET_CONFIGURATOR_DETAILS:
      return Object.assign({}, state, {
        configuratorDetail: {}
      });
    case FETCH_CONFIGURATOR_DETAILS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        cofiguratorPDF: {},
      });
    case FETCH_CONFIGURATOR_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        configuratorDetail: action.data,
        isLoading: false,
      });
    case FETCH_CONFIGURATOR_DETAILS_FAILURE:
      return Object.assign({}, state, {
        errorDetails: action.data,
        isLoading: true,
      });
    case DOWNLOAD_CONFIGURATOR_PDF_PENDING:
      return Object.assign({}, state, {
        isPdfLoading: true,
        cofiguratorPDF: {},
      });
    case DOWNLOAD_CONFIGURATOR_PDF_SUCCESS:
      return Object.assign({}, state, {
        cofiguratorPDF: action.data,
        isPdfLoading: false,
      });
    case DOWNLOAD_CONFIGURATOR_PDF_FAILURE:
      return Object.assign({}, state, {
        message: action.data,
        isPdfLoading: true,
      });
    case CONFIGURATOR_SUMMARY_PENDING:
      return Object.assign({}, state, {
        isSummaryLoading: true,
      });
    case CONFIGURATOR_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        cofiguratorSummary: action.data,
        isSummaryLoading: false,
      });
    case CONFIGURATOR_SUMMARY_FAILURE:
      return Object.assign({}, state, {
        message: action.data,
        isSummaryLoading: true,
      });
    case CONFIGURATOR_LIST_PENDING:
      return Object.assign({}, state, {
        configuratorListLoading: true,
        cofiguratorPDF: {},
      });
    case CONFIGURATOR_LIST_SUCCESS:
      return Object.assign({}, state, {
        configuratorList: action.data.configurators,
        configuratorListLoading: false,
        totalConfigurators: action.data.total_configurators,
      });
    case CONFIGURATOR_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.message,
        configuratorListLoading: true,
      });
    case GET_GROUPSET_FE_PENDING:
      return Object.assign({}, state, {
        isGroupsetListLoading: true,
        isGropsetListError: false,
      });
    case GET_GROUPSET_FE_SUCCESS:
      return Object.assign({}, state, {
        groupsetList: action.data.data,
        isGroupsetListLoading: false,
      });
    case GET_GROUPSET_FE_FAILURE:
      return Object.assign({}, state, {
        message: action.message,
        isGropsetListError: true,
        isGroupsetListLoading: false,
      });
    default:
      return state;
  }
}
