import React from "react";

function DashboardAnalyticIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.698"
      height="24.271"
      viewBox="0 0 26.698 24.271"
    >
      <g transform="translate(-24 281.087)">
        <g data-name="Group 7" transform="translate(18 -1243.449)">
          <path
            fill="#f2b239"
            d="M19.349 10A13.358 13.358 0 006 23.349a13.67 13.67 0 004.5 10.315l.578.474a.591.591 0 00.379.133.6.6 0 00.408-.152 3.251 3.251 0 00.341-.455 9.251 9.251 0 017.139-3.641 9.251 9.251 0 017.139 3.641 3.25 3.25 0 00.341.455.6.6 0 00.408.152.591.591 0 00.379-.133l.578-.474a13.67 13.67 0 004.5-10.315A13.358 13.358 0 0019.349 10zm0 1.214a12.126 12.126 0 0112.135 12.135 12.452 12.452 0 01-4.191 9.462 10.333 10.333 0 00-7.945-4 10.333 10.333 0 00-7.945 4 12.452 12.452 0 01-4.191-9.462 12.126 12.126 0 0112.137-12.135zm6.428 5.086a.614.614 0 00-.417.19l-4.124 4.11a3.3 3.3 0 10.863.853l4.115-4.115a.613.613 0 00.142-.664.634.634 0 00-.579-.374zm-6.428 4.93a2.124 2.124 0 11-2.124 2.124 2.115 2.115 0 012.124-2.129z"
            data-name="Path 33"
            transform="translate(0 952.362)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default DashboardAnalyticIcon;
