export const TRANSLATIONS_FR = {
  routes: {
    Dashboard: "dashboard",
    Configuration: "configurer",
    ConfigurationsSaved: "vos-configurations",
    ConfigurationsSearch: "telecharger-la-configuration",
    NewOrder: "nouvelle-commande-de-materiel",
    YourOrders: "vos-commandes",
    backOrders: "commandes-confirmees",
    RegisterBike: "enregistrer-le-velo-dun-client",
    SupportArea: "zone-assistance",
    TrainingArea: "zone-entrainement",
    FTPArea: "zone-telechargement",
    B2BNews: "nouvelles",
    AccountUser: "donnees-de-compte",
    SwitchBrand: "changer-la-marque",
    ShopSelectorAgent: "selecteur-de-magasin",
    Checkout: "checkout",
    Login: "login",
    ResetPassword: "reinitialiser-mot-de-passe",
    NewPassword: "nouveau-mot-de-passe",
    PrivacyPolicy: "politique-de-privacy",
    TermsConditions: "termes-et-conditions",
    BassoFrontend: "https://bassobikes.com",
    LeeCouganFrontend: "https://www.leecougan.com",
    StockProduct:"produits-prets-a-etre-livres",
  },
  PageTitle: {
    Dashboard: "Dashboard | Stardue B2B",
    Configurator: "Configurer | Stardue B2B",
    ConfigurationsSearch: "Télécharger la configuration | Stardue B2B",
    ConfigurationsSaved: "Vos configurations | Stardue B2B",
    NewOrder: "Nouvelle commande de matériel | Stardue B2B",
    YourOrders: "{{orderId}} Vos commandes | Stardue B2B",
    OrderList: "Vos commandes | Stardue B2B",
    BackOrders: "Commandes confirmées | Stardue B2B",
    RegisterBike: "Enregistrer le vélo d'un client | Stardue B2B",
    SupportArea: "Zone d'assistance | Stardue B2B",
    B2BNews: "Nouvelles | Stardue B2B",
    TrainingArea: "Zone d'entraînement | Stardue B2B",
    FTPArea: "Zone de téléchargement | Stardue B2B",
    AccountUser: "Données de compte | Stardue B2B",
    SwitchBrand: "Changer la marque | Stardue B2B",
    ShopSelectorAgent: "Sélecteur de magasin | Stardue B2B",
    Checkout: "Checkout | Stardue B2B",
    Login: "Login | Stardue B2B",
    ResetPassword: "Réinitialiser le mot de passe | Stardue B2B",
    NewPassword: "Nouveau mot de passe | Stardue B2B",
    PrivacyPolicy: "Politique de privacy | Stardue B2B",
    TermsConditions: "Termes et conditions | Stardue B2B",
    ProductsStock: "Produits prêts à être livrés | Stardue B2B",
  },
  Footer: {
    Coopyright: "© 2022 - STARDUE S.R.L. - P.IVA / C.F. 02109950267",
    TermsConditions: "Termes et conditions",
    PrivacyPolicy: "Politique de privacy",
  },
  Header: {
    Option: "Sélectionner une option",
    BikesGroup: {
      Bikes: "Vélos",
      ProductsStock: "Produits prêts à être livrés",
      ConfigurationsSaved: "Mes configurations",
      ConfigurationsSearch: "Trouver la configuration client",
      Configuration: "Créer une nouvelle configuration",
    },
    Equipments: "Équipement",
    OrdersGroup: {
      Orders: "Commandes",
      OrdersWaiting: "En attente de confirmation",
      BackOrders: "Confirmées",
    },
    ServicesGroup: {
      Services: "Services",
      RegisterBike: "Enregistrer le nouveau vélo du client",
      SupportArea: "Zone d'assistance",
      FTPArea: "Zone de téléchargement",
      TrainingArea: "Zone d'entraînement",
      B2BNews: "Dernières nouvelles",
    },
    UserGroup: {
      YourData: "Vos données",
      Logout: "Logout",
      ChangePlatform: "Changer la marque",
      ChangeShop: "",
    },
  },
  Dashboard: {
    ftpArea: "Zone de téléchargement",
    ftpAreaText:
      "Connectez-vous à votre espace de téléchargement pour télécharger un contenu réservé.",
    ticketArea: "Zone d'assistance",
    ticketAreaText: "",
    trainingArea: "Zone d'entraînement",
    trainingAreaText:
      "Découvrez tous les tutos vidéos réservés à nos revendeurs.",
    warranty: "Enregistrer le nouveau vélo du client",
    warrantyText: "",
    ticketArea: "Zone d'assistance",
    ticketAreaText: "",
    configuration: "Créer une nouvelle configuration",
    configurationText: "",
    configurationSaved: "Vos configurations",
    configurationSavedText: "",
    equipmentSearch: "Trouver de l'équipement",
    searchPlaceholder: "SKU ou nom du produit",
    order: "Commandes confirmées",
    orderText:"Suivez la progression de vos commandes confirmées",
    newsArea: "Dernières nouvelles",
    newsAreaText:
      "Les dernières nouvelles de notre monde afin que vous puissiez être le premier à savoir.",
    webSite: "Site Web",
    webSiteText: "Visitez le site Web B2C",
    button: "Enter",
    goToWebsite: "Aller sur un site Web",
    ProductStock:"Produits prêts à être livrés",
    ProductStockText: "Produits dans notre entrepôt prêts à être expédiés.",
  },
  Common: {
    ServiceNotAvailable: "Données non disponibles en raison de la migration de l'ERP. Nous sommes désolés pour la gêne occasionnée. Le service sera bientôt de retour.",
    NoMobileView:
      "Le tableau de bord n'est pas disponible sur l'écran mobile. Veuillez continuer à utiliser votre ordinateur portable.",
    PasswordMatchError:
      "Les mots de passe que vous avez entrés ne correspondent pas.",
    hi: "Bonjour",
    Back: "Arrière",
    Logout: "Logout",
    Address: "Adresse",
    Contacts: "Contacts",
    FiscalCode: "Code fiscal",
    AddNewAddress: "Ajouter une nouvelle adresse",
    NewAddress: "Nouvelle adresse",
    RequiredFields:
      "Tous les champs marqués d'un astérisque* sont obligatoires.",
    Confirm: "Confirmer",
    Nominative: "Nominatif",
    okText: "Oui",
    CancelText: "Non",
    Proceed: "Procéder",
    DiscoverButton: "Découvrir",
    FiltersSidebar: {
      Title: "Filtre",
      Submit: "Appliquer",
      Reset: "Supprimer les filtres",
      All1: "Tout",
      All2: "Tout",
      Model: "Modèle",
      Groupset: "Groupe",
      idOrder: "ID commande",
      IDConfiguration: "ID configuration",
      SKU: "Rechercher SKU ou nom de produit",
      SKUADMIN: "Cerca per SKU, nome prodotto, per nome o codice rivenditore",
      Category: "Choisissez une catégorie",
      RelatedModel: "Trouvez des pièces de rechange liées à:",
      State: "Filtrer par statut",
      FromDate: "De",
      ToDate: "À",
      SerialNumber: "Numéro de série",
      ChooseOption: "Sélectionnez l'option",
      RegisterNewBike: "Enregistrer un nouveau vélo",
      OpenTicket: "Ouvrir un nouveau ticket",
      Ready_to_ship: 'Prêt pour l\'expédition',
      order_confirmed: 'Commande confirmée',
      in_production: 'En production',
      on_hold: 'En attente',
    },
    Pagination: {
      Prev: "Précédent",
      Next: "Suivant",
    },
    ConfirmationMessage: {
      Products: {
        Title: "Produit ajouté à votre'commande",
        Label: "Merci, le produit a été ajouté à votre commande",
      },
    },
    Form: {
      SelectOption: "Choisir",
      companyname: "Raison social",
      requireField: "Ce champ est requis. Entrer un nombre, SVP.",
      name: "",
      PublicEmail: "Nom",
      require_name: "Veuillez insérer votre prénom.",
      surname: "Nom de famille",
      require_surname: "Veuillez insérer votre nom de famille.",
      address: "Adresse",
      country: "Pays",
      require_country: "Veuillez insérer votre pays.",
      city: "Ville",
      require_city: "Veuillez insérer votre ville.",
      province: "Province",
      require_province: "Veuillez insérer la province.",
      street: "Rue et numéro",
      require_street: "Veuillez insérer votre nom et numéro de rue.",
      postalcode: "Code Postal",
      require_postalcode: "Veuillez entrer le code postal.",
      contact: "Informations de contact",
      phone: "Téléphoner",
      require_phone: "Veuillez entrer votre numéro de téléphone.",
      defaultAddressSettings: "Paramètres",
      is_default_address: "Marquez cette adresse par défaut.",
      edit: "Éditer",
      email: "E-mail",
      valid_email:
        "Cette adresse e-mail n'est pas valide, veuillez entrer une adresse e-mail valide.",
      require_email: "Veuillez insérer votre e-mail",
      first_name: "Prénom",
      require_first_name: "Veuillez insérer votre prénom",
      last_name: "Nom de famille",
      require_last_name: "Veuillez insérer votre nom de famille",
      password: "Mot de passe",
      require_password: "Veuillez insérer votre mot de passe",
      confirmPassword: "Confirmez le mot de passe",
      require_confirmPassword: "Veuillez confirmer votre mot de passe",
      model: "Modèle",
      serial_number: "Numéro de série",
      require_serial_number: "Veuillez ajouter votre numéro de série",
      purchase_date: "Date d'achat",
      proof_purchase: "Preuve d'achat",
      store: "Revendeur",
      require_store:
        "Veuillez utiliser ce champ pour sélectionner votre revendeur",
      save: "Sauver",
      sendrequest: "Envoyer une demande",
      otherInformation: "Autres informations",
      generic_required:
        "Ce champ est requis. Veuillez ajouter les informations demandées.",
      couopon: "Coupon",
      request: "Demande",
      query: "Informations Complémentaires",
      topic: "Sujet",
      Update: "Mise à jour",
    },
    Bikes: {
      SummaryBike: {
        ConfigurationID: "ID configuration",
        Sku: "Sku",
        Groupset: "Groupe",
        Size: "Taille",
        Wheels: "Roues",
        Color: "Couleur",
      },
    },
  },
  Pages: {
    ChangeBrand: {
      RequestAccess: "Demande d'accès",
      Form: {
        Title: "Demande d'accès",
        Label: "Décrivez votre demande",
        Submit: "Envoyer une demande"
      }
    },
    Login: {
      Title: "Bienvenu",
      Subtitle: "Connectez-vous à votre dashboard dédié",
      Email: "Votre e-mail",
      Password: "Votre mot de passe",
      Submit: "Login",
      LostPassword: "Avez-vous oublié votre mot de passe?",
    },
    ForgotPassword: {
      Title: "Avez-vous oublié votre mot de passe?",
      Subtitle: "Réinitialisez-le maintenant et login",
      Email: "Votre e-mail",
      Submit: "Procéder",
    },
    NewPassword: {
      Title: "Mot de passe oublié?",
      Subtitle: "Réinitialisez-le maintenant et login",
      Email: "Votre e-mail",
      Password: "Nouveau mot de passe",
      ConfirmPassword: "Confirmer votre mot de passe",
      Message: "",
      Submit: "Confirmer",
    },
    AgentPage: {
      Loader: "Nous chargeons vos clients",
      Title: "Login au nom d'un client.",
      NoClient: "Aucun client associé. Veuillez contacter notre assistance.",
    },
    UserDetails: {
      YourData: "Votre profil",
      Anagraphic: "Votre ",
      Company: "Raison social",
      VAT: "Numéro de TVA",
      EmailAccount: "Compte e-mail",
      StoreLocatorTitle: "Informations sur le localisateur",
      TimeTable: {
        ShopTime: "Heures d'ouverture",
        monday: "Lundi",
        tuesday: "Mardi",
        wednesday: "Mercredi",
        thursday: "Jeudi",
        friday: "Vendredi",
        saturday: "Samedi",
        sunday: "Dimanche",
        morning: "Matin",
        afternoon: "Après-midi",
        start: "Démarrer",
        end: "Finir",
        closed: "Fermé",
        nonStop: "non-stop",
      },
    },
    Configurator: {
      PageTitle: "Configurateur de vélo",
      ConfigureNow: "Configurez le vôtre",
      LoaderLabel: "Nous chargeons le configurateur.",
      NotSelected: "Non séléctionné",
      AvailableFrom: "De",
      Quantity: "Quantité",
      Geometry: "Géométrie",
      OutOfStock: "En rupture de stock",
      Type: {
        Bike: "Le vélo Basso complet prêt à rouler.",
        FrameKit:
          "Le cadre, la fourche et tous les composants spécifiques Basso.",
        BuildKit: "Kit cadre et tous les composants sans groupe.",
      },
      Step: {
        Header: {
          Categories: "Catégorie",
          Models: "Modèle",
          Type: "Type",
          Wheels: "Roues",
          Color: "Couleur",
          Size: "Taille",
          Groupset: "Groupe",
          Options: "Choix",
          Quantity: "Quantité",
        },
        Price: {
          Included: "Inclus",
          Upgrade: "Améliorer sélectionné",
        },
      },
      BottomBar: {
        ProceedToCart: "Ajouter au panier",
        FindYourDealer: "Trouvez votre revendeur",
        Save: "Sauver",
        DownloadPDF: "Télécharger le PDF",
        YourPrice: "Prix réservé",
        PublicPrice: "Prix de détail suggéré",
        DiscountLabel: "Votre markup",
      },
      ConfiguratorSummary: {
        Title: "Résumé de la configuration",
        FrameSet: "Ensemble de cadre",
        Frame: "Cadre",
        Fork: "Fourchette",
        SeatPostClamp: "Collier de selle",
        ThruAxleFornt: "Axe traversant avant",
        ThruAxleRear: "Axe traversant arrière",
        Hanger: "Pattes de dérailleur",
        Headset: "Jeu de direction",
        Groupset: "Groupe",
        Crank: "Pédalier",
        CrankArm: "Manivelles",
        ChainRings: "Couronnes",
        Chain: "Chaîne",
        BottomBraket: "Support inférieur",
        Cassette: "Cassette",
        CassetteGears: "Rapports de cassette",
        FrontDerailleur: "Dérailleur avant",
        RearDerailleur: "Dérailleur arrière",
        Shifters: "Manettes",
        Brakes: "Freins",
        Rotors: "Disques de frein",
        Cockpit: "Cockpit",
        SeatpostAndSaddle: "Tige de selle et selle",
        WheelsAndTires: "Roues et pneus",
        Wheels: "Roues",
        Upgrade: "Améliorer",
        HeadUnitMount: "Head unit mount",
        HandlebarTap: "Handlebar tape",
        PowerUnit: "Power Unit",
        Motor: "Moteur",
        Display: "Display",
        Battery: "Batterie",
        Other: "Autre",
        Editing: "Continuez à éditer",
        DownloadPDF: "Télécharger le PDF",
        ConfirmAndProceed: "Passer au panier",
      },
      SaveConfigurator: {
        Popup: {
          Loader: "Nous enregistrons votre configuration...",
          Header: {
            Title: "Enregistrez votre configuration",
            Subtitle: "Félicitations, votre configuration a été enregistrée.",
          },
          DiscoverButton: "Découvrir",
        },
      },
      AddToCart: {
        Popup: {
          Loader: "Nous ajoutons la configuration au panier...",
          Header: {
            Title: "Configuration ajoutée",
            Subtitle:
              "Félicitations, votre configuration a été ajoutée au panier.",
          },
        },
      },
      DowloadPDF: {
        Popup: {
          Loader: "Nous préparons le PDF de votre configuration...",
          Header: {
            Title: "Télécharger le PDF",
            Subtitle: "Félicitations, votre PDF a été généré.",
          },
        },
      },
      SummarySmall: {
        Title: "Résumé",
        Categories: "Catégorie",
        Models: "Modèle",
        Type: "Type",
        Wheels: "Roues",
        Color: "Couleur",
        Size: "Taille",
        Groupset: "Groupe",
      },
      PreviewImage: {
        Title: "Prévisualisation",
        LargeImage: "Cliquez pour agrandir l'image",
      },
    },
    ConfigurationSaved: {
      PageTitle: "Configurations enregistrées",
      Loader: "Nous chargeons vos configurations...",
      Table: {
        ID: "ID",
        Model: "Modèle",
        Groupset: "Groupe",
        Size: "Taille",
        Color: "Couleur",
        Wheels: "Roues",
        Price: "Prix",
        Edit: "Éditer",
        Summary: "Résumé",
        DownloadPDF: "Télécharger le PDF",
        OrderDetails: "Voir l'ordre",
        Delete: "Supprimer",
      },
    },
    SearchConfiguration: {
      Title: "Rechercher la configuration sortante",
      Info:
        "Insérez un code d'identification de configuration pour rappeler une configuration existante. L'ID de configuration se trouve sur la page \"Vos configurations\" sur les sites Web B2B et B2C et sur tous les documents de configuration PDF.",
      Search: "Rechercher maintenant",
      ConfigCode: "Insérer le code d'identification de configuration",
      Loader: "Nous recherchons la configuration demandée...",
      Table: {
        Edit: "Éditer",
        Summary: "Résumé",
        DownloadPDF: "Télécharger le PDF",
        OrderDetails: "Voir l'ordre",
      },
    },
    Address: {
      Header: {
        Title: "Vos adresses",
        Subtitle: "Gérez vos adresses de livraison et de facturation.",
      },
      ShippingAddress: {
        Label: "Adresse de livraison",
      },
      BillingAddress: {
        Label: "Adresse de facturation",
      },
    },
    Equipments: {
      PageTitle: "Online Commande",
      Loader: "Nous téléchargeons les produits",
      NotFound: "Aucun produit trouvé. Veuillez utiliser un autre mot-clé.",
      Table: {
        Image: "Image",
        SKU: "SKU",
        Name: "Nom",
        Categories: "Catégorie",
        Stock: "Stock",
        Price: "Prix",
        OrderNow: "Ajouter à la commande",
      },
      Details: {
        RecomandedPrice: "Prix de détail suggéré",
        YourDiscount: "Votre markup",
        Description: "Description",
        Features: "Caractéristiques",
        BuyNow: "Ajouter à ma commande",
        ConfirmationAddToCart: "",
      },
    },
    Orders: {
      PageTitle: "Vos commandes",
      Loader: "Nous chargeons vos commandes...",
      Table: {
        OrderNumber: "ID",
        OrderDate: "Date de commande",
        ExpectiveDate: "Livraison prévue",
        Destination: "Livré à",
        State: "Status",
        Products: "Produits",
        ProductIncluded: "Produits",
        Total: "Total",
        Details: "Détails",
        ChildOrderLabel: "Commandes associées",
      },
      Single: {
        Order: "Commande",
        EstimatedDate: "Date estimée",
        NotAvailableEstimateDate: "Indisponible",
        OpenTicket: "Ouvrir un ticket",
        OrderDate: "Date de commande",
        ShipmentTo: "Envoi à",
        OrderStatus: "Status",
        DownloadReceipt: "Télécharger le reçu",
        ReturnOrder: "Retour de commande",
        ReturnRequests: "Demande de retour",
        ReturnRequestsInfo: "Votre demande a été soumise avec succès",
        OrderSummary: "Récapitulatif de la commande",
        Sku: "Sku",
        Groupset: "Groupe",
        ConfiguratorID: "ID onfigurator",
        Size: "Taille",
        Color: "Couleur",
        Wheels: "Roues",
        DownloarConf: "Téléchargez votre configuration",
        Quantity: "Quantité",
        Price: "Prix",
        Subtotal: "Total",
        Shipment: "Expédition",
        PaymentMethod: "Mode de paiement",
        Total: "Total",
        BillingInformation: "Détails de facturation",
        ShippingData: "Données d'expédition",
        FiscalCode: "Code fiscal",
        IncludeOption: "Options incluses",
        Upgrade: "Améliorer sélectionné",
        Bacs: "Comme convenu avec Stardue Srl",
      },
      TicketOrder: {
        Common: {
          Title: "Commande {{orderId}} - Ticket d'assistance",
          Request: "Demande",
          Order: "Commande",
          StepTitle1: "Demande",
          StepTitle2: "Produits",
          StepTitle3: "Dis nous en plus",
          StepTitle4: "Confirmation",
          Next: "Suivant",
          Proceed: "Procéder",
          Back: "Arrière",
          SuccessTitle: "Votre demande a été soumise avec succès.",
          SuccessSubtitle:
            "Vous serez contacté par l'équipe de Basso Bikes dans les prochaines 24/48 heures.",
          SuccessButton: "Retour à la commande",
          FileUploadTitle: "Joindre un fichier",
          FileUploadContent:
            "Importez un fichier: .pdf, .png et .jpeg sont acceptés. Taille de fichier maximale: 10MB",
        },
        Step1: {
          Content: "Sélectionnez le sujet de votre demande",
          ReturnReplacement: "Remplacement / Retour",
          Tracking: "Information de suivi",
        },
        Step2: {
          Content: "Sélectionnez le produit qui pose problème :",
        },
        Step3ReturnProducts: {
          Content:
            "Quel genre de problèmes avez-vous rencontré? Aidez le personnel de Basso à comprendre ce qui n'a pas fonctionné.",
        },
        Step3Tracking: {
          Content:
            "Quel genre de problèmes avez-vous rencontré? Aidez le personnel de Basso à comprendre ce qui n'a pas fonctionné.",
        },
        Step4ReturnProducts: {
          Content:
            "Si vous choisissez de confirmer, votre demande de remplacement/retour sera prise en charge par l'équipe Basso Bikes qui vous contactera dans les plus brefs délais.",
          Title:
            "Voulez-vous vraiment ouvrir une demande de remplacement/retour pour ces produits ?",
        },
        Step4Tracking: {
          Content:
            "Si vous choisissez de confirmer, votre demande de remplacement/retour sera prise en charge par l'équipe Basso Bikes qui vous contactera dans les plus brefs délais.",
          Title: "Êtes-vous sûr de vouloir remplacer/retourner les produits ?",
        },
        Step5: {
          Content:
            "Vous serez contacté par l'équipe de Basso Bikes dans les prochaines 24/48 heures.",
          Title: "Votre demande a été soumise avec succès.",
        },
      },
    },
    BackOrders: {
      Title: "Commandes confirmées",
      Loader: "Nous chargeons vos commandes...",
      Table: {
        OrderNumber: "Commande n°",
        NumberRow: "Numéro de ligne",
        OrderDate: "Date de commande",
        ExpectiveDate: "Livraison prévue",
        Client: "Client",
        Destin: "Destination",
        CodArt: "SKU",
        Groupset: "Groupe",
        TG: "Taille",
        ArtDescription: "Description",
        ClientData: "Commande date demandée",
        QRes: "Quantité",
        State: "Status",
        Note: "Noter",
      },
    },
    ProductsStock: {
      Title: "Produits prêts à être livrés",
      Loader: "Nous chargeons vos commandes...",
      Table: {
        CodArt: "SKU",
        Groupset: "Groupe",
        TG: "Taille",
        ArtDescription: "Description",
        QRes: "Quantité",
      },
    },
    RegisterBike: {
      PageTitle: "Enregistrer un nouveau vélo",
      Loader: "Nous chargeons vos vélos enregistrés...",
      Table: {
        Client: "Client",
        Email: "E-mail",
        PurchaseDate: "Date d'achat",
        Model: "Modèle",
        SerialNumber: "Numéro de série",
        ProofOfPurchase: "Preuve d'achat",
      },
      FormPage: {
        Header: {
          Title: "Enregistrer un nouveau vélo",
        },
        Form: {
          MessageUserNotFound:
            "Il n'y a pas de compte associé à cet e-mail. Entrez votre nom et prénom pour en créer un.",
          MessageUserFound:
            "Cet utilisateur est déjà enregistré dans notre base de données. Vous pouvez poursuivre l'enregistrement.",
          EmailNotValid: "L'e-mail saisi n'est pas valide.",
          Email: "E-mail",
          Name: "Nom",
          Surname: "Nom de famille",
          TitleStep1: "Données client",
          TitleStep2: "Données du vélo",
          TitleStep3: "Résumé",
          TitleStepInner1: "Saisissez les coordonnées du propriétaire.",
          TitleStepInner2: "Insérez les informations demandées du vélo",
          requireField: "Ce champ est requis. Entrer un nombre, SVP.",
          Shop: "Magasin",
          SerialNumber: "Where to find the serial number?",
          SelectModel: "Select your model",
          PurchaseDate: "Date d'achat",
          Model: "Modèle",
          SerialNumber: "Numéro de série",
          ProofOfPurchase: "Preuve d'achat",
          importFIle:
            "Importer un fichier avec la facture du vélo : .pdf, .png et .jpeg sont acceptés. Taille de fichier maximale : 10 MB",
        },
      },
      ListPage: {
        Header: {
          Title: "Vos vélos enregistrés",
          Subtitle: "Consultez vos vélos enregistrés ou ajoutez-en un nouveau.",
        },
        Form: {
          MessageUserNotFound:
            "Il n'y a pas de compte associé à cet e-mail. Entrez votre nom et prénom pour en créer un.",
          MessageUserFound:
            "Cet utilisateur est déjà enregistré dans notre base de données. Vous pouvez poursuivre l'enregistrement.",
          EmailNotValid: "L'e-mail saisi n'est pas valide.",
          Email: "E-ail",
          Name: "Nom",
          Surname: "Nom de famille",
          TitleStep1: "Données client",
          TitleStep2: "Données du vélo",
          TitleStep3: "Résumé",
          TitleStepInner1: "Saisissez les coordonnées du propriétaire.",
          TitleStepInner2: "Insérez les informations demandées du vélo",
          requireField: "This field is required. Please enter a value.",
          Shop: "Magasin",
          SerialNumber: "Where to find the serial number?",
          SelectModel: "Select your model",
          PurchaseDate: "Date d'achat",
          Model: "Modèle",
          SerialNumber: "Numéro de série",
          ProofOfPurchase: "Preuve d'achat",
          importFIle:
            "Importer un fichier avec la facture du vélo : .pdf, .png et .jpeg sont acceptés. Taille de fichier maximale : 10 MB",
        },
      },
    },
    SupportArea: {
      PageTitle: "Zone d'assistance",
      Loader: "Nous chargeons vos tickets ...",
      Table: {
        ID: "ID",
        State: "Status",
        Topic: "Sujet",
        Description: "Description",
        File: "Enceinte",
        Data: "Date",
        Product: "Produits",
        YourRequest: "Votre demande",
      },
      Popup: {
        Common: {
          Title: "Ouvrir une demande d'assistance",
          Step1: "Demande",
          Step2: "Sujet",
          Step3: "Dis nous en plus",
          Next: "Suivant",
          Proceed: "Procéder",
          Back: "Arrière",
          FileUploadTitle: "Enceinte",
          FileUploadContent:
            "Importez un fichier: .pdf, .png et .jpeg sont acceptés. Taille de fichier maximale: 10MB",
        },
        Step1: {
          Content: "Comment pouvons-nous vous aider?",
        },
        Step2: {
          Content: "De quel sujet s'agit-il ?",
        },
        Step3: {
          Content:
            "S'il vous plaît, donnez-nous toutes les informations qui peuvent nous aider à mieux vous aider. Veuillez ajouter des images qui peuvent nous aider à traiter le ticket :",
        },
        Options: {
          information: "Information",
          assisted_purchase: "Achat assisté",
          leecougan: "Lee Cougan",
          microtech: "Microtech",
          basso: "Basso",
          other: "Autre",
          e_commerce_assistance: "E-commerce assistance",
          availability: "Disponibilité",
          leecougan_product_assistance: "Lee Cougan Assistance produit",
          on_microtech_product_assistance: "Microtech Assistance produit",
          other: "Autre",
          warranty_request: "Demande de garantie",
          support_dealer: "Assistance technique concessionnaire",
          basso_product_assistance: "Basso Assistance produit",
          rider_weight: "Poids du cycliste (équipé)",
          rider_weight_placeholder: "80 kg",
          discipline: "Discipline",
          level: "Niveau",
          amateur: "Amateur",
          agonist: "Agonisten",
          pro: "Professionnel",
          weekly_training_hours: "Heures de formation par semaine",
          weekly_training_hours_placeholder: "16 ore",
          rampage_Innova_frame_size: "Taille du cadre Ramapage Innova"
        },
      },
    },
    DownloadArea: {
      PageTitle: "Zone de téléchargement",
      Loader: "Nous chargeons tous les fichiers...",
      Table: {
        FileName: "Nom de fichier",
        Category: "Catégorie",
        Description: "Description",
      },
    },
    TraningArea: {
      PageTitle: "Zone d'entraînement",
      Loader: "Nous chargeons tous les tutoriels...",
      Table: {
        Title: "Titre",
        Model: "Modèle",
        Description: "Description",
        Date: "Description",
      },
    },
    News: {
      PageTitle: "Nouvelles",
      Loader: "Nous chargeons toutes les dernières nouvelles...",
      LoaderSingle: "Nous chargeons toutes les dernières nouvelles...",
      FindOutMore: "Découvrir plus",
    },
    ShopSelectorAgent: {
      Hello: "Bonjour",
      Loader: "Nous chargeons vos clients",
      Subtitle: "Login au nom de votre client",
      Proceed: "Procéder",
    },
    Checkout: {
      CartTitle: "Veuillez vérifier votre commande",
      NoProducts: "Aucun produit dans le panier",
      Table: {
        img: "Image",
        Product: "Produit",
        Sku: "SKU",
        Qty: "Quantité",
        Price: "Prix",
        Total: "Total",
      },
      Item: {
        Qty: "Montant",
        viewConfiguration: "Afficher les détails de la configuration",
        ViewDetails: "Voir les détails",
        EditConfiguration: "Modifier la configuration",
        RemoveProduct: "Retirer le produit",
        Price: "Prix",
      },
      ShipmentPaymentTitle: "Livraison et paiements",
      Shipment: {
        Title: "Expédition",
        ShipmentByClientLabel:
          "Vous souhaitez organiser vous-même votre expédition ?",
        ShipmentByClientTxt:
          "Les frais de port seront déduits de votre commande",
        ShipmentByClientCheckbox:
          "Oui, je veux organiser l'expédition avec mon coursier.",
        ShipmentAddress: "Adresse de livraison",
        SameAsBilling: "Identique à la facturation",
      },
      Billing: {
        Title: "Facturation",
        YourBillingData: "Vos données de facturation",
        YourBillingDataDescription:
          "Pour modifier vos informations de facturation, veuillez contacter le service client.",
        CompanyDetails: "Coordonnées de l'entreprise",
        Vat: "T.V.A.",
        Address: "Adresse",
        Contacts: "Contacts",
        OrderForCustomer: {
          Title: "Vous passez une commande pour votre client ?",
          Description:
            "Si vous passez une commande pour l'un de vos clients, saisissez-la ici et la commande sera également chargée dans son espace réservé.",
          Checkbox: "Oui, je passe la commande pour mon client",
          MessageUserNotFound:
            "Il n'y a pas de compte associé à cet e-mail. Entrez votre nom et prénom pour en créer un.",
          MessageUserFound:
            "Cet utilisateur est déjà enregistré dans notre base de données. Vous pouvez poursuivre l'enregistrement.",
          EmailNotValid: "L'e-mail saisi n'est pas valide.",
          Email: "E-mail",
          Name: "Nom",
          Surname: "Nom de famille",
        },
      },
      Payment: {
        Title: "Paiement",
        TypeLabel: "Modalités et conditions de paiement",
        Bacs: "Comme convenu avec Stardue Srl",
        BankTitle: "Coordonnées bancaires",
        BeneficiaryName: "Nom du bénéficiaire",
      },
      BottomBar: {
        TotalProducts: "Produit total",
        Shipment: "Expédition",
        ShipmentTxt: "Répertoriés séparément",
        Total: "Total de la commande",
        Submit: "Soumettre",
      },
    },
    DealersShop: {
      PageTitle: "Boutique des revendeurs",
      Loader: "Wir laden Ihre Tickets...",
      Table: {
        ID: "ID",
        CompanyName: "Nom de l'entreprise",
        Vat: "Vat",
        Phone: "Phone",
      },
    },
  },
  PopupConfirmation: {
    TicketAdded: {
      Title: "Title",
      Content: "Content",
    },
    ProductAddedToCart: {
      Title: "Title",
      Content: "Content",
    },
    OrderPlaced: {
      Title: "Title",
      Content: "Content",
    },
    RegisteredBike: {
      Title: "Title",
      Content: "Content",
    },
  },
};
