import React from "react";
import BassoGeometrics from "../basso/Geometrics";
import LeecouganGeometrics from "../leecougan/Geometrics";
import Cookies from "js-cookie";

const Geometrics = ({ module, isStep, setCurrentSizeStep, modelName, modalId }) => {
  return (
    <>
      {Cookies.get('platformkey') == "7368496012" && (
        <BassoGeometrics
          module={module}
          isStep={isStep}
          setCurrentSizeStep={setCurrentSizeStep}
          modelName={modelName}
          modalId={modalId}
        />
      )}
      {Cookies.get('platformkey') == "7894210751" && (
        <LeecouganGeometrics
          module={module}
        />
      )}
    </>
  );
};
export default Geometrics;
