import React from "react";
import { findPrice } from "../../utils/commons";
import PriceFormat from "../PriceFormat";

const Price = ({ price, displayDiscouted, discount }) => {
  const priceList = localStorage.getItem('stardue_price_list');

  if (price == "" || price == undefined || priceList == "" || priceList === undefined) {
    return <></>;
  }
  let priceDisplay = findPrice(price, "regular_price", discount, false);
  if (displayDiscouted) {
    let priceOriginal = findPrice(price, "regular_price", discount, true);
    if (priceOriginal > priceDisplay) {
      return (<>
        <strike><PriceFormat price={priceOriginal} /></strike> <PriceFormat price={priceDisplay} />
      </>)
    } else {
      return (<>
        <PriceFormat price={priceDisplay} />
      </>)
    }

  }
  return <PriceFormat price={priceDisplay} />;
};
export default Price;
