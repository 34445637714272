import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import i18next from "i18next";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { i18n } from "../../translations/i18n";

const LoginComponent = ({ onSubmitClick, isLoading, isError, message }) => {
  const [form] = Form.useForm();
  let { search } = useLocation();
  const urlData = new URLSearchParams(search);
  return (
    <>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="login-image">
            <div
              className="graphic"
              style={{ backgroundImage: "url(/images/B2B_main.jpg" }}
            />
          </div>
          <div className="login-content">
            <div className="login-content__wrapper">
              <div className="logo">
                <Link to={"/" + i18n.language}>
                  <img src="/images/logo.svg" className="img" />
                </Link>
              </div>
              <div className="login-form">
                <div className="form-title">
                  <h1>{i18next.t('Pages.Login.Title')}</h1>
                  <p>{i18next.t('Pages.Login.Subtitle')}</p>
                </div>
                {urlData.get('message') && <>{urlData.get('message')}</>}
                <div className="form-wrapper">


                  <Form form={form} onFinish={onSubmitClick} layout="vertical">
                    <div className="form-label">
                      <label>{i18next.t('Pages.Login.Email')}</label>
                    </div>
                    <Form.Item
                      name={"email"}
                      rules={[
                        {
                          required: true,
                          message: i18next.t('Common.Form.require_email'),
                        },
                      ]}
                    >
                      <Input placeholder="" />
                    </Form.Item>
                    <div className="form-label">
                      <label>{i18next.t('Pages.Login.Password')}</label>
                    </div>
                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: i18next.t('Common.Form.require_password'),
                        },
                      ]}
                    >
                      <Input.Password placeholder="" />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        block
                        className="btn btn-primary"
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        {i18next.t('Pages.Login.Submit')}
                      </Button>
                    </Form.Item>
                  </Form>
                  {isError && (
                    <>
                      <div className="messagge message-error">{message}</div>
                    </>
                  )}
                  <div className="forgot-password">
                    <Link
                      className="btn cleanbtn font s14 b2"
                      to={"/" + i18n.language + '/' + i18next.t('routes.ResetPassword')}
                    >
                      {i18next.t('Pages.Login.LostPassword')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginComponent;
