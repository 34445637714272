import React, { useEffect, useState } from "react";
import { arrayRemove } from "../../utils/commons";
import {
  Steps,
  Button,
  Modal,
  Checkbox,
  Input,
  Spin,
  Upload,
  Radio,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Item from "antd/lib/list/Item";
import i18next from "i18next";
const { TextArea } = Input;
const { Step } = Steps;
const { Dragger } = Upload;
const OrderReturnTrackingPopup = ({
  isReturnPopup,
  setIsReturnPopup,
  products,
  returnOrder,
  isLoading,
  isSuccess,
  returnData,
  orderId,
  orderTrackingTicket,
}) => {
  const [currentStepSize, setCurrentSizeStep] = useState(0);
  const [productIds, setProductIds] = useState([]);
  const [reason, setReason] = useState("");
  const [file, setFile] = useState([]);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [requestType, setRequestType] = useState("return");
  const [step2Disabled, setstep2Disabled] = useState(false);
  const [step3Disabled, setstep3Disabled] = useState(true);
  const [step4Disabled, setstep4Disabled] = useState(true);

  const handleOnChangeProduct = (id) => {
    let data = [...productIds];
    if (data.includes(id)) {
      data = arrayRemove(data, id);
    } else {
      data.push(id);
    }
    setProductIds(data);
  };

  let disableitem = [];
  returnData &&
    returnData.length > 0 &&
    returnData.map((item) => {
      item.products.forEach((element) => {
        disableitem.push(element);
      });
    });

  useEffect(() => {
    if (requestType === "tracking") {
      setstep2Disabled(true);
    } else {
      setstep2Disabled(false);
    }
    if (productIds.length > 0 && requestType === "return") {
      setstep3Disabled(false);
    } else if (requestType === "tracking") {
      setstep3Disabled(false);
    } else {
      setstep3Disabled(true);
    }
    if (reason.length > 0) {
      setstep4Disabled(false);
    } else {
      setstep4Disabled(true);
    }
  }, [productIds, reason, requestType]);

  const draggerData = {
    name: "file",
    multiple: true,
    headers: {
      Authorization: "Bearer " + userData.token,
    },
    action: `${process.env.REACT_APP_API_URL}v1/content/file_upload`,
    onSuccess: function (response, file_data) {
      setFile([]);
      let temp_file = file;
      temp_file.push(response.data.image);
      setFile(temp_file);
    },
    onChange(info) {
      // console.log("info", info)
      // info.file.status = 'done';
      // info.fileList[0].status = 'done';
      info.fileList.map((fileData, index) => {
        if (fileData.name == info.file.name) {
          info.file.status = "done";
          info.fileList[index].status = "done";
        }
      });
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Modal
      className="modal-s2 returnPopup"
      visible={isReturnPopup}
      width={1200}
      footer={null}
      centered
      closeIcon={
        <svg
          id="Modalità_Isolamento"
          data-name="Modalità Isolamento"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 222.99 222.99"
        >
          <path
            d="M461.5,192.25l-23.74-23.74L350,256.26l-87.75-87.75-23.74,23.74L326.26,280l-87.75,87.76,23.74,23.74L350,303.75l87.76,87.75,23.74-23.74L373.75,280Z"
            transform="translate(-238.51 -168.51)"
          />
        </svg>
      }
      onCancel={() => {
        setIsReturnPopup(false);
      }}
    >
      <div className="modal-header">
        <h2>
          {i18next.t("Pages.Orders.TicketOrder.Common.Title", {
            orderId: orderId,
          })}
        </h2>
      </div>
      {!isSuccess && (
        <div className="Modal-content">
          <Steps
            current={currentStepSize}
            onChange={(e) => {
              setCurrentSizeStep(e);
            }}
          >
            <Step
              key="0"
              title={i18next.t("Pages.Orders.TicketOrder.Common.StepTitle1")}
            />
            <Step
              key="1"
              title={i18next.t("Pages.Orders.TicketOrder.Common.StepTitle2")}
              disabled={step2Disabled}
            />
            <Step
              key="2"
              title={i18next.t("Pages.Orders.TicketOrder.Common.StepTitle3")}
              disabled={step3Disabled}
            />
            <Step
              key="3"
              title={i18next.t("Pages.Orders.TicketOrder.Common.StepTitle4")}
              disabled={step4Disabled}
            />
          </Steps>

          {currentStepSize == 0 && (
            <>
              <div className="Modal-step_content">
                <div className="modal-info">
                  <p>{i18next.t("Pages.Orders.TicketOrder.Step1.Content")}</p>
                </div>

                <Radio.Group
                  onChange={(e) => {
                    setRequestType(e.target.value);
                  }}
                  value={requestType}
                >
                  <Radio value={"return"}>
                    <div className="Radio-boxImage">
                      <div className="Radio-image">
                        <img src="/images/ticket/return-order.png" />
                      </div>
                      <div className="Radio-label">
                        <p>
                          {i18next.t(
                            "Pages.Orders.TicketOrder.Step1.ReturnReplacement"
                          )}
                        </p>
                      </div>
                    </div>
                  </Radio>
                  <Radio value={"tracking"}>
                    <div className="Radio-boxImage">
                      <div className="Radio-image">
                        <img src="/images/ticket/shipment_tracking.png" />
                      </div>
                      <div className="Radio-label">
                        <p>
                          {i18next.t("Pages.Orders.TicketOrder.Step1.Tracking")}
                        </p>
                      </div>
                    </div>
                  </Radio>
                </Radio.Group>
              </div>

              <div className="Modal-footer">
                <Button
                  className="btn ant-btn-primary"
                  onClick={() => {
                    if (requestType == "tracking") {
                      setCurrentSizeStep(2);
                    } else {
                      setCurrentSizeStep(1);
                    }
                  }}
                >
                  {i18next.t("Pages.Orders.TicketOrder.Common.Next")}
                </Button>
              </div>
            </>
          )}
          {currentStepSize == 1 && (
            <>
              <div className="Modal-step_content">
                <div className="modal-info">
                  <p>{i18next.t("Pages.Orders.TicketOrder.Step2.Content")}</p>
                </div>
                <div className="d-flex justify-content-center products-row__return">
                  {requestType == "return" &&
                    products &&
                    products.length > 0 &&
                    products.map((product) => {
                      return (
                        <div className="item-col">
                          <div className="product-items__return">
                            <Checkbox
                              key={product.item_id}
                              className="checkbox-stardue"
                              value={product.item_id}
                              type="checkbox"
                              id="isDefault"
                              checked={productIds.includes(product.item_id)}
                              onChange={(e) =>
                                handleOnChangeProduct(product.item_id)
                              }
                              disabled={disableitem.includes(product.item_id)}
                            >
                              <span className="Order-img">
                                <img
                                  src={
                                    product.item_metas.preview_image
                                      ? product.item_metas.preview_image
                                      : product.image
                                  }
                                />
                              </span>
                              <h5 className="info-checkbox">{product.name}</h5>
                            </Checkbox>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="Modal-footer">
                <Button
                  className="btn ant-btn-primary"
                  disabled={step3Disabled}
                  onClick={() => setCurrentSizeStep(2)}
                >
                  {i18next.t("Pages.Orders.TicketOrder.Common.Next")}
                </Button>
              </div>
            </>
          )}
          {currentStepSize == 2 && (
            <>
              <div className="Modal-step_content">
                <div className="modal-info">
                  {requestType == "return" && (
                    <p>
                      {i18next.t(
                        "Pages.Orders.TicketOrder.Step3ReturnProducts.Content"
                      )}
                    </p>
                  )}
                  {requestType == "tracking" && (
                    <p>
                      {i18next.t(
                        "Pages.Orders.TicketOrder.Step3Tracking.Content"
                      )}
                    </p>
                  )}
                </div>
                <div className="form-wrapper">
                  <TextArea
                    rows={4}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    value={reason}
                  />

                  {requestType === "return" && (
                    <Dragger {...draggerData}>
                      <p className="ant-upload-drag-icon">
                        {/* <InboxOutlined /> */}
                      </p>
                      <p className="ant-upload-text">
                        {i18next.t(
                          "Pages.Orders.TicketOrder.Common.FileUploadTitle"
                        )}
                      </p>
                      <p className="ant-upload-hint">
                        <span>
                          {i18next.t(
                            "Pages.Orders.TicketOrder.Common.FileUploadContent"
                          )}
                        </span>
                      </p>
                    </Dragger>
                  )}
                </div>
              </div>

              <div className="Modal-footer">
                <Button
                  className="btn ant-btn-primary"
                  disabled={step4Disabled}
                  onClick={() => setCurrentSizeStep(3)}
                >
                  {i18next.t("Pages.Orders.TicketOrder.Common.Next")}
                </Button>
              </div>
            </>
          )}
          {currentStepSize == 3 && (
            <>
              {requestType == "return" && (
                <>
                  <div className="Modal-step_content">
                    <div className="modal-info">
                      <h4>
                        {i18next.t(
                          "Pages.Orders.TicketOrder.Step4ReturnProducts.Title"
                        )}
                      </h4>
                      <p>
                        {i18next.t(
                          "Pages.Orders.TicketOrder.Step4ReturnProducts.Content"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="Modal-footer">
                    <Button
                      loading={isLoading}
                      className="btn ant-btn-primary"
                      onClick={() => returnOrder(productIds, reason, file)}
                    >
                      {i18next.t("Pages.Orders.TicketOrder.Common.Proceed")}
                    </Button>
                  </div>
                </>
              )}
              {requestType == "tracking" && (
                <>
                  <div className="Modal-step_content">
                    <div className="modal-info">
                      <h4>
                        {i18next.t(
                          "Pages.Orders.TicketOrder.Step4Tracking.Title"
                        )}
                      </h4>
                      <p>
                        {i18next.t(
                          "Pages.Orders.TicketOrder.Step4Tracking.Content"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="Modal-footer">
                    <Button
                      loading={isLoading}
                      className="btn ant-btn-primary"
                      onClick={() => {
                        orderTrackingTicket(reason);
                      }}
                    >
                      {i18next.t("Pages.Orders.TicketOrder.Common.Proceed")}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
      {isSuccess && (
        <>
          <div className="Modal-content">
            <div className="Modal-step_content">
              <div className="modal-info">
                <h4>{i18next.t("Pages.Orders.TicketOrder.Step5.Title")}</h4>
                <p>{i18next.t("Pages.Orders.TicketOrder.Step5.Content")}</p>
              </div>
              <div className="Modal-footer">
                <Button
                  className="btn ant-btn-primary"
                  onClick={() => {
                    setIsReturnPopup(false);
                  }}
                >
                  {i18next.t("Pages.Orders.TicketOrder.Common.Back")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};
export default OrderReturnTrackingPopup;
