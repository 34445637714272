import { request } from "../services/apiService";
import {
    TRAINING_LIST_PENDING,
    TRAINING_LIST_SUCCESS,
    TRAINING_LIST_FAILURE,
    TRAINING_TYPE_PENDING,
    TRAINING_TYPE_SUCCESS,
    TRAINING_TYPE_FAILURE,
} from "./actionTypes";


export const getTraining = (page, size, type) => dispatch => {
    dispatch({
        type: TRAINING_LIST_PENDING
    });
    const url = `v1/training/list/?page=` + page + `&size=` + size + `&type=` + type;
    request("get", url, '', true).then(
        (res) => {
            dispatch({
                type: TRAINING_LIST_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: TRAINING_LIST_FAILURE,
                error: err.response,
            });
        }
    );
}

export const getTrainingType = () => dispatch => {
    dispatch({
        type: TRAINING_TYPE_PENDING,
    });
    const url = `v1/training/type/`;
    request("get", url, '', true).then(
        (res) => {
            dispatch({
                type: TRAINING_TYPE_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: TRAINING_TYPE_FAILURE,
                data: err.response,
            });
        }
    );
}