import { request } from "../services/apiService";
import {
    FETCH_SUPPORT_DEALER_ORDER_LIST_PENDING,
    FETCH_SUPPORT_DEALER_ORDER_LIST_SUCCESS,
    FETCH_SUPPORT_DEALER_ORDER_LIST_FAILURE
} from "./actionTypes";

export const getSupportDealerOrderList = (page, size, status, start_date, end_date) => dispatch => {
  dispatch({ type: FETCH_SUPPORT_DEALER_ORDER_LIST_PENDING });
  var url = "v1/db/support-dealer-order/?page=" + page + "&size=" + size + "&status=" + status + "&start_date=" + start_date + "&end_date=" + end_date
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: FETCH_SUPPORT_DEALER_ORDER_LIST_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: FETCH_SUPPORT_DEALER_ORDER_LIST_FAILURE,
        data: error.response,
      });
    }
  );
}