import React from "react";
import StarDueCollapse from "../../components/StarDueCollapse/StarDueCollapse";
import { Space, Radio, InputNumber } from "antd";
import OptionalStep from "./OptionalStep";
import i18next from "i18next";
import PriceFormat from "../../components/PriceFormat";
const OtherSteps = ({
  other,
  onChangeOtherSteps,
  onChangeOtherStepsCheckbox,
  otherIndex,
  productIndex,
  otherStep,
  disbaleProductId,
  productQuantity,
  onChangeOtherOptions,
  onChangeOtherOptionsChecbox,
  options,
  optionalQuantity,
  onChangesOptionalQuantity,
  stepTitle,
  stepId,
  onChangesQantity,
  style,
  onChangesQantityForOptions,
  otherStepDisplayPrice,
  userData,
  priceList,
}) => {
  const renderName = (id) => {
    let data =
      other.allowed_products &&
      other.allowed_products.length > 0 &&
      other.allowed_products.filter((item) => item.id === id);
    if (data && data[0]) {
      return data[0].title;
    }
  };
  return (
    <>
      <StarDueCollapse
        title={stepTitle}
        stepId={stepId}
        active={true}
        value={
          other.is_optional_step !== "1" &&
          otherStep &&
          renderName(otherStep[other.step_id])
        }
        style={style}
      >
        {other.is_optional_step && other.is_optional_step === "1" ? (
          <OptionalStep
            onChangeOtherSteps={onChangeOtherStepsCheckbox}
            other={other}
            otherIndex={otherIndex}
            productIndex={productIndex}
            otherStep={otherStep}
            disbaleProductId={disbaleProductId}
            productQuantity={productQuantity}
            onChangeOtherOptions={onChangeOtherOptionsChecbox}
            options={options}
            optionalQuantity={optionalQuantity}
            onChangesOptionalQuantity={onChangesOptionalQuantity}
            onChangesQantityForOptions={onChangesQantityForOptions}
            onChangesQantity={onChangesQantity}
            priceList={priceList}
            userData={userData}
          />
        ) : (
          <div className="step-body required-additional-step">
            {other.descriptions !== "" && (
              <div className="other-step-description">{other.descriptions}</div>
            )}
            <div className="RadioGroupWithImage">
              <Radio.Group
                className="Radio-img"
                onChange={(e) => {
                  onChangeOtherSteps(
                    e,
                    other.step_id,
                    other.affect_preview,
                    otherIndex
                  );
                }}
                value={otherStep[other.step_id]}
              >
                <Space direction="vertical">
                  {other.allowed_products &&
                    other.allowed_products.length > 0 &&
                    other.allowed_products.map((allowed_product, index) => {
                      return allowed_product &&
                        disbaleProductId[allowed_product.id] ? (
                        <div className="additional-step-required">
                        
                          <Radio
                            key={allowed_product.title + "_" + index}
                            value={allowed_product.id}
                            product={allowed_product}
                            indexValue={index}
                            data-id={allowed_product.id}
                            className="OptionRadioParent"
                          >
                            <div className="Radio-custom-img">
                              <img src={allowed_product.image} />
                            </div>
                            <span>{allowed_product.title}</span>
                            {otherStepDisplayPrice[other.step_id] &&
                              //&& otherStepDisplayPrice[other.step_id]['is_price_calculation']
                              otherStepDisplayPrice[other.step_id][
                                allowed_product.id
                              ] &&
                              otherStepDisplayPrice[other.step_id][
                                allowed_product.id
                              ]["text"] == 0 && (
                                <span className="inclusionProduct">
                                  {allowed_product.id == other.defult_selection
                                    ? "Included"
                                    : ""}
                                </span>
                              )}
                            {otherStepDisplayPrice[other.step_id] && //otherStepDisplayPrice[other.step_id]['is_price_calculation'] &&
                              otherStepDisplayPrice[other.step_id][
                                allowed_product.id
                              ] &&
                              otherStepDisplayPrice[other.step_id][
                                allowed_product.id
                              ]["text"] != 0 && (
                                <>
                                  <span className="RadioWheelsPrice">
                                    <span>
                                      {otherStepDisplayPrice[other.step_id][
                                        allowed_product.id
                                      ]["text"] > 0
                                        ? "+"
                                        : ""}
                                    </span>
                                    <PriceFormat
                                      price={
                                        otherStepDisplayPrice[other.step_id][
                                          allowed_product.id
                                        ]["text"]
                                      }
                                    />
                                  </span>
                                </>
                              )}
                          </Radio>
                          {allowed_product.type != "simple" && (
                            <div
                              className={
                                "substep-container" +
                                (otherStep[other.step_id] === allowed_product.id
                                  ? " active"
                                  : "")
                              }
                            >
                              {otherStep[other.step_id] ===
                                allowed_product.id && (
                                <div className="step-item substep-item step-child-wrapper">
                                  <div className="step-body">
                                    <div className="step-inner">
                                      <div className="step-item step-child step-option-variable">
                                        <div className="step-header">
                                          <div className="label">
                                            <p>
                                              {i18next.t(
                                                "Pages.Configurator.Step.Header.Options"
                                              )}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="step-child-container is-substep-inner">
                                          <Radio.Group
                                            onChange={(e) =>
                                              onChangeOtherOptions(
                                                e,
                                                other.step_id
                                              )
                                            }
                                            value={
                                              options[other.step_id] &&
                                              options[other.step_id]
                                            }
                                            className="ColumnGroup"
                                          >
                                            <Space direction="vertical">
                                              {allowed_product.options &&
                                                allowed_product.options.map(
                                                  (option, index) => {
                                                    return (
                                                      <Radio
                                                        key={option.id}
                                                        value={option.id}
                                                        data-id={option.id}
                                                      >
                                                        {option.name}
                                                      </Radio>
                                                    );
                                                  }
                                                )}
                                            </Space>
                                          </Radio.Group>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {otherStep[other.step_id] ===
                                allowed_product.id &&
                                allowed_product.have_quantity === "1" && (
                                  <div className="step-item substep-item step-child-wrapper">
                                    <div className="step-body">
                                      <div className="step-inner">
                                        <div className="step-item step-child step-option-variable">
                                          <div className="step-header">
                                            <div className="label">
                                              <p>
                                                {i18next.t(
                                                  "Pages.Configurator.Quantity"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="step-child-container is-substep-inner is-substep-qty">
                                            <Space direction="vertical">
                                              <div className="quantity-box">
                                                <button
                                                  onClick={() =>
                                                    onChangesQantity(
                                                      other.allowed_products[
                                                        productIndex[
                                                          other.step_id
                                                        ]
                                                      ].id,
                                                      false
                                                    )
                                                  }
                                                  className="btn-qty minus"
                                                >
                                                  <svg
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="32.9px"
                                                    height="4px"
                                                    viewBox="0 0 32.9 4"
                                                    style={{
                                                      overflow: "visible",
                                                      enableBackground:
                                                        "new 0 0 32.9 4",
                                                    }}
                                                    xmlSpace="preserve"
                                                  >
                                                    <path d="M1.9,0C0.8,0.1,0,1,0,2.1s1,2,2.1,1.9h28.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c0,0,0,0-0.1,0H2.1C2,0,2,0,1.9,0z" />
                                                  </svg>
                                                </button>
                                                <div className="qty-wrapper">
                                                  <InputNumber
                                                    min={1}
                                                    max={10}
                                                    value={
                                                      productQuantity[
                                                        other.allowed_products[
                                                          productIndex[
                                                            other.step_id
                                                          ]
                                                        ].id
                                                      ]
                                                    }
                                                  />
                                                </div>
                                                <button
                                                  className="btn-qty plus"
                                                  onClick={() =>
                                                    onChangesQantity(
                                                      other.allowed_products[
                                                        productIndex[
                                                          other.step_id
                                                        ]
                                                      ].id,
                                                      true
                                                    )
                                                  }
                                                >
                                                  <svg
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    x="0px"
                                                    y="0px"
                                                    width="32.9px"
                                                    height="33.3px"
                                                    viewBox="0 0 32.9 33.3"
                                                    style={{
                                                      overflow: "visible",
                                                      enableBackground:
                                                        "new 0 0 32.9 33.3",
                                                    }}
                                                    xmlSpace="preserve"
                                                  >
                                                    <path
                                                      d="M16.3,0c-1,0.1-1.8,1-1.8,2v12.6H2.1c-0.1,0-0.1,0-0.2,0c-1.1,0.1-2,1-1.9,2.1s1,2,2.1,1.9h12.4v12.6c0,1.1,0.9,2,2,2
                                                c1.1,0,2-0.9,2-2c0,0,0,0,0-0.1V18.6h12.4c1.1,0,2-0.9,2-2s-0.9-2-2-2c0,0,0,0-0.1,0H18.5V2c0-1.1-0.9-2-2-2C16.4,0,16.3,0,16.3,0z"
                                                    />
                                                  </svg>
                                                </button>
                                              </div>
                                            </Space>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      );
                    })}
                </Space>
              </Radio.Group>
            </div>
          </div>
        )}
      </StarDueCollapse>
    </>
  );
};

export default OtherSteps;
