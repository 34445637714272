import Axios from "axios";
import Cookies from "js-cookie";
import i18n from "i18next";
import { logOutClearData } from "../actions/loginActions";
import history from "../history";

export const request = (method, path, requestBody, authRequired) => {
  let config = {
    method,
    url: `${process.env.REACT_APP_API_URL}` + path + (path.includes('?') ? '&lang=' + i18n.language + '&origin=b2b' : '?lang=' + i18n.language + '&origin=b2b'),
  };
  if (authRequired) {
    config.headers = {
      'Authorization': `Bearer ${localStorage.getItem("user-token")}`,
    };
  }
  let platform = Cookies.get('platformkey');
  if (platform && config.headers) {
    config.headers['PLATFORM'] = platform;
  } else if (platform) {
    config.headers = {
      'PLATFORM': platform,
    };
  }

  let Carthash = Cookies.get("stardue-carthash");
  if (config.headers) {
    config.headers['Carthash'] = Carthash;
  } else if (Carthash) {
    config.headers = {
      'Carthash': Carthash,
    };
  }

  if (method === "get") {
    config.params = requestBody;
  } else {
    config.data = requestBody;
  }

  return Axios.request(config)
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status == 401 || error.response.status == 403) {
        logOutClearData();

        history.push("/" + i18n.language);
      }
      throw error
    });
};



