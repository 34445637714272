import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments } from "../../actions/actionAreaFTP";
import { config } from "dotenv";
import { Row, Col, Select, Skeleton, Pagination, Modal } from "antd";
import { saveAs } from "file-saver";
import paginationLabel from "../../components/PaginationRender";
import { ReactTitle } from 'react-meta-tags';
import i18next from "i18next";
const { Option } = Select;
const AreaFtp = () => {
  const dispatch = useDispatch();
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [total, setTotal] = useState(9);
  const [videoContent, setvideoContent] = useState("");
  const [modelVisibal, setmodelVisibal] = useState(false);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [filterButton, setFilterButton] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const { isLoading, documents } = useSelector(state => {
    return state.areaFTP
  });

  const [selectedCata, setSelectedSelector] = useState('');

  const [applyCata, setapplyCata] = useState(0);
  const [ftpFiles, setFtpFiles] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    dispatch(getDocuments());
  }, []);

  function handleChange(value) {
    setapplyCata(value);
    setFilterButton(true)
  }
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    if (documents != undefined) {

      setTotal(documents.data.length)

      setFtpFiles(documents.data);
      setDisplayData(documents.data);
      const categories_temp = [];
      documents.data.map((document) => {
        document.documents.map((categories_list) => {
          categories_temp[categories_list.type_id] = categories_list.type_name
        })
      })
      setCategories(categories_temp);
    }
  }, [documents]);

  const onFilterApply = () => {
    let calculate_offset = pageSize * (current - 1);
    let calculate_limit = calculate_offset + pageSize;
    setOffset(calculate_offset);
    setLimit(calculate_limit);

    let data = []
    let total_data = []
    ftpFiles != null && ftpFiles.map((item, idx) => {
        let cat_array = [];
        item.documents.map((document) => {
           cat_array.push(document.type_id);
        })
        if (cat_array.includes(selectedCata) || selectedCata == 0) {
          data.push(item);
          total_data.push(item);
        }
    })
    
    let setData = [];
    data.map((item, idx) => {
      idx >= calculate_offset && idx < calculate_limit && setData.push(item)
    })
    
    setDisplayData([]);
    setDisplayData(setData);
    setTotal(total_data.length);
  };

  const makeResetFilter = () => {
    setSelectedSelector('');
    setResetFilter(true);
    setFilterButton(false);
  }

  useEffect(() => {
    onFilterApply();
  }, [ftpFiles, current, resetFilter]);

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.FTPArea')} />
      <Header />
      <div className="dashboard-content area-ftp-p">
        <div className="wrap">
          <div className="page-content">
            <div className="left-side">
              <div className="filter-side-container">
                <div className="filter-blk">
                 <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>
                  {/* categories */}
                  <div className="filter">
                    <div className="form-wrapper">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.Category")}</label>
                      </div>
                      <Select value={selectedCata} onChange={(value) => {
                        handleChange(value);
                        setSelectedSelector(value);
                        setResetFilter(false);
                        setFilterButton(true);
                      }}>
                        <Option value="">{i18next.t("Common.FiltersSidebar.All2")}</Option>
                        {categories.map((item, key) => (

                          <Option value={key}>{item}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary btn-submit" onClick={onFilterApply}>
                {i18next.t("Common.FiltersSidebar.Submit")}
                </button>
                {resetFilter && <button
                    className="btn btn-only-icon"
                    onClick={makeResetFilter}
                  >
                    <div className="icon">
                      <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1219.76 1087.17"><path d="M1192.6,422.91a18.73,18.73,0,0,0-26.25,3.66L1066.13,558.85a533.06,533.06,0,1,0-15.28,175.31,18.75,18.75,0,1,0-36.28-9.37,496.85,496.85,0,1,1,14.82-154.69L890.64,464.63A18.77,18.77,0,1,0,868,494.54L1040.07,625a18.76,18.76,0,0,0,11.34,3.75,11.89,11.89,0,0,0,2.53,0,18.75,18.75,0,0,0,12.38-7.22l130.31-172a18.76,18.76,0,0,0-4-26.53Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /><path d="M534.78,1141a540.07,540.07,0,1,1,536.61-600.64l89.38-118a25.75,25.75,0,0,1,36-5l3.51,2.65-.08.37a25.75,25.75,0,0,1,2,33.33l-130.32,172a25.54,25.54,0,0,1-17,9.92l-.17,0a18.66,18.66,0,0,1-3.66,0,25.93,25.93,0,0,1-15.19-5.15L863.72,500.12a25.77,25.77,0,0,1,31.14-41.07l126.35,96a490,490,0,1,0-13.45,168.07A25.75,25.75,0,1,1,1057.6,736,540.33,540.33,0,0,1,534.78,1141Zm-.1-1066.17a526.07,526.07,0,1,0,509.39,657.63l.07-.26a11.75,11.75,0,1,0-22.73-5.87l-.06.26a503.9,503.9,0,1,1,15-156.87l.94,15.25L886.4,470.21A11.77,11.77,0,0,0,872.18,489L1044.3,619.37a11.55,11.55,0,0,0,7.08,2.33h.41l.4,0a3.87,3.87,0,0,0,.9,0,11.66,11.66,0,0,0,7.66-4.51l130.3-172a11.75,11.75,0,0,0-2.53-16.61l-.84-.6a11.75,11.75,0,0,0-15.75,2.78L1060.6,577.73l-1.45-18.33A526,526,0,0,0,536.85,74.79Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /></svg>
                    </div>{i18next.t("Common.FiltersSidebar.Reset")}
                  </button>}
              </div>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t("Pages.DownloadArea.PageTitle")}</h3>
              </div>
              <div className="table with-pagination">
                <div className="hdr sticky">
                  <Row gutter={[24, 24]}>
                    <Col
                      span={4}
                      xs={{ span: 6 }}
                      sm={{ span: 6 }}
                      lg={{ span: 6 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.DownloadArea.Table.FileName")}</div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.DownloadArea.Table.Category")}</div>
                    </Col>
                    
                    <Col span={4}
                      xs={{ span: 10 }}
                      sm={{ span: 10 }}
                      lg={{ span: 10 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.DownloadArea.Table.Description")}</div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item"></div>
                    </Col>
                  </Row>
                </div>
                {/* <Skeleton loading={isLoading} > */}
                <div className="table-container-wrapper">
                  <div className={isLoading ? "loader-container" : "loader-container is-loaded"}>
                    <span className="loader"></span>
                    <div className="loader-text">{i18next.t("Pages.DownloadArea.Loader")}</div>
                  </div>
                  <div className="table-wrap">
                    {displayData != null && displayData.map((item, idx) => (
                      <div className="table-item">
                        <Row gutter={[24, 24]}>
                          <Col
                            span={4}
                            xs={{ span: 6 }}
                            sm={{ span: 6 }}
                            lg={{ span: 6 }}
                          >
                            <div className="table-item__content">
                              {item.title}
                            </div>
                          </Col>
                          <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            lg={{ span: 4 }}
                          >
                            <div className="table-item__content">{item.documents && item.documents.map((document) =>(
                                <div>{document.type_name}</div>
                            ))}</div>
                          </Col>
                          <Col
                            span={4}
                            xs={{ span: 10 }}
                            sm={{ span: 10 }}
                            lg={{ span: 10 }}
                          >
                            <div className="table-item__content">
                              <div dangerouslySetInnerHTML={{__html: item.description}}/>
                            </div>
                          </Col>
                          <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            lg={{ span: 4 }}
                          >
                            <div className="table-item__content">
                              <button className="btn btn-primary btn-small" 
                              onClick={() => { setvideoContent(item); setmodelVisibal(true); }}
                              >
                                View
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="pagination-wrapper">
                <Modal
                      className="modal-s2 modal-training"
                      visible={modelVisibal}
                      footer={null}
                      onCancel={() => { setmodelVisibal(false) }}
                      closeIcon={<svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.99 222.99"><path d="M461.5,192.25l-23.74-23.74L350,256.26l-87.75-87.75-23.74,23.74L326.26,280l-87.75,87.76,23.74,23.74L350,303.75l87.76,87.75,23.74-23.74L373.75,280Z" transform="translate(-238.51 -168.51)" /></svg>}
                    >
                      <div className="modal-header">
                        <h2>
                         {videoContent.document_type_name}
                        </h2>
                      </div>
                      <div className="video-training__wrapper">
                        <div dangerouslySetInnerHTML={{
                          __html: videoContent.select_document
                        }}></div>
                      </div>
                    </Modal>
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} documenti`}
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
                {/* </Skeleton> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AreaFtp;
