import { request } from "../services/apiService";
import {
    UPDATE_DEALER_DATA_PENDING,
    UPDATE_DEALER_DATA_SUCCESS,
    UPDATE_DEALER_DATA_FAILURE,
    GET_DEALER_DATA_PENDING,
    GET_DEALER_DATA_SUCCESS,
    GET_DEALER_DATA_FAILURE,
    SET_DEALER_DATA,
    UPDATE_DEALER_PERSONAL_DATA_PENDING,
    UPDATE_DEALER_PERSONAL_DATA_SUCCESS,
    UPDATE_DEALER_PERSONAL_DATA_FAILURE
} from "./actionTypes";

export const setDealerData = (data) => dispatch => { 
    dispatch({ type: SET_DEALER_DATA, data:data });
}

export const updateDealerData = (data) => dispatch => {
    dispatch({ type: UPDATE_DEALER_DATA_PENDING });
    const url = `v1/dealer/update_timing`;
    request("post", url, data, true).then(
        (res) => {
            dispatch({
                type: UPDATE_DEALER_DATA_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: UPDATE_DEALER_DATA_FAILURE,
                data: err.response,
            });
        }
    );
}

export const getDealerData = () => dispatch => {
    dispatch({ type: GET_DEALER_DATA_PENDING });
    const url = `v1/dealer/get_timing`;
    request("get", url, '', true).then(
        (res) => {
            dispatch({
                type: GET_DEALER_DATA_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: GET_DEALER_DATA_FAILURE,
                data: err.response,
            });
        }
    );
}

export const updateDealerPersonalData = (data) => dispatch => {
    dispatch({ type: UPDATE_DEALER_PERSONAL_DATA_PENDING });
    const url = `v1/dealer/dashboard-update`;
    request("post", url, data, true).then(
        (res) => {
            localStorage.setItem("user", JSON.stringify(res.data.data));
            dispatch({
                type: UPDATE_DEALER_PERSONAL_DATA_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: UPDATE_DEALER_PERSONAL_DATA_FAILURE,
                data: err.response,
            });
        }
    );
}