import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  getOrderList,
  getOrderStatusList,
} from "../../actions/orderReceivedActions";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Pagination, Skeleton, Select, Input } from "antd";
import { i18n } from "../../translations/i18n";
import paginationLabel from "../../components/PaginationRender";
import { ReactTitle } from "react-meta-tags";
import DatePicker from "../../components/DatePicker";
import Sortable from "../../components/Sortable";
import DateFormat from "../../components/DateFormat";
import i18next from "i18next";

const { Option } = Select;

const OrderRecevied = () => {
  const dispatch = useDispatch();
  const [selectedStatus, setselectedStatus] = useState("wc-pending");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(9);
  const [orderBy, setOrderBy] = useState({
    orderby: "",
    order: "",
  });

  const [resetFilter, setResetFilter] = useState(false);
  const { isLoading, status, ordersList, total } = useSelector((state) => {
    return state.orderRecived;
  });
  const [moreProduct, setMoreProduct] = useState(-1);
  const [childOrder, setChildOrder] = useState(-1);
  const [search, setSearch] = useState('');
  useEffect(() => {
    dispatch(getOrderStatusList());
  }, []);

  useEffect(() => {
    fetchOrder();
  }, [current]);

  const onFilterApply = () => {
    fetchOrder();
  };

  useEffect(() => {
    if (orderBy.orderby != "") {
      fetchOrder();
    }
  }, [orderBy]);

  const fetchOrder = () => {
    let order_for_dealer = '';

    if (localStorage.getItem('agent-selected-user')) {
      const userDataDealer = JSON.parse(localStorage.getItem("agent-selected-user"))
      order_for_dealer = userDataDealer.id;
    }

    dispatch(
      getOrderList(
        current,
        pageSize,
        selectedStatus,
        startDate,
        endDate,
        orderBy.order,
        orderBy.orderby,
        search,
        order_for_dealer
      )
    );
  };

  const makeResetFilter = () => {
    setselectedStatus("All");
    setstartDate("");
    setendDate("");
    setResetFilter(false);
    setSearch('');
  };
  return (
    <>
      <ReactTitle title={i18next.t("PageTitle.OrderList")} />
      <Header />
      <div className="dashboard-content prd-list">
        <div className="wrap">
          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-side-container">
                <div className="filter-blk">
                  <div className="title">
                    {i18next.t("Common.FiltersSidebar.Title")}
                  </div>

                  <div className="form-wrapper">
                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.SKU")}</label>
                      </div>
                      <Input value={search} placeholder="" onChange={(e) => { setSearch(e.target.value); setResetFilter(true); }}
                      />
                    </div>
                    <div className="form-group" style={{ 'display': 'none' }}>
                      <div className="form-label">
                        <label>
                          {i18next.t("Common.FiltersSidebar.State")}
                        </label>
                      </div>
                      <Select
                        dropdownClassName="dropdown-select-s2"
                        value={selectedStatus}
                        onChange={(value) => {
                          setselectedStatus(value);
                          setResetFilter(true);
                        }}
                      >
                        <Option value="">
                          {i18next.t("Common.FiltersSidebar.All1")}
                        </Option>
                        {Object.keys(status).length > 0 &&
                          Object.keys(status).map((key) => (
                            <Option value={key}>{status[key]}</Option>
                          ))}
                      </Select>
                    </div>
                    <div className="form-group">
                      <div className="form-label">
                        <label>
                          {i18next.t("Common.FiltersSidebar.FromDate")}
                        </label>
                      </div>
                      <DatePicker
                        setDay={setstartDate}
                        defultDay={startDate}
                        reset={setResetFilter}
                        postDate={endDate}
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label">
                        <label>
                          {i18next.t("Common.FiltersSidebar.ToDate")}
                        </label>
                      </div>
                      <DatePicker
                        setDay={setendDate}
                        defultDay={endDate}
                        reset={setResetFilter}
                        preDate={startDate}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary btn-submit"
                  onClick={onFilterApply}
                >
                  {i18next.t("Common.FiltersSidebar.Submit")}
                </button>
                {resetFilter && (
                  <button
                    className="btn btn-only-icon"
                    onClick={makeResetFilter}
                  >
                    <div className="icon">
                      <svg
                        id="Livello_1"
                        data-name="Livello 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1219.76 1087.17"
                      >
                        <path
                          d="M1192.6,422.91a18.73,18.73,0,0,0-26.25,3.66L1066.13,558.85a533.06,533.06,0,1,0-15.28,175.31,18.75,18.75,0,1,0-36.28-9.37,496.85,496.85,0,1,1,14.82-154.69L890.64,464.63A18.77,18.77,0,1,0,868,494.54L1040.07,625a18.76,18.76,0,0,0,11.34,3.75,11.89,11.89,0,0,0,2.53,0,18.75,18.75,0,0,0,12.38-7.22l130.31-172a18.76,18.76,0,0,0-4-26.53Z"
                          transform="translate(8.85 -57.28)"
                          style={{
                            stroke: "#000",
                            strokeMiterlimit: 10,
                            strokeWidth: "7px",
                          }}
                        />
                        <path
                          d="M534.78,1141a540.07,540.07,0,1,1,536.61-600.64l89.38-118a25.75,25.75,0,0,1,36-5l3.51,2.65-.08.37a25.75,25.75,0,0,1,2,33.33l-130.32,172a25.54,25.54,0,0,1-17,9.92l-.17,0a18.66,18.66,0,0,1-3.66,0,25.93,25.93,0,0,1-15.19-5.15L863.72,500.12a25.77,25.77,0,0,1,31.14-41.07l126.35,96a490,490,0,1,0-13.45,168.07A25.75,25.75,0,1,1,1057.6,736,540.33,540.33,0,0,1,534.78,1141Zm-.1-1066.17a526.07,526.07,0,1,0,509.39,657.63l.07-.26a11.75,11.75,0,1,0-22.73-5.87l-.06.26a503.9,503.9,0,1,1,15-156.87l.94,15.25L886.4,470.21A11.77,11.77,0,0,0,872.18,489L1044.3,619.37a11.55,11.55,0,0,0,7.08,2.33h.41l.4,0a3.87,3.87,0,0,0,.9,0,11.66,11.66,0,0,0,7.66-4.51l130.3-172a11.75,11.75,0,0,0-2.53-16.61l-.84-.6a11.75,11.75,0,0,0-15.75,2.78L1060.6,577.73l-1.45-18.33A526,526,0,0,0,536.85,74.79Z"
                          transform="translate(8.85 -57.28)"
                          style={{
                            stroke: "#000",
                            strokeMiterlimit: 10,
                            strokeWidth: "7px",
                          }}
                        />
                      </svg>
                    </div>
                    {i18next.t("Common.FiltersSidebar.Reset")}
                  </button>
                )}
              </div>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t("Pages.Orders.PageTitle")}</h3>
              </div>
              <div className="table rel with-pagination">
                <div className="hdr sticky">
                  <Row gutter={[24, 24]}>
                    <Col
                      span={2}
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      lg={{ span: 2 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.Orders.Table.OrderNumber")}
                        <Sortable
                          columnName="order_id"
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          defultSorting="DESC"
                        />
                      </div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.Orders.Table.OrderDate")}
                        <Sortable
                          columnName="created_at"
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          defultSorting="DESC"
                        />
                      </div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.Orders.Table.ExpectiveDate")}
                        <Sortable
                          columnName=""
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          defultSorting="DESC"
                        />
                      </div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.Orders.Table.Destination")}
                      </div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.Orders.Table.State")}
                      </div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">
                        {i18next.t("Pages.Orders.Table.Products")}
                      </div>
                    </Col>
                    <Col
                      span={2}
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      lg={{ span: 2 }}
                    >
                      <div className="table-header-item">
                        {" "}
                        {i18next.t("Pages.Orders.Table.Total")}
                        <Sortable
                          columnName="total"
                          orderBy={orderBy}
                          setOrderBy={setOrderBy}
                          defultSorting="DESC"
                        />
                      </div>
                    </Col>
                    <Col
                      span={2}
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      lg={{ span: 2 }}
                    >
                      <div className="table-header-item"></div>
                    </Col>
                  </Row>
                </div>
                <div className="table-container-wrapper">
                  <div
                    className={
                      isLoading
                        ? "loader-container"
                        : "loader-container is-loaded"
                    }
                  >
                    <span className="loader"></span>
                    <div className="loader-text">
                      {" "}
                      {i18next.t("Pages.Orders.Loader")}
                    </div>
                  </div>
                  <div className="table-wrap">
                    {ordersList.length > 0 &&
                      ordersList.map((item, idx) => (
                        <div className="table-item" key={idx}>
                          <Row gutter={[24, 24]}>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content">
                                {item.id}
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                <DateFormat date={item.created_at} />
                              </div>
                            </Col>
                            <Col
                              span={4}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              {item.estimated_date && (
                                <div className="table-item__content">
                                  <DateFormat date={item.estimated_date} />
                                </div>
                              )}
                            </Col>
                            <Col
                              span={4}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              <div className="table-item__content">
                                {item.shipping_address && (
                                  <>
                                    {item.shipping_address.company}
                                    <br></br>
                                    {item.shipping_address.firstname}&nbsp;
                                    {item.shipping_address.lastname}
                                    <br></br>
                                    {item.shipping_address.address_1}
                                    <br></br>
                                    {item.shipping_address.address_2}
                                    {item.shipping_address.postcode} -{" "}
                                    {item.shipping_address.city}
                                  </>
                                )}
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                <div className={`status ${item.status_key}`}>
                                  <div className="status-text">
                                    {item.status}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col
                              span={4}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              <div className="table-item__content">
                                <div className="product-in-order">
                                  {/* {item.items.length > 0 && <>
                                  {item.items[0].name}
                                </>} */}
                                  <div className="products-items">
                                    {item.items.map((itemData, index) => {
                                      return (
                                        <>
                                          {
                                            <>
                                              <span key={`snap_${index}`}>
                                                {(index ? ", " : "") +
                                                  itemData.name}
                                              </span>{" "}
                                              X {itemData.quantity}
                                            </>
                                          }
                                        </>
                                      );
                                    })}
                                  </div>
                                  {item.items.length > 1 && (
                                    <>
                                      <button
                                        className="accordion-toggle"
                                        onClick={() => {
                                          //console.log("idx", idx, moreProduct)
                                          if (idx == moreProduct) {
                                            setMoreProduct(-1);
                                          } else {
                                            setMoreProduct(idx);
                                          }
                                        }}
                                      >
                                        <svg
                                          id="Modalità_Isolamento"
                                          data-name="Modalità Isolamento"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 392 392"
                                        >
                                          <path
                                            d="M350,84a11.2,11.2,0,0,0-11.2,11.2V268.8H165.2a11.2,11.2,0,0,0,0,22.4H338.8V464.8a11.2,11.2,0,0,0,22.4,0V291.2H534.8a11.2,11.2,0,1,0,0-22.4H361.2V95.2A11.21,11.21,0,0,0,350,84Z"
                                            transform="translate(-154 -84)"
                                          />
                                        </svg>
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content">
                                {`${item.total}€`}
                              </div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content align-end">
                                <Link
                                  to={
                                    `/${i18n.language}` +
                                    "/" +
                                    i18next.t("routes.YourOrders") +
                                    `/${item.id}`
                                  }
                                  className="btn-action"
                                >
                                  <button className="btn btn-icon">
                                    <svg
                                      id="a"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 21.69 11.09"
                                    >
                                      <path d="M10.89,0C6.82,0,3.01,1.84,.13,5.18c-.08,.1-.13,.23-.13,.36s.05,.26,.16,.36c2.88,3.34,6.69,5.18,10.73,5.18s7.75-1.84,10.68-5.21c.18-.21,.16-.49,0-.67C18.64,1.84,14.83,0,10.89,0h0Zm0,10.06c-3.63,0-7.05-1.61-9.67-4.51C3.84,2.64,7.26,1.04,10.89,1.04s6.89,1.61,9.59,4.51c-2.7,2.9-6.07,4.51-9.59,4.51Z" />
                                      <path d="M10.83,1.4c-2.28,0-4.15,1.87-4.15,4.15s1.87,4.15,4.15,4.15,4.15-1.87,4.15-4.15S13.12,1.4,10.83,1.4Zm0,7.26c-1.71,0-3.11-1.4-3.11-3.11s1.4-3.11,3.11-3.11,3.11,1.4,3.11,3.11-1.4,3.11-3.11,3.11Z" />
                                      <path d="M10.83,2.95c-1.43,0-2.59,1.17-2.59,2.59,0,.29,.23,.52,.52,.52s.52-.23,.52-.52c0-.86,.7-1.56,1.56-1.56,.29,0,.52-.23,.52-.52s-.23-.52-.52-.52h0Z" />
                                    </svg>
                                  </button>
                                </Link>
                                {/* {(item.is_group_order) && <><button onClick={() => {
                                  if (idx == moreProduct) {
                                    setChildOrder(-1)
                                  } else {
                                    setChildOrder(idx)
                                  }
                                }} className="btn btn-primary btn-small" type="button">Open Child Order</button></>} */}
                              </div>
                            </Col>
                          </Row>
                          {moreProduct == idx && (
                            <>
                              <div className="all-products-order">
                                <div className="label">
                                  {i18next.t(
                                    "Pages.Orders.Table.ProductIncluded"
                                  )}
                                  :
                                </div>
                                {item.items.map((itemData, index) => {
                                  return (
                                    <>
                                      <div className="product-item">
                                        {itemData.name} X {itemData.quantity}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          )}
                          {item.is_group_order === true &&
                            item.group_order &&
                            item.group_order.child_order &&
                            item.group_order.child_order.length > 0 && (
                              <div className="group-order-title">
                                <span className="title">
                                  {i18next.t(
                                    "Pages.Orders.Table.ChildOrderLabel"
                                  )}
                                </span>
                              </div>
                            )}
                          {item.is_group_order === true &&
                            item.group_order &&
                            item.group_order.child_order &&
                            item.group_order.child_order.length > 0 &&
                            item.group_order.child_order.map((item, idx) => {
                              return (
                                <div className="subOrder-item">
                                  <Row gutter={[24, 24]}>
                                    <Col
                                      span={2}
                                      xs={{ span: 2 }}
                                      sm={{ span: 2 }}
                                      lg={{ span: 2 }}
                                    >
                                      <div className="table-item__content">
                                        {item.id}
                                      </div>
                                    </Col>
                                    <Col
                                      span={3}
                                      xs={{ span: 3 }}
                                      sm={{ span: 3 }}
                                      lg={{ span: 3 }}
                                    >
                                      <div className="table-item__content">
                                        <DateFormat date={item.created_at} />
                                      </div>
                                    </Col>
                                    <Col
                                      span={4}
                                      xs={{ span: 4 }}
                                      sm={{ span: 4 }}
                                      lg={{ span: 4 }}
                                    >
                                      <div className="table-item__content"></div>
                                    </Col>
                                    <Col
                                      span={4}
                                      xs={{ span: 4 }}
                                      sm={{ span: 4 }}
                                      lg={{ span: 4 }}
                                    >
                                      <div className="table-item__content">
                                        {item.shipping_address && (
                                          <>
                                            {item.shipping_address.company}
                                            <br></br>
                                            {item.shipping_address.firstname}
                                            &nbsp;
                                            {item.shipping_address.lastname}
                                            <br></br>
                                            {item.shipping_address.address_1}
                                            <br></br>
                                            {item.shipping_address.address_2}
                                            {
                                              item.shipping_address.postcode
                                            } - {item.shipping_address.city}
                                          </>
                                        )}
                                      </div>
                                    </Col>
                                    <Col
                                      span={3}
                                      xs={{ span: 3 }}
                                      sm={{ span: 3 }}
                                      lg={{ span: 3 }}
                                    >
                                      <div className="table-item__content">
                                        <div
                                          className={`status ${item.status_key}`}
                                        >
                                          <div className="status-text">
                                            {item.status}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col
                                      span={4}
                                      xs={{ span: 4 }}
                                      sm={{ span: 4 }}
                                      lg={{ span: 4 }}
                                    >
                                      <div className="table-item__content">
                                        <div className="product-in-order">
                                          <div className="products-items">
                                            {item.items.map(
                                              (itemData, index) => {
                                                return (
                                                  <>
                                                    {
                                                      <>
                                                        <span
                                                          key={`snap_${index}`}
                                                        >
                                                          {(index ? ", " : "") +
                                                            itemData.name}
                                                        </span>{" "}
                                                        X {itemData.quantity}
                                                      </>
                                                    }
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col
                                      span={2}
                                      xs={{ span: 2 }}
                                      sm={{ span: 2 }}
                                      lg={{ span: 2 }}
                                    >
                                      <div className="table-item__content">
                                        {`${item.total}€`}
                                      </div>
                                    </Col>
                                    <Col
                                      span={3}
                                      xs={{ span: 2 }}
                                      sm={{ span: 2 }}
                                      lg={{ span: 2 }}
                                    >
                                      <div className="table-item__content align-end">
                                        <Link
                                          to={
                                            `/${i18n.language}` +
                                            "/" +
                                            i18next.t("routes.YourOrders") +
                                            `/${item.id}`
                                          }
                                          className="btn-action"
                                        >
                                          <button className="btn btn-icon">
                                            <svg
                                              id="a"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 21.69 11.09"
                                            >
                                              <path d="M10.89,0C6.82,0,3.01,1.84,.13,5.18c-.08,.1-.13,.23-.13,.36s.05,.26,.16,.36c2.88,3.34,6.69,5.18,10.73,5.18s7.75-1.84,10.68-5.21c.18-.21,.16-.49,0-.67C18.64,1.84,14.83,0,10.89,0h0Zm0,10.06c-3.63,0-7.05-1.61-9.67-4.51C3.84,2.64,7.26,1.04,10.89,1.04s6.89,1.61,9.59,4.51c-2.7,2.9-6.07,4.51-9.59,4.51Z" />
                                              <path d="M10.83,1.4c-2.28,0-4.15,1.87-4.15,4.15s1.87,4.15,4.15,4.15,4.15-1.87,4.15-4.15S13.12,1.4,10.83,1.4Zm0,7.26c-1.71,0-3.11-1.4-3.11-3.11s1.4-3.11,3.11-3.11,3.11,1.4,3.11,3.11-1.4,3.11-3.11,3.11Z" />
                                              <path d="M10.83,2.95c-1.43,0-2.59,1.17-2.59,2.59,0,.29,.23,.52,.52,.52s.52-.23,.52-.52c0-.86,.7-1.56,1.56-1.56,.29,0,.52-.23,.52-.52s-.23-.52-.52-.52h0Z" />
                                            </svg>
                                          </button>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} di ${total} ordini`
                    }
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderRecevied;
