import React, { useState, useEffect } from "react";
import { isArray } from "util";
import i18next from "i18next";
const OtherStepsDataSummary = ({ other, stepTitle, otherStep, quantity, optionalQuantity }) => {
  const [stepsName, setStepsName] = useState({});
  
  
  useEffect(() => {
    if (otherStep) {
      let names = { ...stepsName };
      if (otherStep[other.step_id] && Array.isArray(otherStep[other.step_id])) {
        let final_array = otherStep[other.step_id].filter((id) => quantity[id] > 0)

        names[other.step_id] = final_array.map((id) => { return renderName(id) }).join(",");
      } else {
        names[other.step_id] = renderName(otherStep[other.step_id]);
      }
     
      setStepsName(names);
    }
  }, [otherStep, quantity, optionalQuantity]);


  const renderName = (id) => {
    let data =
      other.allowed_products &&
      other.allowed_products.length > 0 &&
      other.allowed_products.filter((item) => item.id === id);


   
    if (data && data[0] && quantity[data[0].id] > 0) {
      return data[0].title;
    }
  };
  



  return (

    <div className="summary-item summary-size">
      <div className="summary-item__title">
        <h3>{stepTitle}</h3>
      </div>
      <div className="summary-item__value">
        <p>
          {stepsName[other.step_id]
            ? stepsName[other.step_id]
            : i18next.t('Pages.Configurator.NotSelected')}
        </p>
      </div>
    </div>
  );
};

export default OtherStepsDataSummary;
