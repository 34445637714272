import {
  FETCH_ORDER_DETAIL_PENDING,
  FETCH_ORDER_DETAIL_SUCCESS,
  FETCH_ORDER_DETAIL_FAILURE,
  ORDER_RETURN_PENDING,
  ORDER_RETURN_SUCCESS,
  ORDER_RETURN_FAILURE
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  order: {},
  message: '',
  isReturnLoading: false,
  isReturnError: false,
  isReturnSuccess: false
};

export default function OrderReceviedDetail(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case FETCH_ORDER_DETAIL_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        isReturnSuccess: false,
        isReturnError: false,
        order: {},
      });
    case FETCH_ORDER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        order: action.data,
        isLoading: false,
        isError: false,
      });
    case FETCH_ORDER_DETAIL_FAILURE:
      return Object.assign({}, state, {
        message: action.data.data.message,
        isError: true,
        isLoading: false
      });
    case ORDER_RETURN_PENDING:
      return Object.assign({}, state, {
        isReturnLoading: false,
        isReturnError: false,
        isReturnSuccess: false
      });
    case ORDER_RETURN_SUCCESS:
      return Object.assign({}, state, {
        isReturnLoading: false,
        isReturnError: false,
        isReturnSuccess: true
      });
    case ORDER_RETURN_FAILURE:
      return Object.assign({}, state, {
        isReturnLoading: false,
        isReturnError: false,
        isReturnSuccess: false
      });
    default:
      return state;
  }
}