import { request } from "../services/apiService";
import {
  FORGOTPASSWORD_FAILURE,
  FORGOTPASSWORD_PENDING,
  FORGOTPASSWORD_SUCCESS
} from "./actionTypes";
import history from "../history";

export function userForgotPassword(data) {

  return (dispatch) => {
    dispatch({
      type: FORGOTPASSWORD_PENDING
    });
    const datas = { email: data.email }
    const url = `v1/user/reset_password`;
    request("post", url, datas, false).then(
      (response) => {
        dispatch({ type: FORGOTPASSWORD_SUCCESS, message: response.message });
        //history.push("/");
      },
      (error) => {
        console.log("userForgotPassword Error", error.response)
        dispatch({
          type: FORGOTPASSWORD_FAILURE,
          message: error.response.data.message
        });
      }
    );
  };
}
