import {
  FETCH_PRODUCT_STOCK_LIST_PENDING,
  FETCH_PRODUCT_STOCK_LIST_SUCCESS,
  FETCH_PRODUCT_STOCK_LIST_FAILURE
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  message: '',
  productStockData: [],
  total: 0
};

export default function ProductStock(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case FETCH_PRODUCT_STOCK_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true
      });
    case FETCH_PRODUCT_STOCK_LIST_SUCCESS:
      return Object.assign({}, state, {
        productStockData: action.data.data.products,
        total: action.data.data.total_products,
        isLoading: false
      });
    case FETCH_PRODUCT_STOCK_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isLoading: false
      });
    default:
      return state;
  }
}
