import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton, Button, Radio, notification } from 'antd';
import AddressPopup from "../AddressPopup";
import { DeleteOutlined } from '@ant-design/icons';
import i18next from "i18next";
import countryData from '../../utils/country';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress, openAddressPopup, deleteAddress } from "../../actions/addressActions";

const AddressListComponent = ({ type, isAddButton, isSelectable, isEdit, isDelete, defaultAddressId, selectedAddress, AddressUncheck, defaultCountry }) => {
    const dispatch = useDispatch();
    const { isLoading, addresses, isDeleteSucces, message, isAddSuccess, isEditSuccess } = useSelector(state => {
        return state.address
    });
    const [addressData, setAddressData] = useState(null);
    const [selectedId, setSelectedId] = useState(defaultAddressId);



    useEffect(() => {
        dispatch(getAddress(type));
    }, []);

    const onOpenAddressPopup = () => {
        dispatch(openAddressPopup(true))
    }

    useEffect(() => {
        if (isDeleteSucces) {
            notification['success']({
                message: "Address deleted successfully"
            });
        }
        if (isAddSuccess) {
            notification['success']({
                message: "Address added successfully"
            });
        }
        if (isEditSuccess) {
            notification['success']({
                message: "Address updated successfully"
            });
        }
    }, [isDeleteSucces, isAddSuccess, isEditSuccess])

    useEffect(() => {
        if (isSelectable) {
            addresses.filter((item) => {
                if (item.is_default == 1 && (selectedId === null || selectedId === undefined)) {
                    setSelectedId(item.id);
                    selectedAddress(item);
                }
            })
        }
    }, [addresses])

    useEffect(() => {
        if (isSelectable) {
            addresses.filter((item) => {
                if (item.id == selectedId) {
                    setSelectedId(item.id);
                    selectedAddress(item);
                }
            })
        }
    }, [AddressUncheck])

    const onEditAddress = (data) => {
        setAddressData(data);
        dispatch(openAddressPopup(true))
    }

    const onDeleteAddress = (data) => {
        dispatch(deleteAddress({ ID: data.id, type: type }));
    }

    const onSelectAddress = (e) => {
        const data = addresses.filter((item) => item.id == e.target.value);
        setSelectedId(e.target.value);
        if (data.length) {
            selectedAddress(data[0]);
        }
    }

    return (
        <>
            <AddressPopup addressData={addressData} type={type} defaultCountry={defaultCountry} />
            <Skeleton loading={isLoading} >
                {isSelectable && (
                    <Radio.Group value={AddressUncheck ? 0 : selectedId} onChange={onSelectAddress} >
                        <Row gutter={[24, 24]}>
                            {addresses.length > 0 && addresses.map((address, index) => {
                                if (defaultCountry && address.country != defaultCountry) {
                                    return <></>
                                }
                                return (
                                    <Col className="AddressColumn d-flex" xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 6 }} key={index}>
                                        <div className="Select-address Address-list selected" key={'address-' + index}>
                                            {isSelectable && (
                                                <Radio value={address.id}>
                                                    <div className="Address-list-head Flex-box space-between">
                                                        {address.id == selectedId && (
                                                            <div className="Add-select">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.68" height="18.68" viewBox="0 0 18.68 18.68">
                                                                    <g id="noun_Check_Mark_478768" data-name="noun_Check Mark_478768" transform="translate(0.5 0.5)">
                                                                        <path id="Path_30" data-name="Path 30" d="M38.84,47.68a8.84,8.84,0,1,1,8.84-8.84A8.85,8.85,0,0,1,38.84,47.68Zm0-16.669a7.83,7.83,0,1,0,7.83,7.83A7.838,7.838,0,0,0,38.84,31.01Z" transform="translate(-30 -30)" fill="#fff" stroke="#fff" strokeWidth="1" />
                                                                        <path id="Path_31" data-name="Path 31" d="M73.361,91.458a.5.5,0,0,1-.35-.141l-2.273-2.189a.505.505,0,1,1,.7-.728l1.975,1.9,4.311-2.992a.505.505,0,0,1,.576.83l-4.652,3.228A.5.5,0,0,1,73.361,91.458Z" transform="translate(-65.458 -79.994)" fill="#fff" stroke="#fff" strokeWidth="1" />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        )}
                                                        {isEdit && (<a className="editAddress" onClick={() => onEditAddress(address)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.952" height="16.919" viewBox="0 0 16.952 16.919">
                                                                <g id="Group_410" data-name="Group 410" transform="translate(-248.35 -900.85)">
                                                                    <g id="noun_edit_4039414" transform="translate(248.5 901)">
                                                                        <path id="Path_54" data-name="Path 54" d="M23.1,24.119H8.517A1.017,1.017,0,0,1,7.5,23.1V8.517A1.017,1.017,0,0,1,8.517,7.5H19.079v.678H8.517a.339.339,0,0,0-.339.339V23.1a.339.339,0,0,0,.339.339H23.1a.339.339,0,0,0,.339-.339V13.062h.678V23.1A1.017,1.017,0,0,1,23.1,24.119Z" transform="translate(-7.5 -7.5)" fill="#3c434a" stroke="#3c434a" strokeWidth="0.3" />
                                                                        <path id="Path_55" data-name="Path 55" d="M18.455,20.729a.627.627,0,0,1-.438-.18.614.614,0,0,1-.176-.512l.214-1.9a.624.624,0,0,1,.176-.366l9.863-9.9a.9.9,0,0,1,.651-.268h0a.889.889,0,0,1,.641.268l1.272,1.275a.909.909,0,0,1,0,1.279l-9.877,9.9a.607.607,0,0,1-.37.18l-1.882.217Zm.271-2.493-.2,1.808,1.794-.207,9.836-9.883a.231.231,0,0,0,0-.322L28.9,8.356a.227.227,0,0,0-.163-.068h0a.22.22,0,0,0-.159.068ZM18.726,18.236Z" transform="translate(-14.33 -7.573)" fill="#3c434a" stroke="#3c434a" strokeWidth="0.3" />
                                                                        <rect id="Rectangle_193" data-name="Rectangle 193" width="0.678" height="2.934" transform="matrix(0.717, -0.697, 0.697, 0.717, 12.432, 2.111)" fill="#3c434a" stroke="#3c434a" strokeWidth="0.3" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </a>)}
                                                    </div>
                                                    {isDelete && <Button type="text" onClick={() => onDeleteAddress(address)} icon={<DeleteOutlined />} />}
                                                    <div className="Address-list-cnt">
                                                        <h4 className="add-title">{address.company_name && <strong> {address.company_name} - </strong>} {address.firstname} {address.lastname}</h4>
                                                        <div className="Address-label">{i18next.t('Common.Address')}</div>
                                                        <p>{address.address_1} - {address.postcode}<br></br>{address.city} - {address.state} - {countryData[address.country]} </p>
                                                        <div className="Address-label">{i18next.t('Common.Contacts')}</div>
                                                        <p className="mb-0">{address.phone}</p>
                                                    </div>
                                                </Radio>
                                            )}
                                        </div>
                                    </Col>
                                )
                            })}
                            {isAddButton && (
                                <Col className="AddressColumn d-flex" xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 8 }} xxl={{ span: 6 }}>
                                    <Button className="add-new-address" onClick={() => onOpenAddressPopup()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.22" height="15.22" viewBox="0 0 15.22 15.22">
                                            <g id="noun_Plus_705280" transform="translate(-12 -12)">
                                                <g id="Group_296" data-name="Group 296" transform="translate(12 12)">
                                                    <path id="Path_45" data-name="Path 45" d="M19.61,1029.583a.586.586,0,0,1-.585-.586v-6.439H12.585a.585.585,0,0,1,0-1.171h6.439v-6.439a.585.585,0,0,1,1.171,0v6.439h6.439a.585.585,0,0,1,0,1.171H20.2V1029A.586.586,0,0,1,19.61,1029.583Z" transform="translate(-12 -1014.362)" fill="#3c434a" />
                                                </g>
                                            </g>
                                        </svg>
                                        <span>{i18next.t('Common.AddNewAddress')}</span>
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Radio.Group>
                )}
                {!isSelectable && (
                    <Row gutter={[24, 24]}>
                        {addresses.length > 0 && addresses.map((address, key) => {
                            return (
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }} key={key} >
                                    <div className="Address-list selected">
                                        <div className="Address-list-head Flex-box space-between">
                                            {address.is_default == 1 && <div className="Add-select">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.68" height="18.68" viewBox="0 0 18.68 18.68">
                                                    <g id="noun_Check_Mark_478768" data-name="noun_Check Mark_478768" transform="translate(0.5 0.5)">
                                                        <path id="Path_30" data-name="Path 30" d="M38.84,47.68a8.84,8.84,0,1,1,8.84-8.84A8.85,8.85,0,0,1,38.84,47.68Zm0-16.669a7.83,7.83,0,1,0,7.83,7.83A7.838,7.838,0,0,0,38.84,31.01Z" transform="translate(-30 -30)" fill="#fff" stroke="#fff" strokeWidth="1" />
                                                        <path id="Path_31" data-name="Path 31" d="M73.361,91.458a.5.5,0,0,1-.35-.141l-2.273-2.189a.505.505,0,1,1,.7-.728l1.975,1.9,4.311-2.992a.505.505,0,0,1,.576.83l-4.652,3.228A.5.5,0,0,1,73.361,91.458Z" transform="translate(-65.458 -79.994)" fill="#fff" stroke="#fff" strokeWidth="1" />
                                                    </g>
                                                </svg>
                                            </div>
                                            }
                                            {isEdit && (<a className="editAddress" onClick={() => onEditAddress(address)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.952" height="16.919" viewBox="0 0 16.952 16.919">
                                                    <g id="Group_410" data-name="Group 410" transform="translate(-248.35 -900.85)">
                                                        <g id="noun_edit_4039414" transform="translate(248.5 901)">
                                                            <path id="Path_54" data-name="Path 54" d="M23.1,24.119H8.517A1.017,1.017,0,0,1,7.5,23.1V8.517A1.017,1.017,0,0,1,8.517,7.5H19.079v.678H8.517a.339.339,0,0,0-.339.339V23.1a.339.339,0,0,0,.339.339H23.1a.339.339,0,0,0,.339-.339V13.062h.678V23.1A1.017,1.017,0,0,1,23.1,24.119Z" transform="translate(-7.5 -7.5)" fill="#3c434a" stroke="#3c434a" strokeWidth="0.3" />
                                                            <path id="Path_55" data-name="Path 55" d="M18.455,20.729a.627.627,0,0,1-.438-.18.614.614,0,0,1-.176-.512l.214-1.9a.624.624,0,0,1,.176-.366l9.863-9.9a.9.9,0,0,1,.651-.268h0a.889.889,0,0,1,.641.268l1.272,1.275a.909.909,0,0,1,0,1.279l-9.877,9.9a.607.607,0,0,1-.37.18l-1.882.217Zm.271-2.493-.2,1.808,1.794-.207,9.836-9.883a.231.231,0,0,0,0-.322L28.9,8.356a.227.227,0,0,0-.163-.068h0a.22.22,0,0,0-.159.068ZM18.726,18.236Z" transform="translate(-14.33 -7.573)" fill="#3c434a" stroke="#3c434a" strokeWidth="0.3" />
                                                            <rect id="Rectangle_193" data-name="Rectangle 193" width="0.678" height="2.934" transform="matrix(0.717, -0.697, 0.697, 0.717, 12.432, 2.111)" fill="#3c434a" stroke="#3c434a" strokeWidth="0.3" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>)}
                                            {isDelete && <Button type="text" onClick={() => onDeleteAddress(address)} icon={<DeleteOutlined />} />}
                                        </div>
                                        <div className="Address-list-cnt">
                                            
                                            <h4 className="add-title">{address.company_name && <strong> {address.company_name} - </strong>} {address.firstname} {address.lastname}</h4>
                                            <div className="Address-label">{i18next.t('Common.Address')}</div>
                                            <p>{address.address_1} - {address.postcode}<br></br> - {address.city} - {address.state} - {countryData[address.country]} </p>
                                            <div className="Address-label">{i18next.t('Common.Contacts')}</div>
                                            <p className="mb-0">{address.phone}</p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                        )}
                        {isAddButton && (
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                                <Button className="modal-btn" onClick={() => onOpenAddressPopup()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.22" height="15.22" viewBox="0 0 15.22 15.22">
                                        <g id="noun_Plus_705280" transform="translate(-12 -12)">
                                            <g id="Group_296" data-name="Group 296" transform="translate(12 12)">
                                                <path id="Path_45" data-name="Path 45" d="M19.61,1029.583a.586.586,0,0,1-.585-.586v-6.439H12.585a.585.585,0,0,1,0-1.171h6.439v-6.439a.585.585,0,0,1,1.171,0v6.439h6.439a.585.585,0,0,1,0,1.171H20.2V1029A.586.586,0,0,1,19.61,1029.583Z" transform="translate(-12 -1014.362)" fill="#3c434a" />
                                            </g>
                                        </g>
                                    </svg>
                                    <span>{i18next.t('Common.AddNewAddress')}</span>
                                </Button>
                            </Col>
                        )}
                    </Row>
                )}
            </Skeleton>
        </>
    );
};
export default AddressListComponent;
