import {
  CREATE_ORDER_PENDING,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_NOTIFICATION_DONE
} from "../actions/actionTypes";
import Cookies from "js-cookie";
const initialState = {
  isCreatingOrder: false,
  isCreateOrderError: false,
  isCreateOrderSuccess: false,
  createOrderMessage: ''
};

export default function TrainingReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ORDER_PENDING:
      return Object.assign({}, state, {
        isCreatingOrder: true,
        isCreateOrderError: false,
        isCreateOrderSuccess: false,
        createOrderMessage: ''
      });
    case CREATE_ORDER_SUCCESS:
      Cookies.set("stardue-cartcount", 0);
      return Object.assign({}, state, {
        isCreatingOrder: false,
        isCreateOrderError: false,
        isCreateOrderSuccess: true,
        createOrderMessage: action.data.message
      });
    case CREATE_ORDER_FAILURE:
      return Object.assign({}, state, {
        createOrderMessage: action.data.data.message,
        isCreatingOrder: false,
        isCreateOrderError: true,
        isCreateOrderSuccess: false,
      });
    case CREATE_ORDER_NOTIFICATION_DONE:
      return Object.assign({}, state, {
        createOrderMessage: '',
        isCreatingOrder: false,
        isCreateOrderError: false,
        isCreateOrderSuccess: false,
      });
    default:
      return state;
  }
}