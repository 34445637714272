import {
  SET_PLATFORM
} from "../actions/actionTypes";
import Cookies from "js-cookie";

const initialState = {
  platform: Cookies.get('platformkey') ? Cookies.get('platformkey') : '',
  allowedPlatform: localStorage.getItem('platform-access') ? JSON.parse(localStorage.getItem('platform-access')) : []
};

export default function PlatformReducer(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case SET_PLATFORM:
      return Object.assign({}, state, {
        platform: action.data
      });
    default:
      return state;
  }
}
