import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DatePicker from "../../components/DatePicker";
import {
  getProductStockList
} from "../../actions/productStockActions";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Pagination, Select, Input } from "antd";
import { i18n } from "../../translations/i18n";
import paginationLabel from "../../components/PaginationRender";
import { ReactTitle } from 'react-meta-tags';
import DateFormat from "../../components/DateFormat";
import i18next from "i18next";

const ProductStock = () => {
  const dispatch = useDispatch();
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(20);
  const [search, setSearch] = useState('');

  const { isLoading, productStockData, total } = useSelector((state) => {
    return state.productStock;
  });

  const loadOrder = (page) => {
    dispatch(getProductStockList(page, pageSize, search));
  }
  const [resetFilter, setResetFilter] = useState(false);
  const makeResetFilter = () => {
    setSearch('');

  }
  useEffect(() => {
    loadOrder(current);
  }, [current]);

  useEffect(() => {
    const timeout = setTimeout(function () {
      loadOrder(1);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [search]);

  const onFilterApply = () => {
    loadOrder(1);
  };


  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.ProductsStock')} />
      <Header />
      <div className="dashboard-content product-stock">
        <div className="wrap">
          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-side-container">
                <div className="filter-blk">
                  <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>
                  <div className="form-wrapper">
                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.SKU")}</label>
                      </div>
                      <Input
                        type="text"
                        className="txt-search"
                        placeholder=""
                        value={search}
                        onChange={(e) => { setSearch(e.target.value); setResetFilter(true); }}
                      />
                    </div>


                    <div className="form-group multi-selection">
                      <button
                        className="btn btn-primary btn-submit"
                        onClick={onFilterApply}
                      >
                        {i18next.t("Common.FiltersSidebar.Submit")}
                      </button>
                      {resetFilter && <button
                        className="btn btn-only-icon"
                        onClick={makeResetFilter}
                      >
                        <div className="icon">
                          <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1219.76 1087.17"><path d="M1192.6,422.91a18.73,18.73,0,0,0-26.25,3.66L1066.13,558.85a533.06,533.06,0,1,0-15.28,175.31,18.75,18.75,0,1,0-36.28-9.37,496.85,496.85,0,1,1,14.82-154.69L890.64,464.63A18.77,18.77,0,1,0,868,494.54L1040.07,625a18.76,18.76,0,0,0,11.34,3.75,11.89,11.89,0,0,0,2.53,0,18.75,18.75,0,0,0,12.38-7.22l130.31-172a18.76,18.76,0,0,0-4-26.53Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /><path d="M534.78,1141a540.07,540.07,0,1,1,536.61-600.64l89.38-118a25.75,25.75,0,0,1,36-5l3.51,2.65-.08.37a25.75,25.75,0,0,1,2,33.33l-130.32,172a25.54,25.54,0,0,1-17,9.92l-.17,0a18.66,18.66,0,0,1-3.66,0,25.93,25.93,0,0,1-15.19-5.15L863.72,500.12a25.77,25.77,0,0,1,31.14-41.07l126.35,96a490,490,0,1,0-13.45,168.07A25.75,25.75,0,1,1,1057.6,736,540.33,540.33,0,0,1,534.78,1141Zm-.1-1066.17a526.07,526.07,0,1,0,509.39,657.63l.07-.26a11.75,11.75,0,1,0-22.73-5.87l-.06.26a503.9,503.9,0,1,1,15-156.87l.94,15.25L886.4,470.21A11.77,11.77,0,0,0,872.18,489L1044.3,619.37a11.55,11.55,0,0,0,7.08,2.33h.41l.4,0a3.87,3.87,0,0,0,.9,0,11.66,11.66,0,0,0,7.66-4.51l130.3-172a11.75,11.75,0,0,0-2.53-16.61l-.84-.6a11.75,11.75,0,0,0-15.75,2.78L1060.6,577.73l-1.45-18.33A526,526,0,0,0,536.85,74.79Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /></svg>
                        </div>{i18next.t("Common.FiltersSidebar.Reset")}
                      </button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t("Pages.ProductsStock.Title")}</h3>
              </div>
              <div className="service-not-available">
                <p>{i18next.t("Common.ServiceNotAvailable")}</p>
              </div>
              {/* <div className="table rel with-pagination">
                <div className="hdr sticky">
                  <Row gutter={[24, 24]}>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.ProductsStock.Table.CodArt")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.ProductsStock.Table.Groupset")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.ProductsStock.Table.TG")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.ProductsStock.Table.ArtDescription")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.ProductsStock.Table.QRes")}</div>
                    </Col>

                  </Row>
                </div>
                <div className={isLoading ? "loader-container" : "loader-container is-loaded"}>
                  <span className="loader"></span>
                </div>
                <div className="table-container-wrapper">
                  <div className="table-wrap">
                    {!isLoading && productStockData.length > 0 &&
                      productStockData.map((item, idx) => (
                        <div className="table-item" key={idx}>

                          <Row gutter={[24, 24]}>
                            <Col
                            >
                              <div className="table-item__content">{item.item_code}</div>
                            </Col>
                            <Col
                            >
                              <div className="table-item__content">{item.item_group} </div>
                            </Col>
                            <Col
                            >
                              <div className="table-item__content">{item.size}</div>
                            </Col>
                            <Col
                            >

                              <div className="table-item__content">{item.item_description}</div>
                            </Col>
                            <Col
                            >
                              
                              <div className="table-item__content">
                                {item.quantity_release >= 5 && (
                                  <div className="qty-with-badge">
                                    <div className="badge-circular prod-available"></div>
                                  </div>
                                )}
                                {item.quantity_release >= 1 && item.quantity_release <= 4 && (
                                  <div className="qty-with-badge">
                                    <div className="badge-circular prod-limited"></div>
                                  </div>
                                )}
                                
                                </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} ordini`}
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
              </div> */}
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductStock;
