import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getOrderStatusList } from "../../actions/orderReceivedActions";
import { getSupportDealerOrderList } from "../../actions/supportDealerOrderAction";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Pagination, Skeleton, Select } from "antd";
import { i18n } from "../../translations/i18n";
import paginationLabel from "../../components/PaginationRender";
import { ReactTitle } from 'react-meta-tags';
import DatePicker from "../../components/DatePicker";
import moment from 'moment';
import { saveAs } from "file-saver";
import i18next from "i18next";

const { Option } = Select;
const SupportDealerOrder = () => {
  const dispatch = useDispatch();
  const [selectedStatus, setselectedStatus] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(10);

  const [filterButton, setFilterButton] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const { status } = useSelector((state) => {
    return state.orderRecived;
  });
  const { isLoading, ordersList, total } = useSelector((state) => {
    return state.supportDealerOrder;
  });

  useEffect(() => {
    dispatchSupportDealer();
    dispatch(getOrderStatusList());
  }, []);

  useEffect(() => {
    dispatchSupportDealer();
  }, [current]);

  const onFilterApply = () => {
    dispatchSupportDealer();
  };
  const dispatchSupportDealer = () => {
    dispatch(getSupportDealerOrderList(current, pageSize, selectedStatus, startDate, endDate));
  }
  //-----------reset filter ---------//
  const makeResetFilter = () => {
    setselectedStatus('');
    setstartDate("");
    setendDate("");
    setResetFilter(true);
    setFilterButton(false);
  }
  useEffect(() => {
    if (resetFilter) {
      dispatchSupportDealer();
    }
  }, [resetFilter]);
  //-----------reset filter ---------//


  return (
    <>
      <ReactTitle title="Dashboard - Order Received" />
      <Header />
      <div className="dashboard-content prd-list">
        <div className="wrap">

          <div className="page-title">
            <h3>Support Dealer Order</h3>
          </div>

          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-side-container">
                <div className="filter-blk">
                 <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>

                  <div className="form-wrapper">
                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.State")}</label>
                      </div>
                      <Select
                        value={selectedStatus}
                        onChange={(value) => {
                          setselectedStatus(value);
                          setResetFilter(false);
                          setFilterButton(true);
                        }}
                      >
                        <Option value="">{i18next.t("Common.FiltersSidebar.All1")}</Option>
                        {Object.keys(status).length > 0 &&
                          Object.keys(status).map((key) => (
                            <Option value={key}>{status[key]}</Option>
                          ))}
                      </Select>
                    </div>
                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.FromDate")}</label>
                      </div>
                      <DatePicker setDay={setstartDate} defultDay={startDate} reset={setFilterButton} postDate={endDate}/>
                    </div>
                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.ToDate")}</label>
                      </div>
                      <DatePicker setDay={setendDate} defultDay={endDate} reset={setFilterButton} preDate={startDate}/>
                    </div>
                  </div>

                </div>

                <button
                  className="btn btn-primary btn-submit"
                  onClick={onFilterApply}
                >
                  {i18next.t("Common.FiltersSidebar.Submit")}
                </button>
                {filterButton &&
                  <button
                    className="btn btn-primary btn-submit"
                    onClick={makeResetFilter}
                  >
                    Reset Filter
                  </button>}

              </div>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="table rel with-pagination">
                <div className="hdr sticky">
                  <Row gutter={[24, 24]}>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">ID</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">Data ordine</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">Stato</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">Customer</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">Shipping Type</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">Bike</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">Other Product</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">Action</div>
                    </Col>
                  </Row>
                </div>
                <div className="table-container-wrapper">
                  <div className={isLoading ? "loader-container" : "loader-container is-loaded"}>
                    <span className="loader"></span>
                    <div className="loader-text">Stiamo caricando i tuoi ordini...</div>
                  </div>
                  <div className="table-wrap">
                    {ordersList.length > 0 &&
                      ordersList.map((item, idx) => (
                        <div className="table-item" key={idx}>

                          <Row gutter={[24, 24]}>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">{item.id}</div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">{item.created_at}</div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                <div className={`status ${item.status_key}`}>
                                  <div className="status-text">{item.status}</div>
                                </div>
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                {item.user.first_name} {item.user.last_name}
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                {item.shipping_type}
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                              {
                                  item.items.map((product) => (
                                    product.item_metas.type == 'bike' &&
                                    <div>{product.item_metas.configurator_name} - {product.item_metas.size_name} - {product.item_metas.color_name}</div>

                                  ))}
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                {
                                  item.items.map((product) => (
                                    product.item_metas.type == 'other_products' &&
                                    <div>{product.sku + ' , '}</div>

                                  ))}
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                
                                {
                                  item.items.map((product) => (
                                    product.item_metas.type == 'bike' &&
                                    <div><button name="configurator_pdf" onClick={()=> saveAs(product.item_metas.pdf)}>Configurator Pdf</button></div>

                                  ))}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} ordini`}
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default SupportDealerOrder;
