import { request } from "../services/apiService";
import {
  FETCH_PRODUCT_LIST_PENDING,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILURE,
  FETCH_PRODUCT_FILTER_PENDING,
  FETCH_PRODUCT_FILTER_SUCCESS,
  FETCH_PRODUCT_FILTER_FAILURE,
  GET_PRODUCT_SIZE_PENDING ,
  GET_PRODUCT_SIZE_SUCCESS,
  GET_PRODUCT_SIZE_FAILURE
} from "./actionTypes";

export const getProductList = (page, size, search, min_price, max_price, filter_level_1, filter_level_2, filter_level_3, selectedModel, order, orderby) => dispatch => {
  dispatch({ type: FETCH_PRODUCT_LIST_PENDING });
  var url = "v1/db/products/?page=" + page + "&size=" + size + "&search=" + search + "&min_price=" + min_price + "&max_price=" + max_price + "&filter_level_1=" + filter_level_1 + "&filter_level_2=" + filter_level_2 + "&filter_level_3=" + filter_level_3 + "&models=" + selectedModel+ "&order=" + order+ "&orderby=" + orderby
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: FETCH_PRODUCT_LIST_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: FETCH_PRODUCT_LIST_FAILURE,
        data: error.response,
      });
    }
  );
}

export const getProductFilter = () => dispatch => {
  dispatch({ type: FETCH_PRODUCT_FILTER_PENDING });
  var url = "v1/db/products/filter"
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: FETCH_PRODUCT_FILTER_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: FETCH_PRODUCT_FILTER_FAILURE,
        data: error.response,
      });
    }
  );
}

export const getProductSize = (data) => dispatch => {
  dispatch({ type: GET_PRODUCT_SIZE_PENDING });
  var url = "v1/products/size"
  request("post", url, data, true).then(
    (res) => {
      dispatch({
        type: GET_PRODUCT_SIZE_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: GET_PRODUCT_SIZE_FAILURE,
        data: error.response,
      });
    }
  );
}