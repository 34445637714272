import Cookies from "js-cookie";
import {
  FETCH_ADDRESS_PENDING,
  FETCH_ADDRESS_SUCCESS,
  FETCH_ADDRESS_FAILURE,
  ADD_ADDRESS_PENDING,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAILURE,
  DELETE_ADDRESS_PENDING,
  DELETE_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_PENDING,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAILURE,
  ADDRESS_POPUP
} from "../actions/actionTypes";
import data from "../utils/country";

const initialState = {
  isLoading: false,
  isError: false,
  message: '',
  addresses: [],
  isDeleteSucces: false,
  isAddressPopup: false,
  isAddSuccess: false,
  isEditSuccess: false
};

export default function address(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case FETCH_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isDeleteSucces: false,
        isAddSuccess: false,
        isEditSuccess: false,
        isLoading: true,
      });
    case FETCH_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        addresses: action.data,
        isLoading: false,
      });
    case FETCH_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isError: true,
        isLoading: false
      });
    case ADD_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isAddressPopup: true,
      });
    case ADD_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        addresses: action.data,
        isAddressPopup: false,
        isLoading: false,
        isDeleteSucces: false,
        isAddSuccess: true,
        isEditSuccess: false,
      });
    case ADD_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isAddressPopup: false,
        isDeleteSucces: false,
        isAddSuccess: false,
        isEditSuccess: false,
      });
    case UPDATE_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isAddressPopup: true,
        isDeleteSucces: false,
        isAddSuccess: false,
        isEditSuccess: false,
      });
    case UPDATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        addresses: action.data,
        isAddressPopup: false,
        isLoading: false,
        isDeleteSucces: false,
        isAddSuccess: false,
        isEditSuccess: true,
      });
    case UPDATE_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isAddressPopup: false,
        isDeleteSucces: false,
        isAddSuccess: false,
        isEditSuccess: false,
      });
    case DELETE_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isDeleteSucces: false,
        isLoading: true,
        isDeleteSucces: false,
        isAddSuccess: false,
        isEditSuccess: false,
      });
    case DELETE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        message: action.data.message,
        isDeleteSucces: true,
        isAddSuccess: false,
        isEditSuccess: false,
      });
    case DELETE_ADDRESS_FAILURE:
      return Object.assign({}, state, {
        isDeleteSucces: false,
        isAddSuccess: false,
        isEditSuccess: false,
        isLoading: false,
      });
    case ADDRESS_POPUP:
      return Object.assign({}, state, {
        isAddressPopup: action.data,
      });
    default:
      return state;
  }
}
