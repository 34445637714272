import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../actions/newsAction";
import { Pagination, Skeleton } from "antd";
import { i18n } from "../../translations/i18n";
import paginationLabel from "../../components/PaginationRender";
import { ReactTitle } from 'react-meta-tags';
import i18next from "i18next";
const BestNews = () => {
  const dispatch = useDispatch();
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(3);

  const { isLoading, news, total } = useSelector((state) => {
    return state.news;
  });

  useEffect(() => {
    dispatch(getNews(current, pageSize, ""));
  }, [current]);

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.B2BNews')} />
      <Header />
      <div className="dashboard-content best-news normal-scroll">
        <div className="wrap">
          <div className="dashboard-content-wrapper">
            <div className="page-title">
              <h3>{i18next.t('Pages.News.PageTitle')}</h3>
            </div>
            {isLoading && <>
              <div className="loader-news">
                <span className="loader"></span>
                <div className="loader-text">{i18next.t('Pages.News.Loader')}</div>
              </div>
              </>}
            <div className="page-content">
              <div className="news-grid">
                {news.length > 0 &&
                  news.map((item, index) => (
                    <div className="news-card">
                      <div className="news-image">
                        <Link
                          to={"/" + i18n.language + '/' + i18next.t('routes.B2BNews') + '/' + item.slug}>
                          <img src={item.image}></img>
                        </Link>
                      </div>
                      <div className="news-preview-content">
                        <div className="news-date">{item.date}</div>
                        <div className="news-title">
                          <h3>{item.title}</h3>
                        </div>
                        <div className="news-excerpt">
                          <p>{item.description}</p>
                        </div>
                      </div>
                      <div className="news-cta">
                        <Link
                          to={"/" + i18n.language + '/' + i18next.t('routes.B2BNews') + '/' + item.slug}
                        >
                          <button className="btn btn-primary">
                            {i18next.t('Common.DiscoverButton')}
                          </button>
                        </Link>
                      </div>
                      {/* <div className="news-tag s16 b5 cfff font">
                        {item.type.name}
                      </div> */}
                    </div>
                  ))}
              </div>
              {!isLoading &&  <>
              <div className="pagination-wrapper">
                <Pagination
                  showSizeChanger={false}
                  current={current}
                  pageSize={pageSize}
                  total={total}
                  onChange={(page) => setcurrent(page)}
                  itemRender={paginationLabel}
                />
              </div>
              </>}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BestNews;
