import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from '../../components/Footer';
import { Checkbox, TimePicker, Button, Form, Skeleton, Row, Col, Input, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { updateDealerData, getDealerData, setDealerData } from "../../actions/userManage";
import moment from 'moment';
import { ReactTitle } from 'react-meta-tags';
import { updateDealerPersonalData } from "../../actions/userManage";
import AddressListComponent from "../../components/AddressListComponent";
import i18next from "i18next";

const UserManagement = () => {

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isUpdateLoading, isGetLoading, updateData, dealerData } = useSelector(state => {
    return state.userManage
  });

  const [data, setData] = useState({})

  const [UserData, setUserData] = useState(JSON.parse(localStorage.getItem("user")))

  const dayNames = [
    { id: 1, lbl: i18next.t('Pages.UserDetails.TimeTable.monday'), key: 'monday' },
    { id: 2, lbl: i18next.t('Pages.UserDetails.TimeTable.tuesday'), key: 'tuesday' },
    { id: 3, lbl: i18next.t('Pages.UserDetails.TimeTable.wednesday'), key: 'wednesday' },
    { id: 4, lbl: i18next.t('Pages.UserDetails.TimeTable.thursday'), key: 'thursday' },
    { id: 5, lbl: i18next.t('Pages.UserDetails.TimeTable.friday'), key: 'friday' },
    { id: 6, lbl: i18next.t('Pages.UserDetails.TimeTable.saturday'), key: 'saturday' },
    { id: 7, lbl: i18next.t('Pages.UserDetails.TimeTable.sunday'), key: 'sunday' },
  ]

  useEffect(() => {
    dispatch(getDealerData());
  }, []);
  useEffect(() => {
    if (Object.keys(dealerData).length > 0) {
      setData(dealerData)
    }
  }, [dealerData]);

  const handleFormSubmit = () => {
    dispatch(updateDealerData({ "data": data }));
  }

  const onCheckBoxChange = (day, field, isChecked) => {
    data[day][field] = isChecked;
    if (field == 'is_morning_close' || field == 'is_afternoon_close') {
      data[day]['is_fullday_open'] = false;
    }
    dispatch(setDealerData(data));
  }
  const onTimerChange = (day, field, time) => {
    data[day][field] = time;
    dispatch(setDealerData(data));
  }

  const { isDealerUpdateLoading, isDealerUpdateError, isDealerUpdateSucces, dealerUpdateData } = useSelector(state => {
    return state.userManage
  });
  useEffect(() => {
    if (dealerUpdateData.length > 0) {
      setUserData(dealerUpdateData)
    }
  }, [dealerUpdateData]);
  const onFinish = (values) => {
    dispatch(updateDealerPersonalData(values));
  }
  useEffect(() => {
    if (isDealerUpdateSucces) {
      notification["success"]({
        message: 'Dealer Updated Succesfully',
        description:
          'Dealer personal information you fill here is updated succesfully',
      });
    }
    if (isDealerUpdateError) {
      notification["error"]({
        message: 'Error While Updating',
        description:
          'There is some error while upade dealer personal information',
      });
    }
  }, [dealerUpdateData, isDealerUpdateError]);
  console.log("UserData", UserData)
  return (
    <>

      <ReactTitle title="Dashboard" />
      <Header />
      <div className="dashboard-content normal-scroll user-management">
        <div className="wrap">

          <div className="page-title">
            <h3>{i18next.t('Pages.UserDetails.YourData')}</h3>
          </div>
          <div className="user-details">
            {/* <Row gutter={[{ xs: 24, sm: 24, lg: 24 }, { xs: 24, sm: 24, lg: 24 }]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}> */}
                {/* <div className="anagraphic-data">
                  <div className="anagraphic-form">
                    <Form
                      form={form}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      initialValues={{
                        address: UserData.dealer.street,
                        city: UserData.dealer.city,
                        country: UserData.dealer.country,
                        postal_code: UserData.dealer.postal_code,
                        state: UserData.dealer.state,
                        phone: UserData.dealer.phone,
                        email: UserData.dealer.email,
                        company_name: UserData.dealer.company_name,
                        vat: UserData.dealer.vat,
                        email_account: UserData.email
                      }}
                      onFinish={(e) => onFinish(e)}>
                      <div className="Form-wrapper">
                        <div className="headline-form with-number-step">
                          <div className="title with-submit">
                            <span>{i18next.t('Pages.UserDetails.Anagraphic')}</span>
                            <Button
                              className="btn btn-primary"
                              type="primary"
                              htmlType={"submit"}
                              loading={isDealerUpdateLoading}
                            >
                              <span>
                                {i18next.t('Common.Form.Update')}
                              </span>
                            </Button>
                          </div>
                        </div>
                        <div className="form-wrapper">

                          <Row gutter={[{ xs: 8, sm: 16, lg: 24 }, { xs: 0, sm: 16, lg: 0 }]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
                              <label className="Form-label">{i18next.t('Pages.UserDetails.Company')}</label>
                              <Form.Item
                                name="company_name"
                                rules={[{ required: false, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
                              <label className="Form-label">{i18next.t('Pages.UserDetails.VAT')}</label>
                              <Form.Item
                                name="vat"
                                rules={[{ required: false, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                              <label className="Form-label">{i18next.t('Pages.UserDetails.EmailAccount')}</label>
                              <Form.Item
                                name="email_account"
                                rules={[{ required: false, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input disabled={true} />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                              <div className="title-divider">
                                <p>{i18next.t('Pages.UserDetails.StoreLocatorTitle')}</p>
                              </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 6 }}>
                              <label className="Form-label">{i18next.t('Common.Form.country')}</label>
                              <Form.Item
                                name="country"
                                rules={[{ required: true, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
                              <label className="Form-label">{i18next.t('Common.Form.city')}</label>
                              <Form.Item
                                name="city"
                                rules={[{ required: true, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 6 }}>
                              <label className="Form-label">{i18next.t('Common.Form.province')}</label>
                              <Form.Item
                                name="state"
                                rules={[{ required: true, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 18 }}>
                              <label className="Form-label">{i18next.t('Common.Form.street')}</label>
                              <Form.Item
                                name="address"
                                rules={[{ required: true, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 6 }}>
                              <label className="Form-label">{i18next.t('Common.Form.postalcode')}</label>
                              <Form.Item
                                name="postal_code"
                                rules={[{ required: true, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 14 }}>
                              <label className="Form-label">{i18next.t('Common.Form.PublicEmail')}</label>
                              <Form.Item
                                name="email"
                                rules={[{ required: true, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 10 }}>
                              <label className="Form-label">{i18next.t('Common.Form.phone')}</label>
                              <Form.Item
                                name="phone"
                                rules={[{ required: true, message: i18next.t('Common.Form.requireField') }]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    </Form>
                  </div>
                </div> */}
                <div className="user-address">
                  <div className="title"><span>{i18next.t('Pages.Address.Header.Title')}</span></div>
                  <div className="userAddressContainer">
                    <div className="User-address-row">
                      <h3>{i18next.t('Pages.Address.ShippingAddress.Label')}</h3>
                      <AddressListComponent type="shipping" isEdit="true" isAddButton="true" />
                    </div>
                    {/* <div className="User-address-row">
                      <h3>{i18next.t('Pages.Address.BillingAddress.Label')}</h3>
                      <AddressListComponent type="billing" isEdit="true" isAddButton="true" />
                    </div> */}
                  </div>
                </div>
              {/* </Col> */}
              {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
                <div className="timeTables-side flex flex-col">
                  {/* <Form > */}
                  {/* <div className="timeTables__wrapper">
                    <div className="title with-save">{i18next.t('Pages.UserDetails.TimeTable.ShopTime')}
                      <div className='save-btn'>
                        <Button htmlType="submit" onClick={handleFormSubmit} className="btn btn-primary" loading={isUpdateLoading}>Salva orari</Button>
                      </div>
                    </div>
                    <Skeleton loading={isGetLoading}>
                      <div className="timetable-col flex flex-col">
                        {
                          Object.keys(data).length > 0 && dayNames.map((item, index) => (
                            <div key={index} className="timetable-row flex flex-col form-wrapper">
                              <div className="day-block flex">
                                <div className="day-lbl">{item.lbl}</div>
                                <div className="day-shedule flex aic">

                                  <Checkbox
                                    day={item.key}
                                    checked={data[item.key].is_fullday_open}
                                    field='is_fullday_open'
                                    className="checkbox-stardue"
                                    onChange={
                                      (event) => {
                                        onCheckBoxChange(event.target.day, event.target.field, event.target.checked)
                                      }
                                    }>
                                    <span className="info-checkbox">
                                      <div className="check-lbl">{i18next.t('Pages.UserDetails.TimeTable.nonStop')}</div>
                                    </span>
                                  </Checkbox>
                                </div>
                              </div>
                              <div className="days-table">
                                <div className="day-row">
                                  <div className="day-type">
                                    <span>{i18next.t('Pages.UserDetails.TimeTable.morning')}</span>
                                  </div>
                                  <div className="from-time">
                                    <span className='label'>{i18next.t('Pages.UserDetails.TimeTable.start')}</span>
                                    <div className="time-picker__wrapper">
                                      <TimePicker day={item.key} defaultValue={moment(dealerData[item.key].morning_start_time, 'HH:mm')} field='morning_start_time' format='HH:mm' onChange={(time) => {
                                        onTimerChange(item.key, 'morning_start_time', moment(time).format('HH:mm'))
                                      }} disabled={dealerData[item.key].is_morning_close} />
                                    </div>
                                  </div>
                                  <div className="to-time">
                                    <span className='label'>{i18next.t('Pages.UserDetails.TimeTable.end')}</span>
                                    <div className="time-picker__wrapper">
                                      <TimePicker format='HH:mm' defaultValue={moment(dealerData[item.key].morning_end_time, 'HH:mm')} onChange={(time) => {
                                        onTimerChange(item.key, 'morning_end_time', moment(time).format('HH:mm'))
                                      }} disabled={dealerData[item.key].is_fullday_open || dealerData[item.key].is_morning_close} />
                                    </div>
                                  </div>
                                  <div className="close">
                                    <Checkbox
                                      day={item.key}
                                      checked={data[item.key].is_morning_close}
                                      className="checkbox-stardue"
                                      field='is_morning_close' onChange={
                                        (event) => {
                                          onCheckBoxChange(event.target.day, event.target.field, event.target.checked)
                                        }
                                      }>
                                      <span className="info-checkbox">
                                        <div className="check-lbl">{i18next.t('Pages.UserDetails.TimeTable.closed')}</div>
                                      </span>
                                    </Checkbox>
                                  </div>
                                </div>
                                <div className="day-row">
                                  <div className="day-type">
                                    <span>{i18next.t('Pages.UserDetails.TimeTable.afternoon')}</span>
                                  </div>
                                  <div className="from-time">
                                    <span className='label'>{i18next.t('Pages.UserDetails.TimeTable.start')}</span>
                                    <div className="time-picker__wrapper">
                                      <TimePicker format='HH:mm' defaultValue={moment(dealerData[item.key].afternoon_start_time, 'HH:mm')} onChange={(time) => {
                                        onTimerChange(item.key, 'afternoon_start_time', moment(time).format('HH:mm'))
                                      }} disabled={dealerData[item.key].is_afternoon_close || dealerData[item.key].is_fullday_open} />
                                    </div>
                                  </div>
                                  <div className="to-time">
                                    <span className='label'>{i18next.t('Pages.UserDetails.TimeTable.end')}</span>
                                    <div className="time-picker__wrapper">
                                      <TimePicker format='HH:mm' defaultValue={moment(dealerData[item.key].afternoon_end_time, 'HH:mm')} onChange={(time) => {
                                        onTimerChange(item.key, 'afternoon_end_time', moment(time).format('HH:mm'))
                                      }} disabled={dealerData[item.key].is_afternoon_close} />
                                    </div>
                                  </div>
                                  <div className="close">
                                    <Checkbox
                                      day={item.key}
                                      checked={data[item.key].is_afternoon_close}
                                      className="checkbox-stardue"
                                      field='is_afternoon_close' onChange={
                                        (event) => {
                                          onCheckBoxChange(event.target.day, event.target.field, event.target.checked)
                                        }
                                      }>
                                      <span className="info-checkbox">
                                        <div className="check-lbl">{i18next.t('Pages.UserDetails.TimeTable.closed')}</div>
                                      </span>
                                    </Checkbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        }

                      </div>
                    </Skeleton>
                  </div> */}
                  {/* </Form> */}
                {/* </div>
              </Col> */} 
            {/* </Row> */}
          </div>
        </div>
      </div >
      <Footer />
    </>
  )
}

export default UserManagement
