import { request } from "../services/apiService";
import {
    NEWS_LIST_PENDING,
    NEWS_LIST_SUCCESS,
    NEWS_LIST_FAILURE,
    NEWS_DETAILS_PENDING,
    NEWS_DETAILS_SUCCESS,
    NEWS_DETAILS_FAILURE,
} from "./actionTypes";


export const getNews = (page, size, type) => dispatch => {
    dispatch({
        type: NEWS_LIST_PENDING,
        payload: { isLoading: true }
    });
    const url = `v1/b2bnews/list/?page=` + page + `&size=` + size + `&type=` + type;
    request("get", url, '', true).then(
        (res) => {
            dispatch({
                type: NEWS_LIST_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: NEWS_LIST_FAILURE,
                data: err.response,
            });
        }
    );
}

export const getNewsDetails = (slug) => dispatch => {
    dispatch({
        type: NEWS_DETAILS_PENDING
    });
    const url = `v1/b2bnews/` + slug;
    request("get", url, '', true).then(
        (res) => {
            dispatch({
                type: NEWS_DETAILS_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: NEWS_DETAILS_FAILURE,
                data: err.response,
            });
        }
    );
}