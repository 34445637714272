import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { i18n } from "../../translations/i18n";
import i18next from "i18next";
const ResetPasswordComponent = ({
  onSubmitClick,
  isLoading,
  isError,
  isSuccess,
  message,
  email
}) => {

  const [form] = Form.useForm();

  if (isSuccess == true) {
    form.resetFields();
  }
  return (
    <>

      <div className="login-page">
        <div className="login-wrapper">
          <div className="login-image">
            <div
              className="graphic"
              style={{ backgroundImage: "url(/images/B2B_main.jpg" }}
            />
          </div>
          <div className="login-content">
            <div className="login-content__wrapper">
              <div className="logo">
                <Link to={"/" + i18n.language}>
                  <img src="/images/logo.svg" className="img" />
                </Link>
              </div>
              <div className="login-form">
                <div className="form-title">
                  <h1>{i18next.t('Pages.NewPassword.Title')}</h1>
                  <p>{i18next.t('Pages.NewPassword.Subtitle')}</p>
                </div>
                <div className="form-wrapper">
                  <Form
                    form={form}
                    onFinish={onSubmitClick}
                    layout="vertical"
                    initialValues={{
                      ["email"]: email
                    }}
                  >
                    <div className="form-label">
                      <label>{i18next.t('Pages.NewPassword.Email')}</label>
                    </div>
                    <Form.Item
                      
                      name={"email"}
                      rules={[
                        {
                          required: true,
                          message: i18next.t('Common.Form.require_email'),
                        },
                      ]}
                      value={email}
                    >
                      <Input disabled placeholder="" />
                    </Form.Item>
                    <div className="form-label">
                      <label>{i18next.t('Pages.NewPassword.Password')}</label>
                    </div>
                    <Form.Item
                      name={"password"}
                      rules={[
                        {
                          required: true,
                          message: i18next.t('Common.Form.require_password'),
                        },
                      ]}
                    >
                      <Input.Password placeholder="" />
                    </Form.Item>
                    <div className="form-label">
                      <label>{i18next.t('Pages.NewPassword.ConfirmPassword')}</label>
                    </div>
                    <Form.Item
                      name={"confirm_password"}
                      rules={[
                        {
                          required: true,
                          message: i18next.t('Common.Form.require_password'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            console.log("value", getFieldValue('password'))
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(new Error(i18next.t('Common.PasswordMatchError')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="" />
                    </Form.Item>
                    <Form.Item>
                      <Button block className="btn btn-primary" type="primary" htmlType="submit" loading={isLoading}>
                      {i18next.t('Pages.NewPassword.Submit')}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                {isError && <div className="message message-error">{message}</div>}
                {isSuccess && <div className="message message-success">{message}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResetPasswordComponent;
