import Cookies from "js-cookie";
import { SET_PLATFORM } from "./actionTypes";
import history from "../history";
import { i18n } from "../translations/i18n";
import { getCountryData } from "./loginActions";

export const setPlatform = (key, isredirect) => (dispatch) => {
  Cookies.set("platformkey", key, { expires: 30 });
  dispatch(getCountryData());
  dispatch({
    type: SET_PLATFORM,
    data: key,
  });

  if (isredirect) {
    history.push("/" + i18n.language + "/dashboard");
  }
};
