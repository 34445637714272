import { request } from "../services/apiService";
import {
  FETCH_ORDER_LIST_PENDING,
  FETCH_ORDER_LIST_SUCCESS,
  FETCH_ORDER_LIST_FAILURE,
  FETCH_STATUS_LIST_SUCCESS,
  FETCH_STATUS_LIST_PENDING,
  FETCH_STATUS_LIST_FAILURE,
} from "./actionTypes";

export const getOrderList = (page, size, status, start_date, end_date, order, orderBy, search, order_for_dealer) => dispatch => {
  dispatch({ type: FETCH_ORDER_LIST_PENDING });
  var url = "v1/db/orders/?page=" + page + "&size=" + size + "&status=" + status + "&start_date=" + start_date + "&end_date=" + end_date + "&order=" + order + "&orderBy=" + orderBy + "&search=" + search + "&order_for_dealer=" + order_for_dealer
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: FETCH_ORDER_LIST_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: FETCH_ORDER_LIST_FAILURE,
        data: error.response,
      });
    }
  );
}

export const getOrderStatusList = () => dispatch => {
  dispatch({ type: FETCH_STATUS_LIST_PENDING });
  var url = "v1/db/status"
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: FETCH_STATUS_LIST_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: FETCH_STATUS_LIST_FAILURE,
        data: error.response,
      });
    }
  );
}