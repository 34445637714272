import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Input, Space, Row, Col, Dropdown, Menu, Button, Pagination, Select } from 'antd';
import { ReactTitle } from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { getConfiguratorList, getGroupsetFE, downloadPDF } from "../../actions/configuratorActions";
import paginationLabel from "../../components/PaginationRender";
import PriceFormat from "../../components/PriceFormat";
import ConfiguratorSummary from "../../components/ConfiguratorPopup";
import { getModelFE } from "../../actions/registerBike";
import i18next from "i18next";
import { i18n } from "../../translations/i18n";
import Cookie from "js-cookie";

import { saveAs } from "file-saver";
const { Option } = Select;

const ConfiguratorList = () => {
  const [configuratorPopupData, setConfiguratorPopupData] = useState('')
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedGroupset, setSelectedGroupset] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedConfigurator, setSelectedConfigurator] = useState("");
  const [resetFilter, setResetFilter] = useState(false);
  const [downloadPdfId, setDownloadPdfId] = useState('')
  const dispatch = useDispatch();
  const { configuratorListLoading, configuratorList, totalConfigurators, groupsetList, cofiguratorPDF } = useSelector((state) => {
    return state.configure;
  });

  const { modelList } = useSelector(state => {
    return state.registerBike
  });
  useEffect(() => {
    dispatch(getGroupsetFE());
    dispatch(getModelFE());
  }, []);

  const configuratorPopup = (data) => {
    setConfiguratorPopupData(data.configurator_id);
    setisSummaryPopup(true);
  }

  const [isSummaryPopup, setisSummaryPopup] = useState(false)
  useEffect(() => {
    getConfigurator();
  }, [current, pageSize]);

  const makeResetFilter = () => {
    setSelectedModel("");
    setSelectedConfigurator("");
    setSelectedOrder("");
    setSelectedGroupset("");
    setResetFilter(false);
  }
  const onFilterApply = () => {
    setResetFilter(true);
    getConfigurator();
  };

  const getConfigurator = () => {
    dispatch(getConfiguratorList(current, pageSize, selectedModel, selectedOrder, selectedConfigurator, selectedGroupset));
  }

  const downloadConfiguratorPDF = (id) => {
    setDownloadPdfId(id);
    dispatch(downloadPDF(id));
  }

  useEffect(() => {
    if (cofiguratorPDF != undefined && cofiguratorPDF.url) {
      saveAs(cofiguratorPDF.url, "your-new-"+(Cookie.get("platformkey") == '7368496012' ? 'basso' : 'leecougan')+"-bike-" + downloadPdfId + ".pdf")
    }
  }, [cofiguratorPDF]);
  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.ConfigurationsSaved')} />
      <Header />
      <div className="dashboard-home">
        <div className="dashboard-content">
          <div className="wrap">
            <div className="page-content">
              <div className="left-side">
                <div className="filter-side-container">
                  <div className="filter-blk">
                    <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>

                    <div className="form-wrapper">
                      <div className="form-group">
                        <div className="form-label">
                          <label>{i18next.t("Common.FiltersSidebar.Model")}</label>
                        </div>
                        <Select value={selectedModel}
                          dropdownClassName="dropdown-select-s2"
                          disabled={false}
                          onChange={(value) => {
                            setResetFilter(true);
                            setSelectedModel(value)
                          }}
                          placeholder={i18next.t("Common.FiltersSidebar.Model")}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Option key={"All"} value={""}>{i18next.t("Common.FiltersSidebar.All1")}</Option>
                          {modelList.length > 0 && modelList.map((model, index) => (
                            <Option key={model.name} value={model.term_id}>{model.name}</Option>
                          ))}
                        </Select>
                      </div>
                      <div className="form-group">
                        <div className="form-label">
                          <label>{i18next.t("Common.FiltersSidebar.Groupset")}</label>
                        </div>
                        <Select value={selectedGroupset}
                          dropdownClassName="dropdown-select-s2"
                          disabled={false}
                          onChange={(value) => {
                            setResetFilter(true);
                            setSelectedGroupset(value)
                          }}
                          placeholder="Scegli un modello"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          <Option key={"All"} value={""}>{i18next.t("Common.FiltersSidebar.All1")}</Option>
                          {groupsetList.length > 0 && groupsetList.map((groupset, index) => (
                            <Option key={groupset.name} value={groupset.term_id}>{groupset.name}</Option>
                          ))}
                        </Select>
                      </div>
                      <div className="form-group">
                        <div className="form-label">
                          <label htmlFor="">{i18next.t("Common.FiltersSidebar.idOrder")}</label>
                        </div>
                        <Input value={selectedOrder} onChange={(e) => { setSelectedOrder(e.target.value) }} />
                      </div>
                      <div className="form-group">
                        <div className="form-label">
                          <label htmlFor="">{i18next.t("Common.FiltersSidebar.IDConfiguration")}</label>
                        </div>
                        <Input value={selectedConfigurator} onChange={(e) => { setSelectedConfigurator(e.target.value) }} />
                      </div>
                    </div>

                  </div>
                  <button
                    className="btn btn-primary btn-submit"
                    onClick={onFilterApply}
                  >
                    {i18next.t("Common.FiltersSidebar.Submit")}
                  </button>
                  {resetFilter && <button
                    className="btn btn-only-icon"
                    onClick={makeResetFilter}
                  >
                    <div className="icon">
                      <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1219.76 1087.17"><path d="M1192.6,422.91a18.73,18.73,0,0,0-26.25,3.66L1066.13,558.85a533.06,533.06,0,1,0-15.28,175.31,18.75,18.75,0,1,0-36.28-9.37,496.85,496.85,0,1,1,14.82-154.69L890.64,464.63A18.77,18.77,0,1,0,868,494.54L1040.07,625a18.76,18.76,0,0,0,11.34,3.75,11.89,11.89,0,0,0,2.53,0,18.75,18.75,0,0,0,12.38-7.22l130.31-172a18.76,18.76,0,0,0-4-26.53Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /><path d="M534.78,1141a540.07,540.07,0,1,1,536.61-600.64l89.38-118a25.75,25.75,0,0,1,36-5l3.51,2.65-.08.37a25.75,25.75,0,0,1,2,33.33l-130.32,172a25.54,25.54,0,0,1-17,9.92l-.17,0a18.66,18.66,0,0,1-3.66,0,25.93,25.93,0,0,1-15.19-5.15L863.72,500.12a25.77,25.77,0,0,1,31.14-41.07l126.35,96a490,490,0,1,0-13.45,168.07A25.75,25.75,0,1,1,1057.6,736,540.33,540.33,0,0,1,534.78,1141Zm-.1-1066.17a526.07,526.07,0,1,0,509.39,657.63l.07-.26a11.75,11.75,0,1,0-22.73-5.87l-.06.26a503.9,503.9,0,1,1,15-156.87l.94,15.25L886.4,470.21A11.77,11.77,0,0,0,872.18,489L1044.3,619.37a11.55,11.55,0,0,0,7.08,2.33h.41l.4,0a3.87,3.87,0,0,0,.9,0,11.66,11.66,0,0,0,7.66-4.51l130.3-172a11.75,11.75,0,0,0-2.53-16.61l-.84-.6a11.75,11.75,0,0,0-15.75,2.78L1060.6,577.73l-1.45-18.33A526,526,0,0,0,536.85,74.79Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /></svg>
                    </div>{i18next.t("Common.FiltersSidebar.Reset")}
                  </button>}
                </div>
              </div>
              <div className="right-side flex flex-col rel">
                <div className="page-title">
                  <h3>{i18next.t("Pages.ConfigurationSaved.PageTitle")}</h3>
                </div>
                <div className="table rel with-pagination">
                  <div className="hdr sticky">
                    <Row gutter={[60, 60]}>
                      <Col
                        span={5}
                        xs={{ span: 5 }}
                        sm={{ span: 5 }}
                        lg={{ span: 5 }}
                      >
                        <div className="table-header-item">{i18next.t("Pages.ConfigurationSaved.Table.ID")}</div>
                      </Col>
                      <Col
                        span={3}
                        xs={{ span: 3 }}
                        sm={{ span: 3 }}
                        lg={{ span: 3 }}
                      >
                        <div className="table-header-item">{i18next.t("Pages.ConfigurationSaved.Table.Model")}</div>
                      </Col>
                      <Col
                        span={5}
                        xs={{ span: 5 }}
                        sm={{ span: 5 }}
                        lg={{ span: 5 }}
                      >
                        <div className="table-header-item">{i18next.t("Pages.ConfigurationSaved.Table.Groupset")}</div>
                      </Col>
                      <Col
                        span={2}
                        xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        lg={{ span: 2 }}
                      >
                        <div className="table-header-item">{i18next.t("Pages.ConfigurationSaved.Table.Size")}</div>
                      </Col>
                      <Col
                        span={2}
                        xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        lg={{ span: 2 }}
                      >
                        <div className="table-header-item">{i18next.t("Pages.ConfigurationSaved.Table.Color")}</div>
                      </Col>
                      <Col
                        span={3}
                        xs={{ span: 3 }}
                        sm={{ span: 3 }}
                        lg={{ span: 3 }}
                      >
                        <div className="table-header-item">{i18next.t("Pages.ConfigurationSaved.Table.Wheels")}</div>
                      </Col>
                      <Col
                        span={2}
                        xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        lg={{ span: 2 }}
                      >
                        <div className="table-header-item">{i18next.t("Pages.ConfigurationSaved.Table.Price")}</div>
                      </Col>
                      <Col
                        span={1}
                        xs={{ span: 1 }}
                        sm={{ span: 1 }}
                        lg={{ span: 1 }}
                      >
                        <div className="table-header-item"></div>
                      </Col>
                    </Row>

                  </div>

                  <div className="table-container-wrapper">
                    <div className={configuratorListLoading ? "loader-container" : "loader-container is-loaded"}>
                      <span className="loader"></span>
                      <div className="loader-text">{i18next.t("Pages.ConfigurationSaved.Loader")}</div>
                    </div>
                    <div className="table-wrap">
                      {/* {total == 0 && <>No ticket</>} */}
                      {configuratorList.length > 0 && configuratorList.map((item, idx) => (
                        <div className="table-item">
                      
                          <Row gutter={[60, 60]}>
                            <Col
                              span={5}
                              xs={{ span: 5 }}
                              sm={{ span: 5 }}
                              lg={{ span: 5 }}
                            >
                              <div className="table-item__content"><span className="configuration-id">{item.configurator_id}</span></div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                <div className="status-text">{item.model_name}</div>
                              </div>

                            </Col>
                            <Col
                              span={5}
                              xs={{ span: 5 }}
                              sm={{ span: 5 }}
                              lg={{ span: 5 }}
                            >
                              <div className="table-item__content">
                                {item.groupset_name}
                              </div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content">
                                {item.size_name}
                              </div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              {/* <div className="table-item__content">{item.color_name} <img src={item.color_image} /></div> */}
                              <div className="table-item__content">{item.color_name}</div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">{item.wheel_name}</div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content"><PriceFormat price={item.regular_price} /></div>
                            </Col>

                            <Col
                              span={1}
                              xs={{ span: 1 }}
                              sm={{ span: 1 }}
                              lg={{ span: 1 }}
                            >
                              <div className="table-item__content">
                                <Dropdown overlay={
                                  <Menu>
                                    <Menu.Item onClick={() => configuratorPopup(item)}>{i18next.t("Pages.ConfigurationSaved.Table.Summary")}</Menu.Item>
                                    <Menu.Item onClick={() => downloadConfiguratorPDF(item.configurator_id)} >{i18next.t("Pages.ConfigurationSaved.Table.DownloadPDF")}</Menu.Item>
                                    {item.is_order_created == '0' && <Menu.Item><Link to={i18next.t('routes.Configuration') + "/?configurator_id=" + item.configurator_id}>{i18next.t("Pages.ConfigurationSaved.Table.Edit")}</Link></Menu.Item>}
                                    {item.is_order_created != '0' &&
                                      <Menu.Item>
                                        <Link target="_blank" to={"/" + i18n.language + "/" + i18next.t('routes.YourOrders') + '/' + `${item.is_order_created}`} type="primary">
                                          {i18next.t("Pages.ConfigurationSaved.Table.OrderDetails")}
                                        </Link>
                                      </Menu.Item>
                                    }
                                  </Menu>
                                } placement="bottomLeft" arrow>
                                  <Button>
                                    <svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 56"><path d="M350,308a28,28,0,1,0-28-28A28,28,0,0,0,350,308Zm112,0a28,28,0,1,0-28-28A28,28,0,0,0,462,308Zm-224,0a28,28,0,1,0-28-28A28,28,0,0,0,238,308Z" transform="translate(-210 -252)" style={{ fillRule: 'evenodd' }} /></svg>
                                  </Button>
                                </Dropdown>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="pagination-wrapper">
                    <Pagination
                      showSizeChanger={false}
                      current={current}
                      pageSize={pageSize}
                      total={totalConfigurators}
                      onChange={(page) => setcurrent(page)}
                      itemRender={paginationLabel}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {configuratorPopupData && isSummaryPopup && <ConfiguratorSummary isSummaryPopup={isSummaryPopup} configurator_id={configuratorPopupData} setisSummaryPopup={setisSummaryPopup} />}
      <Footer />
    </>
  );
};
export default ConfiguratorList;