import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ArrowDownIcon } from "../../Icons";
import { ReactTitle } from 'react-meta-tags';

const AreaAssistenza = () => {
  const [selectedCata, setSelectedSelector] = useState("All");
  const [dropCata, setDropCata] = useState(false);
  const [categories, setCategories] = useState([
    { label: "All" },
    { label: "Accessori" },
    { label: "Bikes" },
    { label: "Abbigliamento" },
  ]);

  const [ftpFiles, setFtpFiles] = useState([
    {
      id: "4352678",
      status: "Risolto",
      title: "Richiesta aiuto",
      disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ess.",
      date: "02/07/2021",
    },
    {
      id: "4352678",
      status: "Letto",
      title: "Errore configurazione",
      disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ess.",
      date: "25/07/2021",
    },
    {
      id: "4352678",
      status: "Inviato",
      title: "Richiesta modifica",
      disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ess.",
      date: "14/07/2021",
    },
    {
      id: "4352678",
      status: "Risolto",
      title: "Sostituzione materiale",
      disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit ess.",
      date: "06/07/2021",
    },
  ]);

  useEffect(() => {
    document.body.addEventListener("click", () => {
      setDropCata(false);
    });
  });

  return (
    <>
      <ReactTitle title="Dashboard" />
      <Header />
      <div className="area-ftp-p area-assistenza-p">
        <div className="wrapWidth flex flex-col wrapper">
          <div className="page-title font s16 cfff">
            Area assistenza - Ticket
          </div>
          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-blk flex flex-col">
                <div className="title font s22 c555">Filtra </div>
                {/* categories */}
                <div className="filter flex flex-col">
                  <div className="label font s15 b3 c333">Categorie</div>
                  <button
                    className="cleanbtn flex aic font s14 c000 cstm-slt rel"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropCata(!dropCata);
                    }}
                  >
                    {categories.map(
                      (item) =>
                        selectedCata === item.label && (
                          <div className="iput font s14 c000 wordwrap upc b6">
                            {item.label}
                          </div>
                        )
                    )}
                    {dropCata && (
                      <div className="options flex flex-col abs">
                        {categories.map((item) => (
                          <button
                            className="cleanbtn item flex aic anim"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedSelector(item.label);
                              setDropCata(false);
                            }}
                          >
                            <div className="txt font s14 b4 c000 wordwrap">
                              {item.label}
                            </div>
                          </button>
                        ))}
                      </div>
                    )}
                    <div className="ico">
                      <ArrowDownIcon />
                    </div>
                  </button>
                </div>
              </div>
              <button className="cleanbtn apply-btn font s16 b7 cfff upc anim">
                APPLICA
              </button>
              <button className="cleanbtn apply-btn ticket-btn font s16 b7 cfff upc anim">
                APRI TICKET
              </button>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="table rel">
                <div className="row hdr flex aic sticky">
                  <div className="col">
                    <div className="lbl font">ID Ticket</div>
                  </div>
                  <div className="col">
                    <div className="lbl font">Stato</div>
                  </div>
                  <div className="col">
                    <div className="lbl font">Titolo</div>
                  </div>
                  <div className="col">
                    <div className="lbl font">Descrizione</div>
                  </div>
                  <div className="col">
                    <div className="lbl font">Data invio</div>
                  </div>
                </div>
                <div className="table-wrap">
                  {ftpFiles.map((item, idx) => (
                    <div className="row flex aic">
                      <div className="col">
                        <div className="id font s16 b8 upc">{item.id}</div>
                      </div>
                      <div className="col">
                        <div className="status flex aic">
                          <div className={`dot ${item.status}`} />
                          <div className="lb font s13 b3">{item.status}</div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="title font s14">{item.title}</div>
                      </div>
                      <div className="col">
                        <div className="disc font s14">{item.disc}</div>
                      </div>
                      <div className="col">
                        <div className="date font s14">{item.date}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AreaAssistenza;
