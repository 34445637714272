import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DatePicker from "../../components/DatePicker";
import {
  getBackOrderList
} from "../../actions/backorderActions";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Pagination, Select, Input } from "antd";
import { i18n } from "../../translations/i18n";
import paginationLabel from "../../components/PaginationRender";
import { ReactTitle } from 'react-meta-tags';
import DateFormat from "../../components/DateFormat";
import i18next from "i18next";
import moment from 'moment';

const OrderBackorder = () => {
  const dispatch = useDispatch();
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(20);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [select, setSelect] = useState([]);
  const [userRoles, setUserRoles] = useState([]);

  const { isLoading, ordersList, total } = useSelector((state) => {
    return state.backOrder;
  });

  useEffect(() => {
    if (localStorage.getItem("user-roles")) {
      try {
        let userRolesLocal = JSON.parse(localStorage.getItem("user-roles"));
        setUserRoles(userRolesLocal);

      } catch (e) {
        console.log(e)
      }
    }
  }, [])

  const loadOrder = (page) => {
    dispatch(getBackOrderList(page, pageSize, search, select, startDate ? moment(startDate).format("YYYY-MM-DD") : "", endDate ? moment(endDate).format("YYYY-MM-DD") : ""));
  }
  const [resetFilter, setResetFilter] = useState(false);
  const makeResetFilter = () => {
    setSearch('');
    setStartDate('');
    setEndDate('');
    setSelect([]);
    setResetFilter(false);
  }
  useEffect(() => {
    loadOrder(current);
  }, [current]);

  useEffect(() => {
    const timeout = setTimeout(function () {
      loadOrder(1);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [search]);

  const onFilterApply = () => {
    loadOrder(1);
  };


  return (
    <>
      <ReactTitle title="Dashboard" />
      <Header />
      <div className="dashboard-content back-orders">
        <div className="wrap">
          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-side-container">
                <div className="filter-blk">
                  <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>
                  <div className="form-wrapper">
                    <div className="form-group">
                      <div className="form-label">
                        <label>{
                          userRoles.includes("administrator") ? i18next.t("Common.FiltersSidebar.SKUADMIN") : i18next.t("Common.FiltersSidebar.SKU")}</label>
                      </div>
                      <Input
                        type="text"
                        className="txt-search"
                        placeholder=""
                        value={search}
                        onChange={(e) => { setSearch(e.target.value); setResetFilter(true); }}
                      />
                    </div>

                    <div className="form-group multi-selection">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.State")}</label>
                      </div>
                      <Select value={select} onChange={(value) => {
                        setSelect(value);
                        setResetFilter(true);
                      }}
                      >
                        <Select.Option key={1} value={"'Confirmed'"}>{i18next.t('Common.FiltersSidebar.order_confirmed')}</Select.Option>
                        <Select.Option key={2} value={"'On hold'"}>{i18next.t('Common.FiltersSidebar.on_hold')}</Select.Option>
                        <Select.Option key={3} value={"'in production'"}>{i18next.t('Common.FiltersSidebar.in_production')}</Select.Option>
                        <Select.Option key={4} value={"'Ready to ship'"}>{i18next.t('Common.FiltersSidebar.Ready_to_ship')}</Select.Option>

                      </Select>
                    </div>

                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.FromDate")}</label>
                      </div>
                      <DatePicker placeholder="Scegli una data" setDay={setStartDate} defultDay={startDate} reset={setResetFilter} postDate={endDate} />
                    </div>

                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.ToDate")}</label>
                      </div>
                      <DatePicker placeholder="Scegli una data" setDay={setEndDate} defultDay={endDate} reset={setResetFilter} preDate={startDate} />
                    </div>

                    <div className="form-group multi-selection">
                      <button
                        className="btn btn-primary btn-submit"
                        onClick={onFilterApply}
                      >
                        {i18next.t("Common.FiltersSidebar.Submit")}
                      </button>
                      {resetFilter && <button
                        className="btn btn-primary btn-submit"
                        onClick={makeResetFilter}
                      >
                        {i18next.t("Common.FiltersSidebar.Reset")}
                      </button>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t("Pages.BackOrders.Title")}</h3>
              </div>
              <div className="service-not-available">
                <p>{i18next.t("Common.ServiceNotAvailable")}</p>
              </div>
              {/* <div className="table rel with-pagination">
                <div className="hdr sticky">
                  <Row gutter={[24, 24]}>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.OrderNumber")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.OrderDate")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.ExpectiveDate")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.ClientData")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.Client")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.Destin")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.CodArt")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.Groupset")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.TG")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.ArtDescription")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.QRes")}</div>
                    </Col>
                    <Col
                    >
                      <div className="table-header-item">{i18next.t("Pages.BackOrders.Table.State")}</div>
                    </Col>
                  </Row>
                </div>
                <div className={isLoading ? "loader-container" : "loader-container is-loaded"}>
                  <span className="loader"></span>
                  <div className="loader-text">{i18next.t("Pages.Orders.Loader")}</div>
                </div>
                <div className="table-container-wrapper">
                  <div className="table-wrap">
                    {!isLoading && ordersList.length > 0 &&
                      ordersList.map((item, idx) => (
                        <div className="table-item" key={idx}>

                          <Row gutter={[24, 24]}>
                            <Col
                            >
                              <div className="table-item__content">{item.order_id} / {item.sequal_number}</div>
                            </Col><Col
                            >
                              <div className="table-item__content"><DateFormat date={item.order_date} /></div>
                            </Col><Col
                            >
                              <div className="table-item__content">{item.data_prev_cons != '0000-00-00 00:00:00' ? <DateFormat date={item.data_prev_cons} /> : ""}</div>
                            </Col>
                            <Col
                            >

                              <div className="table-item__content">{item.date_r_client != '0000-00-00 00:00:00' ? <DateFormat date={item.date_r_client} /> : ""}</div>
                            </Col>
                            <Col
                            >

                              <div className="table-item__content">{item.client}</div>
                            </Col><Col
                            >
                              <div className="table-item__content">{item.destination}</div>

                            </Col><Col
                            >
                              <div className="table-item__content">{item.item_code}</div>

                            </Col><Col
                            >
                              <div className="table-item__content">{item.item_group}</div>
                            </Col><Col
                            >
                              <div className="table-item__content">{item.tg}</div>

                            </Col><Col
                            >
                              <div className="table-item__content">{item.item_description}</div>
                            </Col><Col
                            >
                              <div className="table-item__content">{item.quantity_release}</div>
                            </Col><Col
                            >
                              <div className="table-item__content">{item.status}</div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} ordini`}
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
              </div> */}
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderBackorder;
