import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownIcon } from "../../Icons";
import Price from "../Price";
import PriceFormat from '../PriceFormat';
import { findPrice } from '../../utils/commons';
import { Collapse, Button, notification, Radio, Space, Modal } from 'antd';
import { addProductTocart, resetCartNotification } from "../../actions/cartActions";
import SizeGuide from "../SizeGuide"
import i18next from 'i18next';

const ProductDetails = ({ id, product, isOpen, setProductDetailOpen }) => {
     const ConfirmationIcon = () => (
          <svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.99 335.43"><path d="M287.32,447.71a20.72,20.72,0,0,1-14.67-6.07L104.07,273.05a20.75,20.75,0,1,1,29.34-29.34L287.32,397.62,566.57,118.36a20.75,20.75,0,0,1,29.34,29.34L302,441.63a20.69,20.69,0,0,1-14.67,6.08Z" transform="translate(-97.99 -112.28)" style={{ fill: '#04945e' }} /></svg>
     );

     const dispatch = useDispatch()
     const { isLoading, isAddedTocart } = useSelector(state => {
          return state.cart
     });

     const { Panel } = Collapse;
     const [productData, setProductData] = useState({});
     const [price, setPrice] = useState()
     const [descShow, setDescShow] = useState(false);
     const [featuresShow, setFeaturesShow] = useState(false);
     const [qty, setQty] = useState(1);
     const [stock, setStock] = useState(0);
     const [totalItemPrice, setTotalItemPrice] = useState(0);
     const [itemPrice, setItemPrice] = useState(0);
     const [nonLoggedInPrice, setNonLoggedInPrice] = useState(0);
     const [priceDifference, setPriceDifference] = useState(0);
     const [priceDifferencePer, setPriceDifferencePer] = useState(0);
     const [image, setImage] = useState('')
     /***varaible***/
     const [selectedVariation, setVariation] = useState(null);
     const [variationId, setVariationId] = useState(0);
     const [disableAttribute, setDisableAttribute] = useState({});
     const [selectedAttribute, setselectedAttribute] = useState(product.default_attribute);
     const [isSizePopup, setFindYourSizePopup] = useState(false);

     useEffect(() => {
          setProductData({})
          setStock(1);
          setPrice();
          setImage('');
          setVariation(null);
          setVariationId(0);
          setProductData(product); setselectedAttribute(product.default_attribute);
     }, [product, id]);

     useEffect(() => {
          setStock(productData.stock);
          setPrice(productData.prices);
          setImage(productData.image);
          setQty(1);
     }, [productData]);


     useEffect(() => {
          let disableAttributes = {};
          productData.variation &&
               productData.variation.map((variation_data, index) => {
                    if (JSON.stringify(variation_data.attributes) === JSON.stringify(selectedAttribute)) {

                         setVariationId(variation_data.variation_id);
                         setVariation(variation_data);
                         setStock(variation_data.stock);
                         if (qty > variation_data.stock) {
                              setQty(variation_data.stock);
                         } else {
                              setQty(1);
                         }
                    }
                    let is_stock_matter = false;
                    let key_not_to_reset = [];
                    Object.keys(variation_data.attributes).map((key, index) => {
                         if (variation_data.attributes[key] == selectedAttribute[key]) {
                              is_stock_matter = true;
                              if (Object.keys(variation_data.attributes).length > 1) {
                                   key_not_to_reset.push(key);
                              }
                         }
                    });

                    if (is_stock_matter && (variation_data.stock == 0 || !variation_data.stock)) {
                         Object.keys(variation_data.attributes).map((key, index) => {
                              if (!key_not_to_reset.includes(key)) {
                                   if (disableAttributes[key] === undefined) {
                                        disableAttributes[key] = [];
                                   }
                                   disableAttributes[key].push(variation_data.attributes[key]);
                              }
                         });
                    }
                    if (Object.keys(variation_data.attributes).length === 1 && (variation_data.stock == 0 || !variation_data.stock)) {
                         Object.keys(variation_data.attributes).map((key, index) => {
                              if (disableAttributes[key] === undefined) {
                                   disableAttributes[key] = [];
                              }
                              if ((variation_data.stock == 0 || !variation_data.stock)) {
                                   disableAttributes[key].push(variation_data.attributes[key]);
                              }
                         })
                    }
               });
          setDisableAttribute(disableAttributes);
     }, [selectedAttribute]);

     useEffect(() => {
          if (variationId == 0 && productData.prices !== null) {
               setPrice(productData.prices)
               setImage(productData.image);
          } else if (selectedVariation !== null) {
               setPrice(selectedVariation.prices)
               setImage(selectedVariation.image.src);
          } else {
               setPrice(productData.prices)
               setImage(productData.image);
          }

          if (variationId == 0) {
               setImage(productData.image);
          } else if (selectedVariation !== null) {
               setImage(selectedVariation.image.src);
          } else {
               setImage(productData.image);
          }
     }, [variationId, selectedVariation, productData]);


     /***varaible***/


     const onChangeAttribute = (e, type) => {
          let attributes = { ...selectedAttribute };
          attributes[type] = e.target.value;
          setselectedAttribute(attributes);
     };

     useEffect(() => {
          if (price === undefined) {
               setTotalItemPrice(0); setNonLoggedInPrice(0); setItemPrice(0);
          } else {
               setTotalItemPrice(parseFloat(findPrice(price, 'regular_price', 'product', false) * qty).toFixed(2));
               setItemPrice(parseFloat(findPrice(price, 'regular_price', 'product', false)).toFixed(2));
               setNonLoggedInPrice(parseFloat(findPrice(price, 'regular_price', 'product', false, true)));
          }
     }, [qty, price])

     useEffect(() => {
          setPriceDifference(nonLoggedInPrice - itemPrice)
          setPriceDifferencePer((((nonLoggedInPrice - itemPrice) * 100) / nonLoggedInPrice).toFixed(2))
     }, [nonLoggedInPrice, price])

     useEffect(() => {
          if (isAddedTocart) {
               setProductDetailOpen(false);
               document.body.style.overflowY = 'auto';
               notification['success']({
                    message: i18next.t('Common.ConfirmationMessage.Products.Title'),
                    description: i18next.t('Common.ConfirmationMessage.Products.Label'),
                    icon: <ConfirmationIcon></ConfirmationIcon>
               });
               dispatch(resetCartNotification());
          }
     }, [isAddedTocart]);

     // console.log("size", sizeSelect)
     const addItemTocart = () => {
          var cartObj = {
               "id": productData.id,
               "quantity": qty,
               "variation_id": variationId,
               "item_metas": {
                    "type": "other_products",
                    "regular_price": findPrice(price, 'regular_price', 'product', false),
                    "sale_price": findPrice(price, 'regular_price', 'product', false)
               },
               "country": localStorage.getItem('stardue_country')
          }
          dispatch(addProductTocart(cartObj));
     }
     if (!isOpen) {
          return (
               <div className={`cart-sidebar-container fixed rel anim ${isOpen ? 'show' : 'hide'}`}>
                    <div className={`cart-sidebar flex flex-col anim ${isOpen ? 'show' : 'hide'}`} onClick={(e) => e.stopPropagation()}></div>
               </div>
          );
     }

     // console.log("productData", price)
     return (
          <div className={`cart-sidebar-container ${isOpen ? 'show' : 'hide'}`}>

               <div className={`cart-sidebar ${isOpen ? 'show' : 'hide'}`} onClick={(e) => e.stopPropagation()}>
                    <div className="cart-sidebar__inner">
                         <Button className="ant-btn-close" type='button' onClick={() => {
                              setProductDetailOpen(false);
                              setFindYourSizePopup(false);
                         }}>
                              <svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.99 222.99"><path d="M461.5,192.25l-23.74-23.74L350,256.26l-87.75-87.75-23.74,23.74L326.26,280l-87.75,87.76,23.74,23.74L350,303.75l87.76,87.75,23.74-23.74L373.75,280Z" transform="translate(-238.51 -168.51)" /></svg>
                         </Button>
                         {/* <div className="product-image">
<img className="img" src={image} />
</div> */}
                         <div className="product-content">
                              <div className="prd-name"><h4>{productData.title}</h4></div>
                              <div className="prd-price">
                                   <Price price={price} discount={'product'} displayDiscouted={true} />
                              </div>
                              <div className="prd-recommended-price">
                                   <div className="label">{i18next.t("Pages.Equipments.Details.RecomandedPrice")}</div>
                                   <div className="price-recommended">
                                        <PriceFormat price={nonLoggedInPrice} />
                                   </div>
                              </div>
                              <div className="prd-recommended-price-difference">
                                   <div className="label">{i18next.t("Pages.Equipments.Details.YourDiscount")}</div>
                                   <div className="price-difference">
                                        <PriceFormat price={priceDifference} />
                                        {/* {priceDifferencePer} */}
                                   </div>
                              </div>
                         </div>
                         <div className="prd-details">
                              <Collapse defaultActiveKey={['description']} accordion expandIconPosition="right">
                                   {productData.description !== '' && (
                                        <Panel header={i18next.t("Pages.Equipments.Details.Description")} key="description">
                                             <div
                                                  dangerouslySetInnerHTML={{
                                                       __html: productData.description
                                                  }}
                                             />
                                        </Panel>
                                   )}
                                   {productData.short_description !== '' && (
                                        <Panel header={i18next.t("Pages.Equipments.Details.Features")} key="details">
                                             <div
                                                  dangerouslySetInnerHTML={{
                                                       __html: productData.short_description
                                                  }}
                                             />
                                        </Panel>
                                   )}
                              </Collapse>
                         </div>

                         {productData.type == "variable" && (
                              <>
                                   <div className="variations-group">
                                        {Object.keys(productData.attributs).map(
                                             (keyName, i) => {
                                                  if (
                                                       keyName == "attribute_pa_helmet-color" ||
                                                       keyName == "attribute_pa_spacer-color" ||
                                                       keyName == "attribute_pa_apparel-color"
                                                  ) {
                                                       return (
                                                            <div className="variations-item" key={"color" + i}>
                                                                 <h2 key={"h2" + i}>
                                                                      {
                                                                           productData.attributs[keyName]
                                                                                .title
                                                                      }
                                                                 </h2>
                                                                 <div
                                                                      key={"div" + i}
                                                                      className="Pro-radio-box Pro-img-radio-box"
                                                                 >
                                                                      <Radio.Group
                                                                           className="ColumnGroup WithImage"
                                                                           key={i}
                                                                           value={selectedAttribute[keyName]}
                                                                           onChange={(e) =>
                                                                                onChangeAttribute(e, keyName)
                                                                           }
                                                                      >
                                                                           <Space>
                                                                                {productData.attributs[keyName] && productData.attributs[keyName].terms && productData.attributs[keyName].terms.length && productData.attributs[keyName].terms.map((color, colorIndex) => {
                                                                                     return (<Radio value={color.slug} key={"color" + colorIndex} disabled={
                                                                                          disableAttribute[keyName] &&
                                                                                               disableAttribute[
                                                                                                    keyName
                                                                                               ].includes(color.slug)
                                                                                               ? true
                                                                                               : false
                                                                                     }
                                                                                     >
                                                                                          <div className="img">
                                                                                               <img src={productData.images[color.slug] ? productData.images[color.slug].image : ""} />
                                                                                          </div>
                                                                                          <div className="Label">
                                                                                               {color.label}
                                                                                          </div>
                                                                                     </Radio>
                                                                                     );
                                                                                })}
                                                                           </Space>
                                                                      </Radio.Group>
                                                                 </div>
                                                            </div>
                                                       );
                                                  } else if (keyName == "attribute_pa_size") {
                                                       return (
                                                            <div className="variations-item" key={"size" + i}>
                                                                 <h2 className="Flex-box space-between">
                                                                      {
                                                                           productData.attributs[keyName]
                                                                                .title
                                                                      }
                                                                      <span className="Flex-box space-between">
                                                                           <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="23.923"
                                                                                height="11.913"
                                                                                viewBox="0 0 23.923 11.913"
                                                                           >
                                                                                <g
                                                                                     id="noun_Ruler_16879"
                                                                                     transform="translate(-8.835 -29.5)"
                                                                                >
                                                                                     <g
                                                                                          id="Group_505"
                                                                                          data-name="Group 505"
                                                                                          transform="translate(8.835 29.5)"
                                                                                     >
                                                                                          <path
                                                                                               id="Path_65"
                                                                                               data-name="Path 65"
                                                                                               d="M32.322,29.5H9.271a.435.435,0,0,0-.436.436V40.978a.435.435,0,0,0,.436.436H32.322a.435.435,0,0,0,.436-.436V29.936A.435.435,0,0,0,32.322,29.5Zm-.436,11.042H29.259V37.11a.291.291,0,1,0-.581,0v3.432H25.836V34.35a.581.581,0,0,0-1.162,0v6.192H21.289v-3.19a.291.291,0,0,0-.581,0v3.19H17.889V34.35a.581.581,0,0,0-1.162,0v6.192H13.339v-3.19a.291.291,0,0,0-.581,0v3.19H9.707V30.372H31.886Z"
                                                                                               transform="translate(-8.835 -29.5)"
                                                                                               fill="#3c434a"
                                                                                          />
                                                                                     </g>
                                                                                </g>
                                                                           </svg>
                                                                           Size guide
                                                                      </span>
                                                                 </h2>
                                                                 <div
                                                                      key={"div" + i}
                                                                      className="Pro-radio-box"
                                                                 >
                                                                      <Radio.Group
                                                                           className="ColumnGroup"
                                                                           value={selectedAttribute[keyName]}
                                                                           onChange={(e) =>
                                                                                onChangeAttribute(e, keyName)
                                                                           }
                                                                      >
                                                                           <Space direction="vertical">
                                                                                {productData.general.attributs
                                                                                     .attribute_pa_size.terms.length &&
                                                                                     productData.general.attributs.attribute_pa_size.terms.map(
                                                                                          (size, sizeIndex) => {
                                                                                               return (
                                                                                                    <Radio
                                                                                                         value={size.slug}
                                                                                                         key={sizeIndex}
                                                                                                         disabled={
                                                                                                              disableAttribute[
                                                                                                                   keyName
                                                                                                              ] &&
                                                                                                                   disableAttribute[
                                                                                                                        keyName
                                                                                                                   ].includes(size.slug)
                                                                                                                   ? true
                                                                                                                   : false
                                                                                                         }
                                                                                                    >
                                                                                                         <div className="Flex-box space-between Size">
                                                                                                              <div>{size.label}</div>
                                                                                                              <span>
                                                                                                                   Spedito in 2 giorni
                                                                                                              </span>
                                                                                                         </div>
                                                                                                    </Radio>
                                                                                               );
                                                                                          }
                                                                                     )}
                                                                           </Space>
                                                                      </Radio.Group>
                                                                 </div>
                                                            </div>
                                                       );
                                                  } else {
                                                       return (
                                                            <div className="variations-item" key={"variations" + i}>
                                                                 <h2
                                                                      key={"h2" + i}
                                                                      className="Flex-box space-between"
                                                                 >
                                                                      {
                                                                           productData.attributs[keyName]
                                                                                .title
                                                                      }
                                                                      {(productData.attributs[keyName] == 'attribute_pa_apparel-size' || productData.attributs[keyName] == 'attribute_pa_helmet-size') && (
                                                                           <div>ciao</div>
                                                                      )}
                                                                      {productData.geometries &&
                                                                           productData.geometries
                                                                                .svg_file &&
                                                                           productData.geometries
                                                                                .data_sheet &&
                                                                           productData.geometries.data_sheet
                                                                                .header && (
                                                                                <div className="find-your-size-other-product">
                                                                                     <div
                                                                                          className="size-btn"
                                                                                          onClick={() => {
                                                                                               setFindYourSizePopup(true);
                                                                                          }}
                                                                                     >
                                                                                          <div className="icon" style={{ width: "40px" }}>
                                                                                               <svg
                                                                                                    id="Livello_1"
                                                                                                    data-name="Livello 1"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    viewBox="0 0 89.4 29.4"
                                                                                               >
                                                                                                    <path
                                                                                                         d="M89.8,35.4H10.2a4.91,4.91,0,0,0-4.9,4.9V59.9a4.91,4.91,0,0,0,4.9,4.9H89.8a4.91,4.91,0,0,0,4.9-4.9V40.2A4.82,4.82,0,0,0,89.8,35.4Zm2.1,24.4a2.11,2.11,0,0,1-2.1,2.1H10.2a2.11,2.11,0,0,1-2.1-2.1V40.2a2.11,2.11,0,0,1,2.1-2.1h7.6v8h2.8v-8h9.6v8H33v-8h9.6v8h2.8v-8H55v8h2.8v-8h9.6v8h2.8v-8h9.6v8h2.8v-8h7.3A2.11,2.11,0,0,1,92,40.2V59.8Z"
                                                                                                         transform="translate(-5.3 -35.4)"
                                                                                                    />
                                                                                               </svg>
                                                                                          </div>
                                                                                          Size Guide
                                                                                     </div>
                                                                                </div>
                                                                           )}
                                                                 </h2>
                                                                 <div
                                                                      key={"div" + i}
                                                                      className="Pro-radio-box"
                                                                 >
                                                                      <Radio.Group
                                                                           className="ColumnGroup"
                                                                           value={selectedAttribute[keyName]}
                                                                           onChange={(e) =>
                                                                                onChangeAttribute(e, keyName)
                                                                           }
                                                                      >
                                                                           <Space direction="vertical">
                                                                                {productData.attributs[keyName]
                                                                                     .terms &&
                                                                                     productData.attributs[
                                                                                          keyName
                                                                                     ].terms.length &&
                                                                                     productData.attributs[
                                                                                          keyName
                                                                                     ].terms.map((size, sizeIndex) => {
                                                                                          return (
                                                                                               <Radio
                                                                                                    value={size.slug}
                                                                                                    key={sizeIndex}
                                                                                                    disabled={
                                                                                                         disableAttribute[keyName] &&
                                                                                                              disableAttribute[
                                                                                                                   keyName
                                                                                                              ].includes(size.slug)
                                                                                                              ? true
                                                                                                              : false
                                                                                                    }
                                                                                               >
                                                                                                    <div className="VariationOption Flex-box">
                                                                                                         <div>{size.label}</div>
                                                                                                    </div>
                                                                                               </Radio>
                                                                                          );
                                                                                     })}
                                                                           </Space>
                                                                      </Radio.Group>
                                                                 </div>
                                                            </div>
                                                       );
                                                  }
                                             }
                                        )}
                                   </div>
                              </>
                         )}
                         <div className="action flex aic jc">
                              <button className="minus-btn cleanbtn s20 b6" onClick={(e) => { if (qty <= 1) { return; } setQty(qty - 1) }}>-</button>
                              <div className="numb flex aic jc s15 c555 b5 font">{qty}</div>
                              <button className="plus-btn cleanbtn s20 b6" onClick={(e) => { if (qty >= stock) { return; } setQty(qty + 1) }}>+</button>
                         </div>
                         <Button disabled={itemPrice == 0 || stock == 0} loading={isLoading} type='button' className="btn btn-primary" onClick={() => { addItemTocart(); }} ><span className="current-price"><PriceFormat price={totalItemPrice} /></span><span className="delimiter"> - </span>{i18next.t("Pages.Equipments.Details.BuyNow")}</Button>

                         {isSizePopup && (
                              <Modal
                                   className="SizeGuideModal SuccessModal"
                                   centered
                                   width={1000}
                                   footer={null}
                                   onCancel={() => {
                                        setFindYourSizePopup(false);
                                   }}
                                   visible={isSizePopup}
                              >
                                   <SizeGuide module={productData.geometries} />
                              </Modal>
                         )}
                    </div>
               </div>
          </div>
     )
}

export default ProductDetails
