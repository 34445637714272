import Cookies from "js-cookie";
import { request } from "../services/apiService";
import {
    AREAFTP_PENDING,
    AREAFTP_SUCCESS,
    AREAFTP_FAILURE,
} from "./actionTypes";
import history from "../history";

export const getDocuments = () => dispatch => {
    dispatch({ type: AREAFTP_PENDING  });
    // const url = `/api/DeviceData/`+data.deviceId+`/logs?Dal=`+data.startDate+`&Al=`+data.endDate;
    const url = `v1/documents/list`;
    request("get", url, '', true).then(
      (res) => {
        dispatch({ type: AREAFTP_SUCCESS, payload: res });
      },
      (err) => {
        dispatch({ type: AREAFTP_FAILURE });
        console.error("err", err);
      }
    );
  };