import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Spin, Tabs, Button, notification, Modal, Skeleton } from "antd";
import Sticky from "wil-react-sticky";
import Cookies from "js-cookie";
import PriceFormat from "../../components/PriceFormat";
import * as htmlToImage from "html-to-image";
import { saveAs } from "file-saver";
import {
  saveConfigurator,
  downloadPDF,
  displaySaveLoader
} from "../../actions/configuratorActions";
import { addProductTocart, resetCartNotification } from "../../actions/cartActions";
import { i18n } from "../../translations/i18n";
import i18next from "i18next";
import Cookie from "js-cookie";
const ActionsBar = ({
  price,
  salePrice,
  nonLoggedInPrice,
  productId,
  wheel,
  configuratorId,
  modalId,
  color,
  size,
  groupId,
  cassette,
  crankArm,
  chainRings,
  otherStep,
  options,
  configuratorType,
  productQuantity,
  optionalQuantity,
  isGroupsetLoader,
  disableStep,
  otherStepLength
}) => {
  const dispatch = useDispatch();
  const { configuratorSaveLoding, configuratorSaveError, userSavedConfigurator } = useSelector(
    (state) => {
      return state.configurNow;
    }
  );

  const { cofiguratorPDF, isPdfLoading } = useSelector((state) => {
    return state.configure;
  });

  const { isLoading, isError, isAddedTocart } = useSelector((state) => {
    return state.cart;
  });

  const [isAddToCart, setIsAddToCart] = useState(false);
  const [isDownloadPdf, setIsDownloadPdf] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [action, setAction] = useState('save');
  const [isDownloadLoader, setDownloadLoader] = useState(false);
  const [isAddtocartloader, setAddtocartLoader] = useState(false);

  useEffect(() => {
    if (isAddedTocart) {
      // notification["success"]({
      //   message: "Your product is added successfully!",
      // });
      dispatch(resetCartNotification());
      setAddtocartLoader(false)
    }
  }, [isAddedTocart]);

  useEffect(() => {
    if (isError) {
      dispatch(resetCartNotification());
      setAddtocartLoader(false);
      setIsAddToCart(false);
      setModalVisible(false);
      notification["error"]({
        message: "Error is adding cart",
      });
    }
  }, [isError])

  useEffect(() => {
    if (
      userSavedConfigurator &&
      Object.keys(userSavedConfigurator).length > 0
    ) {
      if (isAddToCart) {
        addTocart();
      } else if (isDownloadPdf) {
        onDownloadPdf();
      }
    }
  }, [userSavedConfigurator]);

  useEffect(() => {
    if (cofiguratorPDF && Object.keys(cofiguratorPDF).length > 0) {
      downLoadConfiguratorPDF(cofiguratorPDF);
    }
  }, [cofiguratorPDF]);

  const addTocart = async () => {

    let data = {
      lang: i18n.language,
      locale: i18n.language,
      id: productId,
      quantity: 1,
      variation_id: wheel,
      item_metas: {
        configurator_id: configuratorId ? configuratorId : userSavedConfigurator.configurator_id,
        regular_price: price,
        sale_price: salePrice,
        currency: Cookies.get("currency"),
        type: configuratorType,
        model: modalId,
        groupset: groupId,
        color: color,
        size: size,
        groupset_data: {
          cassette: cassette,
          crankArm: crankArm,
          chainRings: chainRings,
        },
        other_steps: filterOtherStepData(otherStep),
        other_steps_options: filterOtherStepData(options),
        wheel: wheel,
        quantity: productQuantity,
        optional_quantity: optionalQuantity,
        origin: 'b2b',
        otherStepLength: otherStepLength
      },
      country: Cookies.get("countryCodeShipping"),
    };
    dispatch(addProductTocart(data));
    setIsAddToCart(false);
    // setAddtocartLoader(false)
  };

  const onSaveConfigurator = async () => {
    dispatch(displaySaveLoader());
    htmlToImage
      .toPng(document.getElementById("view_0"))
      .then(async function (data_image) {
        let data = {
          lang: i18n.language,
          locale: i18n.language,
          type: configuratorType,
          model: modalId,
          groupset: groupId,
          color: color,
          size: size,
          groupset_data: {
            cassette: cassette,
            crankArm: crankArm,
            chainRings: chainRings,
          },
          other_steps: filterOtherStepData(otherStep),
          other_steps_options: filterOtherStepData(options),
          wheel: wheel,
          configurator_id: configuratorId ? configuratorId : (userSavedConfigurator.configurator_id
            ? userSavedConfigurator.configurator_id
            : ""),
          quantity: productQuantity,
          optional_quantity: optionalQuantity,
          action_type: "save",
          regular_price: price,
          sale_price: salePrice,
          currency: Cookies.get("currency"),
          data_image: data_image,
          origin: "b2b",
          otherStepLength: otherStepLength
        };
        dispatch(saveConfigurator(data));
      });
  };

  const onDownloadPdf = () => {
    dispatch(downloadPDF(userSavedConfigurator.configurator_id));
    setDownloadLoader(false)
  };

  const downLoadConfiguratorPDF = (data) => {
    if (userSavedConfigurator && userSavedConfigurator.configurator_id) {
      saveAs(
        data.url,
        "your-new-" + (Cookie.get("platformkey") == '7368496012' ? 'basso' : 'leecougan') + "-bike-" + userSavedConfigurator.configurator_id + ".pdf"
      );
    }
  };

  const onClickAddToCart = () => {
    // if (userSavedConfigurator && userSavedConfigurator.configurator_id) {
    //   addTocart();
    // } else {
    setAction('cart');
    setAddtocartLoader(true)
    setIsAddToCart(true);
    onSaveConfigurator();
    // }
  };
  const onClickDownload = () => {
    // if (userSavedConfigurator && userSavedConfigurator.configurator_id) {
    //   onDownloadPdf();
    // } else {
    setIsDownloadPdf(true);
    onSaveConfigurator();
    setDownloadLoader(true)
    // }
  };


  const filterOtherStepData = (stepData) => {
    let finalData = { ...stepData };
    let disableStepLocal = { ...disableStep };
    Object.keys(stepData).map((data, index) => {
      if (disableStepLocal[data] == false) {
        finalData[data] = stepData[data];
      }
    })

    return finalData
  }
  // console.log("isloader", isAddtocartloader, configuratorSaveLoding)
  return (
    <>
      <div className="configurator-action-bar">
        <div className="configurator-action-bar__wrapper">
          <div className={isGroupsetLoader ? "configurator-price" : "configurator-price is-loaded"}>
            <div className="configurator-price__wrapper">
              <div className="configurator-price__current">
                <div className="configurator-price__item current-user-price"><span className="label">{i18next.t('Pages.Configurator.BottomBar.YourPrice')} </span><span className="value"><PriceFormat price={salePrice} />
                  {((price != salePrice) && salePrice < price) && <del><PriceFormat price={price} /></del>}
                </span></div>
              </div>
              <div className="configurator-price__current">
                <div className="configurator-price__item current-other-price"><span className="label">{i18next.t('Pages.Configurator.BottomBar.PublicPrice')}</span><span className="value"><PriceFormat price={nonLoggedInPrice} /></span></div>
              </div>
              <div className="configurator-price__current">
                <div className="configurator-price__item current-other-price"><span className="label">{i18next.t('Pages.Configurator.BottomBar.DiscountLabel')}</span><span className="value"><PriceFormat price={(nonLoggedInPrice - salePrice)} /></span></div>
              </div>
            </div>
          </div>
          <div className="configurator-actions">
            <Button
              className="btn ant-btn-primary"
              onClick={() => {
                onSaveConfigurator();
                setAction('download');
                onClickDownload();
                setModalVisible(true);
              }}
              loading={isDownloadLoader}
            >
              {i18next.t("Pages.Configurator.BottomBar.DownloadPDF")}
            </Button>

            <Button
              className="btn ant-btn-primary"
              onClick={() => {
                onSaveConfigurator();
                setAction('save');
                setModalVisible(true);
              }}
              loading={configuratorSaveLoding}
            >
              {i18next.t("Pages.Configurator.BottomBar.Save")}
            </Button>

            <Button
              className="btn ant-btn-primary"
              onClick={() => {
                onClickAddToCart();
                setAction('cart');
                setModalVisible(true);
              }}
              loading={configuratorSaveLoding}
            >
              {i18next.t("Pages.Configurator.BottomBar.ProceedToCart")}
            </Button>
            {isModalVisible && <Modal
              title=""
              className="modal-s2 modal-configurator"
              visible={isModalVisible}
              width={680}
              footer={null}
              header={null}
              centered
              onCancel={(e) => {
                setModalVisible(false);
              }}

            >

              <div className="modal-header">
                <h2>
                  {action == 'download' && <> {isDownloadLoader ? i18next.t('Pages.Configurator.DowloadPDF.Popup.Loader') : i18next.t('Pages.Configurator.DowloadPDF.Popup.Header.Title')}</>}
                  {action == 'save' && <>  {configuratorSaveLoding ? i18next.t('Pages.Configurator.SaveConfigurator.Popup.Loader') : i18next.t('Pages.Configurator.SaveConfigurator.Popup.Header.Title')}</>}
                  {action == 'cart' && <>  {(configuratorSaveLoding || isAddtocartloader) ? i18next.t('Pages.Configurator.AddToCart.Popup.Loader') : i18next.t('Pages.Configurator.AddToCart.Popup.Header.Title')}</>}
                </h2>
              </div>
              <div className="modal-content">
                {(action == 'download' && !configuratorSaveError) && <>  {isDownloadLoader ? <div className="loader-configurator-popup">
                  <span className="loader"></span>
                </div> : i18next.t('Pages.Configurator.DowloadPDF.Popup.Header.Subtitle')}</>}
                {(action == 'save' && !configuratorSaveError) && <>  {configuratorSaveLoding ? <div className="loader-configurator-popup">
                  <span className="loader"></span>
                </div> : i18next.t('Pages.Configurator.SaveConfigurator.Popup.Header.Subtitle')}</>}
                {(action == 'cart' && !configuratorSaveError) && <>  {(configuratorSaveLoding || isAddtocartloader) ? <div className="loader-configurator-popup">
                  <span className="loader"></span>
                </div> : i18next.t('Pages.Configurator.AddToCart.Popup.Header.Subtitle')}</>}
                {configuratorSaveError && <>Error is saving configurator</>}
              </div>
              {!configuratorSaveError && <div className="modal-footer">
                <div className="cta-button">
                  {(!configuratorSaveLoding && (action == 'save')) && (
                    <Button
                      type="primary"
                      onClick={() => {
                        setAction('download');
                        onClickDownload();
                      }}
                      loading={isDownloadLoader}
                    >
                      {i18next.t("Pages.Configurator.BottomBar.DownloadPDF")}
                    </Button>
                  )}
                  {
                    (!configuratorSaveLoding && !isAddtocartloader) &&
                    <>
                      {
                        (action == 'save' || action == 'download') ?
                          <Button
                            type="primary"
                            onClick={() => {
                              onClickAddToCart();
                            }}
                            loading={isAddtocartloader}
                          >
                            {i18next.t("Pages.Configurator.BottomBar.ProceedToCart")}
                          </Button>
                          :
                          <Link
                            to={"/" + i18n.language + '/' + i18next.t('routes.Checkout')}
                          >
                            <Button type="primary">
                              {i18next.t("Pages.Configurator.ConfiguratorSummary.ConfirmAndProceed")}
                            </Button>
                          </Link>
                      }
                    </>
                  }
                </div>
              </div>}
            </Modal>}
          </div>
        </div>
      </div>
    </>
  );
};
export default ActionsBar;
