import React, { useState, useEffect } from "react";
import LoginComponent from "../../components/Login";
import { userLogin, getCountryData } from "../../actions/loginActions";
import { useDispatch, useSelector } from 'react-redux';
import { ReactTitle } from 'react-meta-tags';
import i18next from "i18next";

const LoginContainer = (props) => {
  const dispatch = useDispatch();
  const { isLoading, isError, message } = useSelector(state => {
    return state.login
  });

  useEffect(() => {
    dispatch(getCountryData());
  }, [])

  const onSubmitClick = (values) => {
    dispatch(userLogin(values));
  };

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.Login')} />
      <LoginComponent
        onSubmitClick={onSubmitClick}
        isLoading={isLoading}
        isError={isError}
        message={message}
      />
    </>
  );
};

export default LoginContainer;
