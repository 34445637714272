import React from "react";

const LeecouganGeometrics = ({ module }) => {
  const rowMouseOver = (element) => {
    let svgTag = document.getElementById(element.currentTarget.dataset.id);
    if(svgTag) {
      svgTag.classList.add('active'); 
    }

  }
  const rowMouseOut = (element) => {
    let svgTag = document.getElementById(element.currentTarget.dataset.id);
    if(svgTag) {
      svgTag.classList.remove('active'); 
    }
  }
  return (
  <div id="detailed-geometry-section" className="detailed-geometry-section">
    <div className="detailedGeometry__wrapper">
      <div className="inner-section">
        <div className="productDetailsHeader">
          <div className="productDetailsHeader__headingWrapper">
            <div className="headline-section align-horizzontal-left-flex align-vertical-top importance-small type-normal order-title-subtitle spacing-large">
              <div className="headline-inner">
                <div className="headline-wrap">
                  <div className="title">
                    <h2>Tabella Geometrie</h2>
                  </div>
                  <div className="paragraph">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="geometryContent productDetailsContent">
          <div className="geometryRow">
            <div className="geometryTable">
              <table className="table-geometry">
                <thead className="table-geometry__heading">
                  <tr className="geometryTable__headingRow">
                    {module && module.table && module.table.header && module.table.header.map((content, key) => {
                      if(key !== 0) {
                        return (<th className="geometryTable__heading" key={key}>{content.c}</th>)
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {module && module.table && module.table.body && module.table.body.map((content, key) => {
                      return (<tr key={key} data-id={content[0].c} onMouseOver={rowMouseOver} onMouseOut={rowMouseOut} >
                        {
                          content.map((rowData, index) => {
                            if (index !== 0) {
                              return <td className={(index == 0) ? "geometryTable__title" : 'geometryTableData'}>
                                <span>{rowData.c}</span>
                              </td>
                            }
                          })
                        }

                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
            <div className="geometryImage">
              <div className="geometryImage__inner">
                <div
                  dangerouslySetInnerHTML={{
                    __html: module.geometries
                  }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
};
export default LeecouganGeometrics;
