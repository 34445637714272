import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, Pagination, Input, Select, Steps, Modal, AutoComplete, Button, Upload, Alert, notification, Form, Search } from "antd";
import paginationLabel from "../../components/PaginationRender";
import { i18n } from "../../translations/i18n";
import { getModelFE, getSearchUser, createRegisterBike, uploadFile, getDealerRegisterBike } from "../../actions/registerBike";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import DatePicker from "../../components/DatePicker";
import Dateformat from "../../components/DateFormat";
import i18next from "i18next";
import { ReactTitle } from 'react-meta-tags';

const { Option } = Select;
const { Step } = Steps;
const { Dragger } = Upload;

const DealerRegisterBike = (props) => {

  const dispatch = useDispatch();
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(5);
  const [search, setSearch] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [filterButton, setFilterButton] = useState(false);
  const [step2Disabled, setStep2Disabled] = useState(true);
  const [step3Disabled, setStep3Disabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [isRegisterBikePopup, setRegisterBikePopup] = useState(false);
  const [file, setFile] = useState('');
  const [isFileError, setFileError] = useState(false);
  const [isCreateUser, setCreateUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [userFieldDisabled, setUserFieldDisabled] = useState(true);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [customerName, setCustomerName] = useState('');
  const [surName, setSurname] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [model, setModel] = useState('');
  const [date, setDate] = useState('')
  const [dateError, setDateError] = useState(false)
  const [reset, setReset] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [shopName, setShopName] = useState();
  const [dealerId, setDealerID] = useState();


  const { modelList, isUserSuccess, dealerBikeList, isDealerBikesLoading, message, dealerBikeTotal, isRegisterLoading, isRegisterSucces, isRegisterError, isUserError, userList, isUserLoading } = useSelector(state => {
    return state.registerBike
  });

  const draggerData = {
    name: 'file',
    multiple: false,
    disabled: file ? true : false,
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: 'Bearer ' + userData.token,

    },
    action: `${process.env.REACT_APP_API_URL}v1/content/file_upload`,
    onSuccess: function (response, file) {
      setFile(response.data.image)
      setFileError(false)
    },
    onChange(info) {
      info.file.status = 'done';
      info.fileList[0].status = 'done';
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const dispatchDealerBikes = () => {
    dispatch(getDealerRegisterBike(current, pageSize, search, selectedModel));
  }

  useEffect(() => {
    if (userList.id) {
      setSurname(userList.firstName);
      setCustomerName(userList.lastName);
    } else {
      setSurname('')
      setCustomerName('')
    }
  }, [userList]);

  useEffect(() => {
    dispatchDealerBikes();
  }, [current]);

  useEffect(() => {
    dispatch(getModelFE());
    dispatchDealerBikes();
  }, []);
  const makeResetFilter = () => {
    setSearch("");
    setSelectedModel();
    setFilterButton(false);
    dispatchDealerBikes();
  }
  useEffect(() => {
    const timeout = setTimeout(function () {
      setcurrent(1);
      dispatchDealerBikes();
    }, 2000);
    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    if (userEmail != '' && customerName != '' && surName != '' && validEmail == false) {
      setStep2Disabled(false)
    } else {
      setStep2Disabled(true)
    }
  }, [userEmail, customerName, surName, validEmail])

  useEffect(() => {
    if (serialNumber != '' && model != '' && date != '' && file != '') {
      setStep3Disabled(false)
    } else {
      setStep3Disabled(true)
    }
  }, [serialNumber, model, date, file])

  const registerBikeFun = () => {
    dispatch(createRegisterBike(
      {
        "customer_name": customerName,
        "email": userEmail,
        "model": model,
        "purchase_date": date,
        "serial_number": serialNumber,
        "shop_name": dealerId,
        "surname": surName,
        "file": file,
      }
    ));
  }


  useEffect(() => {
    isUserError && setUserFieldDisabled(false)
  }, [isUserError]);
  const onSearch = (searchText) => {
    setUserEmail(searchText);
    let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(searchText)) {
      dispatch(getSearchUser(searchText));
      setValidEmail(false);
    } else {
      setCustomerName('');
      setSurname('');
      setValidEmail(true);
    }
  }

  useEffect(() => {
    if (isRegisterSucces) {
      dispatchDealerBikes();
      setRegisterBikePopup(false)
      notification['success']({
        message: message
      });
    }
  }, [isRegisterSucces, message])

  useEffect(() => {
    if (localStorage.getItem("user-roles")) {
      let userRoles = JSON.parse(localStorage.getItem("user-roles"));
   
      if (userRoles.includes('agent')) {
        if (localStorage.getItem('agent-selected-user')) {
          const userDataDealer = JSON.parse(localStorage.getItem("agent-selected-user"))
          setShopName(userDataDealer.company_name);
          setDealerID(userDataDealer.id)
        }
      } else {
        setShopName(userData.dealer.company_name);
        setDealerID(userData.id)
      }
    } else {
      console.log("userData", "Oops")
    }
  }, []);

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.RegisterBike')} />
      <Header />
      <section className="dashboard-content prd-list">
        <div className="wrap">
          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-side-container">
                <div className="filter-blk">
                  <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>
                  <>
                    <div className="form-wrapper">
                      <div className="form-group">
                        <div className="form-label">
                          <label>{i18next.t("Common.FiltersSidebar.SerialNumber")}</label>
                          <Input
                            type="text"
                            className="txt-search"
                            placeholder=""
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setFilterButton(true)
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-label">
                          <label>{i18next.t("Common.FiltersSidebar.Model")}</label>
                          <Select value={selectedModel}
                            dropdownClassName="dropdown-select-s2"
                            onChange={(value) => {
                              setSelectedModel(value)
                              setFilterButton(true)
                            }}
                            placeholder="Seleziona un modello"
                          >
                            {modelList.length > 0 && modelList.map((model, index) => (
                              <Option key={model.name} value={model.term_id}>{model.name}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </>
                </div>

                <button
                  className="btn btn-primary btn-submit"
                  onClick={dispatchDealerBikes}
                >
                  {i18next.t("Common.FiltersSidebar.Submit")}
                </button>


                <button className="btn btn-secondary btn-hover-outline btn-submit"
                  onClick={() => setRegisterBikePopup(true)}>
                  {i18next.t("Common.FiltersSidebar.RegisterNewBike")}
                </button>


                {filterButton && <button
                  className="btn btn-primary btn-submit"
                  onClick={makeResetFilter}
                >
                  {i18next.t("Common.FiltersSidebar.Reset")}
                </button>}
              </div>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t("Pages.RegisterBike.PageTitle")}</h3>
              </div>
              <div className="table rel with-pagination">
                <div className="hdr sticky">
                  <Row gutter={[24, 24]}>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.RegisterBike.Table.Client")}</div>
                    </Col>
                    <Col
                      span={5}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                      lg={{ span: 5 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.RegisterBike.Table.Email")}</div>
                    </Col>
                    <Col
                      span={5}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                      lg={{ span: 5 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.RegisterBike.Table.PurchaseDate")}</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.RegisterBike.Table.Model")}</div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.RegisterBike.Table.SerialNumber")}</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.RegisterBike.Table.ProofOfPurchase")}</div>
                    </Col>
                  </Row>
                </div>
                <div className="table-container-wrapper">
                  <div className={isDealerBikesLoading ? "loader-container" : "loader-container is-loaded"}>
                    <span className="loader"></span>
                    <div className="loader-text">{i18next.t("Pages.RegisterBike.Loader")}</div>
                  </div>
                  <div className="table-wrap">
                    {dealerBikeList.length > 0 &&
                      dealerBikeList.map((item, idx) => (
                        <div className="table-item" key={idx}>

                          <Row gutter={[24, 24]}>
                            <Col
                              span={4}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              <div className="table-item__content">{item.customer_name} {item.surname}</div>
                            </Col>
                            <Col
                              span={5}
                              xs={{ span: 5 }}
                              sm={{ span: 5 }}
                              lg={{ span: 5 }}
                            >
                              <div className="table-item__content">{item.email}</div>
                            </Col>
                            <Col
                              span={5}
                              xs={{ span: 5 }}
                              sm={{ span: 5 }}
                              lg={{ span: 5 }}
                            >
                              <div className="table-item__content">
                                <Dateformat date={item.purchase_date} />
                              </div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">{item.model.name}</div>
                            </Col>
                            <Col
                              span={4}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              <div className="table-item__content">{item.serial_number}</div>
                            </Col>
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">
                                <button className="btn btn-icon" name="configurator_pdf" onClick={() => saveAs(item.file)}>
                                  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.98 280.78"><path d="M157.64,280.78c-47.21,0-81.67-2.48-102.8-7.41C31.18,267.86,2.57,248.45,.7,187.33-.43,150.32-.21,114.77,1.45,68.44l.16-6.1C2.05,41.93,2.72,11.09,30.09,3.28,43.77-.62,62.79-.61,74.7,1.07c4.79,.67,8.13,5.09,7.45,9.88-.67,4.78-5.1,8.16-9.88,7.45-10.2-1.42-26.76-1.31-37.37,1.71-13.86,3.95-15.32,20.89-15.79,42.61-.05,2.2-.1,4.33-.18,6.35-1.64,45.91-1.85,81.11-.73,117.72,1.23,40.14,14.88,63.54,40.61,69.54,26.24,6.12,75.34,8.21,146.03,6.24,48.71-1.36,69.88-23.34,70.76-73.5l.2-10.79c.61-32.3,1.36-72.52-.66-110.83-.06-1.23-.11-2.62-.16-4.15-.51-16.95-2.34-34.07-18.28-40.93-11.74-5.07-27.78-4.3-43.21-3.07-4.93,.34-9.03-3.22-9.41-8.03-.39-4.81,3.21-9.03,8.03-9.41,17.61-1.4,36.09-2.2,51.53,4.45,27.51,11.85,28.42,41.99,28.86,56.48l.14,3.75c2.05,38.94,1.28,79.49,.66,112.09l-.19,10.76c-1.04,59.4-29.75,89.07-87.76,90.68-17.13,.46-33.04,.71-47.71,.71h0Z" /><path d="M146.98,199.48h-.04c-4.83-.03-8.73-3.96-8.71-8.79,.13-25.71,.26-75.83,0-100.54-.05-4.83,3.82-8.79,8.65-8.85h.1c4.8,0,8.7,3.86,8.75,8.65,.26,24.82,.13,75.05,0,100.81-.03,4.83-3.94,8.72-8.75,8.72h0Z" /><path d="M146.97,199.48c-1.98,0-3.95-.66-5.59-2.02-3.72-3.1-4.22-8.61-1.13-12.33,4.67-5.62,10.04-11.33,15.23-16.86,4.02-4.28,8.05-8.57,11.8-12.86,2.12-2.42,4.06-4.93,6-7.45l2.58-3.32c2.99-3.78,8.5-4.45,12.29-1.45,3.8,2.99,4.45,8.49,1.45,12.29l-2.46,3.18c-2.17,2.81-4.32,5.59-6.7,8.29-3.87,4.43-8.04,8.87-12.2,13.3-5.25,5.59-10.2,10.87-14.54,16.08-1.74,2.07-4.22,3.15-6.73,3.15h0Z" /><path d="M146.99,199.48c-2.51,0-5-1.08-6.74-3.15-4.34-5.21-9.3-10.5-14.54-16.08-4.16-4.43-8.32-8.87-12.21-13.31-2.36-2.7-4.52-5.48-6.68-8.29l-2.46-3.18c-2.99-3.8-2.34-9.3,1.45-12.29,3.8-2.98,9.3-2.33,12.29,1.45l2.58,3.32c1.94,2.52,3.87,5.02,5.99,7.45,3.75,4.29,7.79,8.58,11.8,12.86,5.19,5.53,10.55,11.24,15.23,16.86,3.1,3.71,2.59,9.23-1.13,12.33-1.63,1.35-3.61,2.02-5.59,2.02h0Z" /></svg>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))
                    }
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={dealerBikeTotal}
                    showTotal={(total, range) => `${range[0]}-${range[1]} di ${dealerBikeTotal} garanzie`}
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isRegisterBikePopup && <>
        <Modal
          width={1200}
          className="modal-s2 modal-registerbike"
          centered
          closeIcon={<svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.99 222.99"><path d="M461.5,192.25l-23.74-23.74L350,256.26l-87.75-87.75-23.74,23.74L326.26,280l-87.75,87.76,23.74,23.74L350,303.75l87.76,87.75,23.74-23.74L373.75,280Z" transform="translate(-238.51 -168.51)" /></svg>}
          visible={isRegisterBikePopup}
          onClose={(e) => {
            setRegisterBikePopup(false);
          }}
          onCancel={(e) => {
            setRegisterBikePopup(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          footer={null} closeIcon={<svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.99 222.99"><path d="M461.5,192.25l-23.74-23.74L350,256.26l-87.75-87.75-23.74,23.74L326.26,280l-87.75,87.76,23.74,23.74L350,303.75l87.76,87.75,23.74-23.74L373.75,280Z" transform="translate(-238.51 -168.51)" /></svg>}
        >
          <div className="modal-header">
            <h2>
              {i18next.t('Pages.RegisterBike.FormPage.Header.Title')}
            </h2>
          </div>
          <div className="modal-content">
            <Steps
              current={currentStep}
              onChange={(e) => {
                setCurrentStep(e);
              }}
            >
              <Step key="0" title={i18next.t('Pages.RegisterBike.FormPage.Form.TitleStep1')} />
              <Step key="1" title={i18next.t('Pages.RegisterBike.FormPage.Form.TitleStep2')} disabled={step2Disabled} />
              <Step key="2" title={i18next.t('Pages.RegisterBike.FormPage.Form.TitleStep3')} disabled={step3Disabled} />
            </Steps>
            {currentStep == 0 && (
              <>
                <div className="Modal-step_content">
                  <div className="modal-info">
                    <p>{i18next.t('Pages.RegisterBike.FormPage.Form.TitleStepInner1')}</p>
                  </div>
                  <div className="form-wrapper">

                    <Row gutter={[{ xs: 8, sm: 16, lg: 24 }, { xs: 0, sm: 16, lg: 0 }]}>

                      <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="form-group">
                          <div className="form-label">
                            <label>{i18next.t('Pages.RegisterBike.FormPage.Form.Email')}</label>
                          </div>

                          <Input.Search className="user-search" name="email" type='email' onChange={(e) => onSearch(e.target.value)} loading={isUserLoading} />
                          {validEmail && <div className="message message-error">{i18next.t('Pages.RegisterBike.FormPage.Form.EmailNotValid')}</div>}
                          {!validEmail && isUserError && <div className="message message-warning">{i18next.t('Pages.RegisterBike.FormPage.Form.MessageUserNotFound')}</div>}
                          {!validEmail && isUserSuccess && <div className="message message-success">{i18next.t('Pages.RegisterBike.FormPage.Form.MessageUserFound')}</div>}
                        </div>
                      </Col>

                      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="form-group">
                          <div className="form-label">
                            <label>{i18next.t('Pages.RegisterBike.FormPage.Form.Name')}</label>
                          </div>
                          <Input disabled={userFieldDisabled} value={customerName} onChange={(e) => {
                            setCustomerName(e.target.value)
                          }} name="customer_name" />
                        </div>
                      </Col>

                      <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="form-group">
                          <div className="form-label">
                            <label>{i18next.t('Pages.RegisterBike.FormPage.Form.Surname')}</label>
                          </div>
                          <Input disabled={userFieldDisabled} value={surName} onChange={(e) => {
                            setSurname(e.target.value)
                          }} />
                        </div>
                      </Col>
                    </Row>

                  </div>
                </div>
                <div className="Modal-footer">
                  <Button className="btn ant-btn-primary" disabled={step2Disabled} onClick={() => setCurrentStep(1)} >Prossimo</Button>
                </div>
              </>
            )}
            {currentStep == 1 && (
              <>
                <div className="Modal-step_content">
                  <div className="modal-info">
                    <p>{i18next.t('Pages.RegisterBike.FormPage.Form.TitleStepInner2')}</p>
                  </div>
                  <div className="form-wrapper">
                    <Row gutter={[{ xs: 8, sm: 16, lg: 24 }, { xs: 0, sm: 16, lg: 0 }]}>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>

                        <div className="form-group">
                          <div className="form-label">
                            <label>{i18next.t('Pages.RegisterBike.FormPage.Form.Shop')}</label>
                          </div>
                          <Select defaultValue={shopName} disabled>
                            <Option value={shopName}>
                              {shopName}
                            </Option>
                          </Select>
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>
                        <div className="form-group">
                          <div className="form-label">
                            <label>{i18next.t('Pages.RegisterBike.FormPage.Form.PurchaseDate')}</label>
                          </div>
                          <DatePicker setDay={setDate} defultDay={date} reset={setReset} />
                          {dateError && <div>select date</div>}
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>
                        <div className="form-group">
                          <div className="form-label">
                            <label>{i18next.t('Pages.RegisterBike.FormPage.Form.Model')}</label>
                          </div>
                          <Select defaultValue="" value={model} onChange={(value) => setModel(value)}>
                            <Select.Option value="">
                              {i18next.t('Pages.RegisterBike.FormPage.Form.SelectModel')}
                            </Select.Option>
                            {modelList &&
                              modelList.length > 0 &&
                              modelList.map((modelData) => {
                                return (
                                  <Select.Option value={modelData.term_id}>
                                    {modelData.name}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>
                        <div className="form-group">
                          <div className="form-label">
                            <label>{i18next.t('Pages.RegisterBike.FormPage.Form.SerialNumber')}</label>
                          </div>
                          <Input value={serialNumber} onChange={(e) => {
                            setSerialNumber(e.target.value)
                          }} />
                        </div>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                        <div className="form-group">
                          <Dragger
                            {...draggerData}
                          >
                            <p className="ant-upload-drag-icon">
                              {/* <InboxOutlined /> */}
                            </p>
                            <p className="ant-upload-text">{i18next.t('Pages.RegisterBike.FormPage.Form.ProofOfPurchase')}</p>
                            <p className="ant-upload-hint">
                              <span>{i18next.t('Pages.RegisterBike.FormPage.Form.importFIle')}</span>
                            </p>
                          </Dragger>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="Modal-footer">
                  <Button className="btn ant-btn-primary" disabled={step3Disabled} onClick={() => setCurrentStep(2)} >Prossimo</Button>
                </div>
              </>
            )}
            {currentStep == 2 && (
              <>
                <div className="Modal-step_content">
                  <div className="modal-info">
                    <p>Controlla i dati della registrazione</p>
                  </div>
                  <div className="summary-form">

                    {isRegisterError && (
                      <Alert message={message} type="error" />
                    )}
                    <div className="warranty-bike-summary">
                      <div className="warranty-bike-summary__inner">

                        <div className="warranty-summary-item">
                          <div className="label">
                            <p>Email:</p>
                          </div>
                          <div className="value">
                            <p>{userEmail}</p>
                          </div>
                        </div>
                        <div className="warranty-summary-item">
                          <div className="label">
                            <p>Name and Surname:</p>
                          </div>
                          <div className="value">
                            <p>{customerName} {surName}</p>
                          </div>
                        </div>
                        <div className="warranty-summary-item">
                          <div className="label">
                            <p>Purchase Date:</p>
                          </div>
                          <div className="value">
                            <p><Dateformat date={date} /></p>
                          </div>
                        </div>
                        <div className="warranty-summary-item">
                          <div className="label">
                            <p>Model</p>
                          </div>
                          <div className="value">
                            <p>{modelList.length > 0 && modelList.map((modelData, index) => {
                              if (modelData.term_id == model) {
                                return <>{modelData.name}</>
                              }
                            })} </p>
                          </div>
                        </div>
                        <div className="warranty-summary-item">
                          <div className="label">
                            <p>Serial Number</p>
                          </div>
                          <div className="value">
                            <p>{serialNumber}</p>
                          </div>
                        </div>
                        <div className="warranty-summary-item">
                          <div className="label">
                            <p>Proof of Purchase</p>
                          </div>
                          <div className="value">
                            <p><a target={"_blank"} href={file}>Download your file</a></p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="Modal-footer">
                  {/* <Button className="btn ant-btn-primary"onClick={() => setCurrentStep(1)}>Precedente</Button> */}
                  <Button
                    className="btn btn-primary"
                    type="primary"
                    htmlType={"button"}
                    loading={isRegisterLoading}
                    onClick={() => { registerBikeFun() }}
                  >
                    Conferma
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      </>
      }
      <Footer />
    </>
  );
};

export default withRouter(DealerRegisterBike);
