export const TRANSLATIONS_ES = {
  routes: {
    Dashboard: "dashboard",
    Configuration: "configurar",
    ConfigurationsSaved: "sus-configuraciones",
    ConfigurationsSearch: "upload-configuraciones",
    NewOrder: "nuevo-pedido-equipo",
    YourOrders: "tus-ordenes",
    backOrders: "pedidos-confirmados",
    RegisterBike: "registrar-bicicleta-cliente",
    SupportArea: "area-soporte",
    TrainingArea: "entrenamiento-area",
    FTPArea: "area-de-descargas",
    B2BNews: "noticias",
    AccountUser: "datos-cuenta",
    SwitchBrand: "cambiar-marca",
    ShopSelectorAgent: "selector-tienda",
    Checkout: "checkout",
    Login: "login",
    ResetPassword: "restablecer-contrasena",
    NewPassword: "nueva-contrasena",
    PrivacyPolicy: "privacy",
    TermsConditions: "terminos-condiciones",
    BassoFrontend: "https://bassobikes.com",
    LeeCouganFrontend: "https://www.leecougan.com",
    StockProduct:"productos-listos-para-entrega",
  },
  PageTitle: {
    Dashboard: "Dashboard | Stardue B2B",
    Configurator: "Configurar | Stardue B2B",
    ConfigurationsSearch: "Upload configuraciones | Stardue B2B",
    ConfigurationsSaved: "Sus Configurations | Stardue B2B",
    NewOrder: "Nuevo pedido de equipo | Stardue B2B",
    YourOrders: "{{orderId}} tus ordenes | Stardue B2B",
    OrderList: "Tus ordenes | Stardue B2B",
    BackOrders: "Pedidos confirmados | Stardue B2B",
    RegisterBike: "Registrar bicicleta de cliente | Stardue B2B",
    SupportArea: "Área de soporte | Stardue B2B",
    B2BNews: "Noticias | Stardue B2B",
    TrainingArea: " Área Training | Stardue B2B",
    FTPArea: "Área Download | Stardue B2B",
    AccountUser: "Datos de la cuenta | Stardue B2B",
    SwitchBrand: "Cambiar marca | Stardue B2B",
    ShopSelectorAgent: "Seleccionador de tiendas | Stardue B2B",
    Checkout: "Checkout | Stardue B2B",
    Login: "Login | Stardue B2B",
    ResetPassword: "Restablecer la contraseña | Stardue B2B",
    NewPassword: "Nueva contraseña | Stardue B2B",
    PrivacyPolicy: "Política de Privacy | Stardue B2B",
    TermsConditions: "Términos y condiciones | Stardue B2B",
    ProductsStock: "Productos listos para entrega| Stardue B2B",
  },
  Footer: {
    Coopyright: "© 2022 - STARDUE S.R.L. - P.IVA / C.F. 02109950267",
    TermsConditions: "Términos y condiciones",
    PrivacyPolicy: "Política de Privacy",
  },
  Header: {
    Option: "Seleccione una opción",
    BikesGroup: {
      Bikes: "Bicicletas",
      ProductsStock: "Productos listos para entrega",
      ConfigurationsSaved: "Mis configuraciones",
      ConfigurationsSearch: "Encuentra la configuración del cliente",
      Configuration: "Crear nueva configuración",
    },
    Equipments: "Equipo",
    OrdersGroup: {
      Orders: "Pedidos",
      OrdersWaiting: "Confirmación pendiente",
      BackOrders: "Confirmados",
    },
    ServicesGroup: {
      Services: "Servicios",
      RegisterBike: "Registrar la nueva bicicleta del cliente",
      SupportArea: "Área de asistencia",
      FTPArea: "Área Download",
      TrainingArea: "Área Training",
      B2BNews: "Últimas noticias",
    },
    UserGroup: {
      YourData: "Tu información",
      Logout: "Logout",
      ChangePlatform: "Cambiar marca",
      ChangeShop: "Cambiar tienda",
    },
  },
  Dashboard: {
    ftpArea: "Área Download",
    ftpAreaText:
      "Login en su área de descarga para descargar un contenido reservado.",
    ticketArea: "Área de asistencia",
    ticketAreaText: "",
    trainingArea: "Área Training",
    trainingAreaText:
      "Descubra todos los videotutoriales reservados para nuestros revendedores.",
    warranty: "Registrar la nueva bicicleta del cliente",
    warrantyText: "",
    ticketArea: "Área de asistencia",
    ticketAreaText: "",
    configuration: "Crear una nueva configuración",
    configurationText: "",
    configurationSaved: "Tus configuraciones",
    configurationSavedText: "",
    equipmentSearch: "Buscar equipo",
    searchPlaceholder: "SKU o nombre del producto",
    order: "Pedidos confirmados",
    orderText:
      "Siga el progreso de sus pedidos confirmados",
    newsArea: "Últimas noticias",
    newsAreaText:
      "Las últimas noticias de nuestro mundo para que puedas ser el primero en enterarte.",
    webSite: "Sitio web",
    webSiteText: "Visite el sitio web B2C",
    button: "Enter",
    goToWebsite: "Ir al sitio web",
    ProductStock: "Productos listos para entrega",
    ProductStockText: "Productos en nuestro almacén listos para ser enviados.",
  },
  Common: {
    ServiceNotAvailable: "Datos no disponibles debido a la migración de ERP. Lamentamos las molestias. El servicio volverá pronto.",
    NoMobileView:
      "Dashboard is not available on mobile screen. Please continue to use your Laptop.",
    PasswordMatchError: "Las contraseñas no coinciden.",
    hi: "Hola",
    Back: "Atrás",
    Logout: "Logout",
    Address: "Dirección",
    Contacts: "Contactos",
    FiscalCode: "Código Fiscal",
    AddNewAddress: "Agregar nueva dirección",
    NewAddress: "Nueva direccion",
    RequiredFields:
      "Todos los campos marcados con asterisco* son obligatorios. ",
    Confirm: "Confirmar",
    Nominative: "Nominativo",
    okText: "Sí",
    CancelText: "No",
    Proceed: "Proceder",
    DiscoverButton: "Descubrir",
    FiltersSidebar: {
      Title: "Filtrar",
      Submit: "Aplicación",
      Reset: "Eliminar filtros",
      All1: "Todos",
      All2: "Todos",
      Model: "Modelo",
      Groupset: "Grupo",
      idOrder: "ID de pedido",
      IDConfiguration: "ID de configuración",
      SKU: "Search SKU o nombre del producto",
      SKUADMIN: "Busque SKU, nombre de producto o por nombre y código del distribuidor",
      Category: "Elige una categoría",
      RelatedModel: "Encuentre repuestos relacionados con:",
      State: "Filtrar por status",
      FromDate: "Del",
      ToDate: "Al",
      SerialNumber: "Número de serie",
      ChooseOption: "Seleccionar opción",
      RegisterNewBike: "Registrar una bicicleta nueva",
      OpenTicket: "Abrir nuevo ticket",
      Ready_to_ship: 'Listo para enviar',
      order_confirmed: 'Pedido confirmado',
      in_production: 'En producción',
      on_hold: 'En espera',
    },
    Pagination: {
      Prev: "Anterior",
      Next: "Próximo",
    },
    ConfirmationMessage: {
      Products: {
        Title: "Producto añadido a su pedido",
        Label: "Gracias, el producto ha sido añadido a su pedido.",
      },
    },
    Form: {
      SelectOption: "Escoger",
      companyname: "Nombre de la compania",
      requireField: "Este campo es requerido. Porfavor introduzca un valor.",
      name: "Nombre",
      PublicEmail: "E-mail público",
      require_name: "Por favor inserte su nombre.",
      surname: "Apellido",
      require_surname: "Por favor inserte su apellido.",
      address: "Dirección",
      country: "País",
      require_country: "Por favor inserte su país.",
      city: "Ciudad",
      require_city: "Por favor inserte su ciudad.",
      province: "Provincia",
      require_province: "Por favor inserte la provincia.",
      street: "Calle y número",
      require_street: "Por favor, introduzca su nombre de calle y número.",
      postalcode: "Código Postal",
      require_postalcode: "Por favor, introduzca el código postal.",
      contact: "Información del contacto",
      phone: "Teléfono",
      require_phone: "Por favor, introduzca su número de teléfono.",
      defaultAddressSettings: "Ajustes",
      is_default_address: "Marque esta dirección como predeterminada.",
      edit: "Editar",
      email: "E-mail",
      valid_email: "Esta e-mail no es válida, ingrese una e-mail válida.",
      require_email: "Por favor inserte su e-mail",
      first_name: "Nombre",
      require_first_name: "Por favor inserte su nombre.",
      last_name: "Apellido",
      require_last_name: "Por favor inserte su apellido.",
      password: "Password",
      require_password: "Por favor inserte su contraseña",
      confirmPassword: "Confirmar contraseña",
      require_confirmPassword: "Por favor, confirme su contraseña",
      model: "Modelo",
      serial_number: "Número de serie",
      require_serial_number: "Por favor agregue su número de serie",
      purchase_date: "Fecha de compra",
      proof_purchase: "Comprobante de compra",
      store: "Distribuidor",
      require_store:
        "Por favor, utilice este campo para seleccionar su Distribuidor",
      save: "Guardar",
      sendrequest: "Enviar petición",
      otherInformation: "Otra información",
      generic_required:
        "Este campo es requerido. Por favor agregue la información solicitada.",
      couopon: "Cupón",
      request: "Petición",
      query: "Información adicional",
      topic: "Tema",
      Update: "Actualizar",
    },
    Bikes: {
      SummaryBike: {
        ConfigurationID: "ID de configuración",
        Sku: "Sku",
        Groupset: "Grupo",
        Size: "Talla",
        Wheels: "Ruedas",
        Color: "Color",
      },
    },
  },
  Pages: {
    ChangeBrand: {
      RequestAccess: "Solicitar acceso",
      Form: {
        Title: "Solicitar acceso",
        Label: "Describa su solicitud",
        Submit: "Enviar Consulta"
      }
    },
    Login: {
      Title: "Bienvenido",
      Subtitle: "Log in en su dashboard dedicado",
      Email: "Tu e-mail",
      Password: "Tu contraseña",
      Submit: "Login",
      LostPassword: "¿Has olvidado tu contraseña?",
    },
    ForgotPassword: {
      Title: "¿Has olvidado tu contraseña?",
      Subtitle: "Restablecerlo ahora e login",
      Email: "Tu e-mail",
      Submit: "Proceder",
    },
    NewPassword: {
      Title: "¿Olvidaste tu contraseña?",
      Subtitle: "Restablecerlo ahora e login",
      Email: "Tu e-mail",
      Password: "Nueva contraseña",
      ConfirmPassword: "Confirmar la contraseña",
      Message: "",
      Submit: "Confirmar",
    },
    AgentPage: {
      Loader: "Estamos cargando a tus clientes",
      Title: "Login en nombre de un cliente.",
      NoClient:
        "Sin clientes asociados. Póngase en contacto con nuestro soporte.",
    },
    UserDetails: {
      YourData: "Tu perfil",
      Anagraphic: "Tu ",
      Company: "Nombre de compañía",
      VAT: "Número IVA",
      EmailAccount: "Cuenta e-mail",
      StoreLocatorTitle: "Información del localizador",
      TimeTable: {
        ShopTime: "Hora de apertura",
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
        morning: "Mañana",
        afternoon: "Tarde",
        start: "Comienzo",
        end: "Fin",
        closed: "Cerrado",
        nonStop: "Continuo",
      },
    },
    Configurator: {
      PageTitle: "Configurador de bicicletas",
      ConfigureNow: "Configura el tuyo",
      LoaderLabel: "Estamos cargando el configurador.",
      NotSelected: "No seleccionado",
      AvailableFrom: "Desde",
      Quantity: "Cantidad",
      Geometry: "Geometría",
      OutOfStock: "Agotado",
      Type: {
        Bike: "La bicicleta Basso completa lista para conducido.",
        FrameKit:
          "El cuadro, la horquilla y todos los componentes específicos de Basso.",
        BuildKit: "Kit cuadro y todos los componentes sin grupo.",
      },
      Step: {
        Header: {
          Categories: "Categorías",
          Models: "Modelo",
          Type: "Type",
          Wheels: "Ruedas",
          Color: "Color",
          Size: "Talla",
          Groupset: "Grupo",
          Options: "Options",
          Quantity: "Cantidad",
        },
        Price: {
          Included: "Incluido",
          Upgrade: "Upgrade seleccionado",
        },
      },
      BottomBar: {
        ProceedToCart: "Añadir al carrito",
        FindYourDealer: "Encuentre su distribuidor",
        Save: "Guardar",
        DownloadPDF: "Descargar PDF",
        YourPrice: "Reserved price",
        PublicPrice: "Precio de venta sugerido",
        DiscountLabel: "Tu marcado",
      },
      ConfiguratorSummary: {
        Title: "Resumen de configuración",
        FrameSet: "Kit cuadro",
        Frame: "Cuadro",
        Fork: "Horquilla",
        SeatPostClamp: "Abrazadera",
        ThruAxleFornt: "Eje pasante delantero",
        ThruAxleRear: "Eje pasante trasero",
        Hanger: "Patilla de cambio",
        Headset: "Dirección",
        Groupset: "Grupo",
        Crank: "Bielas",
        CrankArm: "Brazo de bielas",
        ChainRings: "Platos de cadena",
        Chain: "Cadena",
        BottomBraket: "Cazoletas",
        Cassette: "Cassette",
        CassetteGears: "Ratio",
        FrontDerailleur: "Desviador",
        RearDerailleur: "Cambio",
        Shifters: "Manetas",
        Brakes: "Frenos",
        Rotors: "Discos de freno",
        Cockpit: "Cockpit",
        SeatpostAndSaddle: "Tija de sillín y sillín",
        WheelsAndTires: "Ruedas y cubiertas",
        Wheels: "Ruedas",
        Upgrade: "Upgrade",
        HeadUnitMount: "Soportes para computadora",
        HandlebarTap: "Cinta de manillar",
        PowerUnit: "Power Unit",
        Motor: "Motor",
        Display: "Display",
        Battery: "Batería",
        Other: "Otro",
        Editing: "Sigue editando",
        DownloadPDF: "Descargar PDF",
        ConfirmAndProceed: "Proceder al carrito",
      },
      SaveConfigurator: {
        Popup: {
          Loader: "Estamos guardando su configuración...",
          Header: {
            Title: "Guarda tu configuración",
            Subtitle: "Felicitaciones, su configuración ha sido guardada.",
          },
          DiscoverButton: "Descubrir",
        },
      },
      AddToCart: {
        Popup: {
          Loader: "Estamos agregando la configuración al carrito...",
          Header: {
            Title: "Configuración añadida",
            Subtitle:
              "Felicitaciones, su configuración ha sido agregada al carrito.",
          },
        },
      },
      DowloadPDF: {
        Popup: {
          Loader: "Estamos preparando el PDF de su configuración...",
          Header: {
            Title: "Descargar PDF",
            Subtitle: "Felicitaciones, su PDF ha sido generado.",
          },
        },
      },
      SummarySmall: {
        Title: "Resumen",
        Categories: "Categorías",
        Models: "Modelo",
        Type: "Type",
        Wheels: "Ruedas",
        Color: "Color",
        Size: "Talla",
        Groupset: "Grupo",
      },
      PreviewImage: {
        Title: "Avance",
        LargeImage: "Click para agrandar la imagen",
      },
    },
    ConfigurationSaved: {
      PageTitle: "Configuraciones guardadas",
      Loader: "Estamos cargando sus configuraciones...",
      Table: {
        ID: "ID",
        Model: "Modelo",
        Groupset: "Grupo",
        Size: "Talla",
        Color: "Color",
        Wheels: "Ruedas",
        Price: "Precio",
        Edit: "Editar",
        Summary: "Resumen",
        DownloadPDF: "Descargar PDF",
        OrderDetails: "Ver pedido",
        Delete: "Borrar",
      },
    },
    SearchConfiguration: {
      Title: "Buscar configuración existente",
      Info:
        'Inserte un código de ID de configuración para recuperar una configuración existente. El ID de configuración se puede encontrar en la página "Sus configuraciones" en el sitio web B2B y B2C y en todos los documentos de configuración PDF.',
      Search: "Buscar ahora",
      ConfigCode: "Insertar código de ID configuración",
      Loader: "Buscamos la configuración solicitada...",
      Table: {
        Edit: "Editar",
        Summary: "Resumen",
        DownloadPDF: "Descargar PDF",
        OrderDetails: "Ver pedido",
      },
    },
    Address: {
      Header: {
        Title: "Tus direcciones",
        Subtitle: "Administre sus direcciones de envío y facturación.",
      },
      ShippingAddress: {
        Label: "Dirección de envío",
      },
      BillingAddress: {
        Label: "Dirección de facturación",
      },
    },
    Equipments: {
      PageTitle: "Pedido online",
      Loader: "Estamos subiendo los productos",
      NotFound: "No se encontraron productos. Utilice otra palabra clave.",
      Table: {
        Image: "Imagen",
        SKU: "SKU",
        Name: "Nombre",
        Categories: "Categorías",
        Stock: "Surtir",
        Price: "Precio",
        OrderNow: "Agregar al pedido",
      },
      Details: {
        RecomandedPrice: "Precio de venta sugerido",
        YourDiscount: "Tu markup",
        Description: "Descripción",
        Features: "Características",
        BuyNow: "Agregar al pedido",
        ConfirmationAddToCart: "",
      },
    },
    Orders: {
      PageTitle: "Tus pedidos",
      Loader: "Estamos cargando sus pedidos...",
      Table: {
        OrderNumber: "ID",
        OrderDate: "Fecha de pedido",
        ExpectiveDate: "Entrega esperada",
        Destination: "Entregado a",
        State: "Status",
        Products: "Productos",
        ProductIncluded: "Productos",
        Total: "Total",
        Details: "Detalles",
        ChildOrderLabel: "Pedidos relacionados",
      },
      Single: {
        Order: "Pedido",
        EstimatedDate: "Fecha estimada",
        NotAvailableEstimateDate: "Indisponible",
        OpenTicket: "Abrir un ticket",
        OrderDate: "Fecha de pedido",
        ShipmentTo: "Envío a",
        OrderStatus: "Status",
        DownloadReceipt: "Descargar recibo",
        ReturnOrder: "Devolución de pedido",
        ReturnRequests: "Solicitud de devolución",
        ReturnRequestsInfo: "Su solicitud ha sido enviada con éxito",
        OrderSummary: "Resumen del pedido",
        Sku: "Sku",
        Groupset: "Grupo",
        ConfiguratorID: "ID Configurador",
        Size: "Talla",
        Color: "Color",
        Wheels: "Ruedas",
        DownloarConf: "Descarga tu configuración",
        Quantity: "Cantidad",
        Price: "Precio",
        Subtotal: "Total parcial",
        Shipment: "Envío",
        PaymentMethod: "Método de pago",
        Total: "Total",
        BillingInformation: "Datos de facturación",
        ShippingData: "Datos de envìo",
        FiscalCode: "Código Fiscal",
        IncludeOption: "Opciones incluidas",
        Upgrade: "Upgrade seleccionado",
        Bacs: "Según lo acordado con Stardue Srl",
      },
      TicketOrder: {
        Common: {
          Title: "Pedido {{orderId}} - Ticket de asistencia",
          Request: "Petición",
          Order: "Pedido",
          StepTitle1: "Petición",
          StepTitle2: "Productos",
          StepTitle3: "Cuéntanos más",
          StepTitle4: "Confirmación",
          Next: "Próximo",
          Proceed: "Proceder",
          Back: "Atrás",
          SuccessTitle: "Su solicitud ha sido enviada con éxito.",
          SuccessSubtitle:
            "Serás contactado por el equipo de Basso Bikes dentro de las próximas 24/48 horas.",
          SuccessButton: "Volver al pedido",
          FileUploadTitle: "Adjuntar un file",
          FileUploadContent:
            "Importe imágenes que representen el problema encontrado; Se aceptan .pdf, .png y .jpeg. Tamaño máximo de archivo: 10 MB",
        },
        Step1: {
          Content: "Selecciona el tema de tu pedido",
          ReturnReplacement: "Reemplazo / Devolución",
          Tracking: "Información de seguimiento",
        },
        Step2: {
          Content: "Seleccione el producto con problemas:",
        },
        Step3ReturnProducts: {
          Content:
            "¿Qué tipo de problemas has encontrado? Ayude al personal de Basso a comprender qué salió mal.",
        },
        Step3Tracking: {
          Content:
            "¿Qué tipo de problemas has encontrado? Ayude al personal de Basso a comprender qué salió mal.",
        },
        Step4ReturnProducts: {
          Content:
            "Si elige confirmar, el equipo de Basso Bikes se hará cargo de su solicitud de reemplazo/devolución y se pondrá en contacto lo antes posible.",
          Title:
            "¿Está seguro de que desea abrir una solicitud de reemplazo/devolución para estos productos?",
        },
        Step4Tracking: {
          Content:
            "Si elige confirmar, el equipo de Basso Bikes se hará cargo de su solicitud de reemplazo/devolución y se pondrá en contacto lo antes posible.",
          Title: "¿Está seguro de que desea reemplazar/devolver los productos?",
        },
        Step5: {
          Content:
            "Serás contactado por el equipo de Basso Bikes dentro de las próximas 24/48 horas.",
          Title: "Su solicitud ha sido enviada con éxito.",
        },
      },
    },
    BackOrders: {
      Title: "Pedidos confirmados",
      Loader: "Estamos cargando sus pedidos...",
      Table: {
        OrderNumber: "Pedido n°",
        NumberRow: "Numero de fila",
        OrderDate: "Fecha de pedido",
        ExpectiveDate: "Entrega esperada",
        Client: "Cliente",
        Destin: "Destino",
        CodArt: "SKU",
        Groupset: "Grupo",
        TG: "Talla",
        ArtDescription: "Descripción",
        ClientData: "Pedido Fecha solicitada",
        QRes: "Cantidad",
        State: "Status",
        Note: "Note",
      },
    },
    ProductsStock: {
      Title: "Productos listos para entregas",
      Loader: "Estamos cargando sus pedidos...",
      Table: {
        CodArt: "SKU",
        Groupset: "Grupo",
        TG: "Talla",
        ArtDescription: "Descripción",
        QRes: "Cantidad",
      },
    },
    RegisterBike: {
      PageTitle: "Registrar una bicicleta nueva",
      Loader: "Estamos cargando tus bicicletas registradas...",
      Table: {
        Client: "Cliente",
        Email: "E-mail",
        PurchaseDate: "Fecha de compra",
        Model: "Modelo",
        SerialNumber: "Número de serie",
        ProofOfPurchase: "Comprobante de compra",
      },
      FormPage: {
        Header: {
          Title: "Registrar una bicicleta nueva",
        },
        Form: {
          MessageUserNotFound:
            "No hay ninguna cuenta asociada a este e-mail. Ingrese su nombre y apellido para crear uno.",
          MessageUserFound:
            "Este usuario ya está registrado en nuestra database. Puede continuar con el registro.",
          EmailNotValid: "El e-mail ingresado no es válido",
          Email: "E-mail",
          Name: "Nombre",
          Surname: "Apellido",
          TitleStep1: "Información de los clientes",
          TitleStep2: "Datos de la bici",
          TitleStep3: "Resumen",
          TitleStepInner1: "Introduce los datos del propietario.",
          TitleStepInner2: "Introduce los datos solicitados de la bici",
          requireField:
            "Este campo es requerido. Porfavor introduzca un valor.",
          Shop: "Tienda",
          SerialNumber: "¿Dónde encontrar el número de serie?",
          SelectModel: "Seleccione su modelo",
          PurchaseDate: "Fecha de compra",
          Model: "Modelo",
          SerialNumber: "Número de serie",
          ProofOfPurchase: "Comprobante de compra",
          importFIle:
            "Importa un archivo con el recibo de la bicicleta: se aceptan .pdf, .png y .jpeg. Tamaño máximo de archivo: 10 MB",
        },
      },
      ListPage: {
        Header: {
          Title: "Tus bicicletas registradas",
          Subtitle: "Consulta tus bicicletas registradas o añade una nueva.",
        },
        Form: {
          MessageUserNotFound:
            "No hay ninguna cuenta asociada a este E-mail. Ingrese su nombre y apellido para crear uno.",
          MessageUserFound:
            "Este usuario ya está registrado en nuestra database. Puede continuar con el registro.",
          EmailNotValid: "El e-mail ingresado no es válido",
          Email: "E-mail",
          Name: "Nombre",
          Surname: "Apellido",
          TitleStep1: "Información de los clientes",
          TitleStep2: "Datos de la bici",
          TitleStep3: "Resumen",
          TitleStepInner1: "Introduce los datos del propietario.",
          TitleStepInner2: "Introduce los datos solicitados de la bici",
          requireField:
            "Este campo es requerido. Porfavor introduzca un valor.",
          Shop: "Tienda",
          SerialNumber: "¿Dónde encontrar el número de serie?",
          SelectModel: "Seleccione su modelo",
          PurchaseDate: "Fecha de compra",
          Model: "Modelo",
          SerialNumber: "Número de serie",
          ProofOfPurchase: "Comprobante de compra",
          importFIle:
            "Importa un archivo con el recibo de la bicicleta: se aceptan .pdf, .png y .jpeg. Tamaño máximo de archivo: 10 MB",
        },
      },
    },
    SupportArea: {
      PageTitle: "Área de asistencia",
      Loader: "Estamos cargando tus tickets...",
      Table: {
        ID: "ID",
        State: "Status",
        Topic: "Tema",
        Description: "Descripción",
        File: "Adjunto archivo",
        Data: "Fecha",
        Product: "Productos",
        YourRequest: "Your request",
      },
      Popup: {
        Common: {
          Title: "Abrir una solicitud de asistencia",
          Step1: "Petición",
          Step2: "Tema",
          Step3: "Cuéntanos más",
          Next: "Próximo",
          Proceed: "Proceder",
          Back: "Atrás",
          FileUploadTitle: "Adjunto archivo",
          FileUploadContent:
            "Importe imágenes que representen el problema encontrado; Se aceptan .pdf, .png y .jpeg. Tamaño máximo de archivo: 10 MB",
        },
        Step1: {
          Content: "Como podemos ayudarte?",
        },
        Step2: {
          Content: "¿De qué tema se trata?",
        },
        Step3: {
          Content:
            "Por favor, danos toda la información que pueda ayudarnos a atenderte mejor. Agregue imágenes que puedan ayudarnos a abordar el ticket:",
        },
        Options: {
          information: "Información",
          assisted_purchase: "Compra Asistida",
          leecougan: "Lee Cougan",
          microtech: "Microtech",
          basso: "Basso",
          other: "Otro",
          e_commerce_assistance: "Asistencia E-commerce",
          availability: "Disponibilidad",
          leecougan_product_assistance: "Lee Cougan asistencia de producto",
          on_microtech_product_assistance: "Microtech asistencia de producto",
          other: "Otro",
          warranty_request: "Solicitud de garantía",
          support_dealer: "Soporte técnico del distribuidor",
          basso_product_assistance: "Basso asistencia de producto",
          rider_weight: "Peso del ciclista (equipado)",
          rider_weight_placeholder: "80 kg",
          discipline: "Discipline",
          level: "Nivel",
          amateur: "Aficionado",
          agonist: "Amateur",
          pro: "Profesional",
          weekly_training_hours: "Horas de entrenamiento semanales",
          weekly_training_hours_placeholder: "16 heures",
          rampage_Innova_frame_size: "Talla del quadro Ramapage Innova"
        },
      },
    },
    DownloadArea: {
      PageTitle: "Área Download",
      Loader: "Estamos cargando todos los archivos...",
      Table: {
        FileName: "Nombre del archivo",
        Category: "Categoría",
        Description: "Descripción",
      },
    },
    TraningArea: {
      PageTitle: "Area Training",
      Loader: "Estamos cargando todos los tutoriales...",
      Table: {
        Title: "Título",
        Model: "Modelo",
        Description: "Descripción",
        Date: "Descripción",
      },
    },
    News: {
      PageTitle: "Noticias",
      Loader: "Estamos cargando todas las novedades...",
      LoaderSingle: "Estamos cargando todas las novedades...",
      FindOutMore: "Descubrir más",
    },
    ShopSelectorAgent: {
      Hello: "Hola",
      Loader: "Estamos cargando a tus clientes",
      Subtitle: "Login en nombre de su cliente",
      Proceed: "Proceder",
    },
    Checkout: {
      CartTitle: "Por favor revise su orden",
      NoProducts: "No hay productos en el carrito",
      Table: {
        img: "Imagen",
        Product: "Producto",
        Sku: "SKU",
        Qty: "Cantidad",
        Price: "Precio",
        Total: "Total",
      },
      Item: {
        Qty: "Monto",
        viewConfiguration: "Ver detalles de configuración",
        ViewDetails: "Ver detalles",
        EditConfiguration: "Editar configuración",
        RemoveProduct: "Retirar el producto",
        Price: "Precio",
      },
      ShipmentPaymentTitle: "Envíos y pagos",
      Shipment: {
        Title: "Envío",
        ShipmentByClientLabel: "¿Quieres organizar tu propio envío?",
        ShipmentByClientTxt: "Los gastos de envío se eliminarán de su pedido.",
        ShipmentByClientCheckbox:
          "Sí, quiero organizar el envío con mi mensajero.",
        ShipmentAddress: "Dirección de envío",
        SameAsBilling: "Lo mismo que la facturación",
      },
      Billing: {
        Title: "Facturación",
        YourBillingData: "Tus datos de facturación",
        YourBillingDataDescription:
          "Para cambiar su información de facturación, comuníquese con el servicio al cliente.",
        CompanyDetails: "Detalles de la compañía",
        Vat: "IVA",
        Address: "Dirección",
        Contacts: "Contactos",
        OrderForCustomer: {
          Title: "¿Está haciendo un pedido para su cliente?",
          Description:
            "Si está realizando un pedido para uno de sus clientes, introdúzcalo aquí y el pedido también se cargará en su área reservada.",
          Checkbox: "Sí, estoy haciendo el pedido para mi cliente.",
          MessageUserNotFound:
            "No hay ninguna cuenta asociada a este E-mail. Ingrese su nombre y apellido para crear uno.",
          MessageUserFound:
            "Este usuario ya está registrado en nuestra database. Puede continuar con el registro.",
          EmailNotValid: "El e-mail ingresado no es válido.",
          Email: "E-mail",
          Name: "Nombre",
          Surname: "Apellido",
        },
      },
      Payment: {
        Title: "Pago",
        TypeLabel: "Formas y condiciones de pago",
        Bacs: "Según lo acordado con Stardue Srl",
        BankTitle: "Datos bancarios",
        BeneficiaryName: "Nombre del Beneficiario",
      },
      BottomBar: {
        TotalProducts: "Total del producto",
        Shipment: "Envío",
        ShipmentTxt: "Precio separado",
        Total: "Total del pedido",
        Submit: "Entregar",
      },
    },
    DealersShop: {
      PageTitle: "Dealers Shop",
      Loader: "Cargamos tus Tickets...",
      Table: {
        ID: "ID",
        CompanyName: "Nombre de empresa",
        Vat: "Vat",
        Phone: "Phone",
      },
    },
  },
  PopupConfirmation: {
    TicketAdded: {
      Title: "Title",
      Content: "Content",
    },
    ProductAddedToCart: {
      Title: "Title",
      Content: "Content",
    },
    OrderPlaced: {
      Title: "Title",
      Content: "Content",
    },
    RegisteredBike: {
      Title: "Title",
      Content: "Content",
    },
  },
};
