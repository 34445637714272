import {
  GET_CONTENT_PENDING,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  content: "",
  isError: false
};

export default function NewsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTENT_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
        content: ""
      });
    case GET_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        content: action.data.content,
        isLoading: false,
        isError: false
      });
    case GET_CONTENT_FAILURE:
      return Object.assign({}, state, {
        message: action.data.data.message,
        isLoading: false,
        isError: true
      });
    default:
      return state;
  }
}