import {
  FETCH_ORDER_LIST_PENDING,
  FETCH_ORDER_LIST_SUCCESS,
  FETCH_ORDER_LIST_FAILURE,
  FETCH_STATUS_LIST_SUCCESS,
  FETCH_STATUS_LIST_PENDING,
  FETCH_STATUS_LIST_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  message: '',
  ordersList: [],
  total: 0,
  status: {}
};

export default function OrderRecevied(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case FETCH_ORDER_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true
      });
    case FETCH_ORDER_LIST_SUCCESS:
      return Object.assign({}, state, {
        ordersList: action.data.data.order,
        total: action.data.data.total_order,
        isLoading: false
      });
    case FETCH_ORDER_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isLoading: false
      });
    case FETCH_STATUS_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true
      });
    case FETCH_STATUS_LIST_SUCCESS:
      return Object.assign({}, state, {
        status: action.data.data,
        isLoading: false
      });
    case FETCH_STATUS_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isLoading: false
      });
    default:
      return state;
  }
}
