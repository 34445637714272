import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getNewsDetails } from "../../actions/newsAction";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import { i18n } from "../../translations/i18n";
import { ReactTitle } from 'react-meta-tags';
import i18next from "i18next";
const NewsDetail = (props) => {
  const slug = props.match ? props.match.params.id : props.computedMatch.params.id;
  const dispatch = useDispatch();
  const { isLoading, newsDetail, isError, message } = useSelector((state) => {
    return state.news;
  });
  useEffect(() => {
    dispatch(getNewsDetails(slug));
  }, []);

  return (
    <>
      <ReactTitle title="Dashboard" />
      <Header />
      <div className="dashboard-content best-news normal-scroll">
        <div className="wrap">
          {isLoading && <>
            <div className="loader-news">
              <span className="loader"></span>
              <div className="loader-text">{i18next.t('Pages.News.LoaderSingle')}</div>
            </div>
            </>}
          {newsDetail != undefined && (
            <div className="dashboard-content-wrapper">
              <div className="page-title">
                <h3>{newsDetail.title}</h3>
                <Link
                  to={"/" + i18n.language + "/" + i18next.t('routes.B2BNews')}
                  className="back-link"
                  >
                  <div className="icon">
                    <svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 261.34 485.34"><path d="M255.87,5.47c-7.3-7.3-19.1-7.3-26.39,0L5.47,229.47c-7.3,7.3-7.3,19.1,0,26.39l224,224c3.64,3.64,8.42,5.47,13.2,5.47s9.56-1.83,13.2-5.47c7.3-7.3,7.3-19.1,0-26.39L45.07,242.68,255.87,31.88c7.3-7.3,7.3-19.1,0-26.4h0Z" /></svg>
                  </div>
                  <div className="text">
                    {i18next.t('Common.Back')}
                  </div>
                </Link>
              </div>
              <div className="page-content">
                <div className="news-content">
                  <div className="news-image">
                    <img className="detail-img" src={newsDetail.image} />
                  </div>
                  <div className="news-content-wrapper">
                    <div className="news-desc" dangerouslySetInnerHTML={{
                      __html: newsDetail.description,
                    }}/>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isError && <>{message}</>}
      <Footer />
    </>
  );
};

export default NewsDetail;
