import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getContentPage } from "../../actions/contentActions";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import { ReactTitle } from 'react-meta-tags';
const PrivacyPolicy = (props) => {
  const dispatch = useDispatch();
  const { isLoading, content, isError, message } = useSelector((state) => {
    return state.content;
  });
  useEffect(() => {
    dispatch(getContentPage('privacy-policy'));
  }, []);

  return (
    <>
      <ReactTitle title="Dashboard" />
      <Header />
      {isError && <>{message}</>}
      <Skeleton loading={isLoading}>
        {content != '' && (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </Skeleton>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
