import React from "react";

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="6"
      viewBox="0 0 7 6"
    >
      <g data-name="Polygon 1">
        <path
          d="M6.13 5.5H.87L3.5.992 6.13 5.5z"
          transform="rotate(180 3.5 3)"
        ></path>
        <path
          d="M3.5 1.985L1.741 5h3.518L3.5 1.985M3.5 0L7 6H0l3.5-6z"
          transform="rotate(180 3.5 3)"
        ></path>
      </g>
    </svg>
  );
}

export default ArrowDownIcon;
