import React from "react";

function DashboardFilterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.001"
      height="25"
      viewBox="0 0 24.001 25"
    >
      <g transform="translate(-5.968 -55.689)">
        <g data-name="Group 9" transform="translate(13.967 57.999)">
          <g data-name="Group 8">
            <path
              fill="#f2b239"
              d="M361.5 141.671h12.6c.578 0 1.159.011 1.737 0h.025a.822.822 0 000-1.644h-12.6c-.578 0-1.159-.011-1.737 0h-.025a.822.822 0 100 1.644z"
              data-name="Path 34"
              transform="translate(-360.676 -140.022)"
            ></path>
          </g>
        </g>
        <g data-name="Group 11" transform="translate(5.968 58.004)">
          <g data-name="Group 10">
            <path
              fill="#f2b239"
              d="M69.5 141.844h3.4a.822.822 0 100-1.644h-3.4a.822.822 0 100 1.644z"
              data-name="Path 35"
              transform="translate(-68.676 -140.2)"
            ></path>
          </g>
        </g>
        <g data-name="Group 13" transform="translate(9.368 55.689)">
          <g data-name="Group 12">
            <path
              fill="#f2b239"
              d="M195.923 60.308a2.831 2.831 0 01-.3-.019l.219.03a2.551 2.551 0 01-.6-.164l.2.082a2.34 2.34 0 01-.381-.208c-.016-.011-.088-.049-.088-.063 0 .005.178.148.068.052-.055-.047-.107-.093-.159-.145s-.09-.1-.134-.145-.093-.153.038.055c-.019-.03-.041-.058-.063-.088a2.437 2.437 0 01-.208-.381l.082.2a2.315 2.315 0 01-.164-.6l.03.219a2.509 2.509 0 010-.608l-.03.219a2.552 2.552 0 01.164-.6l-.082.2a2.339 2.339 0 01.208-.381c.011-.016.049-.088.063-.088-.005 0-.148.178-.052.068.047-.055.093-.107.145-.159s.1-.09.145-.134.153-.093-.055.038c.03-.019.058-.041.088-.063a2.438 2.438 0 01.381-.208l-.2.082a2.314 2.314 0 01.6-.164l-.219.03a2.51 2.51 0 01.608 0l-.219-.03a2.551 2.551 0 01.6.164l-.2-.082a2.341 2.341 0 01.381.208c.016.011.088.049.088.063 0-.005-.178-.148-.068-.052.055.047.107.093.159.145s.09.1.134.145.093.153-.038-.055c.019.03.041.058.063.088a2.438 2.438 0 01.208.381l-.082-.2a2.315 2.315 0 01.164.6l-.03-.219a2.511 2.511 0 010 .608l.03-.219a2.552 2.552 0 01-.164.6l.082-.2a2.339 2.339 0 01-.208.381c-.011.016-.049.088-.063.088.005 0 .148-.178.052-.068-.047.055-.093.107-.145.159s-.1.09-.145.134-.153.093.055-.038c-.03.019-.058.041-.088.063a2.438 2.438 0 01-.381.208l.2-.082a2.315 2.315 0 01-.6.164l.219-.03a2.831 2.831 0 01-.3.019.822.822 0 100 1.644 3.1 3.1 0 001.761-.545 3.3 3.3 0 001.2-1.561 3.123 3.123 0 00-.915-3.394 3.3 3.3 0 00-1.849-.753 3.117 3.117 0 00-3.011 1.767 3.259 3.259 0 00-.238 2.024 3.157 3.157 0 003.057 2.46.82.82 0 100-1.641z"
              data-name="Path 36"
              transform="translate(-192.803 -55.689)"
            ></path>
          </g>
        </g>
        <g data-name="Group 15" transform="translate(24.651 67.373)">
          <g data-name="Group 14">
            <path
              fill="#f2b239"
              d="M751.5 483.844h3.671a.822.822 0 000-1.644H751.5a.822.822 0 100 1.644z"
              data-name="Path 37"
              transform="translate(-750.676 -482.2)"
            ></path>
          </g>
        </g>
        <g data-name="Group 17" transform="translate(5.968 67.368)">
          <g data-name="Group 16">
            <path
              fill="#f2b239"
              d="M69.5 483.671h12.345c.57 0 1.14.011 1.709 0h.025a.822.822 0 000-1.644H71.233c-.57 0-1.14-.011-1.709 0H69.5a.822.822 0 000 1.644z"
              data-name="Path 38"
              transform="translate(-68.676 -482.022)"
            ></path>
          </g>
        </g>
        <g data-name="Group 19" transform="translate(20.052 65.058)">
          <g data-name="Group 18">
            <path
              fill="#f2b239"
              d="M585.923 402.308a2.825 2.825 0 01-.3-.019l.219.03a2.553 2.553 0 01-.6-.164l.2.082a2.338 2.338 0 01-.381-.208c-.016-.011-.088-.049-.088-.063 0 .005.178.148.068.052-.055-.047-.107-.093-.159-.145s-.09-.1-.134-.145-.093-.153.038.055c-.019-.03-.041-.058-.063-.088a2.443 2.443 0 01-.208-.381l.082.2a2.316 2.316 0 01-.164-.6l.03.219a2.509 2.509 0 010-.608l-.03.219a2.554 2.554 0 01.164-.6l-.082.2a2.345 2.345 0 01.208-.381c.011-.016.049-.088.063-.088-.005 0-.148.178-.052.068.047-.055.093-.107.145-.159s.1-.09.145-.134.153-.093-.055.038c.03-.019.057-.041.088-.063a2.437 2.437 0 01.381-.208l-.2.082a2.314 2.314 0 01.6-.164l-.219.03a2.509 2.509 0 01.608 0l-.219-.03a2.551 2.551 0 01.6.164l-.2-.082a2.343 2.343 0 01.381.208c.016.011.088.049.088.063 0-.005-.178-.148-.068-.052.055.047.107.093.159.145s.09.1.134.145.093.153-.038-.055c.019.03.041.057.063.088a2.437 2.437 0 01.208.381l-.082-.2a2.313 2.313 0 01.164.6l-.03-.219a2.509 2.509 0 010 .608l.03-.219a2.551 2.551 0 01-.164.6l.082-.2a2.339 2.339 0 01-.208.381c-.011.016-.049.088-.063.088.005 0 .148-.178.052-.068-.047.055-.093.107-.145.159s-.1.09-.145.134-.153.093.055-.038c-.03.019-.057.041-.088.063a2.437 2.437 0 01-.381.208l.2-.082a2.315 2.315 0 01-.6.164l.219-.03a2.823 2.823 0 01-.3.019.822.822 0 000 1.644 3.1 3.1 0 001.761-.545 3.3 3.3 0 001.2-1.561 3.123 3.123 0 00-.915-3.394 3.3 3.3 0 00-1.849-.753 3.117 3.117 0 00-3.011 1.767 3.258 3.258 0 00-.238 2.024 3.157 3.157 0 003.057 2.46.82.82 0 100-1.641z"
              data-name="Path 39"
              transform="translate(-582.803 -397.689)"
            ></path>
          </g>
        </g>
        <g data-name="Group 21" transform="translate(18.35 76.738)">
          <g data-name="Group 20">
            <path
              fill="#f2b239"
              d="M521.5 825.714h8.736c.405 0 .811.008 1.216 0h.019a.822.822 0 100-1.644h-8.736c-.405 0-.811-.008-1.216 0h-.019a.822.822 0 100 1.644z"
              data-name="Path 40"
              transform="translate(-520.676 -824.067)"
            ></path>
          </g>
        </g>
        <g data-name="Group 23" transform="translate(5.968 76.741)">
          <g data-name="Group 22">
            <path
              fill="#f2b239"
              d="M69.5 825.844h7.78a.822.822 0 000-1.644H69.5a.822.822 0 000 1.644z"
              data-name="Path 41"
              transform="translate(-68.676 -824.2)"
            ></path>
          </g>
        </g>
        <g data-name="Group 25" transform="translate(13.751 74.426)">
          <g data-name="Group 24">
            <path
              fill="#f2b239"
              d="M355.923 744.308a2.824 2.824 0 01-.3-.019l.219.03a2.554 2.554 0 01-.6-.164l.2.082a2.335 2.335 0 01-.381-.208c-.016-.011-.088-.049-.088-.063 0 .005.178.148.068.052-.055-.047-.107-.093-.159-.145s-.09-.1-.134-.145-.093-.153.038.055c-.019-.03-.041-.057-.063-.088a2.437 2.437 0 01-.208-.381l.082.2a2.314 2.314 0 01-.164-.6l.03.219a2.509 2.509 0 010-.608l-.03.219a2.551 2.551 0 01.164-.6l-.082.2a2.341 2.341 0 01.208-.381c.011-.016.049-.088.063-.088-.005 0-.148.178-.052.068.047-.055.093-.107.145-.159s.1-.09.145-.134.153-.093-.055.038c.03-.019.058-.041.088-.063a2.438 2.438 0 01.381-.208l-.2.082a2.314 2.314 0 01.6-.164l-.219.03a2.509 2.509 0 01.608 0l-.219-.03a2.551 2.551 0 01.6.164l-.2-.082a2.344 2.344 0 01.381.208c.016.011.088.049.088.063 0-.005-.178-.148-.068-.052.055.047.107.093.159.145s.09.1.134.145.093.153-.038-.055c.019.03.041.057.063.088a2.439 2.439 0 01.208.381l-.082-.2a2.315 2.315 0 01.164.6l-.03-.219a2.512 2.512 0 010 .608l.03-.219a2.552 2.552 0 01-.164.6l.082-.2a2.343 2.343 0 01-.208.381c-.011.016-.049.088-.063.088.005 0 .148-.178.052-.068-.047.055-.093.107-.145.159s-.1.09-.145.134-.153.093.055-.038c-.03.019-.058.041-.088.063a2.433 2.433 0 01-.381.208l.2-.082a2.316 2.316 0 01-.6.164l.219-.03a2.824 2.824 0 01-.3.019.822.822 0 100 1.644 3.1 3.1 0 001.761-.545 3.3 3.3 0 001.2-1.561 3.123 3.123 0 00-.915-3.394 3.3 3.3 0 00-1.849-.753 3.117 3.117 0 00-3.011 1.767 3.259 3.259 0 00-.238 2.024 3.157 3.157 0 003.057 2.46.82.82 0 100-1.641z"
              data-name="Path 42"
              transform="translate(-352.803 -739.689)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DashboardFilterIcon;
