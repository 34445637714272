import React from "react";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
const DatePicker = ({setDay , defultDay, reset, preDate, postDate}) => {

    


    return <>
    {preDate && <DayPickerInput placeholder={""}  value={defultDay == '' ? null : moment(defultDay).format("DD MMM YYYY")} onDayChange={(day)=>{
        setDay(day);
        reset(true);
    }}
    dayPickerProps={{
        disabledDays: { before: new Date(moment(preDate).format("DD MMM YYYY"))}
    }}
    /> }
    
    {postDate && <DayPickerInput placeholder={""}  value={defultDay == '' ? null : moment(defultDay).format("DD MMM YYYY")} onDayChange={(day)=>{
        setDay(day);
        reset(true);
    }}
    dayPickerProps={{
        disabledDays: { after: new Date(moment(postDate).format("DD MMM YYYY"))}
    }}
    /> }

    {!preDate && !postDate && <DayPickerInput placeholder={""}  value={defultDay == '' ? null : moment(defultDay).format("DD MMM YYYY")} onDayChange={(day)=>{
        setDay(day);
        reset(true);
    }}
    /> }
    
    </>;
};
export default DatePicker;
