import { request } from "../services/apiService";
import {
  FETCH_CART_PENDING,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  ADD_CART_PENDING,
  ADD_CART_SUCCESS,
  ADD_CART_FAILURE,
  ADD_CART_NOTIFICATION_DONE,
  REMOVE_CART_PENDING,
  REMOVE_CART_SUCCESS,
  REMOVE_CART_FAILURE,
  CHANGE_QUANTITY_PENDING,
  CHANGE_QUANTITY_SUCCESS,
  CHANGE_QUANTITY_FAILURE,
} from "./actionTypes";

export const resetCartNotification = () => (dispatch) => {
  dispatch({
    type: ADD_CART_NOTIFICATION_DONE,
  });
};

export const getCart = (isFreeShipping, isB2B) => (dispatch) => {
  dispatch({
    type: FETCH_CART_PENDING,
  });
  const url =
    `v1/cart/?country=IT&lang=en&freeshipping=` +
    isFreeShipping +
    `&isb2b=` +
    isB2B;
  request("get", url, {}, true).then(
    (res) => {
      dispatch({
        type: FETCH_CART_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: FETCH_CART_FAILURE,
        data: err.response,
      });
    }
  );
};

export const addProductTocart = (data) => (dispatch) => {
  dispatch({
    type: ADD_CART_PENDING,
  });
  const url = `v1/cart/add`;
  request("post", url, data, true).then(
    (res) => {
      dispatch({
        type: ADD_CART_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: ADD_CART_FAILURE,
        data: err.response,
      });
    }
  );
};

export const removeCart = (data) => (dispatch) => {
  dispatch({
    type: REMOVE_CART_PENDING,
  });
  const url = `v1/cart/remove`;
  request("post", url, data, true).then(
    (res) => {
      dispatch({
        type: REMOVE_CART_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: REMOVE_CART_FAILURE,
        data: err.response,
      });
    }
  );
};


export const updateQuantity = (data) => (dispatch) => {
  dispatch({
    type: CHANGE_QUANTITY_PENDING,
  });
  const url = `v1/cart/update-quantity`;
  request("post", url, data, true).then(
    (res) => {
      dispatch({
        type: CHANGE_QUANTITY_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: CHANGE_QUANTITY_FAILURE,
        data: err.response,
      });
    }
  );
};
