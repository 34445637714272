export const TRANSLATIONS_IT = {
  routes: {
    Dashboard: "dashboard",
    Configuration: "configura",
    ConfigurationsSaved: "le-tue-configurazioni",
    ConfigurationsSearch: "upload-configurazioni",
    NewOrder: "ordini-nuova-attrezzatura",
    YourOrders: "i-tuoi-ordini",
    backOrders: "ordini-confermati",
    RegisterBike: "bici-registrate-cliente",
    SupportArea: "area-supporto",
    TrainingArea: "area-training",
    FTPArea: "area-download",
    B2BNews: "notizie",
    AccountUser: "dati-account",
    SwitchBrand: "cambia-brand",
    ShopSelectorAgent: "localizzatore-negozi",
    Checkout: "checkout",
    Login: "login",
    ResetPassword: "reimposta-password",
    NewPassword: "nuova-password",
    PrivacyPolicy: "privacy",
    TermsConditions: "termini-condizioni",
    BassoFrontend: "https://bassobikes.com",
    LeeCouganFrontend: "https://www.leecougan.com",
    StockProduct:"merce-a-stock",
  },
  PageTitle: {
    Dashboard: "Dashboard | Stardue B2B",
    Configurator: "Configura | Stardue B2B",
    ConfigurationsSearch: "Upload configurazioni | Stardue B2B",
    ConfigurationsSaved: "Le tue Configurations | Stardue B2B",
    NewOrder: "Ordini nuova attrezzatura | Stardue B2B",
    YourOrders: "{{orderId}} I tuoi ordini | Stardue B2B",
    OrderList: "I tuoi ordini | Stardue B2B",
    BackOrders: "Ordini confermati | Stardue B2B",
    RegisterBike: "Le bici registrate dei clienti | Stardue B2B",
    SupportArea: "Area Supporto | Stardue B2B",
    B2BNews: "Notizie | Stardue B2B",
    TrainingArea: "Area Training | Stardue B2B",
    FTPArea: "Area Download | Stardue B2B",
    AccountUser: "Dati Account | Stardue B2B",
    SwitchBrand: "Cambia brand | Stardue B2B",
    ShopSelectorAgent: "Localizzatore | Stardue B2B",
    Checkout: "Checkout | Stardue B2B",
    Login: "Login | Stardue B2B",
    ResetPassword: "Reimposta la password | Stardue B2B",
    NewPassword: "Nuova password | Stardue B2B",
    PrivacyPolicy: "Privacy | Stardue B2B",
    TermsConditions: " Termini & Condizioni | Stardue B2B",
    ProductsStock: "Merce a stock | Stardue B2B",
  },
  Footer: {
    Coopyright: "© 2022 - STARDUE S.R.L. - P.IVA / C.F. 02109950267",
    TermsConditions: "Termini e condizioni",
    PrivacyPolicy: "Privacy Policy",
  },
  Header: {
    Option: "Seleziona un opzione",
    BikesGroup: {
      Bikes: "Bici",
      ProductsStock: "Merce a stock",
      ConfigurationsSaved: "Le mie configurazioni",
      ConfigurationsSearch: "Trova le configurazioni del cliente",
      Configuration: "Crea una nuova configurazione",
    },
    Equipments: "Attrezzatura",
    OrdersGroup: {
      Orders: "Ordini",
      OrdersWaiting: "In attesa di conferma",
      BackOrders: "Confermati",
    },
    ServicesGroup: {
      Services: "Servizi",
      RegisterBike: "Registra la nuova bici di un cliente",
      SupportArea: "Area assistenza",
      FTPArea: "Area download",
      TrainingArea: "Area training",
      B2BNews: "Ultime notizie",
    },
    UserGroup: {
      YourData: "I tuoi dati",
      Logout: "Logout",
      ChangePlatform: "Cambia brand",
      ChangeShop: "Cambia negozio",
    },
  },
  Dashboard: {
    ftpArea: "Area download",
    ftpAreaText:
      "Accedi alla tua area download per scaricare contenuti riservati.",
    ticketArea: "Area assistenza",
    ticketAreaText: "",
    trainingArea: "Area training",
    trainingAreaText:
      "Scopri tutti i viedo tutorial riservati ai nostri rivenditori.",
    warranty: "Registra la nuova bici di un cliente",
    warrantyText: "",
    ticketArea: "Area assistenza",
    ticketAreaText: "",
    configuration: "Crea una nuova configurazione",
    configurationText: "",
    configurationSaved: "Le tue configurazioni",
    configurationSavedText: "",
    equipmentSearch: "Trova attrezzatura",
    searchPlaceholder: "SKU o nome prodotto",
    order: "Ordini confermati",
    orderText: "Traccia l'andamento in produzione dei tuoi ordini confermati",
    newsArea: "Ultime notizie",
    newsAreaText:
      "Le ultime notizie dal nostro mondo, per essere sempre aggiornato.",
    webSite: "Sito Web",
    webSiteText: "Visita il sito B2C",
    button: "Entra",
    goToWebsite: "Vai al sito web",
    ProductStock:"Merce a stock",
    ProductStockText: "Prodotti nel nostro magazzino pronti per essere spediti.",
  },
  Common: {
    ServiceNotAvailable: "I dati non sono disponibili a causa della migrazione di gestionale. Ci scusiamo per l'inconveniente. Il servizio tornerà attivo a breve.",
    NoMobileView:
      "Dashboard non è disponibile sullo schermo mobile. Continua a utilizzare il tuo laptop.",
    PasswordMatchError: "La password inserita non corrisponde.",
    hi: "Buongiorno",
    Back: "Indietro",
    Logout: "Logout",
    Address: "Indirizzo",
    Contacts: "Contatti",
    FiscalCode: "Codice fiscale",
    AddNewAddress: "Aggiungi un nuovo indirizzo",
    NewAddress: "Nuovo indirizzo",
    RequiredFields: "Tutti i campi segnati con asterisco* sono obbligartori.",
    Confirm: "Conferma",
    Nominative: "Nominativo",
    okText: "Si",
    CancelText: "No",
    Proceed: "Procedi",
    DiscoverButton: "Esplora",
    FiltersSidebar: {
      Title: "Filtro",
      Submit: "Applica",
      Reset: "Rimuovi filtri",
      All1: "Tutto",
      All2: "Tutto",
      Model: "Modello",
      Groupset: "Gruppo",
      idOrder: "ID ordine",
      IDConfiguration: "ID configurazione",
      SKU: "Cerca SKU o nome prodotto",
      SKUADMIN: "Cerca per SKU, nome prodotto, per nome o codice rivenditore",
      Category: "Scegli una categoria",
      RelatedModel: "Trova ricambi per:",
      State: "Filtra per status",
      FromDate: "Da",
      ToDate: "A",
      SerialNumber: "Numero di serie",
      ChooseOption: "Seleziona un'opzione",
      RegisterNewBike: "Registra una nuova bici",
      OpenTicket: "Apri un nuovo ticket",
      Ready_to_ship: 'Pronto alla spedizione',
      order_confirmed: 'Ordine confermato',
      in_production: 'In produzione',
      on_hold: 'In attesa',
    },
    Pagination: {
      Prev: "Precedente",
      Next: "Prossimo",
    },
    ConfirmationMessage: {
      Products: {
        Title: "Prodotto aggiunto al tuo ordine",
        Label: "Grazie, il prodotto è stato aggiunto al tuo ordine",
      },
    },
    Form: {
      SelectOption: "Scegli",
      companyname: "Nome della società",
      requireField: "Questo campo è richiesto. Per favore, inserire un valore.",
      name: "Nome",
      PublicEmail: "E-mail pubblico",
      require_name: "Per favore, inserisci il tuo nome",
      surname: "Cognome",
      require_surname: "Per favore, inserisci il tuo cognome.",
      address: "Indirizzo",
      country: "Paese",
      require_country: "Per favore, inserisci il tuo Paese.",
      city: "Città",
      require_city: "Per favore, inserisci la tua città.",
      province: "Provincia",
      require_province: "Per favore, inserisci la tua provincia.",
      street: "Via e numero civico",
      require_street: "Per favore, inserisci la tua via e il numero civico.",
      postalcode: "Codice postale",
      require_postalcode: "Per favore, inserisci il codice postale.",
      contact: "Informazioni di contatto",
      phone: "Telefono",
      require_phone: "Per favore, inserisci il tuo numero di telefono.",
      defaultAddressSettings: "Impostazioni",
      is_default_address: "Imposta questo indirizzo come predefinito",
      edit: "Edita",
      email: "E-mail",
      valid_email:
        "Questo indirizzo E-mail non è valido, per favore inserici un indirizzo E-mail valido.",
      require_email: "Per favore, inserisci il tuo indirizzo E-mail",
      first_name: "Nome",
      require_first_name: "Per favore, inserisci il tuo nome",
      last_name: "Cognome",
      require_last_name: "Per favore, inserisci il tuo cognome",
      password: "Password",
      require_password: "Per favore, inserisci la tua password",
      confirmPassword: "Conferma password",
      require_confirmPassword: "Per favore, conferma la tua password",
      model: "Modello",
      serial_number: "Numero di serie",
      require_serial_number: "Per favore, inserisci il tuo numero di serie",
      purchase_date: "Data di acquisto",
      proof_purchase: "Ricevuta di acquisto",
      store: "Negozio",
      require_store:
        "Per favore, usa questo campo per selezionare il tuo rivenditore",
      save: "Salva",
      sendrequest: "Invia richiesta",
      otherInformation: "Altre informazioni",
      generic_required:
        "Questo campo è richiesto. Per favore, inserisci le informazioni richieste.",
      couopon: "Codice sconto",
      request: "Richiesta",
      query: "Informazione aggiuntiva",
      topic: "Argomento",
      Update: "Aggiorna",
    },
    Bikes: {
      SummaryBike: {
        ConfigurationID: "ID configurazione",
        Sku: "Sku",
        Groupset: "Gruppo",
        Size: "Taglia",
        Wheels: "Ruote",
        Color: "Colore",
      },
    },
  },
  Pages: {
    ChangeBrand: {
      RequestAccess: "Richiedi accesso",
      Form: {
        Title: "Richiedi accesso",
        Label: "Descrivi la tua richiesta",
        Submit: "Invia richiesta"
      }
    },
    Login: {
      Title: "Benvenuto",
      Subtitle: "Entra nella tua dashboard dedicata",
      Email: "La tua e-mail",
      Password: "La tua password",
      Submit: "Login",
      LostPassword: "Hai dimenticato la tua password?",
    },
    ForgotPassword: {
      Title: "Hai dimenticato la tua password?",
      Subtitle: "Reimpostala adesso e connettiti",
      Email: "La tua e-mail",
      Submit: "Procedi",
    },
    NewPassword: {
      Title: "Hai dimenticato la tua password?",
      Subtitle: "Reimpostala adesso e connettiti",
      Email: "La tua e-mail",
      Password: "Nuova password",
      ConfirmPassword: "Conferma la tua nuova password",
      Message: "",
      Submit: "Conferma",
    },
    AgentPage: {
      Loader: "Stiamo caricando i tuoi clienti...",
      Title: "Connettiti per conto di un cliente",
      NoClient:
        "Nessun cliente associato. Per favore, contatta il nostro servizio di assistenza.",
    },
    UserDetails: {
      YourData: "Il tuo profilo",
      Anagraphic: "Tuo ",
      Company: "Nome dell'azienda",
      VAT: "Partita IVA",
      EmailAccount: "Account e-mail",
      StoreLocatorTitle: "Informazioni localizzatore",
      TimeTable: {
        ShopTime: "Orari negozio",
        monday: "Lunedì",
        tuesday: "Martedì",
        wednesday: "Mercoledì",
        thursday: "Giovedì",
        friday: "Venerdì",
        saturday: "Sabato",
        sunday: "Domenica",
        morning: "Mattina",
        afternoon: "Pomeriggio",
        start: "Apertura",
        end: "Chiusura",
        closed: "Chiuso",
        nonStop: "Orario continuato",
      },
    },
    Configurator: {
      PageTitle: "Configuratore",
      ConfigureNow: "Configura la tua",
      LoaderLabel: "Stiamo caricando il configuratore...",
      NotSelected: "Non selezionato",
      AvailableFrom: "Da",
      Quantity: "Quantità",
      Geometry: "Geometria",
      OutOfStock: "Prodotto esaurito",
      Type: {
        Bike: "La tua Basso pronta per essere guidata.",
        FrameKit:
          "Telaio, forcella e tutte le sue specifiche componenti Basso.",
        BuildKit:
          "Kit telaio e tutte le specifiche componenti senza il gruppo.",
      },
      Step: {
        Header: {
          Categories: "Categoria",
          Models: "Modello",
          Type: "Type",
          Wheels: "Ruote",
          Color: "Colore",
          Size: "Taglia",
          Groupset: "Gruppo",
          Options: "Opzioni",
          Quantity: "Quantità",
        },
        Price: {
          Included: "Incluso",
          Upgrade: "Upgrade selezionato",
        },
      },
      BottomBar: {
        ProceedToCart: "Aggiungi al carrello",
        FindYourDealer: "Seleziona il tuo rivenditore",
        Save: "Salva",
        DownloadPDF: "Scarica PDF",
        YourPrice: "Prezzo riservato",
        PublicPrice: "Prezzo consigliato",
        DiscountLabel: "Il tuo markup",
      },
      ConfiguratorSummary: {
        Title: "Riassunto della configurazione",
        FrameSet: "Kit telaio",
        Frame: "Telaio",
        Fork: "Forcella",
        SeatPostClamp: "Morsetto reggisella",
        ThruAxleFornt: "Perno passante anteriore",
        ThruAxleRear: "Perno passante posteriore",
        Hanger: "Forcellino",
        Headset: "Serie sterzo",
        Groupset: "Gruppo",
        Crank: "Guarnitura",
        CrankArm: "Pedivella",
        ChainRings: "Corone",
        Chain: "Catena",
        BottomBraket: "Movimento centrale",
        Cassette: "Cassetta",
        CassetteGears: "Rapporto pignoni",
        FrontDerailleur: "Deragliatore anteriore",
        RearDerailleur: "Deragliatore posteriore",
        Shifters: "Comandi",
        Brakes: "Freni",
        Rotors: "Dischi",
        Cockpit: "Cockpit",
        SeatpostAndSaddle: "Reggisella e sella",
        WheelsAndTires: "Ruote e copertoni",
        Wheels: "Ruote",
        Upgrade: "Upgrade",
        HeadUnitMount: "Supporto per ciclo-computer",
        HandlebarTap: "Nastro manubrio",
        PowerUnit: "Power Unit",
        Motor: "Motore",
        Display: "Display",
        Battery: "Batteria",
        Other: "Altro",
        Editing: "Continua a editare",
        DownloadPDF: "Scarica PDF",
        ConfirmAndProceed: "Procedi al carrello",
      },
      SaveConfigurator: {
        Popup: {
          Loader: "Stiamo salvando la tua configurazione...",
          Header: {
            Title: "Salva la tua configurazione",
            Subtitle: "Congratulazioni, la tua configurazione è stata salvata.",
          },
          DiscoverButton: "Esplora",
        },
      },
      AddToCart: {
        Popup: {
          Loader: "Stiamo aggiungendo la configurazione al carrello...",
          Header: {
            Title: "Configurazione aggiunta",
            Subtitle:
              "Congratulazioni, la tua configurazione è stata aggiunta  al carrello.",
          },
        },
      },
      DowloadPDF: {
        Popup: {
          Loader: "Stiamo preparando il PDF della tua configurazione...",
          Header: {
            Title: "Scarica PDF",
            Subtitle:
              "Congratulazioni, il tuo PDF è stato creato e verrà scaricato automaticamente.",
          },
        },
      },
      SummarySmall: {
        Title: "Riassunto",
        Categories: "Categoria",
        Models: "Modello",
        Type: "Type",
        Wheels: "Ruote",
        Color: "Colore",
        Size: "Taglia",
        Groupset: "Gruppo",
      },
      PreviewImage: {
        Title: "Anteprima",
        LargeImage: "Clicca per ingrandire l'immagine",
      },
    },
    ConfigurationSaved: {
      PageTitle: "Configurazioni salvate",
      Loader: "Stiamo caricando la tua configurazione...",
      Table: {
        ID: "ID",
        Model: "Modello",
        Groupset: "Gruppo",
        Size: "Taglia",
        Color: "Colore",
        Wheels: "Ruote",
        Price: "Prezzo",
        Edit: "Crea",
        Summary: "Riassunto",
        DownloadPDF: "Scarica PDF",
        OrderDetails: "Vedi l'ordine",
        Delete: "Cancella",
      },
    },
    SearchConfiguration: {
      Title: "Cerca una configurazione esistente",
      Info:
        "Inserisci un codice ID configurazione per richiamare una configurazione esistente. L'ID configurazione può essere trovato in “Le tue configurazioni” sia nel sito B2B che B2C, e in tutti i riassunti di configurazione scaricati in PDF.",
      Search: "Cerca ora",
      ConfigCode: "Inserisci il codice ID configurazione",
      Loader: "Stiamo cercando la configurazione richiesta...",
      Table: {
        Edit: "Crea",
        Summary: "Riassunto",
        DownloadPDF: "Scarica PDF",
        OrderDetails: "Vedi l'ordine",
      },
    },
    Address: {
      Header: {
        Title: "I tuoi indirizzi",
        Subtitle: "Gestisci i tuoi indirizzi di spedizione e fatturazione.",
      },
      ShippingAddress: {
        Label: "Indirizzo di spedizione",
      },
      BillingAddress: {
        Label: "Indirizzo di fatturazione",
      },
    },
    Equipments: {
      PageTitle: "Ordine online",
      Loader: "Stiamo caricando i prodotti",
      NotFound:
        "Nessun prodotto trovato. Per favore, inserisci un'altra parola chiave.",
      Table: {
        Image: "Immagine",
        SKU: "SKU",
        Name: "Nome",
        Categories: "Categoria",
        Stock: "Stock",
        Price: "Prezzo",
        OrderNow: "Aggiungi all'ordine",
      },
      Details: {
        RecomandedPrice: "Prezzo consigliato",
        YourDiscount: "Il tuo markup",
        Description: "Descrizione",
        Features: "Caratteristiche",
        BuyNow: "Aggiungi all'ordine",
        ConfirmationAddToCart:
          "Complimenti, il prodotto è stato inserito nel tuo ordine",
      },
    },
    Orders: {
      PageTitle: "I tuoi ordini",
      Loader: "Stiamo caricando i tuoi ordini...",
      Table: {
        OrderNumber: "ID",
        OrderDate: "Data dell'ordine",
        ExpectiveDate: "Consegna stimata",
        Destination: "Spedito a",
        State: "Status",
        Products: "Prodotti",
        ProductIncluded: "Produtti",
        Total: "Totale",
        Details: "Detagli",
        ChildOrderLabel: "Ordini correlati",
      },
      Single: {
        Order: "Ordine",
        EstimatedDate: "Consegna stimata",
        NotAvailableEstimateDate: "Non disponibile",
        OpenTicket: "Apri un ticket",
        OrderDate: "data dell'ordine",
        ShipmentTo: "Spedire a",
        OrderStatus: "Status",
        DownloadReceipt: "Scarica la ricevuta",
        ReturnOrder: "Reso dell'ordine",
        ReturnRequests: "Richiesta di reso",
        ReturnRequestsInfo: "La tua richiesta è stata inoltrata con successo",
        OrderSummary: "Riassunto dell'ordine",
        Sku: "Sku",
        Groupset: "Gruppo",
        ConfiguratorID: "ID configuratore",
        Size: "Taglia",
        Color: "Colore",
        Wheels: "Ruote",
        DownloarConf: "Scarica la tua configurazione",
        Quantity: "Quantità",
        Price: "Prezzo",
        Subtotal: "Subtotale",
        Shipment: "Spedizione",
        PaymentMethod: "Metodo di pagamento",
        Total: "Totale",
        BillingInformation: "Informazioni di fatturazione",
        ShippingData: "Dati di spedizione",
        FiscalCode: "Codice fiscale",
        IncludeOption: "Opzioni incluse",
        Upgrade: "Upgrade selezionati",
        Bacs: "Secondo quanto concordato con Stardue Srl",
      },
      TicketOrder: {
        Common: {
          Title: "Ordine {{orderId}} - Ticket assistenza",
          Request: "Richiesta",
          Order: "Ordine",
          StepTitle1: "Richiesta",
          StepTitle2: "Prodotti",
          StepTitle3: "Fornisci qualche informazione in più",
          StepTitle4: "Conferma",
          Next: "Prossimo",
          Proceed: "Procedi",
          Back: "Indietro",
          SuccessTitle: "La tua richiesta è stata inoltrata con successo.",
          SuccessSubtitle:
            "Sarai contattato dal team Basso Bikes team nelle prossime 24/48 ore.",
          SuccessButton: "Ritorna ai tuoi ordini",
          FileUploadTitle: "Allega un file",
          FileUploadContent:
            "Importa un file: sono accettati .pdf, .png e .jpeg. Dimensione massima del file: 10MB",
        },
        Step1: {
          Content: "Seleziona il motivo della tua richiesta",
          ReturnReplacement: "Sostituzione / Reso",
          Tracking: "Tracciamento della spedizione",
        },
        Step2: {
          Content:
            "Seleziona il prodotto con cui sono stati riscontrati dei problemi:",
        },
        Step3ReturnProducts: {
          Content:
            "Che problemi hai riscontrato? Aiuta lo staff Basso a capire cosa è successo.",
        },
        Step3Tracking: {
          Content:
            "Che problemi hai riscontrato? Aiuta lo staff Basso a capire cosa è successo.",
        },
        Step4ReturnProducts: {
          Content:
            "Se scegli di confermare, la tua richiesta di sostituzione/reso sarà presa in carico dal team Basso Bikes che ti contatterà al più presto.",
          Title:
            "Sei sicuro di voler inoltrare una richiesta di sostituzione/reso rper questi prodotti?",
        },
        Step4Tracking: {
          Content:
            "Se scegli di confermare, la tua richiesta di sostituzione/reso sarà presa in carico dal team Basso Bikes che ti contatterà al più presto.",
          Title: "sei sicuro di voler sostituire/rendere questi prodotti?",
        },
        Step5: {
          Content:
            "Sarai contattato dal team Basso Bikes nelle prossime 24/48 ore.",
          Title: "La tua richiesta è stata inoltrata con successo.",
        },
      },
    },
    BackOrders: {
      Title: "Ordini confermati",
      Loader: "Stiamo caricando i tuoi ordini...",
      Table: {
        OrderNumber: "Ordine n°",
        NumberRow: "Numero di riga",
        OrderDate: "Data dell'ordine",
        ExpectiveDate: "Data prevista consegna",
        Client: "Cliente",
        Destin: "Destinazione",
        CodArt: "SKU",
        Groupset: "Gruppo",
        TG: "Taglia",
        ArtDescription: "Descrizione",
        ClientData: "Data di richiesta dell'ordine",
        QRes: "Quantità",
        State: "Status",
        Note: "Note",
      },
    },
    ProductsStock: {
      Title: "Merce a stock",
      Loader: "Stiamo caricando la merce a magazzino...",
      Table: {
        CodArt: "SKU",
        Groupset: "Gruppo",
        TG: "Taglia",
        ArtDescription: "Descrizione",
        QRes: "Quantità",
      },
    },
    RegisterBike: {
      PageTitle: "Registra una nuova bici",
      Loader: "Stiamo caricando le tue bici registrate...",
      Table: {
        Client: "Cliente",
        Email: "E-mail",
        PurchaseDate: "Data di acquisto",
        Model: "Modello",
        SerialNumber: "Numero di serie",
        ProofOfPurchase: "Ricevuta di acquisto",
      },
      FormPage: {
        Header: {
          Title: "Registra una nuova bici",
        },
        Form: {
          MessageUserNotFound:
            "Nessun account associato a questa E-mail. Inserisci il tuo nome e cognome per crearne uno.",
          MessageUserFound:
            "Questo utente è già registrato nel nostro database. Puoi procedere con la registrazione.",
          EmailNotValid: "L'e-mail inserita non è valida.",
          Email: "E-mail",
          Name: "Nome",
          Surname: "Cognome",
          TitleStep1: "Dati del cliente",
          TitleStep2: "Dati della bici",
          TitleStep3: "Riassunto",
          TitleStepInner1: "Inserisci i dettagli del proprietario.",
          TitleStepInner2: "Inserisci le informazioni richieste della bici.",
          requireField:
            "Questo campo è richiesto. Per favore, inserisci un valore.",
          Shop: "Negozio",
          SerialNumber: "Dove trovo il numero di serie?",
          SelectModel: "Seleziona il modello",
          PurchaseDate: "Data di acquisto",
          Model: "Modello",
          SerialNumber: "Numero di serie",
          ProofOfPurchase: "Ricevuta di acquisto",
          importFIle:
            "Inserisci il file con la ricevuta d'acquisto: .pdf, .png e .jpeg sono accettati. Dimensione massima del file: 10 MB",
        },
      },
      ListPage: {
        Header: {
          Title: "Le tue bici registrate",
          Subtitle: "Guarda le tue bici registrate oppure aggiungine una.",
        },
        Form: {
          MessageUserNotFound:
            "Non esiste alcun account associato a questa e-mail. Inserisci nome e cognome per crearne uno.",
          MessageUserFound:
            "Questo utente è già registrato nel nostro database. Puoi continuare con la registrazione.",
          EmailNotValid: "L'e-mail inserita non è valida.",
          Email: "E-mail",
          Name: "Nome",
          Surname: "Cognome",
          TitleStep1: "Dati del cliente",
          TitleStep2: "Dati della bici",
          TitleStep3: "Riassunto",
          TitleStepInner1: "Inserisci i dettagli del proprietario.",
          TitleStepInner2: "Inserisci le informazioni richieste della bici",
          requireField:
            "Questo campo è richiesto. Per favore, inserisci un valore.",
          Shop: "Negozio",
          SerialNumber: "Dove trovo il numero di serie?",
          SelectModel: "Seleziona il modello",
          PurchaseDate: "Data di acquisto",
          Model: "Modello",
          SerialNumber: "Numero di serie",
          ProofOfPurchase: "Ricevuta di acquisto",
          importFIle:
            "Inserisci un file con la ricevuta d'acquisto: .pdf, .png e .jpeg sono accettati. Dimensione massima del file: 10MB",
        },
      },
    },
    SupportArea: {
      PageTitle: "Area assistenza",
      Loader: "Stiamo caricando i tuoi tickets...",
      Table: {
        ID: "ID",
        State: "Status",
        Topic: "Argomento",
        Description: "Descrizione",
        File: "Allegato",
        Data: "Data",
        Product: "Prodotti",
        YourRequest: "La tua richiesta",
      },
      Popup: {
        Common: {
          Title: "Apri una richiesta di assistenza",
          Step1: "Richiesta",
          Step2: "Argomento",
          Step3: "Dacci qualche informazione in più",
          Next: "Prossimo",
          Proceed: "Procedi",
          Back: "Indietro",
          FileUploadTitle: "Allegato",
          FileUploadContent:
            "Importa un file: sono accettati .pdf, .png e .jpeg. Dimensione massima del file: 10MB",
        },
        Step1: {
          Content: "Come possiamo aiutare?",
        },
        Step2: {
          Content: "Di che argomento si tratta?",
        },
        Step3: {
          Content:
            "Per favore, fornisci tutte le informazioni che possano permetterci di aiutarti al meglio. Per favore, aggiungi le immagini che possano aiutarci ad indirizzare la tua richiesta d'assistenza:",
        },
        Options: {
          information: "Informazioni",
          assisted_purchase: "Vendita assistita",
          leecougan: "Lee Cougan",
          microtech: "Microtech",
          basso: "Basso",
          other: "Altro",
          e_commerce_assistance: "Assistenza E-commerce",
          availability: "Disponibilità",
          leecougan_product_assistance: "Lee Cougan assistenza prodotto",
          on_microtech_product_assistance: "Microtech assistenza prodotto",
          other: "Altro",
          warranty_request: "Richiesta di garanzia",
          support_dealer: "Supporto tecnico per rivenditori",
          basso_product_assistance: "Basso assistenza prodotto",
          rider_weight: "Peso del rider (vestito)",
          rider_weight_placeholder: "80 kg",
          discipline: "Disciplina",
          level: "Livello",
          amateur: "Amatoriale",
          agonist: "Agonista",
          pro: "Professionista",
          weekly_training_hours: "Ore di allenamento settimanale",
          weekly_training_hours_placeholder: "16 ore",
          rampage_Innova_frame_size: "Taglia di telaio Rampage Innova"
        },
      },
    },
    DownloadArea: {
      PageTitle: "Area download",
      Loader: "Stiamo caricando tutti i file...",
      Table: {
        FileName: "Nome file",
        Category: "Categoria",
        Description: "Descrizione",
      },
    },
    TraningArea: {
      PageTitle: "Area Training",
      Loader: "Stiamo caricando tutti i tutorial...",
      Table: {
        Title: "Titolo",
        Model: "Modello",
        Description: "Descrizione",
        Date: "Descrizione",
      },
    },
    News: {
      PageTitle: "Notizie",
      Loader: "Stiamo caricando tutte le ultime notizie...",
      LoaderSingle: "Stiamo caricando tutte le ultime notizie...",
      FindOutMore: "Scopri di più",
    },
    ShopSelectorAgent: {
      Hello: "Buongiorno",
      Loader: "Stiamo caricando i tuoi clienti...",
      Subtitle: "Connettiti per conto di un cliente",
      Proceed: "Procedi",
    },
    Checkout: {
      CartTitle: "Per favore, controlla il tuo ordine",
      NoProducts: "Nessun prodotto nel carrello",
      Table: {
        img: "Immagine",
        Product: "Prodotto",
        Sku: "SKU",
        Qty: "Quantità",
        Price: "Prezzo",
        Total: "Totale",
      },
      Item: {
        Qty: "Quantità",
        viewConfiguration: "Vedi dettagli della configurazione",
        ViewDetails: "Vedi dettagli",
        EditConfiguration: "Modifica configurazione",
        RemoveProduct: "Rimuovi il prodotto",
        Price: "Prezzo",
      },
      ShipmentPaymentTitle: "Spedizione e pagamento",
      Shipment: {
        Title: "Spedizione",
        ShipmentByClientLabel: "Vuoi organizzarti la spedizione?",
        ShipmentByClientTxt:
          "I costi di spedizione saranno rimossi dal tuo ordine",
        ShipmentByClientCheckbox:
          "Si, voglio organizzare la spedizione attraverso il mio corriere.",
        ShipmentAddress: "Indirizzo di spedizione",
        SameAsBilling: "Lo stesso della fatturazione",
      },
      Billing: {
        Title: "Fatturazione",
        YourBillingData: "I tuoi dati di fatturazione",
        YourBillingDataDescription:
          "Per cambiare le tue informazioni di fatturazione, per favore contatta il servizio clienti.",
        CompanyDetails: "Dettagli societari",
        Vat: "IVA",
        Address: "Indirizzo",
        Contacts: "Contatti",
        OrderForCustomer: {
          Title: "Stai effettuando un ordine per un tuo cliente?",
          Description:
            "Se stai effettuando un ordine per uno dei tuoi clienti, inserisci qui l'ordine e questo verrà caricato anche nella loro area riservata.",
          Checkbox: "Si, sto effettuando l'ordine per conto di un cliente",
          MessageUserNotFound:
            "Nessun account associato a questa mail. Inserisci nome e cognome per crearne uno.",
          MessageUserFound:
            "Questo utente è già registrato nel nostro database. Puoi continuare con la registrazione.",
          EmailNotValid: "L'e-mail inserita non è valida.",
          Email: "E-mail",
          Name: "Nome",
          Surname: "Cognome",
        },
      },
      Payment: {
        Title: "Pagamento",
        TypeLabel: "Metodi di pagamenti e condizioni",
        Bacs: "Secondo quanto concordato con Stardue Srl",
        BankTitle: "Dettagli bancari",
        BeneficiaryName: "Nome del beneficiario",
      },
      BottomBar: {
        TotalProducts: "Totale prodotti",
        Shipment: "Spedizione",
        ShipmentTxt: "Quotato separatamente",
        Total: "Totale ordine",
        Submit: "Invia",
      },
    },
    DealersShop: {
      PageTitle: "Dealers Shop",
      Loader: "Wir laden Ihre Tickets...",
      Table: {
        ID: "ID",
        CompanyName: "Company Name",
        Vat: "Vat",
        Phone: "Phone",
      },
    },
  },
  PopupConfirmation: {
    TicketAdded: {
      Title: "Title",
      Content: "Content",
    },
    ProductAddedToCart: {
      Title: "Title",
      Content: "Content",
    },
    OrderPlaced: {
      Title: "Title",
      Content: "Content",
    },
    RegisteredBike: {
      Title: "Title",
      Content: "Content",
    },
  },
};
