import React, { useRef, useEffect, useState, useContext } from 'react';
import { ConfiguratorContext } from "../../context/configurator-loader";

const CanvasComponent = (props) => {
    const canvasRef = useRef(null)
    const [isImageLoaded, setImageLoadedAttr] = useState(props.isImageLoaded)
    const { updateImageState, imageData } = useContext(ConfiguratorContext);
    const [src, setSrc] = useState('');
    const imageWidth = props.imageWidth;
    const imageHeight = props.imageHeight;
    const canvasWidth = props.canvasWidth;
    const canvasHeight = props.canvasHeight;

    useEffect(() => {
        setImageLoadedAttr(0)
        // let canvas = document.getElementById(props.id);
        // const context = canvas.getContext("2d");
        const image = new Image();
        image.src = props.image;
        image.crossOrigin = "anonymous"
        image.onload = () => {
            setSrc(props.image);
            // context.imageSmoothingEnabled = false;
            // let devide = 1;
            // if (canvasWidth < 2300) {
            //     devide = 2;
            // }
            // if (canvasWidth < 1000) {
            //     devide = 6;
            // }
            // if (canvasWidth < 500) {
            //     devide = 8;
            // }
            // canvas.width = image.naturalWidth / devide;
            // canvas.height = image.naturalHeight / devide;
            // context.clearRect(0, 0, canvasWidth, canvasHeight);
            // context.drawImage(image, 0, 0, image.width / devide, image.height / devide);
            //context.drawImage(image, 0, 0, 4904, 3678, 0, 0, 798, 600);
            //context.drawImage(image, 0, 0, 798, 600, 0, 0, 4904, 3678);
            //context.drawImage(image, 0, 0, 798, 600);


            // context.clearRect(0, 0, canvasWidth, canvasHeight);
            // context.drawImage(image, 0, 0, imageWidth, imageHeight, 0, 0, canvasWidth, canvasHeight);

            setTimeout(() => {

                if (props.setImageLoaded) {
                    props.setImageLoaded(props.id)
                }
                if (props.id != 'view_22' && props.id != 'view_11') {
                    //  imageData[props.id] = true;
                    updateImageState(imageData)
                }
                setImageLoadedAttr("yes")
                if (document.getElementById(props.id)) {
                    document.getElementById(props.id).setAttribute("data-image-loaded", "yes");
                    document.getElementById(props.id).setAttribute("data-loaded", "yes");
                }
                if (canvasRef.setAttribute) {
                    canvasRef.setAttribute("data-image-loaded", "yes");
                    canvasRef.setAttribute("data-loaded", "yes");
                }
            }, 5000);
        };

    }, [props.image, canvasWidth, canvasHeight])

    return <img src={src} data-image-loaded={0} id={props.id} width={canvasWidth} height={canvasHeight} ref={canvasRef} data-image={props.image} alt=" " />
}
export default CanvasComponent;