import LoginUserIcon from "./LoginUserIcon";
import LoginLockIcon from "./LoginLockIcon";
import DashboardAnalyticIcon from "./DashboardAnalyticIcon";
import DashboardFTPIcon from "./DashboardFTPIcon";
import DashboardFilterIcon from "./DashboardFilterIcon";
import DashboardNewsIcon from "./DashboardNewsIcon";
import DashboardSearchIcon from "./DashboardSearchIcon";
import DashboardTraningIcon from "./DashboardTraningIcon";
import DashboardWarrentyIcon from "./DashboardWarrentyIcon";
import DashboardOrderIcon from "./DashboardOrderIcon";
import ArrowDownIcon from "./ArrowDownIcon";
import DashboardConfiguratorIcon from "./DashboardConfiguratorIcon";
import DashboardConfiguratorationIcon from "./DashboardConfiguratorationIcon";

export {
  LoginUserIcon,
  LoginLockIcon,
  DashboardAnalyticIcon,
  DashboardFTPIcon,
  DashboardFilterIcon,
  DashboardNewsIcon,
  DashboardSearchIcon,
  DashboardTraningIcon,
  DashboardWarrentyIcon,
  DashboardOrderIcon,
  ArrowDownIcon,
  DashboardConfiguratorIcon,
  DashboardConfiguratorationIcon,
};
