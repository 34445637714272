import history from "../history";
import { request } from "../services/apiService";
import {
  RESETPASSWORD_FAILURE,
  RESETPASSWORD_PENDING,
  RESETPASSWORD_SUCCESS
} from "./actionTypes";
import { i18n } from "../translations/i18n"


export const userResetPassword = (data) => dispatch => {
  dispatch({
    type: RESETPASSWORD_PENDING
  });
  const datas = { key: data.key, email: data.email, password: data.password }
  const url = `v1/user/update_password`;
  request("post", url, datas, false).then(
    (response) => {
      dispatch({ type: RESETPASSWORD_SUCCESS, message: response.message });
      history.push("/" + i18n.language + "/login/?message=" + response.message);
    },
    (error) => {
      console.log("userResetPassword Error", error.response)
      dispatch({
        type: RESETPASSWORD_FAILURE,
        message: error.response.data.message
      });
    }
  );
}
