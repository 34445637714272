import {
    GET_AGENT_DEALER_LIST_PENDING,
    GET_AGENT_DEALER_LIST_SUCCESS,
    GET_AGENT_DEALER_LIST_FAILURE
} from "../actions/actionTypes";

const initialState = {
    isLoading: false,
    dealerList: [],
    isError: false,
    message: '',
};


export default function DealerSelectionReducer(state = initialState, action) {
    if (!state) state = {};
    switch (action.type) {
        case GET_AGENT_DEALER_LIST_PENDING:
            return Object.assign({}, state, {
                isLoading: true,
                isError: false
            });
        case GET_AGENT_DEALER_LIST_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                isError: false,
                dealerList: action.data,
            });
        case GET_AGENT_DEALER_LIST_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                message: action.data,
                isError: true,
            });
        default:
            return state;
    }
}
