import {
    UPDATE_DEALER_DATA_PENDING,
    UPDATE_DEALER_DATA_SUCCESS,
    UPDATE_DEALER_DATA_FAILURE,
    GET_DEALER_DATA_PENDING,
    GET_DEALER_DATA_SUCCESS,
    GET_DEALER_DATA_FAILURE,
    SET_DEALER_DATA,
    UPDATE_DEALER_PERSONAL_DATA_PENDING,
    UPDATE_DEALER_PERSONAL_DATA_SUCCESS,
    UPDATE_DEALER_PERSONAL_DATA_FAILURE
} from "../actions/actionTypes";

const initialState = {
    isUpdateLoading: false,
    isDealerUpdateLoading: false,
    isDealerUpdateError: false,
    isDealerUpdateSucces:false,
    isGetLoading: false,
    isError: false,
    message: '',
    updateData: {},
    dealerData: {},
    dealerUpdateData: {}
};


export default function userManageReducer(state = initialState, action) {
    if (!state) state = {};
    switch (action.type) {
        case UPDATE_DEALER_DATA_PENDING:
            return Object.assign({}, state, {
                isUpdateLoading: true,
                isError: false
            });
        case UPDATE_DEALER_DATA_SUCCESS:
            return Object.assign({}, state, {
                isUpdateLoading: false,
                isError: false,
                updateData: action.data,
            });
        case UPDATE_DEALER_DATA_FAILURE:
            return Object.assign({}, state, {
                isUpdateLoading: false,
                message: action.data,
                isError: true,
            });
        case GET_DEALER_DATA_PENDING:
            return Object.assign({}, state, {
                isGetLoading: true,
                isError: false
            });
        case GET_DEALER_DATA_SUCCESS:
            return Object.assign({}, state, {
                isGetLoading: false,
                isError: false,
                dealerData: action.data,
            });
        case GET_DEALER_DATA_FAILURE:
            return Object.assign({}, state, {
                isGetLoading: false,
                message: action.data,
                isError: true,
            });
        case SET_DEALER_DATA:
            return Object.assign({}, state, {
                isGetLoading: false,
                isError: false,
                dealerData: action.data,
            });
        case UPDATE_DEALER_PERSONAL_DATA_PENDING:
            return Object.assign({}, state, {
                isDealerUpdateLoading: true,
                isDealerUpdateError: false,
                isDealerUpdateSucces:false
            });
        case UPDATE_DEALER_PERSONAL_DATA_SUCCESS:
            return Object.assign({}, state, {
                isDealerUpdateLoading: false,
                dealerUpdateData:action.data,
                isDealerUpdateError: false,
                isDealerUpdateSucces:true
            });
        case UPDATE_DEALER_PERSONAL_DATA_FAILURE:
            return Object.assign({}, state, {
                isDealerUpdateLoading: false,
                isDealerUpdateError: true,
                isDealerUpdateSucces:false
            });
        default:
            return state;
    }
}
