import React, { useState, useEffect } from "react";
import Price from "../Price";
import { Image, Row, Col } from "antd";
import i18next from "i18next";

const Items = ({ productData, setProductData, setProductDetailOpen }) => {
  let totalStock = 0;
  if (productData.type == 'simple') {
    totalStock = productData.stock
  } else {
    productData.variation && productData.variation.length > 0 && productData.variation.map((variationData, index) => {
      totalStock = variationData.stock + totalStock;
    })
  }


  return (
    <div className="table-item">
      <div className="items-list">

        <Row gutter={[24, 24]}>
          <Col
            span={3}
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="table-item__content">
              <Image
                className="img" 
                src={productData.image ? productData.image : ''}
              />
              
            </div>
          </Col>
          <Col
            span={3}
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="table-item__content">
              {productData.sku}
            </div>
          </Col>
          <Col
            span={4}
            xs={{ span: 6 }}
            sm={{ span: 6 }}
            lg={{ span: 4 }}
          >
            <div className="table-item__content">
              {productData.title}
            </div>
          </Col>
          <Col
            span={4}
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            lg={{ span: 4 }}
          >
            <div className="table-item__content">
              {productData.terms.length > 0 && productData.terms.map((term) => {
                return <>{term.name}<br /></>
              })}
            </div>
          </Col>
          <Col
            span={2}
            xs={{ span: 2 }}
            sm={{ span: 2 }}
            lg={{ span: 2 }}
          >
            <div className="table-item__content">
              {totalStock >= 50 && (
                <div className="qty-with-badge">
                  <div className="badge-circular prod-available"></div>
                  {/* <p>{totalStock}</p> */}
                </div>
              )}
              {totalStock < 50 && totalStock > 0 && (
                <div className="qty-with-badge">
                  <div className="badge-circular prod-limited"></div>
                  {/* <p>{totalStock}</p> */}
                </div>
              )}
              {totalStock <= 0 && (
                <div className="qty-with-badge">
                <div className="badge-circular prod-out-of-stock"></div>
                {/* <p>{totalStock}</p> */}
              </div>
              )}
            </div>
          </Col>
          <Col
            span={3}
            xs={{ span: 3 }}
            sm={{ span: 3 }}
            lg={{ span: 3 }}
          >
            <div className="table-item__content">
              <span className="product-price"><Price price={productData.prices} discount='product' displayDiscouted={true} /></span>
            </div>
          </Col>
          <Col
            className="flex align-v-center"
            span={5}
            xs={{ span: 5 }}
            sm={{ span: 5 }}
            lg={{ span: 5 }}
          >
            <div className="table-item__content align-end">
              <button className="btn btn-primary btn-select-product"
                onClick={(e) => {
                  document.body.style.overflowY = 'hidden';
                  e.stopPropagation()
                  setProductData(productData)
                  setProductDetailOpen(true)
                }}
                disabled={totalStock <= 0 ? true : false}>
                {i18next.t("Pages.Equipments.Table.OrderNow")}
              </button>
            </div>
          </Col>
        </Row>



      </div>
    </div>
  )
}

export default Items
