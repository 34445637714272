import Cookies from "js-cookie";
import {
  FETCH_CART_PENDING,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  ADD_CART_PENDING,
  ADD_CART_SUCCESS,
  ADD_CART_FAILURE,
  ADD_CART_NOTIFICATION_DONE,
  REMOVE_CART_PENDING,
  REMOVE_CART_SUCCESS,
  REMOVE_CART_FAILURE,
  CHANGE_QUANTITY_PENDING,
  CHANGE_QUANTITY_SUCCESS,
  CHANGE_QUANTITY_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  message: '',
  cart: {},
  totalItem: 0,
  isAddedTocart: false
};

export default function cart(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case FETCH_CART_PENDING:
      return Object.assign({}, state, {
        isLoading: true,

      });
    case FETCH_CART_SUCCESS:
      Cookies.set("stardue-carthash", action.data.carthash_cookie);
      Cookies.set("stardue-cartcount", action.data.quantity ? action.data.quantity : 0);
      return Object.assign({}, state, {
        cart: action.data,
        isLoading: false,
        totalItem: action.data.quantity ? action.data.quantity : 0
      });
    case FETCH_CART_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isError: true,
        isLoading: false
      });
    case ADD_CART_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isAddedTocart: false
      });
    case ADD_CART_SUCCESS:
      Cookies.set("stardue-carthash", action.data.carthash_cookie);
      Cookies.set("stardue-cartcount", action.data.quantity ? action.data.quantity : 0);
      
      if (action.data.errors) {
        return Object.assign({}, state, {
          isLoading: false,
          isAddedTocart: false,
          isError: true,
          totalItem: 0
        });
      } else {
        return Object.assign({}, state, {
          isLoading: false,
          cart: action.data,
          isAddedTocart: true,
          totalItem: action.data.quantity ? action.data.quantity : 0
        });
      }


    case ADD_CART_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isError: true,
        isLoading: false,
        isAddedTocart: false
      });
    case ADD_CART_NOTIFICATION_DONE:
      return Object.assign({}, state, {
        message: '',
        isError: false,
        isLoading: false,
        isAddedTocart: false
      });
    case REMOVE_CART_PENDING:
      return Object.assign({}, state, {
        isLoading: true,

      });
    case REMOVE_CART_SUCCESS:
      return Object.assign({}, state, {
        cart: action.data,
        isLoading: false,
        totalItem: action.data.quantity ? action.data.quantity : 0
      });
    case REMOVE_CART_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isError: true,
        isLoading: false
      });
    case CHANGE_QUANTITY_PENDING:
      return Object.assign({}, state, {
        isLoading: true,

      });
    case CHANGE_QUANTITY_SUCCESS:
      Cookies.set("stardue-cartcount", action.data.quantity ? action.data.quantity : 0);
      return Object.assign({}, state, {
        cart: action.data,
        isLoading: false,
        totalItem: action.data.quantity ? action.data.quantity : 0
      });
    case CHANGE_QUANTITY_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isError: true,
        isLoading: false
      });
    default:
      return state;
  }
}
