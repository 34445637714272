import { request } from "../services/apiService";
import {
  CREATE_ORDER_PENDING,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_NOTIFICATION_DONE,
} from "./actionTypes";
import history from "../history";
import { i18n } from "../translations/i18n";
import i18next from "i18next";

export const resetCreateOrderNotification = () => (dispatch) => {
  dispatch({
    type: CREATE_ORDER_NOTIFICATION_DONE,
  });
};

export const createOrder = (data) => (dispatch) => {
  dispatch({
    type: CREATE_ORDER_PENDING,
  });
  const url = `v1/order`;
  request("post", url, data, true).then(
    (res) => {
      dispatch({
        type: CREATE_ORDER_SUCCESS,
        data: res,
      });
      history.push("/" + i18n.language + "/" + i18next.t('routes.YourOrders'));
    },
    (err) => {
      dispatch({
        type: CREATE_ORDER_FAILURE,
        data: err.response,
      });
    }
  );
};
