export const TRANSLATIONS_DE = {
  routes: {
    Dashboard: "dashboard",
    Configuration: "konfigurieren",
    ConfigurationsSaved: "ihre-konfigurationen",
    ConfigurationsSearch: "upload-konfiguration",
    NewOrder: "neue-ausrustung-bestellen",
    YourOrders: "deine-bestellungen",
    backOrders: "bestatigte-bestellungen",
    RegisterBike: "kundenrad-registrieren",
    SupportArea: "support-bereich",
    TrainingArea: "trainingsbereich",
    FTPArea: "download-bereich",
    B2BNews: "nachrichten",
    AccountUser: "kontodaten",
    SwitchBrand: "schaltermarke",
    ShopSelectorAgent: "shop-selektor",
    Checkout: "checkout",
    Login: "login",
    ResetPassword: "passwort-zurucksetzen",
    NewPassword: "neues-passwort",
    PrivacyPolicy: "datenschutz",
    TermsConditions: "geschaftsbedingungen",
    BassoFrontend: "https://bassobikes.com",
    LeeCouganFrontend: "https://www.leecougan.com",
    StockProduct:"versandfertige-produkte",
  },
  PageTitle: {
    Dashboard: "Dashboard | Stardue B2B",
    Configurator: "Konfigurieren | Stardue B2B",
    ConfigurationsSearch: "Upload konfiguration | Stardue B2B",
    ConfigurationsSaved: " Ihre konfigurationen | Stardue B2B",
    NewOrder: "Neue Ausrüstung bestellen | Stardue B2B",
    YourOrders: "{{orderId}} Deine Bestellungen | Stardue B2B",
    OrderList: "Deine Bestellungen | Stardue B2B",
    BackOrders: "Bestätigte Bestellungen | Stardue B2B",
    RegisterBike: "Register customer bike | Stardue B2B",
    SupportArea: "Support-Bereich | Stardue B2B",
    B2BNews: "Nachrichten | Stardue B2B",
    TrainingArea: " Trainingsbereich | Stardue B2B",
    FTPArea: "Download-Bereich | Stardue B2B",
    AccountUser: "Kontodaten | Stardue B2B",
    SwitchBrand: "Schaltermarke | Stardue B2B",
    ShopSelectorAgent: "Shop-Selektor | Stardue B2B",
    Checkout: "Checkout | Stardue B2B",
    Login: "Login | Stardue B2B",
    ResetPassword: "Passwort zurücksetzen | Stardue B2B",
    NewPassword: "Neues Passwort | Stardue B2B",
    PrivacyPolicy: "Datenshultz - Bestimmungen | Stardue B2B",
    TermsConditions: "Geschäftsbedingungen | Stardue B2B",
    ProductsStock: "Versandfertige Produkte | Stardue B2B",
  },
  Footer: {
    Coopyright: "© 2022 - STARDUE S.R.L. - P.IVA / C.F. 02109950267",
    TermsConditions: "Geschäftsbedingungen",
    PrivacyPolicy: "Datenshultz - Bestimmungen",
  },
  Header: {
    Option: "Wähle eine Option",
    BikesGroup: {
      Bikes: "Fahrräder",
      ProductsStock: "Versandfertige Produkte",
      ConfigurationsSaved: "Meine Konfigurationen",
      ConfigurationsSearch: "Kundenkonfiguration finden",
      Configuration: "Neue Konfiguration erstellen",
    },
    Equipments: "Ausrüstung",
    OrdersGroup: {
      Orders: "Bestellungen",
      OrdersWaiting: "Ausstehende Bestätigung",
      BackOrders: "Bestätigte",
    },
    ServicesGroup: {
      Services: "Dienstleistungen",
      RegisterBike: "Registrieren Sie das neue Fahrrad des Kunden",
      SupportArea: "Support-Bereich",
      FTPArea: "Download-Bereich",
      TrainingArea: "Trainingsbereich",
      B2BNews: "Neuesten Nachrichten",
    },
    UserGroup: {
      YourData: "Deine Daten",
      Logout: "Logout",
      ChangePlatform: "Marke wechseln",
      ChangeShop: "Shop wechseln",
    },
  },
  Dashboard: {
    ftpArea: "Download-Bereich",
    ftpAreaText:
      "Melden Sie sich in Ihrem Download-Bereich an, um einen reservierten Inhalt herunterzuladen.",
    ticketArea: "Support-Bereich",
    ticketAreaText: "",
    trainingArea: "Trainingsbereich",
    trainingAreaText:
      "Entdecken Sie alle Video-Tutorials, die unseren Wiederverkäufern vorbehalten sind.",
    warranty: "Registrieren Sie das neue Fahrrad des Kunden",
    warrantyText: "",
    ticketArea: "Support-Bereich",
    ticketAreaText: "",
    configuration: "Erstellen Sie eine neue Konfiguration",
    configurationText: "",
    configurationSaved: "Ihre konfigurationen",
    configurationSavedText: "",
    equipmentSearch: "Ausrüstung finden",
    searchPlaceholder: "SKU oder Produktname",
    order: "Bestätigte Bestellungen",
    orderText:"Verfolgen Sie den Fortschritt Ihrer bestätigten Bestellungen",
    newsArea: "Neuesten Nachrichten",
    newsAreaText:
      "Die neuesten Nachrichten aus unserer Welt, damit Sie es als Erster erfahren.",
    webSite: "Webseite",
    webSiteText: "Besuchen Sie die B2C-Website",
    button: "Eingeben",
    goToWebsite: "Gehen Sie zur Webseite",
    ProductStock:"Versandfertige Produkte",
    ProductStockText: "Produkte in unserem Lager bereit zum Versand.",
  },
  Common: {
    ServiceNotAvailable: "Daten aufgrund der ERP-Migration nicht verfügbar. Wir entschuldigen uns für die Unannehmlichkeiten. Der Dienst wird bald wieder verfügbar sein.",
    NoMobileView:
      "Das Dashboard ist auf dem mobilen Bildschirm nicht verfügbar. Bitte verwenden Sie Ihren Laptop weiterhin.",
    PasswordMatchError: "Das eingegebene Passwort stimmt nicht überein.",
    hi: "Hallo",
    Back: "Zurück",
    Logout: "Logout",
    Address: "Adresse",
    Contacts: "Kontakte",
    FiscalCode: "Abgabenordnung",
    AddNewAddress: "Neue Adresse hinzufügen",
    NewAddress: "Neue Adresse",
    RequiredFields:
      "Alle mit einem Stern* gekennzeichneten Felder sind Pflichtfelder.",
    Confirm: "Bestätigen",
    Nominative: "Nominativ",
    okText: "Ja",
    CancelText: "Nein",
    Proceed: "Fortfahren",
    DiscoverButton: "Entdecken",
    FiltersSidebar: {
      Title: "Filter",
      Submit: "Anwenden",
      Reset: "Filter entfernen",
      All1: "Alles",
      All2: "Alles",
      Model: "Modell",
      Groupset: "Gruppe",
      idOrder: "Bestellung ID",
      IDConfiguration: "Konfigurations-ID",
      SKU: "Suchen Sie nach SKU oder Produktnamen",
      SKUADMIN: "Suchen Sie nach SKU, Produktname oder nach Händlername und Code",
      Category: "Wähle eine Kategorie",
      RelatedModel: "Finden Sie Ersatzteile für:",
      State: "Nach Status filtern",
      FromDate: "Aus",
      ToDate: "Zu",
      SerialNumber: "Seriennummer",
      ChooseOption: "Wähle eine Option",
      RegisterNewBike: "Registrieren Sie ein neues Fahrrad",
      OpenTicket: "Neues Ticket eröffnen",
      Ready_to_ship: 'Versandfertig',
      order_confirmed: 'Bestellung bestätigt',
      in_production: 'In Produktion',
      on_hold: 'In Wartestellung',
    },
    Pagination: {
      Prev: "Bisherige",
      Next: "Nächste",
    },
    ConfirmationMessage: {
      Products: {
        Title: "Produkt zu Ihrer Bestellung hinzugefügt",
        Label: "Vielen Dank, das Produkt wurde Ihrer Bestellung hinzugefügt",
      },
    },
    Form: {
      SelectOption: "Wählen",
      companyname: "Firmenname",
      requireField: "Dieses Feld wird benötigt. Bitte einen Wert eingeben.",
      name: "Name",
      PublicEmail: "Öffentliche E-Mail",
      require_name: "Bitte geben Sie Ihren Namen ein.",
      surname: "Nachname",
      require_surname: "Bitte geben Sie Ihren Nachnamen ein.",
      address: "Adresse",
      country: "Land",
      require_country: "Bitte geben Sie Ihr Land ein.",
      city: "Stadt",
      require_city: "Bitte geben Sie Ihre Stadt ein.",
      province: "Provinz",
      require_province: "Bitte geben Sie das Bundesland ein.",
      street: "Straße und Hausnummer",
      require_street:
        "Bitte geben Sie Ihren Straßennamen und Ihre Hausnummer ein.",
      postalcode: "Postleitzahl",
      require_postalcode: "Bitte geben Sie die Postleitzahl ein.",
      contact: "Kontaktinformation",
      phone: "Telefon",
      require_phone: "Bitte gib deine Telefonnummer ein.",
      defaultAddressSettings: "Einstellungen",
      is_default_address: "Markieren Sie diese Adresse als Standard.",
      edit: "Bearbeiten",
      email: "E-mail",
      valid_email:
        "Diese E-Mail-Adresse ist ungültig, bitte geben Sie eine gültige E-Mail-Adresse ein.",
      require_email: "Bitte geben Sie Ihre E-Mail ein",
      first_name: "Name",
      require_first_name: "Bitte geben Sie Ihren Namen ein.",
      last_name: "Nachnamen",
      require_last_name: "Bitte geben Sie Ihren Nachnamen ein.",
      password: "Passwort",
      require_password: "Bitte geben Sie Ihr Passwort ein",
      confirmPassword: "Bestätigen Passwort",
      require_confirmPassword: "Bitte bestätigen Sie Ihr Passwort",
      model: "Modell",
      serial_number: "Seriennummer",
      require_serial_number: "Bitte fügen Sie Ihre Seriennummer hinzu",
      purchase_date: "Kaufdatum",
      proof_purchase: "Kaufbeleg",
      store: "Händler",
      require_store:
        "Bitte verwenden Sie dieses Feld, um Ihren Händler auszuwählen",
      save: "Speichern",
      sendrequest: "Anfrage senden",
      otherInformation: "Andere Informationen",
      generic_required:
        "Dieses Feld wird benötigt. Bitte fügen Sie die angeforderten Informationen hinzu.",
      couopon: "Coupon",
      request: "Anfrage",
      query: "Weitere Informationen",
      topic: "Thema",
      Update: "Update",
    },
    Bikes: {
      SummaryBike: {
        ConfigurationID: "Konfigurations-ID",
        Sku: "Sku",
        Groupset: "Gruppe",
        Size: "Größe",
        Wheels: "Räder",
        Color: "Farbe",
      },
    },
  },
  Pages: {
    ChangeBrand: {
      RequestAccess: "Anfrage Zugang",
      Form: {
        Title: "Anfrage Zugang",
        Label: "Beschreiben Sie Ihr Anliegen",
        Submit: "Anfrage absenden"
      }
    },
    Login: {
      Title: "Willkommen",
      Subtitle: "Melden Sie sich bei Ihrem dedizierten Dashboard an",
      Email: "Deine E-Mail",
      Password: "Ihr Passwort",
      Submit: "Login",
      LostPassword: "Hast du dein Passwort vergessen?",
    },
    ForgotPassword: {
      Title: "Hast du dein Passwort vergessen?",
      Subtitle: "Setzen Sie es jetzt zurück und melden Sie sich an",
      Email: "Deine E-Mail",
      Submit: "Fortfahren",
    },
    NewPassword: {
      Title: "Passwort vergessen?",
      Subtitle: "Setzen Sie es jetzt zurück und melden Sie sich an",
      Email: "Deine E-Mail",
      Password: "Neues Passwort",
      ConfirmPassword: "Bestätigen Sie Ihr Passwort",
      Message: "",
      Submit: "Bestätigen",
    },
    AgentPage: {
      Loader: "Wir laden Ihre Kunden",
      Title: "Login im Namen eines Kunden.",
      NoClient:
        "Keine verbundenen Kunden. Bitte wenden Sie sich an unseren Support.",
    },
    UserDetails: {
      YourData: "Dein Profil",
      Anagraphic: "Dein ",
      Company: "Firmenname",
      VAT: "MwSt.-Nummer",
      EmailAccount: "Konto-E-Mail",
      StoreLocatorTitle: "Informationen zur Filialsuche",
      TimeTable: {
        ShopTime: "Öffnungszeiten",
        monday: "Montag",
        tuesday: "Dienstag",
        wednesday: "Mittwoch",
        thursday: "Donnerstag",
        friday: "Freitag",
        saturday: "Samstag",
        sunday: "Sonntag",
        morning: "Morgen",
        afternoon: "Nachmittag",
        start: "Starten",
        end: "Ende",
        closed: "Geschlossen",
        nonStop: "non-stop",
      },
    },
    Configurator: {
      PageTitle: "Fahrradkonfigurator",
      ConfigureNow: "Konfigurieren Sie Ihre",
      LoaderLabel: "Wir laden den Konfigurator.",
      NotSelected: "Nicht ausgewählt",
      AvailableFrom: "Aus",
      Quantity: "Menge",
      Geometry: "Geometrie",
      OutOfStock: "Nicht vorrättig",
      Type: {
        Bike: "Das komplette Basso Bike fahrbereit.",
        FrameKit:
          "Der Rahmen, die Gabel und alle spezifischen Basso-Komponenten",
        BuildKit: "Rahmenkit und alle Komponenten ohne Gruppe.",
      },
      Step: {
        Header: {
          Categories: "Kategorien",
          Models: "Modell",
          Type: "Typ",
          Wheels: "Räder",
          Color: "Farbe",
          Size: "Größe",
          Groupset: "Gruppe",
          Options: "Options",
          Quantity: "Menge",
        },
        Price: {
          Included: "Inbegriffen",
          Upgrade: "Upgrade ausgewählt",
        },
      },
      BottomBar: {
        ProceedToCart: "Zum Warenkorb hinzufügen",
        FindYourDealer: "Finden Sie Ihren Händler",
        Save: "Speichern",
        DownloadPDF: "PDF-Download",
        YourPrice: "Reservierter Preis",
        PublicPrice: "Unverbindliche Preisempfehlung",
        DiscountLabel: "Ihr Markup",
      },
      ConfiguratorSummary: {
        Title: "Zusammenfassung der Konfiguration",
        FrameSet: "Rahmenset",
        Frame: "Rahmen",
        Fork: "Gabel",
        SeatPostClamp: "Sattelstützenklemme",
        ThruAxleFornt: "Steckachse vorne",
        ThruAxleRear: "Steckachse hinten",
        Hanger: "Schaltwerk-aufhänger",
        Headset: "Headset",
        Groupset: "Gruppe",
        Crank: "Kurbel",
        CrankArm: "Kurbelarm",
        ChainRings: "Kettenblätter",
        Chain: "Kette",
        BottomBraket: "Tretlager",
        Cassette: "Kassette",
        CassetteGears: "Kassettengetriebe",
        FrontDerailleur: "Umwerfer",
        RearDerailleur: "Schaltwerk",
        Shifters: "Schalthebel",
        Brakes: "Bremsen",
        Rotors: "Bremsscheiben",
        Cockpit: "Cockpit",
        SeatpostAndSaddle: "Sattelstütze und sattel",
        WheelsAndTires: "Räder und reifen",
        Wheels: "Räder",
        Upgrade: "Upgrade",
        HeadUnitMount: "Computerhalterungen",
        HandlebarTap: "Lenkerband",
        PowerUnit: "Power Unit",
        Motor: "Motor",
        Display: "Display",
        Battery: "Batterie",
        Other: "Andere",
        Editing: "Weiter bearbeiten",
        DownloadPDF: "PDF-Download",
        ConfirmAndProceed: "Weiter zum Warenkorb",
      },
      SaveConfigurator: {
        Popup: {
          Loader: "Wir speichern Ihre Konfiguration...",
          Header: {
            Title: "Speichern Sie Ihre Konfiguration",
            Subtitle:
              "Herzlichen Glückwunsch, Ihre Konfiguration wurde gespeichert.",
          },
          DiscoverButton: "Entdecken",
        },
      },
      AddToCart: {
        Popup: {
          Loader: "Wir fügen die Konfiguration dem Warenkorb hinzu ...",
          Header: {
            Title: "Konfiguration hinzugefügt",
            Subtitle:
              "Herzlichen Glückwunsch, Ihre Konfiguration wurde dem Warenkorb hinzugefügt.",
          },
        },
      },
      DowloadPDF: {
        Popup: {
          Loader: "Wir bereiten das PDF Ihrer Konfiguration vor ...",
          Header: {
            Title: "PDF-Download",
            Subtitle: "Herzlichen Glückwunsch, Ihr PDF wurde generiert.",
          },
        },
      },
      SummarySmall: {
        Title: "Zusammenfassung",
        Categories: "Kategorien",
        Models: "Modell",
        Type: "Typ",
        Wheels: "Räder",
        Color: "Farbe",
        Size: "Größe",
        Groupset: "Gruppe",
      },
      PreviewImage: {
        Title: "Vorschau",
        LargeImage: "Klicken Sie, um das Bild zu vergrößern",
      },
    },
    ConfigurationSaved: {
      PageTitle: "Gespeicherte Konfigurationen",
      Loader: "Wir laden Ihre Konfigurationen...",
      Table: {
        ID: "ID",
        Model: "Modell",
        Groupset: "Gruppe",
        Size: "Größe",
        Color: "Farbe",
        Wheels: "Räder",
        Price: "Preis",
        Edit: "Bearbeiten",
        Summary: "Zusammenfassung",
        DownloadPDF: "PDF-Download",
        OrderDetails: "Bestellung ansehen",
        Delete: "Löschen",
      },
    },
    SearchConfiguration: {
      Title: "Suche nach bestehender Konfiguration",
      Info:
        "Geben Sie einen Konfigurations-ID-Code ein, um eine vorhandene Konfiguration abzurufen. Die Konfigurations-ID finden Sie auf der Seite „Ihre Konfigurationen“ sowohl auf der B2B- als auch auf der B2C-Website und auf allen PDF-Konfigurationsdokumenten.",
      Search: "Jetzt suchen",
      ConfigCode: "Geben Sie den Konfigurations-ID-Code ein",
      Loader: "Wir suchen die gewünschte Konfiguration...",
      Table: {
        Edit: "Bearbeiten",
        Summary: "Zusammenfassung",
        DownloadPDF: "PDF-Download",
        OrderDetails: "Bestellung ansehen",
      },
    },
    Address: {
      Header: {
        Title: "Ihre Adressen",
        Subtitle: "Verwalten Sie Ihre Liefer- und Rechnungsadressen.",
      },
      ShippingAddress: {
        Label: "Lieferanschrift",
      },
      BillingAddress: {
        Label: "Rechnungsadresse",
      },
    },
    Equipments: {
      PageTitle: "Online-Bestellung",
      Loader: "Wir laden die Produkte hoch",
      NotFound:
        "Keine Produkte gefunden. Bitte verwenden Sie ein anderes Schlüsselwort.",
      Table: {
        Image: "Bild",
        SKU: "SKU",
        Name: "Name",
        Categories: "Kategorien",
        Stock: "Vorrat",
        Price: "Preis",
        OrderNow: "Zur Bestellung hinzufügen",
      },
      Details: {
        RecomandedPrice: "Unverbindliche Preisempfehlung",
        YourDiscount: "Ihr Markup",
        Description: "Beschreibung",
        Features: "Merkmale",
        BuyNow: "Zu meiner Bestellung hinzufügen",
        ConfirmationAddToCart: "",
      },
    },
    Orders: {
      PageTitle: "Deine Bestellungen",
      Loader: "Wir laden deine Bestellungen...",
      Table: {
        OrderNumber: "ID",
        OrderDate: "Bestelldatum",
        ExpectiveDate: "Erwarteter Lieferzeitpunkt",
        Destination: "Geliefert an",
        State: "Status",
        Products: "Produkte",
        ProductIncluded: "Produkte",
        Total: "Gesamt",
        Details: "Einzelheiten",
        ChildOrderLabel: "Verwandte Bestellungen",
      },
      Single: {
        Order: "Bestellung",
        EstimatedDate: "Geschätztes Datum",
        NotAvailableEstimateDate: "Nicht verfügbar",
        OpenTicket: "Öffnen Sie ein Ticket",
        OrderDate: "Bestelldatum",
        ShipmentTo: "Lieferung an",
        OrderStatus: "Status",
        DownloadReceipt: "Download Quittung",
        ReturnOrder: "Rücksendung",
        ReturnRequests: "Rücksendeantrag",
        ReturnRequestsInfo: "Ihre Anfrage wurde erfolgreich übermittelt",
        OrderSummary: "Bestellübersicht",
        Sku: "Sku",
        Groupset: "Gruppe",
        ConfiguratorID: "Konfigurator-ID",
        Size: "Größe",
        Color: "Farbe",
        Wheels: "Räder",
        DownloarConf: "Laden Sie Ihre Konfiguration herunter",
        Quantity: "Menge",
        Price: "Preis",
        Subtotal: "Subtotal",
        Shipment: "Versand",
        PaymentMethod: "Bezahlverfahren",
        Total: "Gesamt",
        BillingInformation: "Abrechnungsdaten",
        ShippingData: "Versanddaten",
        FiscalCode: "Abgabenordnung",
        IncludeOption: "Optionen enthalten",
        Upgrade: "Upgrade ausgewählt",
        Bacs: "Wie mit Stardue Srl vereinbart",
      },
      TicketOrder: {
        Common: {
          Title: "Bestellung {{orderId}} - Assistance ticket",
          Request: "Anfrage",
          Order: "Bestellung",
          StepTitle1: "Anfrage",
          StepTitle2: "Produkte",
          StepTitle3: "Erzählen Sie uns mehr",
          StepTitle4: "Bestätigenation",
          Next: "Nächste",
          Proceed: "Fortfahren",
          Back: "Zurück",
          SuccessTitle: "Ihre Anfrage wurde erfolgreich übermittelt.",
          SuccessSubtitle:
            "Sie werden innerhalb der nächsten 24/48 Stunden vom Team von Basso Bikes kontaktiert.",
          SuccessButton: "Zurück zur Bestellung",
          FileUploadTitle: "Eine Datei anhängen",
          FileUploadContent:
            "Importieren Sie eine Datei: .pdf, .png und .jpeg werden akzeptiert. Maximale Dateigröße: 10MB",
        },
        Step1: {
          Content: "Wählen Sie das Thema Ihrer Anfrage aus",
          ReturnReplacement: "Ersatz / Rückgabe",
          Tracking: "Tracking-Informationen",
        },
        Step2: {
          Content: "Wählen Sie das Produkt mit Problemen aus:",
        },
        Step3ReturnProducts: {
          Content:
            "Auf welche Art von Problemen sind Sie gestoßen? Helfen Sie den Basso-Mitarbeitern zu verstehen, was schief gelaufen ist.",
        },
        Step3Tracking: {
          Content:
            "Auf welche Art von Problemen sind Sie gestoßen? Helfen Sie den Basso-Mitarbeitern zu verstehen, was schief gelaufen ist.",
        },
        Step4ReturnProducts: {
          Content:
            "Wenn Sie sich für die Bestätigung entscheiden, wird Ihre Ersatz-/Rückgabeanfrage vom Basso Bikes Team übernommen, das sich so schnell wie möglich mit Ihnen in Verbindung setzen wird.",
          Title:
            "Möchten Sie wirklich eine Ersatz-/Rückgabeanfrage für diese Produkte stellen?",
        },
        Step4Tracking: {
          Content:
            "Wenn Sie sich für die Bestätigung entscheiden, wird Ihre Ersatz-/Rückgabeanfrage vom Basso Bikes Team übernommen, das sich so schnell wie möglich mit Ihnen in Verbindung setzen wird.",
          Title: "Möchten Sie die Produkte wirklich ersetzen/zurücksenden?",
        },
        Step5: {
          Content:
            "Sie werden innerhalb der nächsten 24/48 Stunden vom Team von Basso Bikes kontaktiert.",
          Title: "Ihre Anfrage wurde erfolgreich übermittelt.",
        },
      },
    },
    BackOrders: {
      Title: "Bestätigte Bestellungen",
      Loader: "Wir laden deine Bestellungen...",
      Table: {
        OrderNumber: "Bestellung n°",
        NumberRow: "Zeilennummer",
        OrderDate: "Bestelldatum",
        ExpectiveDate: "Erwarteter Lieferzeitpunkt",
        Client: "Kunde",
        Destin: "Ziel",
        CodArt: "SKU",
        Groupset: "Gruppe",
        TG: "Größe",
        ArtDescription: "Beschreibung",
        ClientData: "Order Reqested date",
        QRes: "Menge",
        State: "Status",
        Note: "Note",
      },
    },
    ProductsStock: {
      Title: "Versandfertige Produkte",
      Loader: "Wir laden deine Bestellungen...",
      Table: {
        CodArt: "SKU",
        Groupset: "Gruppe",
        TG: "Größe",
        ArtDescription: "Beschreibung",
        QRes: "Menge",
      },
    },
    RegisterBike: {
      PageTitle: "Registrieren Sie ein neues Fahrrad",
      Loader: "Wir laden Ihre angemeldeten Fahrräder..",
      Table: {
        Client: "Kunde",
        Email: "E-mail",
        PurchaseDate: "Kaufdatum",
        Model: "Modell",
        SerialNumber: "Seriennummer",
        ProofOfPurchase: "Kaufbeleg",
      },
      FormPage: {
        Header: {
          Title: "Registrieren Sie ein neues Fahrrad",
        },
        Form: {
          MessageUserNotFound:
            "Mit dieser E-Mail ist kein Konto verknüpft. Geben Sie Ihren Vor- und Nachnamen ein, um einen zu erstellen.",
          MessageUserFound:
            "Dieser Benutzer ist bereits in unserer Datenbank registriert. Sie können mit der Registrierung fortfahren.",
          EmailNotValid: "Die eingegebene E-Mail ist ungültig.",
          Email: "E-mail",
          Name: "Name",
          Surname: "Nachname",
          TitleStep1: "Kundendaten",
          TitleStep2: "Fahrraddaten",
          TitleStep3: "Zusammenfassung",
          TitleStepInner1: "Geben Sie die Daten des Besitzers ein.",
          TitleStepInner2:
            "Geben Sie die angeforderten Informationen zum Fahrrad ein.",
          requireField: "Dieses Feld wird benötigt. Bitte einen Wert eingeben.",
          Shop: "Geschäft",
          SerialNumber: "Wo finde ich die Seriennummer?",
          SelectModel: "Wählen Sie Ihr Modell aus",
          PurchaseDate: "Kaufdatum",
          Model: "Modell",
          SerialNumber: "Seriennummer",
          ProofOfPurchase: "Kaufbeleg",
          importFIle:
            "Importieren Sie eine Datei: .pdf, .png und .jpeg werden akzeptiert. Maximale Dateigröße: 10MB",
        },
      },
      ListPage: {
        Header: {
          Title: "Ihre registrierten Fahrräder",
          Subtitle:
            "Sehen Sie sich Ihre registrierten Fahrräder an oder fügen Sie ein neues hinzu.",
        },
        Form: {
          MessageUserNotFound:
            "Mit dieser E-Mail ist kein Konto verknüpft. Geben Sie Ihren Vor- und Nachnamen ein, um einen zu erstellen.",
          MessageUserFound:
            "Dieser Benutzer ist bereits in unserer Datenbank registriert. Sie können mit der Registrierung fortfahren.",
          EmailNotValid: "Die eingegebene E-Mail ist ungültig.",
          Email: "E-mail",
          Name: "Name",
          Surname: "Nahname",
          TitleStep1: "Kundendaten",
          TitleStep2: "Fahrraddaten",
          TitleStep3: "Zusammenfassung",
          TitleStepInner1: "Geben Sie die Daten des Besitzers ein.",
          TitleStepInner2:
            "Geben Sie die angeforderten Informationen zum Fahrrad ein.",
          requireField: "Dieses Feld wird benötigt. Bitte einen Wert eingeben.",
          Shop: "Geschäft",
          SerialNumber: "Wo finde ich die Seriennummer?",
          SelectModel: "Wählen Sie Ihr Modell aus",
          PurchaseDate: "Kaufdatum",
          Model: "Modell",
          SerialNumber: "Seriennummer",
          ProofOfPurchase: "Kaufbeleg",
          importFIle:
            "Importieren Sie eine Datei: .pdf, .png und .jpeg werden akzeptiert. Maximale Dateigröße: 10MB",
        },
      },
    },
    SupportArea: {
      PageTitle: "Support-Bereich",
      Loader: "Wir laden Ihre Tickets...",
      Table: {
        ID: "ID",
        State: "Status",
        Topic: "Thema",
        Description: "Beschreibung",
        File: "Anhang",
        Data: "Date",
        Product: "Produkte",
        YourRequest: "Ihre Anfrage",
      },
      Popup: {
        Common: {
          Title: "Open a assistence request",
          Step1: "Request",
          Step2: "Thema",
          Step3: "Erzählen Sie uns mehr",
          Next: "Nächste",
          Proceed: "Fortfahren",
          Back: "Zurück",
          FileUploadTitle: "Anhang",
          FileUploadContent:
            "Importieren Sie Bilder, die das aufgetretene Problem darstellen; .pdf, .png und .jpeg werden akzeptiert. Maximale Dateigröße: 10 MB",
        },
        Step1: {
          Content: "Wie können wir Ihnen helfen?",
        },
        Step2: {
          Content: "Um welches Thema geht es?",
        },
        Step3: {
          Content:
            "Bitte geben Sie uns alle Informationen, die uns helfen können, Ihnen besser zu helfen. Bitte fügen Sie Bilder hinzu, die uns helfen können, das Ticket zu adressieren:",
        },
        Options: {
          information: "Information",
          assisted_purchase: "Unterstützter Kauf",
          leecougan: "Lee Cougan",
          microtech: "Microtech",
          basso: "Basso",
          other: "Andere",
          e_commerce_assistance: "E-commerce assistance",
          availability: "Verfügbarkeit",
          leecougan_product_assistance: "Lee Cougan Produktunterstützung",
          on_microtech_product_assistance: "Microtech Produktunterstützung",
          other: "Andere",
          warranty_request: "Garantieanfrage",
          support_dealer: "Technische Unterstützung des Händlers",
          basso_product_assistance: "Basso Produktunterstützung",
          rider_weight: "Gewicht des Radfahrers (ausgestattet)",
          rider_weight_placeholder: "80 kg",
          discipline: "Disziplinarverfahren",
          level: "Level",
          amateur: "Amateur",
          agonist: "Agonisten",
          pro: "Professionell",
          weekly_training_hours: "Ausbildungsstunden pro Woche",
          weekly_training_hours_placeholder: "16 Std.",
          rampage_Innova_frame_size: "Größe des Rahmens Ramapage Innova"
        },
      },
    },
    DownloadArea: {
      PageTitle: "Download-Bereich",
      Loader: "Wir laden alle Dateien...",
      Table: {
        FileName: "File name",
        Category: "Kategorie",
        Description: "Beschreibung",
      },
    },
    TraningArea: {
      PageTitle: "Trainingsbereich",
      Loader: "Wir laden alle Tutorials...",
      Table: {
        Title: "Titel",
        Model: "Modell",
        Description: "Beschreibung",
        Date: "Beschreibung",
      },
    },
    News: {
      PageTitle: "Nachrichten",
      Loader: "Wir laden alle Neuigkeiten...",
      FindOutMore: "Entdecke mehr",
    },
    ShopSelectorAgent: {
      Hello: "Hallo",
      Loader: "Wir laden Ihre Kunden",
      Subtitle: "Melden Sie sich im Namen Ihres Kunden an",
      Proceed: "Fortfahren",
    },
    Checkout: {
      CartTitle: "Bitte prüfen Sie Ihre Bestellung",
      NoProducts: "keine Produkte im Einkaufswagen",
      Table: {
        img: "Bild",
        Product: "Produkt",
        Sku: "SKU",
        Qty: "Menge",
        Price: "Preis",
        Total: "Gesamt",
      },
      Item: {
        Qty: "Menge",
        viewConfiguration: "Konfigurationsdetails anzeigen",
        ViewDetails: "Details anzeigen",
        EditConfiguration: "Konfiguration bearbeiten",
        RemoveProduct: "Produkt entfernen",
        Price: "Preis",
      },
      ShipmentPaymentTitle: "Versand und Bezahlung",
      Shipment: {
        Title: "Versand",
        ShipmentByClientLabel:
          "Möchten Sie Ihren eigenen Versand organisieren?",
        ShipmentByClientTxt:
          "Die Versandkosten werden von Ihrer Bestellung abgezogen",
        ShipmentByClientCheckbox:
          "Ja, ich möchte den Versand mit meinem Kurier organisieren.",
        ShipmentAddress: "Lieferanschrift",
        SameAsBilling: "Das Gleiche wie Zahlung",
      },
      Billing: {
        Title: "Abrechnung",
        YourBillingData: "Ihre Rechnungsdaten",
        YourBillingDataDescription:
          "Um Ihre Rechnungsinformationen zu ändern, wenden Sie sich bitte an den Kundendienst.",
        CompanyDetails: "Firmendetails",
        Vat: "MwSt",
        Address: "Adresse",
        Contacts: "Kontakte",
        OrderForCustomer: {
          Title: "Sie bestellen für Ihren Kunden?",
          Description:
            "Wenn Sie eine Bestellung für einen Ihrer Kunden aufgeben, geben Sie diese hier ein und die Bestellung wird auch in deren reservierten Bereich geladen.",
          Checkbox: "Ja, ich gebe die Bestellung für meinen Kunden auf",
          MessageUserNotFound:
            "Mit dieser E-Mail ist kein Konto verknüpft. Geben Sie Ihren Vor- und Nachnamen ein, um einen zu erstellen.",
          MessageUserFound:
            "Dieser Benutzer ist bereits in unserer Datenbank registriert. Sie können mit der Registrierung fortfahren.",
          EmailNotValid: "Die eingegebene E-Mail ist ungültig.",
          Email: "E-mail",
          Name: "Name",
          Surname: "Nachname",
        },
      },
      Payment: {
        Title: "Zahlung",
        TypeLabel: "Zahlungsarten und -bedingungen",
        Bacs: "Wie mit Stardue Srl vereinbart",
        BankTitle: "Bankdaten",
        BeneficiaryName: "Name des Begünstigten",
      },
      BottomBar: {
        TotalProducts: "Produkt insgesamt",
        Shipment: "Versand",
        ShipmentTxt: "Separat aufgeführt",
        Total: "Auftragssumme",
        Submit: "Einreichen",
      },
    },
    DealersShop: {
      PageTitle: "Händler Shop",
      Loader: "Wir laden Ihre Tickets...",
      Table: {
        ID: "ID",
        CompanyName: "Name der Firma",
        Vat: "Vat",
        Phone: "Telefon",
      },
    },
  },
  PopupConfirmation: {
    TicketAdded: {
      Title: "Title",
      Content: "Content",
    },
    ProductAddedToCart: {
      Title: "Title",
      Content: "Content",
    },
    OrderPlaced: {
      Title: "Title",
      Content: "Content",
    },
    RegisteredBike: {
      Title: "Title",
      Content: "Content",
    },
  },
};
