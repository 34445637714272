import { combineReducers } from "redux";
import ConfiguratorReducer from "./ConfiguratorReducer";
import ForgotPasswordReducer from "./ForgotPasswordReducer";
import LoginReducer from "./LoginReducer";
import OrderReceviedDetailReducer from "./OrderReceviedDetailReducer";
import OrderReceviedReducer from "./OrderReceviedReducer";
import PlaformReducer from "./PlatformReducer";
import ResetPasswordReducer from "./ResetPasswordReducer";
import AreaFTPReducer from "./AreaFTPReducer";
import NewsReducer from "./NewsReducer";
import TrainingReducer from "./TrainingReducer";
import ProductListReducer from "./ProductListReducer";
import CartReducer from "./CartReducer";
import createOrderReducer from "./CreateOrderReducer";
import AddressReducer from "./AddressReducer";
import ConfigurNowReducer from "./ConfigurNowReducer";
import userManageReducer from "./UserManageReducer";
import TicketReducer from "./TicketReducer";
import DealerSelectionReducer from "./DealerSelectionReducer";
import BackOrderReducer from "./BackOrderReducer";
import RegisterBikeReducer from "./RegisterBikeReducer";
import SupportDealerOrder from "./SupportDealerOrderReducer";
import ContentReducer from "./ContentReducer";
import DealersShopReducer from "./DealersShopReducer";
import ProductStockReducer from "./ProductStockReducer";

export default combineReducers({
  login: LoginReducer,
  orderReceivedDetail: OrderReceviedDetailReducer,
  orderRecived: OrderReceviedReducer,
  configure: ConfiguratorReducer,
  forgetPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  platform: PlaformReducer,
  news: NewsReducer,
  training: TrainingReducer,
  areaFTP: AreaFTPReducer,
  productList: ProductListReducer,
  cart: CartReducer,
  createOrder: createOrderReducer,
  address: AddressReducer,
  configurNow: ConfigurNowReducer,
  userManage: userManageReducer,
  ticket: TicketReducer,
  dealerSelection: DealerSelectionReducer,
  backOrder: BackOrderReducer,
  registerBike: RegisterBikeReducer,
  supportDealerOrder: SupportDealerOrder,
  content: ContentReducer,
  dealersShop: DealersShopReducer,
  productStock: ProductStockReducer
});
