import { request } from "../services/apiService";
import {
    GET_CONTENT_PENDING,
    GET_CONTENT_SUCCESS,
    GET_CONTENT_FAILURE,
} from "./actionTypes";

export const getContentPage = (slug) => dispatch => {
    dispatch({
        type: GET_CONTENT_PENDING
    });
    const url = `v1/db/content/` + slug;
    request("get", url, '', true).then(
        (res) => {
            dispatch({
                type: GET_CONTENT_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: GET_CONTENT_FAILURE,
                data: err.response,
            });
        }
    );
}