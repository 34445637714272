import React, { useState } from "react";
import i18next from "i18next";
const paginationLabel = (current, type, originalElement) => {
    if (type === 'prev') {
        return <a>{i18next.t("Common.Pagination.Prev")}</a>;
    }
    if (type === 'next') {
        return <a>{i18next.t("Common.Pagination.Next")}</a>;
    }
    return originalElement;
}

export default paginationLabel;
