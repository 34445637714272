import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_IT } from "./it/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_ES } from "./es/translations";
import { TRANSLATIONS_DE } from "./de/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    detection: {
      order: ['path', 'navigator', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag'],
      lookupQuerystring: 'locale',
      lookupCookie: 'i18n',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'],
      htmlTag: document.documentElement
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      it: {
        translation: TRANSLATIONS_IT,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
    },
    supportedLngs: ['en', 'it', 'fr', 'de', 'es'],
    fallbackLng: ['en'],
    interpolation: {
      escapeValue: false
    }
  });

// i18n.changeLanguage("en");
export { i18n };
