import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { getModelFE, getSearchUser, createRegisterBike, uploadFile } from "../../actions/registerBike";
import { withRouter, Link } from "react-router-dom";
import { i18n } from "../../translations/i18n";
import { Alert, Button, Col, Form, Input, Row, Spin, Select, Upload, AutoComplete, ConfigProvider } from "antd";
import DatePicker from "../../components/DatePicker";
import { ReactTitle } from "react-meta-tags";

const { Dragger } = Upload;
const { Option } = AutoComplete;

const RegisterBike = (props) => {

  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [form] = Form.useForm();
  const [file, setFile] = useState('');
  const [isFileError, setFileError] = useState(false);
  const [userFieldDisabled, setUserFieldDisabled] = useState(true);
  const [date, setDate] = useState('')
  const [dateError, setDateError] = useState(false)
  const [reset, setReset] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const dispatch = useDispatch();
  const { isUserError,isUserSuccess, message, modelList, userList, isRegisterLoading, isRegisterSucces, isRegisterError } = useSelector(state => {
    return state.registerBike
  });

  const draggerData = {
    name: 'file',
    multiple: false,
    disabled: file ? true : false,
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: 'Bearer ' + userData.token,

    },
    action: `${process.env.REACT_APP_API_URL}v1/content/file_upload`,
    onSuccess: function (response, file) {
      setFile(response.data.image)
      setFileError(false)
    },
    onChange(info) {
      info.file.status = 'done';
      info.fileList[0].status = 'done';
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  
  useEffect(() => {
    if(userList.id){
      form.setFieldsValue({
        customer_name: userList.firstName,
        surname: userList.lastName,
      });
      setUserFieldDisabled(true)
    }else{
      setUserFieldDisabled(false);
    }
  }, [userList]);
  useEffect(() => {
    isUserError && setUserFieldDisabled(false)
  }, [isUserError]);
  const onFinish = (values) => {
    if(date == ''){
      setDateError(true)
    }else{
      values['file'] = file;
      values['purchase_date'] = date;
      dispatch(createRegisterBike(values));
    }
  }
  const onSearch = (searchText) => {
    let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(regex.test(searchText)){
      dispatch(getSearchUser(searchText));
      setEmailError(false);
    }else{
      setEmailError(true);
      form.setFieldsValue({
        customer_name: "",
        surname: "",
      });
    }
  }
  useEffect(() => {
    dispatch(getModelFE());
  }, []);

  return (
    <>
      <ReactTitle title="B2B Stardue | Registrazione Bici" />
      <Header />
      <section className="dashboard-content normal-scroll">
        <div className="wrap">
          <div className="page-title">
            <h3>Registrazione garanzia</h3>
          </div>
          <div className="content userRegisterBike__form">
            <div className="userRegisterBike__wrapper">

              <Row gutter={[{ xs: 8, sm: 16, lg: 48 }, { xs: 0, sm: 16, lg: 48 }]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                  <div className="Form-wrapper">
                    <div className="headline-form with-number-step">
                      <h3><span className="step-number">1</span>Anagrafica del proprietario</h3>
                    </div>
                    {isRegisterError && (
                      <Alert message={message} type="error" showIcon />
                    )}
                    {isRegisterSucces && (
                      <Alert message={message} showIcon />
                    )}
                    <div className="form-wrapper">
                      <Form
                        form={form}
                        name="basic"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{
                          surname: null, customer_name: null, shop_name: userData.dealer.id
                        }}
                        onFinish={(e) => onFinish(e)}
                      >
                        <Row gutter={[{ xs: 8, sm: 16, lg: 24 }, { xs: 0, sm: 16, lg: 0 }]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <div className="form-group">
                              <div className="form-label">
                                <label>Email</label>
                              </div>
                              <Form.Item
                                name="email"
                                rules={[
                                  {
                                    type: 'email',
                                    message: 'The input is not valid E-mail!',
                                  },
                                  {
                                    required: true,
                                    message: 'Please input your E-mail!',
                                  },
                                ]}
                                onChange={(e) => onSearch(e.target.value)}
                              >
                                <Input />
                              </Form.Item>
                              {!emailError && isUserError && <div><span className="not-found-error">RED </span> Non esiste alcun account associato a questa mail. Inserisci nome e cognome per crearne uno.</div>}
                              {!emailError && isUserSuccess && <div><span className="email-found-success">GREEN </span></div>}
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                            <div className="form-group">
                              <div className="form-label">
                                <label>Nome</label>
                              </div>
                              <Form.Item
                                
                                name="customer_name"
                                rules={[{ required: true, message: i18n.t('Common.Form.require_name') }]}
                              >
                                <Input disabled={userFieldDisabled} />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>
                            <div className="form-group">
                              <div className="form-label">
                                <label>Cognome</label>
                              </div>
                              <Form.Item
                                name="surname"
                                rules={[{ required: true, message: i18n.t('Common.Form.require_surname') }]}
                              >
                                <Input disabled={userFieldDisabled} />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        {/* <Form.Item
                            label="Purchase Date"
                            name="purchase_date"
                            rules={[
                              { required: true, message: "Please input your Purchase Date!" },
                            ]}
                          >
                            <Input />
                          </Form.Item> */}
                        <div className="headline-form with-number-step">
                          <h3><span className="step-number">2</span>Dati della bici</h3>
                        </div>
                        <Row gutter={[{ xs: 8, sm: 16, lg: 24 }, { xs: 0, sm: 16, lg: 0 }]}>
                          <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>
                            {/* <Form.Item
                                  label={i18n.t('Common.Form.store')}
                                  name="shop_name"
                                  rules={[
                                    { required: true, message: (i18n.t('Common.Form.require_store')) },
                                  ]}
                                >
                                  <Input />
                                </Form.Item> */}
                            <div className="form-group">
                              <div className="form-label">
                                <label>Rivenditore</label>
                              </div>
                              <Form.Item
                                name="shop_name"
                              >
                                <Select defaultValue={userData.dealer.id} disabled>
                                  <Option value={userData.dealer.id}>
                                    {userData.dealer.company_name}
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>
                            <div className="form-group">
                              <div className="form-label">
                                <label>Data d'acquisto</label>
                              </div>
                              <Form.Item name="purchase_date" >
                                {/* <ConfigProvider locale={locale_en}> */}
                                <DatePicker setDay={setDate} defultDay={date} reset={setReset}/>
                                {dateError && <div>select date</div>}
                                {/* </ConfigProvider > */}
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>
                            <div className="form-group">
                              <div className="form-label">
                                <label>Modello</label>
                              </div>
                              <Form.Item name="model" rules={[
                                { required: true, message: (i18n.t('Common.Form.generic_required')) },
                              ]}>
                                <Select defaultValue="">
                                  <Select.Option value="">
                                   Seleziona un modello
                                  </Select.Option>
                                  {modelList &&
                                    modelList.length > 0 &&
                                    modelList.map((model) => {
                                      return (
                                        <Select.Option value={model.term_id}>
                                          {model.name}
                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 12 }}>
                            <div className="form-group">
                              <div className="form-label">
                                <label>Modello</label>
                              </div>
                              <Form.Item
                                name="serial_number"
                                rules={[
                                  { required: true, message: (i18n.t('Common.Form.generic_required')) },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        <div className="form-group">
                          <Dragger
                            {...draggerData}
                          >
                            <p className="ant-upload-drag-icon">
                              {/* <InboxOutlined /> */}
                            </p>
                            <p className="ant-upload-text">Prova d'acquisto</p>
                            <p className="ant-upload-hint">
                              <span>Importa un immagine: Sono accettati PDF, JPG o PNG.</span>
                            </p>
                            {/* {isFileError && <>
                                  <span className="error">
                                    {i18n.t('Common.Form.requireField')}
                                  </span></>} */}
                          </Dragger>
                        </div>
                        <Form.Item>
                          <div className="d-flex justify-content-end">
                            <Button
                              className="btn btn-primary"
                              type="primary"
                              htmlType={isRegisterLoading ? "button" : "submit"}
                              loading={isRegisterLoading}
                            >
                              <span>
                                Invia richiesta
                              </span>
                            </Button>
                          </div>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default withRouter(RegisterBike);
