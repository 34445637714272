const ROLE_BASED_CAPABILITY = {
  administrator: ["view_configure", ""],
  dealer: ["view_configure"],
  "distributors-ex-works": ["view_configure"],
  partner: ["view_configure"],
  distributor: ["view_configure"],
  agent: ["view_configure"],
  "partner-ex-works": ["view_configure"],
};

export const checkHasCapability = (cap) => {

  let hasCapability = false;
  let roles = localStorage.getItem('user-roles');
  if (roles) {
    roles = JSON.parse(roles);
    roles &&
      roles.forEach((role) => {
        if (
          ROLE_BASED_CAPABILITY[role] &&
          ROLE_BASED_CAPABILITY[role].includes(cap)
        ) {
          hasCapability = true;
        }
      });
  }

  return hasCapability;
};
