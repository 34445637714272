import React, { useLayoutEffect, useState } from "react";
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';
import Cookies from "js-cookie";
import i18n from "i18next";

const DateFormat = ({ date }) => {
  const [language, setLanguage] = useState(i18n.language);

  if (date === "" || date === undefined || language === undefined) {
    return <></>;
  }
  console.log("language", language)
  Moment.globalLocale = language;
  Moment.globalLocal = true;
  Moment.globalMoment = moment;


  try {
    const d = new Date(date);
    return <><Moment format="D MMMM Y" locale={language}>
      {d}
    </Moment></>;
  } catch (e) {
    console.log(e)
    return <></>;
  }

};
export default DateFormat;
