import {
  TRAINING_LIST_PENDING,
  TRAINING_LIST_SUCCESS,
  TRAINING_LIST_FAILURE,
  TRAINING_TYPE_PENDING,
  TRAINING_TYPE_SUCCESS,
  TRAINING_TYPE_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  total: 0,
  training: [],
  type: []
};

export default function TrainingReducer(state = initialState, action) {
  switch (action.type) {
    case TRAINING_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case TRAINING_LIST_SUCCESS:
      return Object.assign({}, state, {
        training: action.data.trainings,
        isLoading: false,
        total: action.data.total_news
      });
    case TRAINING_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.data.data.message,
        isLoading: false
      });
    case TRAINING_TYPE_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case TRAINING_TYPE_SUCCESS:
      return Object.assign({}, state, {
        type: action.data,
        isLoading: false
      });
    case TRAINING_TYPE_FAILURE:
      return Object.assign({}, state, {
        message: action.data.data.message,
        isLoading: false
      });
    default:
      return state;
  }
}