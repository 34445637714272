import React, { useEffect } from "react";
import StarDueCollapse from "../../components/StarDueCollapse/StarDueCollapse";
import { Collapse, Space, Radio } from "antd";
import i18next from "i18next";
const { Panel } = Collapse;
const GroupSet = ({
  onChangeGroup,
  stepId,
  groupId,
  configuratorData,
  setCassette,
  cassette,
  setCrankArm,
  crankArm,
  setChainRings,
  chainRings,
  configuratorType,
  setGroupName,
  setConfiguratorIdURL
}) => {
  useEffect(() => {
    let data = configuratorData.groupset["items"].filter(
      (item, index) => index === groupId
    );
    if (data && data[0]) {
      setGroupName(data[0].name);
    }
  }, [configuratorData.groupset["items"], groupId]);
  if (groupId === undefined) {
    return <></>;
  }
  const renderName = (id) => {
    let data = configuratorData.groupset["items"].filter(
      (item, index) => index === groupId
    );
    if (data && data[0]) {
      return data[0].name;
    }
  };

  return (
    <>
      <StarDueCollapse
        title={i18next.t("Pages.Configurator.Step.Header.Groupset")}
        stepId={stepId}
        active={true}
        value={renderName(groupId)}
      >
        <div className="step-body">
          <div className="RadioGroupWithImage GroupsetRadio">
            <Radio.Group
              className="Radio-img"
              onChange={(e) => {
                setConfiguratorIdURL(); onChangeGroup(e);
              }}
              value={groupId}
            >
              <Space direction="vertical">
                {configuratorData.groupset["items"].map((group, index) => {
                  return !group.is_no_groupset ? (
                    <Radio
                      key={group.id + index}
                      value={index}
                      id={group.id}
                      slug={group.slug}
                      groupName={group.name}
                      display_slug={group.display_slug}
                    >
                      <div className="Flex-box">
                        <h4>{group.name}</h4>
                        {/* <span>+ 320€</span> */}
                      </div>
                      <div className="Radio-custom-img">
                        <img src={group.img_groupset} alt="Groupset image" />
                      </div>
                      <div className="Radio-custom-img-text">
                        <p>
                          {i18next.t("Pages.Configurator.AvailableFrom")}{" "}
                          {group.availability}
                        </p>
                      </div>
                    </Radio>
                  ) : (
                    <React.Fragment key={index}></React.Fragment>
                  );
                })}
              </Space>
            </Radio.Group>
          </div>
        </div>
        {!(
          configuratorType === "frame_kit" || configuratorType === "build_kit"
        ) &&
          configuratorData &&
          configuratorData.groupset &&
          configuratorData.groupset["items"] &&
          configuratorData.groupset["items"][groupId] && (
            <div className="step-item step-child-wrapper">
              <div className="step-body pt-0">
                <div className="step-inner">
                  <div className="step-item step-child">
                    <div className="step-header">
                      <div className="label">
                        <p>
                          {i18next.t("Pages.Configurator.Step.Header.Options")}
                        </p>
                      </div>
                    </div>
                    <div className="step-child-container">
                      <Collapse expandIconPosition="right">
                        <Panel
                          className="child-accordion"
                          header="Cassette"
                          key="1"
                        >
                          <div className="step-body step-childreen-accordion-inner">
                            <Radio.Group
                              onChange={(e) => setCassette(e.target.value)}
                              value={cassette}
                              className={"Radio-subtext ColumnGroup"}
                            >
                              <Space direction="vertical">
                                {configuratorData.groupset["items"][
                                  groupId
                                ].cassette["items"].map((cas, keyIndex) => {
                                  return (
                                    <Radio key={cas.id + keyIndex} value={cas.id} >
                                      {cas.name}
                                    </Radio>
                                  );
                                })}
                              </Space>
                            </Radio.Group>
                          </div>
                        </Panel>
                        <Panel
                          className="child-accordion"
                          header="Crank Arm"
                          key="2"
                        >
                          <div className="step-body step-childreen-accordion-inner">
                            <Radio.Group
                              onChange={(e) => setCrankArm(e.target.value)}
                              value={crankArm}
                              className={"Radio-subtext ColumnGroup"}
                            >
                              <Space direction="vertical">
                                {configuratorData.groupset["items"][
                                  groupId
                                ].crank_arm["items"].map((crank, keyIndex) => {
                                  return (
                                    <Radio key={crank.id + keyIndex} value={crank.id}>
                                      {crank.name}
                                    </Radio>
                                  );
                                })}
                              </Space>
                            </Radio.Group>
                          </div>
                        </Panel>
                        <Panel
                          className="child-accordion"
                          header="Chain Rings"
                          key="3"
                        >
                          <div className="step-body step-childreen-accordion-inner">
                            <Radio.Group
                              onChange={(e) => setChainRings(e.target.value)}
                              value={chainRings}
                              className={"Radio-subtext ColumnGroup"}
                            >
                              <Space direction="vertical">
                                {configuratorData.groupset["items"][
                                  groupId
                                ].chain_rings["items"].map((chain, keyIndex) => {
                                  return (
                                    <Radio key={chain.id + keyIndex} value={chain.id}>
                                      {chain.name}
                                    </Radio>
                                  );
                                })}
                              </Space>
                            </Radio.Group>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </StarDueCollapse>
    </>
  );
};

export default GroupSet;
