import { request } from "../services/apiService";
import {
  FETCH_PRODUCT_STOCK_LIST_PENDING,
  FETCH_PRODUCT_STOCK_LIST_SUCCESS,
  FETCH_PRODUCT_STOCK_LIST_FAILURE
} from "./actionTypes";

export const getProductStockList = (page, size, search) => dispatch => {
  dispatch({ type: FETCH_PRODUCT_STOCK_LIST_PENDING });
  var url = "v1/db/products/stock?page=" + page + "&size=" + size + "&search=" + search;
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: FETCH_PRODUCT_STOCK_LIST_SUCCESS,
        data: res,
      });
    },
    (error) => {
      dispatch({
        type: FETCH_PRODUCT_STOCK_LIST_FAILURE,
        data: error.response,
      });
    }
  );
}
