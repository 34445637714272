import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userResetPassword } from "../../actions/resetPasswordactions";
import ResetPassword from "../../components/ResetPassword";
import { withRouter } from "react-router-dom";
import { ReactTitle } from 'react-meta-tags';
import i18next from "i18next";

const ResetPasswordContainer = (props) => {
  const params = new URLSearchParams(props.location.search);
  const dispatch = useDispatch();
  const { isLoading, isError, message, isSuccess } = useSelector(state => {
    return state.resetPassword
  });
  const onSubmitClick = (values) => {
    console.log("params", props.location.search, params, params.get('key'))
    values['key'] = params.get('key');
    dispatch(userResetPassword(values));
  };

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.NewPassword')} />
      <ResetPassword
        onSubmitClick={onSubmitClick}
        isLoading={isLoading}
        isError={isError}
        message={message}
        isSuccess={isSuccess}
        email={params.get('email')}
      />
    </>
  );
};

export default withRouter(ResetPasswordContainer);
