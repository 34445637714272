import React from "react";
import ApplicationRouter from "./router/ApplicationRouter";
import { Switch, Route, Redirect, Router as ReactRouter } from "react-router-dom";
import history from "./history";
import routes from "./router/routes";
import "./translations/i18n";
import "./App.scss";
import Cookie from "js-cookie";
import { i18n } from "./translations/i18n";
import i18next from "i18next";

const base = '/:locale(en|it|es|de|fr)';
function App() {
  return (
    <div className={"App " + (Cookie.get('platformkey') == '7368496012' ? "bassobikes" : "leecougan")}>
      <div className="App-Mobile__notification">
        <div className="logo">
          <div className="logo-header">
            <span className="s2">
              <svg xmlns="http://www.w3.org/2000/svg" width="251.216" height="30.766" viewBox="0 0 251.216 30.766">
                <g id="s2" transform="translate(0.002)">
                  <path id="Path_53" data-name="Path 53" d="M339.752,288.8a23.755,23.755,0,0,0,1.71-3.289H305.557a20.867,20.867,0,0,0-1.38,2.7A17.3,17.3,0,0,0,302,286.76c-2.492-1.165-6.148-1.5-9.655-1.71h-4.407c-5.918.311-11.484.934-15.389,3.485-1.99,1.3-3.959,4.2-1.118,5.784,2.563,1.426,7.185,1.666,10.917,2.04a86.01,86.01,0,0,1,8.615,1.053c.835.174,2,.539,2.1,1.118.243,1.352-2.059,2.087-3.286,2.367a34.244,34.244,0,0,1-10.59.461c-2.3-.2-4.983-.6-4.6-2.893h-8.743c-1.043,1.557.044,3.224,1.184,3.943,2.18,1.377,5.743,1.85,9.076,2.105,6.662.514,15.084-.053,20.12-1.775a14.6,14.6,0,0,0,3.738-1.841c1.37-.978,3.653-3.345,1.71-5.064-2.134-1.887-7.528-2.146-11.178-2.563-2.025-.234-4.146-.43-6.245-.657-1.626-.178-5.133-.28-5.261-1.579-.118-1.171,1.813-1.884,2.959-2.18a28.162,28.162,0,0,1,9.468-.458c2.158.246,4.174.533,4.208,2.694h8.746a3.145,3.145,0,0,0,.131-2.3h12.561c.106.34-.193.651-.33.934-2.392,4.725-4.853,9.783-7.3,14.533h9.207c2.722-5.058,5.136-10.428,7.889-15.455Z" transform="translate(-265.42 -285.05)" fill="#fff" />
                  <path id="Path_54" data-name="Path 54" d="M567.805,290.6c-2-3.706-9.032-4.077-14.6-4.077H534.519c-3.2,6-6.086,12.325-9.344,18.28-1.94-.234-.7-2.3-.065-3.551.5-1.009,1.1-2.109.853-3.093-.368-1.479-2.271-1.433-3.738-2.037a16.756,16.756,0,0,0,5.588-1.576c1.473-.713,3.448-1.772,3.616-3.619.153-1.673-1.121-2.492-2.3-3.024-3.273-1.5-8.235-1.38-12.77-1.38H497.483c-2.641,5.27-5.254,10.571-7.958,15.782-1.744-5.245-3.457-10.527-5.192-15.782H473.706c-2.115,1.576-4.276,3.174-6.444,4.8-5.955,4.463-12.047,9.032-17.949,13.415-.237.171-.732.311-.595.526h7.1a11.809,11.809,0,0,0,2.37,0,5.108,5.108,0,0,0,1.314-.934c.934-.726,1.645-1.333,2.563-2.037a4.485,4.485,0,0,1,1.383-.934,22.646,22.646,0,0,1,4.008,0h12.166c.258,1.321.586,2.573.857,3.881h16.635c1.265-2.492,2.492-5.021,3.815-7.431,4.027.153,8.543-.23,12.558.065,1.156.084,2.84.4,3.027,1.246.237,1.062-.623,2.18-.987,2.959a5.033,5.033,0,0,0-.657,3.158h16.635c5.438,0,11.185.311,16.573-.131,7.519-.6,13.816-2.872,17.753-7.235,1.386-1.536,3.354-4.737,1.975-7.3m-100.29,7.559c-.1-.162.673-.607.987-.853,2.638-2.062,5.585-4.513,8.285-6.575.744,2.323,1.168,4.983,1.844,7.363-3.572.156-7.431.025-11.116.065m52.877-4.575c-1.944,1.124-5.173,1.118-8.409,1.118h-9.468c.729-1.722,1.592-3.317,2.432-4.93,4.507.327,9.263-.461,13.872.065,1.025.118,2.934.467,3.093,1.514s-.866,1.869-1.511,2.233m38.2.33c-.623,3.815-4.473,6.41-8.1,7.431-4.407,1.246-9.705.539-14.794.723,2.065-4.09,4.077-8.241,6.179-12.3h9.6c3.426,0,7.681.542,7.1,4.142" transform="translate(-391.613 -286.064)" fill="#fff" />
                  <path id="Path_55" data-name="Path 55" d="M866.3,287.253c.1-.2.417-.439.265-.723h-9.272c-1.682,3.317-3.4,6.634-5.064,9.995a15.468,15.468,0,0,1-1.775,3.286c-1.772,2.018-5.382,2.457-9.207,2.632-2.679.121-6.8-.259-7.164-2.236-.243-1.317.825-2.7,1.314-3.681,1.6-3.186,3.149-6.13,4.672-9.207.112-.224.371-.514.262-.788h-9.275c-1.6,2.872-3.4,6.759-4.983,9.929a19.29,19.29,0,0,0-1.579,3.286c-1.065,3.906,3.7,4.927,7.1,5.46a51.572,51.572,0,0,0,9.914.47c6.8-.311,12.972-1.146,16.838-4.208,1.719-1.364,2.51-3.192,3.485-5.13,1.526-3.037,2.95-6.114,4.469-9.073" transform="translate(-650.261 -286.069)" fill="#fff" />
                  <path id="Path_56" data-name="Path 56" d="M947,286.53c-3.261,6.142-6.335,12.458-9.468,18.741h33.4a26.523,26.523,0,0,0,1.775-3.289h-24.4c-.087-.377.274-.738.461-1.118.582-1.156,1.184-2.56,1.841-3.681h21.491c.623-1.075,1.134-2.243,1.71-3.354H952.39c-.062-.277.165-.654.33-.987a30.274,30.274,0,0,1,1.645-3.024H974.74a13.73,13.73,0,0,0,2.89,0c.782-.293,1.128-2.722,1.778-3.221v-.065Z" transform="translate(-728.195 -286.069)" fill="#fff" />
                  <path id="Path_57" data-name="Path 57" d="M705.684,362.442c.623-1.246,2.046-4.1,2.046-4.1h-3.772s-2.6,5.176-2.906,5.522c-.726.825-2.18,1.006-3.759,1.075-1.093.05-2.778-.106-2.925-.913a2.544,2.544,0,0,1,.389-1.5c.654-1.3,2.274-4.18,2.274-4.18h-3.8s-2.675,5.039-2.8,5.5c-.436,1.595,1.511,2.009,2.9,2.227a20.723,20.723,0,0,0,4.049.187c2.775-.125,5.295-.467,6.874-1.716a5.637,5.637,0,0,0,1.423-2.093" transform="translate(-558.016 -335.513)" fill="#fff" />
                  <path id="Path_58" data-name="Path 58" d="M607.548,360.106c.062-.682-.458-1.012-.934-1.246-1.336-.623-3.364-.564-5.208-.564h-7.712c-1.078,2.152-2.772,5.522-3.875,7.653h3.716c.514-1.015,1.557-3.034,1.557-3.034s3.485-.1,5.127.025c.47.034,1.159.165,1.246.511.1.433-.258.891-.4,1.208a2.059,2.059,0,0,0-.268,1.289h4.149l.065-.19c-.791-.1-.283-.934-.025-1.448a1.994,1.994,0,0,0,.349-1.246c-.153-.623-.934-.586-1.532-.835a6.793,6.793,0,0,0,2.283-.642c.6-.293,1.408-.723,1.476-1.479m-4.51,1.075a7.645,7.645,0,0,1-3.426.458h-3.89l.994-2.015a48.848,48.848,0,0,1,5.665.028c.417.047,1.2.19,1.246.623s-.355.76-.623.913" transform="translate(-488.783 -335.482)" fill="#fff" />
                  <path id="Path_59" data-name="Path 59" d="M655.332,357.529a21.243,21.243,0,0,0-4.049-.187c-2.772.125-5.295.467-6.871,1.716a20.975,20.975,0,0,0-2.348,4.049c-.433,1.595,1.511,2.009,2.9,2.227a20.744,20.744,0,0,0,4.049.187c2.772-.125,5.295-.467,6.871-1.716a16.38,16.38,0,0,0,2.348-4.049c.433-1.592-1.511-2.009-2.9-2.227m-1.047,2.492c-.265.517-.48.934-.9,1.782a6.177,6.177,0,0,1-.726,1.342c-.723.825-2.2,1.006-3.759,1.075-1.093.05-2.775-.106-2.925-.913-.1-.536,1.355-3.115,1.492-3.367a1.824,1.824,0,0,1,.156-.209c.723-.822,2.2-1,3.759-1.075,1.093-.05,2.775.106,2.925.913a1.057,1.057,0,0,1-.025.452" transform="translate(-524.713 -334.808)" fill="#fff" />
                  <path id="Path_60" data-name="Path 60" d="M752.2,358.9c-1.339-.623-5.208-.564-5.208-.564h-7.706s-2.775,5.522-3.878,7.653h3.716c.517-1.015,1.012-2.049,1.557-3.034,1.645.059,4.744.025,6.388.025,1.308,0,5.569-.28,6.067-2.847.134-.67-.458-1.012-.934-1.246m-3.572,2.308a7.631,7.631,0,0,1-3.426.458h-3.878c.311-.7.994-2.015.994-2.015a48.848,48.848,0,0,1,5.665.028c.417.047,1.2.19,1.261.623s-.355.76-.623.913" transform="translate(-589.028 -335.51)" fill="#fff" />
                  <path id="Path_61" data-name="Path 61" d="M560.734,360.935h-7.242l-.642,1.246h3.389a3.7,3.7,0,0,1-.539.934c-.723.825-2.18,1.006-3.756,1.075-1.1.05-2.778-.106-2.928-.913-.1-.536,1.355-3.115,1.492-3.367a1.821,1.821,0,0,1,.156-.209c.726-.822,2.18-1,3.759-1.075,1.093-.05,2.775.106,2.925.913l3.921.19c.433-1.595-1.511-2.012-2.9-2.23a21.218,21.218,0,0,0-4.049-.187c-2.775.125-5.295.467-6.874,1.716a20.8,20.8,0,0,0-2.345,4.049c-.436,1.6,1.511,2.012,2.9,2.23a20.954,20.954,0,0,0,4.049.19c2.775-.128,5.295-.47,6.874-1.719a12.207,12.207,0,0,0,1.806-2.865" transform="translate(-457.951 -334.788)" fill="#fff" />
                </g>
              </svg>
            </span>
          </div>
        </div>
        <div className="App-mobile__content">
          <p>{i18next.t('Common.NoMobileView')}</p>
        </div>
      </div>
      <div className="App-Container">
        <ReactRouter history={history}>
          <Switch>
            <Route exact path="/">
              <Redirect to={"/" + i18n.language + "/dashboard"} />
            </Route>
            <Route exact path="/en">
              <Redirect to={"/en/dashboard"} />
            </Route>
            <Route exact path="/it">
              <Redirect to={"/it/dashboard"} />
            </Route>
            <Route exact path="/de">
              <Redirect to={"/de/dashboard"} />
            </Route>
            <Route exact path="/fr">
              <Redirect to={"/fr/dashboard"} />
            </Route>
            <Route exact path="/es">
              <Redirect to={"/es/dashboard"} />
            </Route>
            {routes.map((route, index) => {
              let routeObj = { ...route };
              routeObj.path = base + routeObj.path;
              return <ApplicationRouter key={index} {...routeObj} />;
            })}
          </Switch>
        </ReactRouter>
      </div>
    </div>
  );
}

export default App;
