import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userForgotPassword } from "../../actions/forgotPasswordactions";
import ForgotPassword from "../../components/ForgotPassword";
import { ReactTitle } from 'react-meta-tags';
import i18next from "i18next";

const ForgotPasswordContainer = ({
}) => {

  const dispatch = useDispatch();
  const { isLoading, isError, isSuccess, message } = useSelector(state => {
    return state.forgetPassword
  });


  const onSubmitClick = (values) => {
    dispatch(userForgotPassword(values));
  };

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.ResetPassword')} />
      <ForgotPassword
        onSubmitClick={onSubmitClick}
        isLoading={isLoading}
        isError={isError}
        message={message}
        isSuccess={isSuccess}
      />
    </>
  );
};

export default ForgotPasswordContainer;
