import React, { Component, useState } from 'react';

const Sortable = ({ columnName, orderBy, setOrderBy, defultSorting }) => {

  const sortingData = () => {
    if (orderBy.order) {
      setOrderBy({
        "orderby": columnName,
        "order": orderBy.order == "ASC"
          ? 'DESC'
          : 'ASC'
      })
    } else {
      setOrderBy({
        "orderby": columnName,
        "order": defultSorting
      })
    }
  }
  return (
    <>
      <span className="sortable" onClick={sortingData}>
        <>
          {orderBy.orderby != columnName && <span className="sort-icon">
            <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.69 48.22"><path d="M6.74,23.13H44a1.72,1.72,0,0,0,1.72-1.7,1.74,1.74,0,0,0-.5-1.22L26.57,1.28a1.75,1.75,0,0,0-2.45,0L5.51,20.21A1.75,1.75,0,0,0,5,21.42a1.77,1.77,0,0,0,.51,1.21,1.72,1.72,0,0,0,1.21.5Zm18.6-18.2L39.85,19.7h-29Z" transform="translate(-5 -0.78)" style={{ fill: '#666' }} /><path d="M5.51,29.56,24.12,48.49a1.69,1.69,0,0,0,1.22.51,1.72,1.72,0,0,0,1.23-.51l18.6-18.93a1.69,1.69,0,0,0,.49-1.22,1.74,1.74,0,0,0-.5-1.21A1.71,1.71,0,0,0,44,26.64H6.72a1.69,1.69,0,0,0-1.21.49,1.73,1.73,0,0,0,0,2.43Zm34.34.51L25.34,44.83,10.84,30.07Z" transform="translate(-5 -0.78)" style={{ fill: '#666' }} /></svg>
          </span>}
          {orderBy.order == 'ASC' && orderBy.orderby == columnName && <span>
            <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.66 22.36"><path d="M5.51,29.56,24.12,48.49a1.69,1.69,0,0,0,1.22.51,1.72,1.72,0,0,0,1.23-.51l18.6-18.93a1.69,1.69,0,0,0,.49-1.22,1.74,1.74,0,0,0-.5-1.21A1.71,1.71,0,0,0,44,26.64H6.72a1.69,1.69,0,0,0-1.21.49,1.73,1.73,0,0,0,0,2.43Zm34.34.51L25.34,44.83,10.84,30.07Z" transform="translate(-5 -0.78)" style={{ fill: '#666' }} /></svg>
          </span>}
          {orderBy.order == 'DESC' && orderBy.orderby == columnName && <span>
            <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.66 22.35"><path d="M6.74,23.13H44a1.72,1.72,0,0,0,1.72-1.7,1.74,1.74,0,0,0-.5-1.22L26.57,1.28a1.75,1.75,0,0,0-2.45,0L5.51,20.21A1.75,1.75,0,0,0,5,21.42a1.77,1.77,0,0,0,.51,1.21,1.72,1.72,0,0,0,1.21.5Zm18.6-18.2L39.85,19.7h-29Z" style={{ fill: "#666" }} /></svg>
          </span>}
        </>
        {/* {orderBy.order && orderBy.orderby == columnName && <>{orderBy.order}</>} */}
      </span>

    </>
  )
}

export default Sortable;