import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Select, Input, Upload, notification, Steps, Pagination, Radio } from 'antd';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getTickets, createTicket } from "../../actions/ticketActions";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import paginationLabel from "../../components/PaginationRender";
import DateFormat from "../../components/DateFormat";
import { ReactTitle } from 'react-meta-tags';
import Cookies from "js-cookie";

const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;
const { Dragger } = Upload;


const Ticket = () => {
  const dispatch = useDispatch();
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(12);
  const [currentStepSize, setCurrentSizeStep] = useState(0);
  const [typeSelected, setTypeSelected] = useState('');
  const [topicSelected, setTopicSelected] = useState('');
  const { isLoading, tickets, total, isCreateLoading, ticketCreated } = useSelector((state) => {
    return state.ticket;
  });

  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [step1Disabled, setstep1Disabled] = useState(false);
  const [step2Disabled, setstep2Disabled] = useState(true);
  const [step3Disabled, setstep3Disabled] = useState(true);
  const [selectedCata, setSelectedSelector] = useState("all");
  const [serialNumber, setSerialNumber] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState([]);
  const [isFileError, setIsFileError] = useState(false);
  const [categories, setCategories] = useState([
    { label: "Tutti gli stati", key: 'all' },
    { label: "Aperto", key: 'open' },
    { label: "Chiuso", key: 'close' },
  ]);
  const [topickError, setTopicError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [serialNumberError, setSerialNumberError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);


  const [level, setLevel] = useState('');
  const [rideWithGear, setRideWithGear] = useState('');
  const [discipline, setDiscipline] = useState('');
  const [frameSize, setFrameSize] = useState('');
  const [weeklyTrainingHours, setWeeklyTrainingHours] = useState('');


  const dataOption = {
    "7894210751": [ // LeeCougan
      {
        "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Infomations.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.information')}</p></div></div>,
        "key": "information",
        "data": [
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/assited_purchace.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.assisted_purchase')}</p></div></div>,
            "key": "assisted_purchase"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Lee_Cougan.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.leecougan')}</p></div></div>,
            "key": "leecougan"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/microtech.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.microtech')}</p></div></div>,
            "key": "microtech"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/other.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.other')}</p></div></div>,
            "key": "other"
          }
        ]
      },
      {
        "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/e-com_assistance.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.e_commerce_assistance')}</p></div></div>,
        "key": "e_commerce_assistance",
        "data": [
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/availability.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.availability')}</p></div></div>,
            "key": "availability"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/other.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.other')}</p></div></div>,
            "key": "other"
          }
        ]
      },
      {
        "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/BASSO_Dealer_supprot.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.support_dealer')}</p></div></div>,
        "key": "support_dealer",
        "data": [
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Lee_Cougan.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.leecougan_product_assistance')}</p></div></div>,
            "key": "leecougan_product_assistance"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Logo_innova_Black.png"} /></div><div className="Radio-label"><p>Rampage Innova Tuning</p></div></div>,
            "key": "rampage_innova_tuning"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/microtech.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.on_microtech_product_assistance')}</p></div></div>,
            "key": "on_microtech_product_assistance"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/other.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.other')}</p></div></div>,
            "key": "other"
          }
        ]
      },
      {
        "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Warranty.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.warranty_request')}</p></div></div>,
        "key": "warranty_request",
        "data": [
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Lee_Cougan.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.leecougan')}</p></div></div>,
            "key": "leecougan"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/microtech.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.microtech')}</p></div></div>,
            "key": "microtech"
          }
        ]
      },
    ],
    "7368496012": [ // Basso
      {
        "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Infomations.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.information')}</p></div></div>,
        "key": "information",
        "data": [
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/assited_purchace.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.assisted_purchase')}</p></div></div>,
            "key": "assisted_purchase"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Basso.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.basso')}</p></div></div>,
            "key": "basso"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/microtech.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.microtech')}</p></div></div>,
            "key": "microtech"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/other.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.other')}</p></div></div>,
            "key": "other"
          }
        ]
      },
      {
        "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/e-com_assistance.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.e_commerce_assistance')}</p></div></div>,
        "key": "e_commerce_assistance",
        "data": [
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/availability.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.availability')}</p></div></div>,
            "key": "availability"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/other.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.other')}</p></div></div>,
            "key": "other"
          }
        ]
      },
      {
        "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/BASSO_Dealer_supprot.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.support_dealer')}</p></div></div>,
        "key": "support_dealer",
        "data": [
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Basso.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.basso_product_assistance')}</p></div></div>,
            "key": "basso_product_assistance"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/microtech.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.on_microtech_product_assistance')}</p></div></div>,
            "key": "on_microtech_product_assistance"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/other.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.other')}</p></div></div>,
            "key": "other"
          }
        ]
      },
      {
        "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Warranty.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.warranty_request')}</p></div></div>,
        "key": "warranty_request",
        "data": [
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/Basso.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.basso')}</p></div></div>,
            "key": "basso"
          },
          {
            "label": <div className="Radio-boxImage"><div className="Radio-image"><img src={"../images/ticket/microtech.png"} /></div><div className="Radio-label"><p>{i18next.t('Pages.SupportArea.Popup.Options.microtech')}</p></div></div>,
            "key": "microtech"
          }
        ]
      },
    ]
  }
  const draggerData = {
    name: 'file',
    multiple: true,
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: 'Bearer ' + userData.token,

    },
    action: `${process.env.REACT_APP_API_URL}v1/content/file_upload`,
    onSuccess: function (response, file_data) {
      setFile([]);
      let temp_file = file;
      temp_file.push(response.data.image);
      setFile(temp_file)
      setIsFileError(false)
    },
    onChange(info) {
      // console.log("info", info)
      // info.file.status = 'done';
      // info.fileList[0].status = 'done';
      info.fileList.map((fileData, index) => {
        if (fileData.name == info.file.name) {
          info.file.status = 'done';
          info.fileList[index].status = 'done';
        }
      })
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (typeSelected == '') {
      setstep2Disabled(true);
    } else {
      setstep2Disabled(false);
    }
  }, [typeSelected])
  useEffect(() => {
    if (topicSelected == '') {
      setstep3Disabled(true);
    } else {
      setstep3Disabled(false);
    }
  }, [topicSelected])
  const [isPopupOpen, setPopupOpen] = React.useState(false);

  useEffect(() => {
    let userID = 0;
    if (localStorage.getItem("agent-selected-user")) {
      try {
        let userDataTemp = JSON.parse(localStorage.getItem('agent-selected-user'))
        userID = userDataTemp.id;
      } catch (e) {
        console.log(e)
      }
    }
    dispatch(getTickets(current, pageSize, selectedCata, userID));
  }, [current]);

  const applyFilter = () => {
    let userID = 0;
    if (localStorage.getItem("agent-selected-user")) {
      try {
        let userDataTemp = JSON.parse(localStorage.getItem('agent-selected-user'))
        userID = userDataTemp.id;
      } catch (e) {
        console.log(e)
      }
    }
    dispatch(getTickets(1, pageSize, selectedCata, userID));
  }

  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  const handleClose = () => {
    setPopupOpen(false);
  };

  const onFinishFailed = (e) => {
    console.log("onFinishFailed", e)
  }

  const onFinish = () => {

    let userID = 0;
    if (localStorage.getItem("agent-selected-user")) {
      try {
        let userDataTemp = JSON.parse(localStorage.getItem('agent-selected-user'))
        userID = userDataTemp.id;
      } catch (e) {
        console.log(e)
      }
    }
    dispatch(createTicket({
      "type": "ticket",
      "request": typeSelected,
      "description": description,
      "orderid": "",
      "products": "",
      "topic": topicSelected,
      "serial_number": serialNumber,
      "file": file,
      "level": level,
      "ridewith_gear": rideWithGear,
      "discipline": discipline,
      "frame_size": frameSize,
      "weekly_training_hours": weeklyTrainingHours,
      "dealer_id": userID
    }))
  }

  useEffect(() => {
    console.log(typeSelected, topicSelected, description, file, file.length, serialNumber);

    if (typeSelected == 'support_dealer' && topicSelected == 'rampage_innova_tuning' && rideWithGear != '' && level != '' && discipline != '' && frameSize != '' && weeklyTrainingHours != '') {
      setIsSubmit(false);
    } else if ((typeSelected == 'information' || typeSelected == 'support_dealer' || typeSelected == 'e_commerce_assistance') && topicSelected != '' && description != '') {
      setIsSubmit(false);
    } else if (typeSelected == 'warranty_request' && topicSelected != '' && description != '' && file.length > 0 && serialNumber != '') {
      setIsSubmit(false);
    } else {
      setIsSubmit(true);
    }
  }, [typeSelected, topicSelected, description, file, serialNumber, level, rideWithGear, discipline, frameSize, weeklyTrainingHours])


  useEffect(() => {
    if (ticketCreated === true) {
      handleClose();
      notification['success']({
        message: "Ticket Created successfully"
      });
      setSelectedSelector('all');
      let userID = 0;
      if (localStorage.getItem("agent-selected-user")) {
        try {
          let userDataTemp = JSON.parse(localStorage.getItem('agent-selected-user'))
          userID = userDataTemp.id;
        } catch (e) {
          console.log(e)
        }
      }
      dispatch(getTickets(1, pageSize, 'all',userID));
    }
  }, [ticketCreated]);


  const disciplineOption = [
    "Cross-Country Marathon",
    "Cross-Country Olimpic",
    "Cross-Country Eliminator",
    "Cross-Country Short Circuit",
  ];

  const levelOption = {
    "Amateur": i18next.t('Pages.SupportArea.Popup.Options.amateur'),
    "Agonist": i18next.t('Pages.SupportArea.Popup.Options.agonist'),
    "Pro": i18next.t('Pages.SupportArea.Popup.Options.pro')
  };

  const sizeOption = ["S", "M", "L"];

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.SupportArea')} />
      <Header />
      <div className="dashboard-content prd-list">
        <div className="wrap">
          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-side-container">
                {/* <div className="filter-blk">
                  <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>

                  <div className="form-wrapper">
                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.State")}</label>
                      </div>
                      <Select dropdownClassName="dropdown-select-s2" value={selectedCata} onChange={(e) => {
                        console.log(e)
                        setSelectedSelector(e)
                      }}>
                        {categories.map((item, key) => (
                          <Option key={key} value={item['key']}>{item['label']}</Option>
                        ))}
                      </Select>

                    </div>
                  </div>
                </div> */}
                <div className="filter-submit open-ticket">
                  {/* <Button loading={isLoading} onClick={applyFilter} className="btn btn-primary btn-submit">
                    {i18next.t("Common.FiltersSidebar.Submit")}
                  </Button> */}
                  <Button className="btn btn-secondary btn-submit"
                    onClick={handleClickOpen}
                  >
                    {i18next.t("Common.FiltersSidebar.OpenTicket")}
                  </Button>
                </div>
              </div>
              <Modal
                className="modal-s2 modal-ticket"
                visible={isPopupOpen}
                onClose={(e) => {
                  handleClose();
                }}
                onCancel={(e) => {
                  handleClose();
                }}
                width={1200}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                footer={null}
                closeIcon={<svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.99 222.99"><path d="M461.5,192.25l-23.74-23.74L350,256.26l-87.75-87.75-23.74,23.74L326.26,280l-87.75,87.76,23.74,23.74L350,303.75l87.76,87.75,23.74-23.74L373.75,280Z" transform="translate(-238.51 -168.51)" /></svg>}
              >
                <div className="modal-header">
                  <h2>
                    {i18next.t('Pages.SupportArea.Popup.Common.Title')}
                  </h2>
                </div>
                <div className="modal-content">
                  <Steps
                    current={currentStepSize}
                    onChange={(e) => {
                      setCurrentSizeStep(e);
                    }}
                  >
                    <Step key="0" title={i18next.t('Pages.SupportArea.Popup.Common.Step1')} disabled={step1Disabled} />
                    <Step key="1" title={i18next.t('Pages.SupportArea.Popup.Common.Step2')} disabled={step2Disabled} />
                    <Step key="2" title={i18next.t('Pages.SupportArea.Popup.Common.Step3')} disabled={step3Disabled} />
                  </Steps>
                  {currentStepSize == 0 && (
                    <>
                      <div className="Modal-step_content">
                        <div className="modal-info"><p>{i18next.t('Pages.SupportArea.Popup.Step1.Content')}</p></div>
                        <Radio.Group onChange={(e) => { setTypeSelected(e.target.value) }} value={typeSelected}>
                          {dataOption[Cookies.get("platformkey")].map((data) => (
                            <Radio value={data.key}>{data.label}</Radio>
                          ))}
                        </Radio.Group>
                      </div>
                      <div className="Modal-footer">
                        <Button type="primary" onClick={() => setCurrentSizeStep(1)} disabled={step2Disabled}>{i18next.t('Pages.SupportArea.Popup.Common.Next')}</Button>
                      </div>
                    </>
                  )}

                  {currentStepSize == 1 && (
                    <>
                      <div className="Modal-step_content">
                        <div className="modal-info"><p>{i18next.t('Pages.SupportArea.Popup.Step2.Content')}</p></div>
                        <Radio.Group onChange={(e) => {
                          setTopicSelected(e.target.value)
                        }} value={topicSelected}>
                          {dataOption[Cookies.get("platformkey")].map((data) => (
                            data.key == typeSelected && data.data.map((item) => (
                              <Radio value={item.key}>{item.label}</Radio>
                            ))
                          ))}
                        </Radio.Group>
                      </div>
                      <div className="Modal-footer">
                        <Button type="primary" onClick={() => setCurrentSizeStep(2)} disabled={step3Disabled}>{i18next.t('Pages.SupportArea.Popup.Common.Next')}</Button>
                      </div>

                    </>
                  )}

                  {currentStepSize == 2 && (
                    <>
                      <div className="Modal-step_content">
                        <div className="modal-info"><p>{i18next.t('Pages.SupportArea.Popup.Step3.Content')}</p></div>

                        <div className="form-wrapper">
                          {typeSelected == 'warranty_request' && <>
                            <div className="form-group">
                              <div className="form-label">
                                <label>{i18next.t('Common.Form.serial_number')}*</label>
                              </div>
                              <Input placeholder="" onChange={(e) => { setSerialNumber(e.target.value); setSerialNumberError(false) }} />
                            </div>
                          </>}
                          {typeSelected == 'warranty_request' && serialNumberError && <div className="error">{i18next.t('Common.Form.generic_required')}</div>}
                          {topicSelected != 'rampage_innova_tuning' && <div className="form-group">
                            <div className="form-label">
                              <label>{i18next.t('Common.Form.otherInformation')}*</label>
                            </div>
                            <TextArea placeholder="" onChange={(e) => { console.log(e.target.value); setDescription(e.target.value); setDescriptionError(false) }} rows={4} />
                          </div>}
                          {descriptionError && <div className="error">{i18next.t('Common.Form.generic_required')}</div>}


                          {topicSelected == 'rampage_innova_tuning' && <>
                            <Row gutter={[24, 6]}>
                              <Col
                                span={24}
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                lg={{ span: 24 }}
                              >
                                <div className="form-group">
                                  <div className="form-label">
                                    <label>{i18next.t('Pages.SupportArea.Popup.Options.rider_weight')}*</label>
                                  </div>
                                  <Input placeholder={i18next.t('Pages.SupportArea.Popup.Options.rider_weight_placeholder')} onChange={(e) => { setRideWithGear(e.target.value); }} rows={4} />
                                </div>
                              </Col>
                              <Col
                                span={24}
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                lg={{ span: 12 }}
                              >
                                <div className="form-group">
                                  <div className="form-label">
                                    <label>{i18next.t('Pages.SupportArea.Popup.Options.discipline')}*</label>
                                  </div>
                                  <Select value={discipline} onChange={(value) => {
                                    setDiscipline(value);
                                  }}>
                                    {/* <Option value="">{i18next.t("Common.Form.Discipline")}</Option> */}
                                    {disciplineOption.map((item, key) => (
                                      <Option value={item}>{item}</Option>
                                    ))}
                                  </Select>
                                </div>
                              </Col>
                              <Col
                                span={24}
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                lg={{ span: 12 }}
                              >
                                <div className="form-group">
                                  <div className="form-label">
                                    <label>{i18next.t('Pages.SupportArea.Popup.Options.level')}*</label>
                                  </div>
                                  <Select value={level} onChange={(value) => {
                                    setLevel(value);
                                  }}>
                                    {/* <Option value="">{i18next.t("Common.Form.Level")}</Option> */}
                                    {Object.keys(levelOption).map((item, key) => (
                                      <Option value={item} key={key}>{levelOption[item]}</Option>
                                    ))}
                                  </Select>
                                </div>
                              </Col>
                              <Col
                                span={24}
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                lg={{ span: 12 }}
                              >
                                <div className="form-group">
                                  <div className="form-label">
                                    <label>{i18next.t('Pages.SupportArea.Popup.Options.weekly_training_hours')}*</label>
                                  </div>
                                  <Input placeholder={i18next.t('Pages.SupportArea.Popup.Options.weekly_training_hours_placeholder')} onChange={(e) => { setWeeklyTrainingHours(e.target.value); }} rows={4} />
                                </div>
                              </Col>
                              <Col
                                span={24}
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                lg={{ span: 12 }}
                              >
                                <div className="form-group">
                                  <div className="form-label">
                                    <label>{i18next.t('Pages.SupportArea.Popup.Options.rampage_Innova_frame_size')}*</label>
                                  </div>
                                  <Select value={frameSize} onChange={(value) => {
                                    setFrameSize(value);
                                  }}>
                                    {/* <Option value="">{i18next.t("Common.Form.FrameSize")}</Option> */}
                                    {sizeOption.map((item, key) => (
                                      <Option value={item} key={key}>{item}</Option>
                                    ))}
                                  </Select>
                                </div>
                              </Col>
                            </Row>
                          </>
                          }


                          <Dragger
                            {...draggerData}
                          >
                            <p className="ant-upload-drag-icon">
                              {/* <InboxOutlined /> */}
                            </p>
                            <p className="ant-upload-text">{i18next.t('Pages.SupportArea.Popup.Common.FileUploadTitle')}</p>
                            <p className="ant-upload-hint">
                              <span>{i18next.t('Pages.SupportArea.Popup.Common.FileUploadContent')}</span>
                            </p>
                          </Dragger>
                        </div>
                        {fileError && <div className="error"></div>}
                        <div className="Modal-footer">
                          <Button type="primary" onClick={() => setCurrentSizeStep(0)}>{i18next.t('Pages.SupportArea.Popup.Common.Back')}</Button>
                          <Button type="primary" disabled={isSubmit} onClick={() => onFinish()} loading={isCreateLoading}>{i18next.t('Pages.SupportArea.Popup.Common.Proceed')}</Button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Modal>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t('Pages.SupportArea.PageTitle')}</h3>
              </div>
              <div className="table rel with-pagination">
                <div className="hdr sticky">

                  <Row gutter={[24, 24]}>
                    <Col
                      span={2}
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      lg={{ span: 2 }}
                    >
                      <div className="table-header-item">{i18next.t('Pages.SupportArea.Table.ID')}</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">{i18next.t('Pages.SupportArea.Table.Data')}</div>
                    </Col>
                    <Col
                      span={7}
                      xs={{ span: 7 }}
                      sm={{ span: 7 }}
                      lg={{ span: 7 }}
                    >
                      <div className="table-header-item">{i18next.t('Pages.SupportArea.Table.Topic')}</div>
                    </Col>
                    <Col
                      span={8}
                      xs={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <div className="table-header-item">{i18next.t('Pages.SupportArea.Table.Description')}</div>
                    </Col>
                    <Col
                      span={2}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">{i18next.t('Pages.SupportArea.Table.File')}</div>
                    </Col>
                  </Row>

                </div>
                <div className="table-container-wrapper">
                  <div className={isLoading ? "loader-container" : "loader-container is-loaded"}>
                    <span className="loader"></span>
                    <div className="loader-text">{i18next.t('Pages.SupportArea.Loader')}</div>
                  </div>
                  <div className="table-wrap">
                    {/* {total == 0 && <>No ticket</>} */}
                    {total > 0 && tickets.map((item, idx) => (
                      <div className="table-item">

                        <Row gutter={[24, 24]}>
                          <Col
                            span={2}
                            xs={{ span: 2 }}
                            sm={{ span: 2 }}
                            lg={{ span: 2 }}
                          >
                            <div className="table-item__content">{item.id}</div>
                          </Col>
                          <Col
                            span={3}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            lg={{ span: 4 }}
                          >
                            <div className="table-item__content"><DateFormat date={item.date} /></div>
                          </Col>
                          <Col
                            span={7}
                            xs={{ span: 7 }}
                            sm={{ span: 7 }}
                            lg={{ span: 7 }}
                          >
                            <div className="table-item__content">
                              {item.request} - {item.topic}
                            </div>
                          </Col>
                          <Col
                            span={8}
                            xs={{ span: 8 }}
                            sm={{ span: 8 }}
                            lg={{ span: 8 }}
                          >
                            <div className="table-item__content">
                              {item.topic_id == 'rampage_innova_tuning' && item.request_id == 'support_dealer' && <>
                                <div className="ticket-label-value">
                                  <span className="label">{i18next.t('Pages.SupportArea.Popup.Options.rider_weight')}</span>: <span className="value">{item.ridewith_gear}</span>
                                </div>
                                <div className="ticket-label-value">
                                  <span className="label">{i18next.t('Pages.SupportArea.Popup.Options.discipline')}</span>: <span className="value">{item.discipline}</span>
                                </div>
                                <div className="ticket-label-value">
                                  <span className="label">{i18next.t('Pages.SupportArea.Popup.Options.level')}</span>: <span className="value">{item.level}</span>
                                </div>
                                <div className="ticket-label-value">
                                  <span className="label">{i18next.t('Pages.SupportArea.Popup.Options.weekly_training_hours')}</span>: <span className="value">{item.weekly_training_hours}</span>
                                </div>
                                <div className="ticket-label-value">
                                  <span className="label">{i18next.t('Pages.SupportArea.Popup.Options.rampage_Innova_frame_size')}</span>: <span className="value">{item.frame_size}</span>
                                </div>
                              </>}
                              {item.topic_id != 'rampage_innova_tuning' && item.request_id != 'support_dealer' && <>
                                {item.order_data && item.order_data.items &&
                                  <div className="product-ticket">
                                    <div className="label">{i18next.t('Pages.SupportArea.Table.Product')}:</div>
                                    {item.order_data && item.order_data.items && item.order_data.items.map((product) => (
                                      item.order_return_data.includes(product.item_id) && product.item_metas.type == 'other_products' && <><div className="product-item">{product.name}</div></>
                                    ))}
                                    {item.order_data && item.order_data.items && item.order_data.items.map((product) => (
                                      item.order_return_data.includes(product.item_id) && product.item_metas.type == 'bike' && <><div className="product-item">{product.item_metas.model_name} - {product.item_metas.groupset_name}</div></>
                                    ))}
                                  </div>
                                }
                                <div className="ticket-content">
                                  <div className="label">{i18next.t('Pages.SupportArea.Table.YourRequest')}:</div>
                                  <div className="content">{item.query}</div>
                                </div>
                              </>}
                            </div>
                          </Col>
                          {/* <Col
                            span={3}
                            xs={{ span: 3 }}
                            sm={{ span: 3 }}
                            lg={{ span: 3 }}
                          >
                            <div className="table-item__content">{item.serial_number}</div>
                          </Col> */}
                          {/* <Col
                            span={2}
                            xs={{ span: 2 }}
                            sm={{ span: 2 }}
                            lg={{ span: 2 }}
                          >
                            <div className="table-item__content">{item.orderid}</div>
                          </Col> */}
                          <Col
                            span={2}
                            xs={{ span: 3 }}
                            sm={{ span: 3 }}
                            lg={{ span: 3 }}
                          >
                            <div className="table-item__content">{item.file && item.file.length > 0 && item.file.map((fileData, index) => {
                              return (
                                <a href={fileData} target={"_blank"}>

                                  <button className="btn btn-icon">
                                    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.98 280.78"><path d="M157.64,280.78c-47.21,0-81.67-2.48-102.8-7.41C31.18,267.86,2.57,248.45,.7,187.33-.43,150.32-.21,114.77,1.45,68.44l.16-6.1C2.05,41.93,2.72,11.09,30.09,3.28,43.77-.62,62.79-.61,74.7,1.07c4.79,.67,8.13,5.09,7.45,9.88-.67,4.78-5.1,8.16-9.88,7.45-10.2-1.42-26.76-1.31-37.37,1.71-13.86,3.95-15.32,20.89-15.79,42.61-.05,2.2-.1,4.33-.18,6.35-1.64,45.91-1.85,81.11-.73,117.72,1.23,40.14,14.88,63.54,40.61,69.54,26.24,6.12,75.34,8.21,146.03,6.24,48.71-1.36,69.88-23.34,70.76-73.5l.2-10.79c.61-32.3,1.36-72.52-.66-110.83-.06-1.23-.11-2.62-.16-4.15-.51-16.95-2.34-34.07-18.28-40.93-11.74-5.07-27.78-4.3-43.21-3.07-4.93,.34-9.03-3.22-9.41-8.03-.39-4.81,3.21-9.03,8.03-9.41,17.61-1.4,36.09-2.2,51.53,4.45,27.51,11.85,28.42,41.99,28.86,56.48l.14,3.75c2.05,38.94,1.28,79.49,.66,112.09l-.19,10.76c-1.04,59.4-29.75,89.07-87.76,90.68-17.13,.46-33.04,.71-47.71,.71h0Z" /><path d="M146.98,199.48h-.04c-4.83-.03-8.73-3.96-8.71-8.79,.13-25.71,.26-75.83,0-100.54-.05-4.83,3.82-8.79,8.65-8.85h.1c4.8,0,8.7,3.86,8.75,8.65,.26,24.82,.13,75.05,0,100.81-.03,4.83-3.94,8.72-8.75,8.72h0Z" /><path d="M146.97,199.48c-1.98,0-3.95-.66-5.59-2.02-3.72-3.1-4.22-8.61-1.13-12.33,4.67-5.62,10.04-11.33,15.23-16.86,4.02-4.28,8.05-8.57,11.8-12.86,2.12-2.42,4.06-4.93,6-7.45l2.58-3.32c2.99-3.78,8.5-4.45,12.29-1.45,3.8,2.99,4.45,8.49,1.45,12.29l-2.46,3.18c-2.17,2.81-4.32,5.59-6.7,8.29-3.87,4.43-8.04,8.87-12.2,13.3-5.25,5.59-10.2,10.87-14.54,16.08-1.74,2.07-4.22,3.15-6.73,3.15h0Z" /><path d="M146.99,199.48c-2.51,0-5-1.08-6.74-3.15-4.34-5.21-9.3-10.5-14.54-16.08-4.16-4.43-8.32-8.87-12.21-13.31-2.36-2.7-4.52-5.48-6.68-8.29l-2.46-3.18c-2.99-3.8-2.34-9.3,1.45-12.29,3.8-2.98,9.3-2.33,12.29,1.45l2.58,3.32c1.94,2.52,3.87,5.02,5.99,7.45,3.75,4.29,7.79,8.58,11.8,12.86,5.19,5.53,10.55,11.24,15.23,16.86,3.1,3.71,2.59,9.23-1.13,12.33-1.63,1.35-3.61,2.02-5.59,2.02h0Z" /></svg>
                                  </button>
                                </a>
                              )
                            })}</div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="pagination-wrapper">
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} tickets`}
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ticket;
