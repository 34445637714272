import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { ArrowDownIcon } from "../../Icons";
import Items from "../../components/Items";
import { ReactTitle } from 'react-meta-tags';
import {
  getProductList,
  getProductFilter
} from "../../actions/productListActions";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Pagination, Skeleton, Select, Button, Input } from "antd";
import ProductDetails from "../../components/ProductDetails";
import paginationLabel from "../../components/PaginationRender";
import { useLocation, useHistory } from "react-router-dom";
import { getModelFE } from "../../actions/registerBike";
import Sortable from "../../components/Sortable";
import i18next from "i18next";

const { Option } = Select;



const ProductList = (props) => {
  let location = useLocation();
  const history = useHistory()

  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(20);
  const [productData, setProductData] = useState({});
  const [isProductDetailOpen, setProductDetailOpen] = useState(false);
  const [selectedCategory, setSelectedSelector] = useState("");
  const [selectedCategory1, setSelector1] = useState("");
  const [selectedCategory2, setSelector2] = useState("");
  const [dataCatagory1, setCatagory1Data] = useState([]);
  const [dataCatagory2, setCatagory2Data] = useState([]);
  const [search, setSearch] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [selectedModel, setSelectedModel] = useState("");


  const [filterButton, setFilterButton] = useState(false);
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState({
    "orderby": '',
    "order": ''
  });
  const { isLoading, productList, total, filterList, isFilterLoading } = useSelector((state) => {
    return state.productList;
  });
  const { modelList } = useSelector(state => {
    return state.registerBike
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const searchValue = query.get('search');
    if (searchValue) {
      setSearch(searchValue);
      query.delete('search')
      history.replace({
        search: query.toString(),
      })
    } else {
      loadProduct(current);
    }

  }, [current, location]);

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const searchValue = query.get('search');
  //   if (searchValue) {
  //     setSearch(searchValue);
  //     setSearchParams({ search: undefined });

  //   }
  // }, [location]);

  useEffect(() => {
    if (orderBy.orderby != '') {
      loadProduct(1);
    }
  }, [orderBy])

  const loadProduct = (page) => {
    dispatch(getProductList(page, pageSize, search, minPrice, maxPrice, selectedCategory, selectedCategory1, selectedCategory2, selectedModel, orderBy.order, orderBy.orderby));
  }
  useEffect(() => {
    dispatch(getProductFilter());
    dispatch(getModelFE());
  }, []);

  const makeResetFilter = () => {
    setSelectedSelector('');
    setSelector1('');
    setSelector2("");
    setSearch("");
    setSelectedModel("");
    setFilterButton(false);

  }

  useEffect(() => {
    const timeout = setTimeout(function () {
      loadProduct(1);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    dataCatagory1.length > 0 && dataCatagory1.map((key) => {
      if (key.id == selectedCategory1) {
        setCatagory2Data(key.child)
      }
    })
  }, [selectedCategory1])

  useEffect(() => {
    if (!isProductDetailOpen) {
      document.body.style.overflowY = 'auto';
    }
  }, [isProductDetailOpen])

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.NewOrder')} />
      <Header />
      <div className="dashboard-content prd-list">
        <div className="wrap">
          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-side-container">
                <div className="filter-blk">
                  <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>

                  <div className="form-wrapper">
                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.SKU")}</label>
                      </div>
                      <Input
                        type="text"
                        className="txt-search"
                        placeholder=""
                        value={search}
                        onChange={(e) => { console.log(e); setSearch(e.target.value); setFilterButton(true); }}
                        disabled={selectedCategory != '' || selectedModel != ''}
                      />
                    </div>

                    <div className="form-group multi-selection">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.Category")}:</label>
                      </div>
                      <Select value={selectedCategory}
                        dropdownClassName="dropdown-select-s2"
                        disabled={search != '' || selectedModel != ''}
                        onChange={(value) => {
                          setFilterButton(true);
                          setSelectedModel("");
                          setSelector1("");
                          setSelector2("");
                          setCatagory2Data([])
                          setSelectedSelector(value);
                          if (filterList[value] && filterList[value].terms && filterList[value].terms.length) {
                            setCatagory1Data(filterList[value].terms)
                          } else {
                            setCatagory1Data([]);
                          }

                        }}>
                        <Option value="">{i18next.t("Common.FiltersSidebar.All2")}</Option>
                        {Object.keys(filterList).length > 0 && Object.keys(filterList).map((key) => (
                          <Option key={key} value={key}>{filterList[key].label}</Option>
                        ))}
                      </Select>
                      {/* drop down 2 */}
                      {selectedCategory != '' && dataCatagory1.length > 0 && <Select value={selectedCategory1}
                        dropdownClassName="dropdown-select-s2"
                        onChange={(value) => {
                          setFilterButton(true);
                          setSelector2("");
                          setCatagory2Data([])
                          setSelector1(value);
                        }}
                      >
                        <Option value="">{i18next.t("Common.FiltersSidebar.All2")}</Option>
                        {dataCatagory1.map((key) => (
                          <Option key={key} value={key.id}>{key.name}</Option>
                        ))}
                      </Select>}

                      {/* drop down 3 */}
                      {dataCatagory2.length > 0 && <Select
                        dropdownClassName="dropdown-select-s2"
                        value={selectedCategory2} onChange={(value) => {
                          setFilterButton(true);
                          setSelector2(value);
                        }}
                      >
                        <Option value="">{i18next.t("Common.FiltersSidebar.All1")}</Option>
                        {dataCatagory2.length > 0 && dataCatagory2.map((data) => (
                          <Option key={data.id} value={data.id}>{data.name}</Option>
                        ))}
                      </Select>}
                    </div>
                    <div className="form-group multi-selection">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.RelatedModel")}</label>
                      </div>
                      <Select value={selectedModel}
                        dropdownClassName="dropdown-select-s2"
                        disabled={selectedCategory != '' || search != ''}
                        onChange={(value) => {
                          setFilterButton(true);
                          setSelector1("");
                          setSelector2("");
                          setCatagory2Data([])
                          setSelectedSelector("");
                          setSearch("");
                          setSelectedModel(value)
                        }}
                        placeholder="Scegli un modello"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {modelList.length > 0 && modelList.map((model, index) => (
                          <Option key={model.name} value={model.term_id}>{model.name}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                {/* <button className="btn btn-primary btn-outline reset-filter with-icon">
                  <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1198.75 1066.14"><path d="M1192.6,422.91a18.73,18.73,0,0,0-26.25,3.66L1066.13,558.85a533.06,533.06,0,1,0-15.28,175.31,18.75,18.75,0,1,0-36.28-9.37,496.85,496.85,0,1,1,14.82-154.69L890.64,464.63A18.77,18.77,0,1,0,868,494.54L1040.07,625a18.76,18.76,0,0,0,11.34,3.75,11.89,11.89,0,0,0,2.53,0,18.75,18.75,0,0,0,12.38-7.22l130.31-172a18.76,18.76,0,0,0-4-26.53Z" transform="translate(-1.67 -67.78)" /></svg>
                  <span>Reimposta filtri</span>
                </button> */}
                <Button loading={isLoading} className="btn btn-primary btn-submit" onClick={() => { loadProduct(1) }}>
                  {i18next.t("Common.FiltersSidebar.Submit")}
                </Button>
                {filterButton && <button
                  className="btn btn-only-icon"
                  onClick={makeResetFilter}
                >
                  <div className="icon">
                    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1219.76 1087.17"><path d="M1192.6,422.91a18.73,18.73,0,0,0-26.25,3.66L1066.13,558.85a533.06,533.06,0,1,0-15.28,175.31,18.75,18.75,0,1,0-36.28-9.37,496.85,496.85,0,1,1,14.82-154.69L890.64,464.63A18.77,18.77,0,1,0,868,494.54L1040.07,625a18.76,18.76,0,0,0,11.34,3.75,11.89,11.89,0,0,0,2.53,0,18.75,18.75,0,0,0,12.38-7.22l130.31-172a18.76,18.76,0,0,0-4-26.53Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /><path d="M534.78,1141a540.07,540.07,0,1,1,536.61-600.64l89.38-118a25.75,25.75,0,0,1,36-5l3.51,2.65-.08.37a25.75,25.75,0,0,1,2,33.33l-130.32,172a25.54,25.54,0,0,1-17,9.92l-.17,0a18.66,18.66,0,0,1-3.66,0,25.93,25.93,0,0,1-15.19-5.15L863.72,500.12a25.77,25.77,0,0,1,31.14-41.07l126.35,96a490,490,0,1,0-13.45,168.07A25.75,25.75,0,1,1,1057.6,736,540.33,540.33,0,0,1,534.78,1141Zm-.1-1066.17a526.07,526.07,0,1,0,509.39,657.63l.07-.26a11.75,11.75,0,1,0-22.73-5.87l-.06.26a503.9,503.9,0,1,1,15-156.87l.94,15.25L886.4,470.21A11.77,11.77,0,0,0,872.18,489L1044.3,619.37a11.55,11.55,0,0,0,7.08,2.33h.41l.4,0a3.87,3.87,0,0,0,.9,0,11.66,11.66,0,0,0,7.66-4.51l130.3-172a11.75,11.75,0,0,0-2.53-16.61l-.84-.6a11.75,11.75,0,0,0-15.75,2.78L1060.6,577.73l-1.45-18.33A526,526,0,0,0,536.85,74.79Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /></svg>
                  </div>{i18next.t("Common.FiltersSidebar.Reset")}
                </button>}
              </div>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t("Pages.Equipments.PageTitle")}</h3>
              </div>
              <div className="table rel with-pagination">
                <div className="hdr sticky">
                  <Row gutter={[24, 24]}>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.Equipments.Table.Image")}</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.Equipments.Table.SKU")}</div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 6 }}
                      sm={{ span: 6 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.Equipments.Table.Name")}
                        <Sortable columnName="product_name" orderBy={orderBy} setOrderBy={setOrderBy} defultSorting="DESC" />
                      </div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.Equipments.Table.Categories")}</div>
                    </Col>
                    <Col
                      span={2}
                      xs={{ span: 2 }}
                      sm={{ span: 2 }}
                      lg={{ span: 2 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.Equipments.Table.Stock")}</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.Equipments.Table.Price")}</div>
                    </Col>
                    <Col
                      span={5}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                      lg={{ span: 5 }}
                    >
                      <div className="table-header-item"></div>
                    </Col>
                  </Row>
                </div>

                <div className="table-container-wrapper">
                  <div className={isLoading ? "loader-container" : "loader-container is-loaded"}>
                    <span className="loader"></span>
                    <div className="loader-text">{i18next.t("Pages.Equipments.Loader")}</div>
                  </div>
                  <div className="table-wrap">
                    {!isLoading && productList.length > 0 && productList.map((item, idx) => (
                      <Items productData={item} key={idx} setProductData={setProductData} setProductDetailOpen={setProductDetailOpen} />
                    ))}
                    {productList.length == 0 && search != '' && <>
                      <div className="not-found-search">
                        {i18next.t("Pages.Equipments.NotFound")}
                      </div>
                    </>}
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} prodotti`}
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductDetails id={productData.id} product={productData} isOpen={isProductDetailOpen} setProductDetailOpen={setProductDetailOpen} />
      <Footer />
    </>
  );
}

export default ProductList
