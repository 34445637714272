import {
    GET_MODEL_FE_PENDING,
    GET_MODEL_FE_SUCCESS,
    GET_MODEL_FE_FAILURE,
    GET_SEARCH_USER_PENDING,
    GET_SEARCH_USER_SUCCESS,
    GET_SEARCH_USER_FAILURE,
    CREATE_REGISTER_BIKE_PENDING,
    CREATE_REGISTER_BIKE_SUCESS,
    CREATE_REGISTER_BIKE_FAILURE,
    GET_DEALER_REGISTER_BIKE_PENDING,
    GET_DEALER_REGISTER_BIKE_SUCCESS,
    GET_DEALER_REGISTER_BIKE_FAILURE
} from "../actions/actionTypes";

const initialState = {
    isDealerBikesLoading: false,
    isDealerBikesError: false,
    dealerBikeList: [],
    dealerBikeTotal: 0,
    isRegisterLoading: false,
    isError: false,
    isUserError: false,
    isUserSuccess: false,
    isRegisterError: false,
    isRegisterSucces: false,
    message: '',
    modelList: [],
    userList: [],
    isUserLoading: false
};

export default function RegisterBikeReducer(state = initialState, action) {
    if (!state) state = {};
    switch (action.type) {
        case GET_MODEL_FE_PENDING:
            return Object.assign({}, state, {
                isUserLoading: false,
                isRegisterError: false,
                isRegisterSucces: false
            });
        case GET_MODEL_FE_SUCCESS:
            return Object.assign({}, state, {
                modelList: action.data.data,
            });
        case GET_MODEL_FE_FAILURE:
            return Object.assign({}, state, {
                message: action.data.message,
                isError: true,
            });
        case GET_SEARCH_USER_PENDING:
            return Object.assign({}, state, {
                isUserError: false,
                isUserSuccess: false,
                isUserLoading: true
            });
        case GET_SEARCH_USER_SUCCESS:
            return Object.assign({}, state, {
                userList: action.data.data,
                isUserError: false,
                isUserSuccess: true,
                isUserLoading: false
            });
        case GET_SEARCH_USER_FAILURE:
            return Object.assign({}, state, {
                message: action.data.message,
                userList: {},
                isUserError: true,
                isUserSuccess: false,
                isUserLoading: false
            });
        case CREATE_REGISTER_BIKE_PENDING:
            return Object.assign({}, state, {
                isRegisterLoading: true,
                isRegisterError: false,
                isRegisterSucces: false
            });
        case CREATE_REGISTER_BIKE_SUCESS:
            return Object.assign({}, state, {
                bikeRegister: action.data.data,
                isRegisterError: false,
                isRegisterLoading: false,
                isRegisterSucces: true
            });
        case CREATE_REGISTER_BIKE_FAILURE:
            return Object.assign({}, state, {
                message: action.data.message,
                isRegisterError: true,
                isRegisterLoading: false,
                isRegisterSucces: false
            });
        case GET_DEALER_REGISTER_BIKE_PENDING:
            return Object.assign({}, state, {
                dealerBikeList: [],
                isDealerBikesError: false,
                isDealerBikesLoading: true
            });
        case GET_DEALER_REGISTER_BIKE_SUCCESS:
            return Object.assign({}, state, {
                isDealerBikesError: false,
                dealerBikeList: action.data.data.bikes,
                isDealerBikesLoading: false,
                dealerBikeTotal: action.data.data.total
            });
        case GET_DEALER_REGISTER_BIKE_FAILURE:
            return Object.assign({}, state, {
                message: action.data.message,
                isDealerBikesError: true,
                isDealerBikesLoading: false
            });
        default:
            return state;
    }
}
