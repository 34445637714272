import Cookies from "js-cookie";
import { request } from "../services/apiService";
import {
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTH_LOGOUT,
  GET_COUNTRY_DATA,
} from "./actionTypes";
import history from "../history";
import { i18n } from "../translations/i18n";
import i18next from "i18next";
export const getCountryData = () => (dispatch) => {
  const url = `v1/content/country-data`;
  request("get", url, {}, false).then(
    (res) => {
      dispatch({
        type: GET_COUNTRY_DATA,
        payload: res,
      });
    },
    (err) => {
      console.log("getCountryData err", err.response);
    }
  );
};

export const userLogin = (data) => (dispatch) => {
  dispatch({
    type: LOGIN_PENDING,
    payload: { isLoading: true },
  });
  const datas = { username: data.email, password: data.password };
  const url = `v1/user/token`;
  request("post", url, datas, false).then(
    (res) => {
      localStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("user-token", res.data.token);
      localStorage.setItem(
        "platform-access",
        JSON.stringify(res.data.dealer.platform)
      );
      localStorage.setItem("user-roles", JSON.stringify(res.data.roles));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: res.data },
      });
      if (res.data.roles.includes('agent')) {
        history.push("/" + i18n.language + '/' + i18next.t('routes.ShopSelectorAgent'), true);
      } else if (
        Cookies.get("platformkey") !== "7894210751" &&
        Cookies.get("platformkey") !== "7368496012"
      ) {
        history.push("/" + i18n.language + '/' + i18next.t('routes.SwitchBrand'), true);
      } else {
        history.push("/" + i18n.language + "/dashboard");
      }
    },
    (err) => {
      dispatch({
        type: LOGIN_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};

export const userLogout = () => (dispatch) => {
  logOutClearData();
  dispatch({ type: AUTH_LOGOUT });
};

export const logOutClearData = () => {
  localStorage.removeItem("user-token");
  localStorage.removeItem("user");
  localStorage.removeItem("platform-access");
  localStorage.removeItem("user-roles");
  localStorage.removeItem("stardue_country");
  localStorage.removeItem("stardue_flat_discount");
  localStorage.removeItem("stardue_flat_discount_frame_kit");
  localStorage.removeItem("stardue_price_list");
  localStorage.removeItem("stardue_currency");

  localStorage.removeItem("agent-selected-user-roles");
  localStorage.removeItem("agent-selected-user");

  Cookies.set("platformkey", '');
  Cookies.set("stardue-carthash", '');
  Cookies.set("stardue-cartcount", 0);

}