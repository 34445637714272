import React, { useLayoutEffect, useState } from "react";
import i18next from "i18next";
const BikeSummary = ({ item }) => {

  return <>
    <div className="bike-summary-items">
      <div className="summary-item summary-bike-configurationID">
        <span className="label">{i18next.t("Common.Bikes.SummaryBike.ConfigurationID")}:</span>
        {/* <br className="d-none-lg"/> */}
        <span className="value">{item.item_metas.configurator_id}</span>
      </div>
      <div className="summary-item summary-bike-sku">
        <span className="label">{i18next.t("Common.Bikes.SummaryBike.Sku")}:</span>
        <span className="value">{item.item_metas.bike_sku}</span>
      </div>
      <div className="summary-item summary-bike-groupset">
        <span className="label">{i18next.t("Common.Bikes.SummaryBike.Groupset")}:</span>
        <span className="value">{item.item_metas.groupset_name}</span>
      </div>
      <div className="summary-item summary-bike-size">
        <span className="label">{i18next.t("Common.Bikes.SummaryBike.Size")}:</span>
        <span className="value">{item.item_metas.size_name}</span>
      </div>
      <div className="summary-item summary-bike-wheels">
        <span className="label">{i18next.t("Common.Bikes.SummaryBike.Wheels")}:</span>
        <span className="value">{item.item_metas.wheel_name}</span>
      </div>
      <div className="summary-item summary-bike-color">
        <span className="label">{i18next.t("Common.Bikes.SummaryBike.Color")}:</span>
        <span className="value">{item.item_metas.color_name}</span>
      </div>
    </div>
  </>;
};
export default BikeSummary;
