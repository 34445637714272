import {
  FETCH_CONFIGURATOR_CATEGORY_PENDING,
  FETCH_CONFIGURATOR_CATEGORY_SUCCESS,
  FETCH_CONFIGURATOR_CATEGORY_FAILURE,
  FETCH_MODELS_PENDING,
  FETCH_MODELS_SUCCESS,
  FETCH_MODELS_FAILURE,
  FETCH_OTHER_STEP_PENDING,
  FETCH_OTHER_STEP_SUCCESS,
  FETCH_OTHER_STEP_FAILURE,
  CONFIGURATOR_SAVE_PENDING,
  CONFIGURATOR_SAVE_SUCCESS,
  CONFIGURATOR_SAVE_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isModelLoading: false,
  isError: false,
  message: "",
  categoryData: [],
  configuratorData: {},
  isAddedTocart: false,
  isBikeLoading: false,
  productdata: {},
  configuratorSaveLoding: false,
  configuratorSaveError: false,
  userSavedConfigurator: {},
};

export default function ConfigurNowReducer(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case FETCH_CONFIGURATOR_CATEGORY_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case FETCH_CONFIGURATOR_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        categoryData: action.data,
        isLoading: false,
      });
    case FETCH_CONFIGURATOR_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        isError: true,
        isLoading: false,
        message: action.message,
      });
    case FETCH_MODELS_PENDING:
      return Object.assign({}, state, {
        isModelLoading: true,
      });
    case FETCH_MODELS_SUCCESS:
      return Object.assign({}, state, {
        configuratorData: action.data.models,
        isModelLoading: false,
      });
    case FETCH_MODELS_FAILURE:
      return Object.assign({}, state, {
        message: action.message,
        isModelLoading: false,
      });
    case FETCH_OTHER_STEP_PENDING:
      return Object.assign({}, state, {
        isBikeLoading: true,
      });
    case FETCH_OTHER_STEP_SUCCESS:
      return Object.assign({}, state, {
        productdata: action.data,
        isBikeLoading: false,
      });
    case FETCH_OTHER_STEP_FAILURE:
      return Object.assign({}, state, {
        message: action.message,
        isBikeLoading: false,
      });
    case CONFIGURATOR_SAVE_PENDING:
      return Object.assign({}, state, {
        configuratorSaveLoding: true,
        userSavedConfigurator: {},
        configuratorSaveError: false
      });
    case CONFIGURATOR_SAVE_SUCCESS:
      return Object.assign({}, state, {
        userSavedConfigurator: action.data,
        configuratorSaveLoding: false,
        configuratorSaveError: false
      });
    case CONFIGURATOR_SAVE_FAILURE:
      return Object.assign({}, state, {
        configuratorSaveLoding: false,
        message: action.message,
        configuratorSaveError: true
      });

    default:
      return state;
  }
}
