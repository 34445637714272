import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation
} from "react-router-dom";
import {
  getConfiguratorCategory,
  getConfiguratorModelData,
  getBikeData,
} from "../../actions/configurNowAction";
import { getSingleConfigurator, resetOldConfigurator } from "../../actions/configuratorActions";
import { Select, Skeleton, Row, Col, Button, Modal, Steps } from "antd";
import Models from "./Models";
import ConfiguratorType from "./ConfiguratorType";
import Color from "./Color";
import Size from "./Size";
import GroupSet from "./GroupSet";
import Wheel from "./Wheel";
import OtherSteps from "./OtherSteps";
import OtherStepsDataSummary from "./OtherStepsDataSummary";
import { arrayRemove, findPrice } from "../../utils/commons";
import { ReactTitle } from "react-meta-tags";
import Sticky from "wil-react-sticky";
import ActionsBar from "./ActionsBar";
import ConfiguratorView from "./ConfiguratorView";
import ConfiguratorContextProvider from "../../context/configurator-loader"
import i18next from "i18next";
import Geometrics from "../../components/Geometrics";
import FindYourSize from "../../components/FindYourSize";
const { Step } = Steps;

const CONFIGURATOR_TYPE = {
  bike: "Bike",
  frame_kit: "Frame Kit",
  build_kit: "Build Kit",
};

const ConfiguratorContainer = (props) => {
  const dispatch = useDispatch();
  const configuratorImageWrap = useRef(null);
  const { search } = useLocation();

  const {
    isLoading,
    categoryData,
    isModelLoading,
    configuratorData,
    productdata,
    configuratorSaveLoding,
  } = useSelector((state) => {
    return state.configurNow;
  });

  const { configuratorDetail, errorDetails } = useSelector((state) => {
    return state.configure;
  });

  const [modalSlug, setModalSlug] = useState('');
  const [urlConfId, setConfiguratorIdURL] = useState();
  const [configuratorId, setConfiguratorID] = useState("");
  const [slug, onChangeModel] = useState("");
  const [slugModel, setSlugModel] = useState("");
  const [categorySlug, setcategorySlug] = useState("");
  const [modelName, setModelName] = useState("");
  const [configuratorType, setConfiguratorType] = useState(
    configuratorData && configuratorData.default_bike_type
      ? configuratorData.default_bike_type
      : "bike"
  );
  const [color, setColor] = useState();
  const [colorName, setColorName] = useState();
  const [size, setSize] = useState();
  const [sizeName, setSizeName] = useState();
  const [groupset, setGroupSet] = useState();
  const [groupsetSlug, setGroupsetSlug] = useState();
  const [groupName, setGroupName] = useState();
  const [groupId, setGroupId] = useState();
  const [isGroupsetLoader, setGroupLoader] = useState(true);
  const [isSizePopup, setSizePopup] = useState(false);
  const [cassette, setCassette] = useState();
  const [crankArm, setCrankArm] = useState();
  const [chainRings, setChainRings] = useState();
  const [wheel, setWheel] = useState();
  const [wheels, setWheels] = useState();
  const [productId, setProductId] = useState();
  const [configuratorViewImages, setConfiguratorViewImages] = useState({});
  const [otherSteps, setOtherSteps] = useState([]);
  const [options, setOptions] = useState({});
  const [otherImages, setOtherImages] = useState({});
  const [groupsetImages, setGroupsetImage] = useState([]);
  const [otherStep, setOtherStep] = useState({});
  const [previewOrder, setPreviewOrder] = useState([]);
  const [disableStep, setDisableStep] = useState({});
  const [optionalQuantity, setOptionalQuantity] = useState({});
  const [otherStepDisplayPrice, setOtherStepDisplayPrice] = useState({});
  const [disbaleProductId, setDisableProductId] = useState({});
  const [productQuantity, setProductQuantity] = useState({});
  const [productIndex, setProductIndex] = useState({});
  const [price, setPrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [nonLoggedInPrice, setNonLoggedinPrice] = useState(0);
  const [wheelPriceText, setWheelPriceText] = useState({});
  const [priceList, setPriceList] = useState(0);
  const [userData, setUserData] = useState(null);
  const [isDefaultData, setIsDefaultData] = useState(false);
  const [otherOptionalImages, setOtherOptionalImages] = useState({});
  const [canvasWidth, setCanvasWidth] = useState(420);
  const [canvasWidthPopup, setCanvasWidthPopup] = useState(420);

  const [activeView, setActiveView] = useState(0);
  const [isViewModel, setViewModel] = useState(false);
  const [currentStepSize, setCurrentSizeStep] = useState(0);

  useLayoutEffect(() => {
    function handleResize() {
      if (configuratorImageWrap && configuratorImageWrap.current) {
        setCanvasWidth(
          configuratorImageWrap.current.offsetWidth
        );
      }
    }
    if (configuratorImageWrap && configuratorImageWrap.current) {
      setCanvasWidth(
        configuratorImageWrap.current.offsetWidth
      );
    }
    window.addEventListener("resize", handleResize);
    setCanvasWidthPopup(window.innerWidth / 2)
  }, []);
  useEffect(() => {
    dispatch(getConfiguratorCategory());
  }, [props.location.pathname]);

  useEffect(() => {
    const id = new URLSearchParams(search).get("configurator_id");
    if (id) {
      setConfiguratorIdURL(id)
      dispatch(getSingleConfigurator(id));
    } else {
      dispatch(resetOldConfigurator())
    }
  }, [props]);

  // useEffect(() => {
  //   if (configuratorDetail) {
  //     // console.log(configuratorDetail);
  //   }
  // }, [configuratorDetail]);

  useEffect(() => {
    if (slug != "") {
      setGroupId();
      dispatch(getConfiguratorModelData(slug));
    }
  }, [slug]);

  useEffect(() => {
    setPriceList(localStorage.getItem("pricelist"));
    if (localStorage.getItem("user")) {
      if (JSON.parse(localStorage.getItem("user"))) {
        setUserData(JSON.parse(localStorage.getItem("user")));
      }
    }
  }, []);

  useEffect(() => {
    // console.log("slugModel", slugModel);
    if ((slugModel && configuratorType, groupsetSlug)) {
      setGroupLoader(true);
      dispatch(getBikeData(configuratorType, slugModel, groupsetSlug));
    }
  }, [slugModel, configuratorType, groupsetSlug]);

  useEffect(() => {
    if (categoryData && categoryData.length > 0) {
      if (
        categoryData[0] &&
        categoryData[0].models &&
        categoryData[0].models[0]
      ) {
        setSlugModel(categoryData[0].models[0].slug);
        onChangeModel(categoryData[0].models[0].display_slug);
        setModelName(categoryData[0].models[0].name);
      }
    }
  }, [categoryData]);

  const onChangeConfiguratorType = (value) => {
    setConfiguratorType(value);
    if (value === "frame_kit" || value === "build_kit") {
      configuratorData.groupset &&
        configuratorData.groupset["items"] &&
        configuratorData.groupset["items"].length > 0 &&
        configuratorData.groupset["items"].map((item, index) => {
          if (item.is_no_groupset) {
            setGroupId(index);
            setGroupSet(item.id);
            setGroupsetSlug(item.slug);
            setGroupName(item.name);
            // setOtherStep({});
            // setOtherImages({});
          }
        });
    } else {
      if (
        configuratorData.groupset &&
        configuratorData.groupset["items"] &&
        configuratorData.groupset["items"].length > 0
      ) {
        let default_groupset = configuratorData.groupset["default"];
        configuratorData.groupset.items.forEach((groupsetdata, index) => {
          if (groupsetdata.id === default_groupset) {
            setGroupsetSlug(groupsetdata.slug);
            setGroupName(groupsetdata.name);
            setGroupId(index);
          }
        });
        // setGroupSet(configuratorData.groupset["default"]);
      }
    }
  };

  const onChangeGroup = (e) => {
    setGroupId(e.target.value);
    setGroupSet(e.target.id);
    setGroupsetSlug(e.target.slug);
    setGroupName(e.target.groupName);
    // setOtherStep({});
    // setOtherImages({});
  };

  useEffect(() => {
    if (productdata.id !== undefined) {
      setProductId(productdata.id);
      setConfiguratorViewImages(productdata.view_images);
      setWheels(productdata.wheels);
      setWheel(productdata.wheels.default);
      setOtherSteps(productdata.other_steps);
      let dataOptions = { ...options };
      let data = {};
      let preview_images = {};

      productdata.other_steps &&
        productdata.other_steps.length &&
        productdata.other_steps.map((otherStepData, index) => {
          otherStepData["allowed_products"] &&
            otherStepData["allowed_products"].map(
              (productData, productIndex) => {
                if (otherStepData.defult_selection == productData.id) {
                  if (otherStepData.is_optional_step != "1") {
                    dataOptions[otherStepData.step_id] = productData.default;
                  } else {
                    dataOptions[otherStepData.step_id] = [];
                  }
                }
              }
            );

          if (otherStepData.step_id !== undefined) {
            if (otherStepData.is_optional_step != "1") {
              data[otherStepData.step_id] = !isNaN(
                parseInt(otherStepData.defult_selection)
              )
                ? parseInt(otherStepData.defult_selection)
                : null;
            } else {
              data[otherStepData.step_id] = [];
            }

            if (otherStepData.affect_preview == "1") {
              otherStepData.allowed_products &&
                otherStepData.allowed_products.map((prouct) => {
                  if (prouct.id == otherStepData.defult_selection) {
                    preview_images[otherStepData.step_id] =
                      prouct.preview_image;
                  }
                });
            }
          }
        });
      if (!urlConfId) {
        setOtherStep(data);
      }
      productdata.other_steps &&
        productdata.other_steps.length &&
        calculatePrice();
      setOptions(dataOptions);
      setOtherImages(preview_images);
      setPreviewOrder(productdata.preivew_order);
      setGroupsetImage(productdata.group_set_images);
    }
  }, [productdata]);

  useEffect(() => {
    setActiveView(0);
    calculatePrice();
  }, [
    wheels,
    otherStep,
    options,
    productQuantity,
    wheel,
    optionalQuantity,
    priceList,
    userData,
  ]);

  const onChangeWheel = (e) => {
    let { value, wheel } = e.target;
    setWheel(value);
  };

  const onChangeOtherOptions = (e, id) => {
    let data = { ...options };
    data[id] = e.target.value;
    setOptions(data);
  };

  useEffect(() => {
    setConfiguratorType(
      configuratorData && configuratorData.default_bike_type
        ? configuratorData.default_bike_type
        : "bike"
    );
    if (
      configuratorData &&
      Object.keys(configuratorData).length > 0 &&
      configuratorData.groupset &&
      Object.keys(configuratorData.groupset).length > 0
    ) {
      configuratorData.groupset.items.forEach((groupsetdata, index) => {
        if (groupsetdata.id === configuratorData.groupset.default) {
          setGroupsetSlug(groupsetdata.slug);
          setGroupId(index);
        }
      });
      setGroupSet(configuratorData.groupset["default"]);
      setColor(configuratorData.color["default"]);
      setSize(configuratorData.size["default"]);
      setSizeName(renderSizeName(configuratorData.size["default"]));
      setColorName(rederColorName(configuratorData.color["default"]));
    }
  }, [configuratorData]);

  const renderSizeName = (id) => {
    let data = configuratorData.size["items"].filter((item) => item.id === id);
    if (data && data[0]) {
      return data[0].name;
    }
  };

  const rederColorName = (id) => {
    let data = configuratorData.color["items"].filter((item) => item.id === id);
    if (data && data[0]) {
      return data[0].name;
    }
  };

  const onChangeOtherSteps = (e, id, affect_preview, otherIndex) => {
    let data = { ...otherStep };
    data[id] = e.target.value;
    setOtherStep(data);
    let { product, indexValue } = e.target;
    onSetDisbaleSteps(product);
    let productIndexs = { ...productIndex };
    productIndexs[id] = indexValue;
    setProductIndex(productIndexs);
    setOtherImagesInCanvas(product, id, affect_preview);

    /**set default child option*/
    let dataOptions = { ...options };
    dataOptions[id] =
      otherSteps &&
      otherSteps[otherIndex]["allowed_products"][indexValue]["default"];

    setOptions(dataOptions);
    /**set default child option*/

    hideProduct();
  };

  const onSetDisbaleSteps = (product) => {
    // let data = { ...disableStep };
    // otherSteps &&
    //   otherSteps.map((otherStep) => {
    //     data[otherStep.step_id] = false;
    //   });
    // if (product) {
    //   if (product.disable_steps && product.disable_steps.length > 0) {
    //     Object.keys(data).map((step) => {
    //       if (product.disable_steps.includes(step)) {
    //         data[step] = true;
    //       } else {
    //         data[step] = false;
    //       }
    //     });
    //   }
    // }

    // setDisableStep(data);
  };

  useEffect(() => {
    let data = { ...disableStep };
    let selectedProduct = { ...otherStep };
    otherSteps &&
      otherSteps.map((otherStep) => {
        data[otherStep.step_id] = false;
      });
    otherSteps && otherSteps.length > 0 && otherSteps.map((otherStepData, index) => {
      let step_id = otherStepData.step_id;
      otherStepData.allowed_products != null &&
        otherStepData.allowed_products.length > 0 &&
        otherStepData.allowed_products.forEach(
          (allowedProduct, allowedProductIndex) => {
            if (Array.isArray(allowedProduct.disable_steps) &&
              (
                (Array.isArray(selectedProduct[step_id]) && Array.isArray(selectedProduct[step_id]).includes(allowedProduct.id))
                ||
                (!Array.isArray(selectedProduct[step_id]) && selectedProduct[step_id] == allowedProduct.id)
              )
            ) {
              allowedProduct.disable_steps.map((stepId, index) => {
                data[stepId] = true;
              })
            }
          })
    })
    setDisableStep(data);
  }, [otherStep])

  const setOtherImagesInCanvas = (product, id, affect_preview) => {
    if (affect_preview === "1") {
      let data = { ...otherImages };
      data[id] = product.preview_image;
      setOtherImages(data);
    }
  };

  const onChangesOptionalQuantity = (id, increment, stepId, stock) => {
    let data = { ...optionalQuantity };
    if (increment) {
      if (stock > (data[id] ? data[id] : 0)) {
        data[id] = data[id] ? data[id] + 1 : 0 + 1;
      }
    } else {
      data[id] = data[id] ? data[id] - 1 : 0;
    }
    setOptionalQuantity(data);
    onChangeOtherOptionsChecbox(id, stepId, data);
  };

  const onChangeOtherOptionsChecbox = (id, stepId, dataQuantity) => {
    let optionData = { ...options };

    if (dataQuantity[id] === 1) {
      if (!Array.isArray(optionData[stepId])) {
        optionData[stepId] = [];
      }
      optionData[stepId].push(id);
    }
    if (dataQuantity[id] === 0) {
      if (
        Array.isArray(optionData[stepId]) &&
        optionData[stepId].includes(id)
      ) {
        optionData[stepId] = arrayRemove(optionData[stepId], id);
      }
    }
    setOptions(optionData);
  };

  const onChangeOtherStepsCheckbox = (e, id, affect_preview, otherIndex) => {
    // e.preventDefault();
    let data = { ...otherStep };
    let { product, indexValue, value } = e.target;
    if (!Array.isArray(data[id])) {
      data[id] = [];
      data[id].push(value);
    } else {
      if (data[id].includes(value)) {
        data[id] = arrayRemove(data[id], value);
      } else {
        data[id].push(value);
      }
    }
    setOtherStep(data);
    let productIndexs = { ...productIndex };
    productIndexs[id] = indexValue;
    setProductIndex(productIndexs);
    setOtherImagesInCanvas(product, id, affect_preview);
    hideProduct();
  };

  const hideProduct = () => {
    let disbaleIds = { ...disbaleProductId };
    otherSteps &&
      otherSteps.length > 0 &&
      otherSteps.forEach((step) => {
        step.allowed_products != null &&
          step.allowed_products.length > 0 &&
          step.allowed_products.forEach((allowed_product) => {
            if (
              allowed_product.active_conditional === "1" &&
              allowed_product.passive_conditional === "1" &&
              allowed_product.passive_conditional_steps_data &&
              Object.keys(allowed_product.passive_conditional_steps_data)
                .length > 0
            ) {
              if (allowed_product.contition_type === "AND") {
                let isAndCondtion = true;
                Object.keys(
                  allowed_product.passive_conditional_steps_data
                ).forEach((key) => {
                  let adata =
                    allowed_product.passive_conditional_steps_data[key];
                  let bData = otherStep[key];
                  if (Array.isArray(bData)) {
                    bData.map((b_data) => {
                      if (bData && !adata.includes(b_data.toString())) {
                        isAndCondtion = false;
                      }
                    });
                  } else {
                    if (bData && !adata.includes(bData.toString())) {
                      isAndCondtion = false;
                    }
                  }
                });
                disbaleIds[allowed_product.id] = isAndCondtion;
              } else if (allowed_product.contition_type === "OR") {
                let isOrCondtion = true;
                Object.keys(
                  allowed_product.passive_conditional_steps_data
                ).forEach((key) => {
                  let adata =
                    allowed_product.passive_conditional_steps_data[key];
                  let bData = otherStep[key];
                  if (
                    (!Array.isArray(bData) && bData !== null) ||
                    (Array.isArray(bData) && bData.length > 0)
                  ) {
                    if (Array.isArray(bData)) {
                      bData.map((b_data) => {
                        if (bData && adata.includes(b_data.toString())) {
                          isOrCondtion = false;
                        }
                      });
                    } else {
                      if (bData && adata.includes(bData.toString())) {
                        isOrCondtion = false;
                      }
                    }
                  }
                });
                disbaleIds[allowed_product.id] = isOrCondtion;
              }
            }
          });
      });
    setDisableProductId(disbaleIds);
  };

  const onChangesQantityForOptions = (e, id) => {
    let quantities = { ...productQuantity };
    quantities[id] = e;
    setProductQuantity(quantities);
  };

  const onChangesQantity = (id, increment) => {
    let quantities = { ...productQuantity };
    if (increment) {
      quantities[id] = quantities[id] + 1;
    } else {
      quantities[id] = quantities[id] ? quantities[id] - 1 : 0;
    }
    setProductQuantity(quantities);
  };
  useEffect(() => {
    if (!isDefaultData && configuratorData && (groupId === 0 || groupId)) {
      configuratorData.groupset &&
        configuratorData.groupset.items &&
        configuratorData.groupset.items.forEach((groupsetdata, index) => {
          if (index === groupId) {
            setCassette(
              configuratorData.groupset.items[groupId].cassette.default
            );
            setCrankArm(
              configuratorData.groupset.items[groupId].crank_arm.default
            );
            setChainRings(
              configuratorData.groupset.items[groupId].chain_rings.default
            );
          }
        });
    }
  }, [groupId]);

  useEffect(() => {
    let data = { ...otherStep };
    let productIndexs = { ...productIndex };
    let disableData = { ...disbaleProductId };
    let quantityData = { ...productQuantity };
    // setProductDataDisbale();
    otherSteps &&
      otherSteps.map((otherStepData) => {
        if (otherStepData.step_id !== undefined) {
          if (otherStepData.is_optional_step != "1") {
            data[otherStepData.step_id] = !isNaN(
              parseInt(otherStepData.defult_selection)
            )
              ? parseInt(otherStepData.defult_selection)
              : null;
          } else {
            data[otherStepData.step_id] = [];
          }

          if (
            otherStepData.allowed_products &&
            otherStepData.allowed_products.length > 0
          ) {
            otherStepData.allowed_products.map((allowed_product, index) => {
              disableData[allowed_product.id] = true;

              if (
                otherStepData.is_optional_step == "1" &&
                allowed_product.type == "simple"
              ) {
                quantityData[allowed_product.id] = 0;
              } else {
                quantityData[allowed_product.id] = 1;
              }

              if (
                allowed_product &&
                parseInt(allowed_product.id) ===
                parseInt(otherStepData.defult_selection)
              ) {
                productIndexs[otherStepData.step_id] = index;
                setProductIndex(productIndexs);
                onSetDisbaleSteps(allowed_product);
              }
            });
          }

          if (!urlConfId) {
            setProductQuantity(quantityData);
          }
          setDisableProductId(disableData);
        }
      });
    if (!urlConfId) {
      setOtherStep(data);
    }
  }, [otherSteps]);


  /*******edit configurator********/
  useEffect(() => {
    setConfiguratorID("");
    if (configuratorDetail.configurator_id && urlConfId) {
      setIsDefaultData(true);
      setConfiguratorID(configuratorDetail.configurator_id);
      const {
        model
      } = configuratorDetail;


      categoryData && categoryData.length > 0 && categoryData.map((category, index) => {
        category && category.models && category.models.length > 0 && category.models.map((modelData, indexModel) => {
          if (modelData.id === model) {
            setModelName(modelData.name);
            setSlugModel(modelData.slug);
            onChangeModel(modelData.display_slug);
            setModalSlug(modelData.display_slug);
            setcategorySlug(category.slug);
          }
        })
      })
    }
  }, [configuratorDetail, categoryData])

  useEffect(() => {
    if (configuratorDetail.configurator_id && urlConfId) {

      setIsDefaultData(true);
      setConfiguratorID(configuratorDetail.configurator_id);
      const {
        color,
        size,
        groupset,
        groupset_data,
        other_steps,
        other_steps_options,
        wheel,
        type,
        quantity,
        optional_quantity,
        color_name,
        size_name
      } = configuratorDetail;

      if (configuratorData.slug) {
        //setModalSlug(configuratorData.slug);
        configuratorData.groupset.items.forEach((groupsetdata, index) => {
          if (groupsetdata.id === groupset) {
            setGroupsetSlug(groupsetdata.slug);
            setGroupId(index);
          }
        });
        setGroupSet(groupset);
      }
      setColor(color);
      setSize(size);
      setCassette(groupset_data.cassette);
      setChainRings(groupset_data.chainRings);
      setCrankArm(groupset_data.crankArm);
      setOtherStep(other_steps);
      setOptions(other_steps_options);
      setWheel(wheel);
      setConfiguratorType(type);
      setProductQuantity(quantity);
      setOptionalQuantity(optional_quantity);
      setColorName(color_name);
      setSizeName(size_name)
    }
  }, [configuratorData, configuratorDetail, productdata])
  /*******edit configurator********/

  let otherstepStart =
    configuratorType === "build_kit"
      ? 6
      : configuratorType === "frame_kit"
        ? 5
        : 7;

  const calculatePrice = () => {
    if (!wheels || !wheels.items || !wheel || !otherStep) {
      return;
    }
    var finalRegularPrice = 0;
    var finalSalePrice = 0;
    var finalNonLoggedInPrice = 0;
    let wheelPriceTextLet = { ...wheelPriceText };
    // console.log("wheels", wheels)
    wheels &&
      wheels.items.forEach((wheelData, index) => {
        const regular_price = findPrice(
          wheelData.prices,
          "configurator_regular_price",
          configuratorType == 'bike' ? 'frame' : "",
          true,
        );
        const sale_price = findPrice(
          wheelData.prices,
          "configurator_regular_price",
          configuratorType == 'bike' ? 'frame' : "",
          false,
        );
        const non_loggedin_price = findPrice(
          wheelData.prices,
          "configurator_regular_price",
          configuratorType == 'bike' ? 'frame' : "",
          true,
          true
        );
        // console.log("regular_price", regular_price, sale_price, non_loggedin_price)
        if (wheelData.id === wheel) {
          finalRegularPrice = finalRegularPrice + parseFloat(regular_price);
          finalSalePrice = finalSalePrice + parseFloat(sale_price);
          finalNonLoggedInPrice = finalNonLoggedInPrice + parseFloat(non_loggedin_price);
        }
        wheelPriceTextLet[wheelData.id] = {};
        wheelPriceTextLet[wheelData.id]["regular_price"] = parseFloat(regular_price);
        wheelPriceTextLet[wheelData.id]["sale_price"] = parseFloat(sale_price);
      });
    // // console.log("wheelPriceText", wheelPriceText)
    // console.log("finalRegularPrice", finalRegularPrice)
    wheels &&
      wheels.items.forEach((wheelData, index) => {
        if (
          wheelPriceTextLet[wheelData.id] &&
          wheelPriceTextLet[wheelData.id]["regular_price"] &&
          wheelPriceTextLet[wheel] &&
          wheelPriceTextLet[wheel]["regular_price"]
        ) {
          wheelPriceTextLet[wheelData.id]["text"] =
            wheelPriceTextLet[wheelData.id]["regular_price"] -
            wheelPriceTextLet[wheel]["regular_price"];
        } else {
          wheelPriceTextLet[wheelData.id]["text"] = "";
        }
      });

    setWheelPriceText(wheelPriceTextLet);

    let otherStepPrice = {};
    // console.log(finalRegularPrice)
    otherSteps &&
      otherSteps.forEach((otherStepData, index) => {
        let step_id = otherStepData.step_id;
        var optionPriceData = null;
        if (!otherStepPrice[step_id]) {
          otherStepPrice[step_id] = [];
        }

        let free_product = 0;
        let isPriceCalcuation = false;
        otherStepData.allowed_products != null &&
          otherStepData.allowed_products.length > 0 &&
          otherStepData.allowed_products.forEach(
            (allowedProduct, allowedProductIndex) => {
              var is_affect_price = allowedProduct.affect_price;
              // if (
              //   is_affect_price &&
              //   (allowedProduct.id === otherStep[step_id] || (Array.isArray(otherStep[step_id]) && otherStep[step_id].includes(allowedProduct.id)))
              // ) {
              let regular_price = 0;
              let sale_price = 0;
              let non_loggedin_price = 0;
              let regular_price_display = 0;
              let sale_price_display = 0;
              let non_loggedin_price_display = 0;
              if (allowedProduct.type == "simple") {

                var options_price = allowedProduct.prices;
                var quantityData = productQuantity[allowedProduct.id]
                  ? productQuantity[allowedProduct.id]
                  : 0;
                regular_price_display = regular_price = findPrice(
                  options_price,
                  "configurator_regular_price",
                  configuratorType == 'bike' ? 'frame' : "",
                  true,
                );
                sale_price_display = sale_price = findPrice(
                  options_price,
                  "configurator_regular_price",
                  configuratorType == 'bike' ? 'frame' : "",
                  false,
                );
                non_loggedin_price_display = non_loggedin_price = findPrice(
                  options_price,
                  "configurator_regular_price",
                  configuratorType == 'bike' ? 'frame' : "",
                  true,
                  true
                );
                if (
                  (allowedProduct.id === otherStep[step_id] ||
                    (Array.isArray(otherStep[step_id]) &&
                      otherStep[step_id].includes(allowedProduct.id))) &&
                  options_price &&
                  is_affect_price
                ) {
                  if (!isNaN(regular_price)) {
                    finalRegularPrice =
                      finalRegularPrice +
                      parseFloat(regular_price) * parseInt(quantityData);
                    finalSalePrice =
                      finalSalePrice + parseFloat(sale_price) * parseInt(quantityData);
                    finalNonLoggedInPrice =
                      finalNonLoggedInPrice + parseFloat(non_loggedin_price) * parseInt(quantityData);
                  }
                }
              } else if (allowedProduct.type == "variable") {
                allowedProduct.options.forEach(
                  (productOptions, productOptionsIndex) => {
                    // if (
                    // Array.isArray(options[step_id])
                    //   ? options[step_id].includes(productOptions.id)
                    //   : productOptions.id === options[step_id]
                    // ) {
                    var options_price = productOptions.prices;
                    var quantityData = Array.isArray(options[step_id])
                      ? optionalQuantity[productOptions.id]
                        ? optionalQuantity[productOptions.id]
                        : 0
                      : productQuantity[allowedProduct.id]
                        ? productQuantity[allowedProduct.id]
                        : 0;
                    if (productOptions.id == allowedProduct.default) {
                      regular_price_display = findPrice(
                        options_price,
                        "configurator_regular_price",
                        configuratorType == 'bike' ? 'frame' : "",
                        true,
                      );
                      sale_price_display = findPrice(
                        options_price,
                        "configurator_regular_price",
                        configuratorType == 'bike' ? 'frame' : "",
                        false,
                      );
                      non_loggedin_price_display = findPrice(
                        options_price,
                        "configurator_regular_price",
                        configuratorType == 'bike' ? 'frame' : "",
                        true,
                        true
                      );
                    }

                    if (
                      options_price &&
                      is_affect_price &&
                      (Array.isArray(options[step_id])
                        ? options[step_id].includes(productOptions.id)
                        : productOptions.id === options[step_id])
                    ) {
                      regular_price = findPrice(
                        options_price,
                        "configurator_regular_price",
                        configuratorType == 'bike' ? 'frame' : "",
                        true,
                      );
                      sale_price = findPrice(
                        options_price,
                        "configurator_regular_price",
                        configuratorType == 'bike' ? 'frame' : "",
                        false,
                      );
                      non_loggedin_price = findPrice(
                        options_price,
                        "configurator_regular_price",
                        configuratorType == 'bike' ? 'frame' : "",
                        true,
                        true
                      );
                      if (!isNaN(regular_price)) {
                        finalRegularPrice =
                          finalRegularPrice +
                          regular_price * parseInt(quantityData);
                        finalSalePrice =
                          finalSalePrice + parseFloat(sale_price) * parseInt(quantityData);
                        finalNonLoggedInPrice =
                          finalNonLoggedInPrice + parseFloat(non_loggedin_price) * parseInt(quantityData);
                      }
                    }
                    // }
                  }
                );
                // }
              }
              if (is_affect_price != "1") {
                free_product = allowedProduct.id;
              }
              otherStepPrice[step_id][allowedProduct.id] = {};
              otherStepPrice[step_id][allowedProduct.id][
                "regular_price"
              ] = regular_price_display;
              otherStepPrice[step_id][allowedProduct.id][
                "sale_price"
              ] = sale_price_display;
              if (is_affect_price) {
                isPriceCalcuation = true;
              }
              // console.log("finalRegularPrice", step_id, finalRegularPrice);
            }
          );
        otherStepPrice[step_id]["free_product"] = free_product;
        otherStepPrice[step_id]["is_price_calculation"] = isPriceCalcuation;
      });
    // console.log("otherStepPrice", otherStepPrice);
    otherSteps &&
      otherSteps.forEach((otherStepData, index) => {
        let step_id = otherStepData.step_id;
        otherStepData.allowed_products != null &&
          otherStepData.allowed_products.length > 0 &&
          otherStepData.allowed_products.forEach(
            (allowedProduct, allowedProductIndex) => {
              var is_affect_price = allowedProduct.affect_price;

              if (
                otherStepData.is_optional_step != "1" &&
                //remove below code if not work
                otherStepPrice[step_id]["is_price_calculation"]
              ) {
                if (otherStepData.defult_selection == otherStep[step_id]) {
                  otherStepPrice[step_id][allowedProduct.id]["text"] =
                    otherStepPrice[step_id][allowedProduct.id][
                    "regular_price"
                    ] - otherStepPrice[step_id][otherStep[step_id]] &&
                    otherStepPrice[step_id][otherStep[step_id]][
                    "regular_price"
                    ] &&
                    otherStepPrice[step_id][otherStep[step_id]][
                    "regular_price"
                    ];
                } else {
                  otherStepPrice[step_id][allowedProduct.id]["text"] =
                    otherStepPrice[step_id][allowedProduct.id][
                    "regular_price"
                    ] -
                    (otherStepPrice[step_id][otherStep[step_id]]
                      ? otherStepPrice[step_id][otherStep[step_id]][
                      "regular_price"
                      ]
                      : 0);
                }
              }
            }
          );
      });

    // console.log("finalRegularPrice", finalRegularPrice);
    setOtherStepDisplayPrice(otherStepPrice);
    setPrice(finalRegularPrice);
    setSalePrice(finalSalePrice);
    setNonLoggedinPrice(finalNonLoggedInPrice);
  };

  const renderName = () => {
    let data =
      wheels &&
      wheels.items &&
      wheels.items.filter((item) => item.id === wheel);
    if (data && data[0]) {
      return data[0].name;
    }
  };
  // console.log("otherother", otherStep, productQuantity, optionalQuantity)
  // console.log("groupset", isGroupsetLoader, isModelLoading)
  return (
    <>
      <ConfiguratorContextProvider>
        <ReactTitle title={i18next.t('PageTitle.Configurator')} />
        <Header />
        <div className="configurator-page">
          <div className="wrap">
            <div id="configurator-inner" className="configurator-inner">
              <div className="configurator-sidebar">
                <Sticky
                  containerSelectorFocus="#configurator-inner"
                  offsetTop={0}
                  stickyEnableRange={[768, Infinity]}
                >
                  <div className="page-title">
                    <h3>{i18next.t("Pages.Configurator.PageTitle")}</h3>
                  </div>
                </Sticky>
                <div className="configurator-sidebar__wrapper">
                  <div className="steps-config">
                    <div>
                      {(isLoading || (isModelLoading && !configuratorData.name) || (isGroupsetLoader && !wheels)) && <>
                        {<div className={isModelLoading || isGroupsetLoader ? "loader-container" : "loader-container is-loaded"}>

                          <span className="loader"></span>
                          <div className="loader-text">{i18next.t('Pages.Configurator.LoaderLabel')}</div>

                        </div>}

                      </>}
                      {!isLoading && <>
                        <Models
                          categoryData={categoryData}
                          categorySlug={categorySlug}
                          slug={slug}
                          modelName={modelName}
                          stepId={0}
                          setConfiguratorIdURL={setConfiguratorIdURL}
                          onChangeModel={onChangeModel}
                          setSlugModel={setSlugModel}
                          setcategorySlug={setcategorySlug}
                          setModelName={setModelName}
                          setIsDefaultData={setIsDefaultData}
                        />
                      </>}
                    </div>
                    {!isLoading &&
                      <div className="configurator-loading-wrapper">

                        <div className={isModelLoading ? "loader-container loader-groupset-change" : "loader-container loader-groupset-change is-loaded"}>
                          <span className="loader"></span>
                          <div className="loader-text">
                            {i18next.t('Pages.Configurator.LoaderLabel')}
                          </div>
                        </div>

                        {configuratorData && configuratorData.name && (
                          <ConfiguratorType
                            onChangeConfiguratorType={onChangeConfiguratorType}
                            configuratorType={configuratorType}
                            stepId={3}
                            allowedBikeType={configuratorData.allowed_bike_type}
                          />
                        )}
                        {configuratorData.color &&
                          configuratorData.color["items"] &&
                          configuratorData.color["items"].length > 0 && (
                            <Color
                              setColor={setColor}
                              color={color}
                              stepId={4}
                              configuratorData={configuratorData}
                              setGroupLoader={setGroupLoader}
                              defaultColor={configuratorData.color.default}
                              setColorName={setColorName}
                            />
                          )}

                        {configuratorData.size &&
                          configuratorData.size["items"] &&
                          configuratorData.size["items"].length > 0 && (
                            <><Size
                              size={size}
                              setSize={setSize}
                              stepId={5}
                              configuratorData={configuratorData}
                              setSizePopup={setSizePopup}
                              setSizeName={setSizeName}
                            />
                              {isSizePopup && (
                                <Modal
                                  //title="Basic Modal"
                                  visible={isSizePopup}
                                  width={1580}
                                  footer={null}
                                  centered
                                  className="FindYourSizePopup"
                                  onCancel={() => {
                                    setSizePopup(false);
                                  }}
                                >
                                  <Steps
                                    current={currentStepSize}
                                    onChange={(e) => {
                                      setCurrentSizeStep(e);
                                    }}
                                  >
                                    <Step key="0" title="Size Data" />
                                    <Step key="1" title="Find your Size" />
                                  </Steps>
                                  {currentStepSize == 0 && (
                                    <>
                                      <div>
                                        <div className="title-geometry-popup">
                                          <h3>Geometry</h3>
                                        </div>
                                        <Geometrics
                                          module={configuratorData.geometrie}
                                          isStep={true}
                                          setCurrentSizeStep={setCurrentSizeStep}
                                          modelName={configuratorData.name}
                                          modalId={configuratorData.id}
                                        />
                                        {/* <Button onClick={() => setCurrentSizeStep(1)}>
                                        Can't find your size
                                      </Button> */}
                                      </div>
                                    </>
                                  )}
                                  {currentStepSize == 1 && (
                                    <>
                                      <FindYourSize modalId={configuratorData.id} />
                                      <div className="back-to-geometry">
                                        <div onClick={() => setCurrentSizeStep(0)}>
                                          Go back to geometry
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </Modal>
                              )}
                            </>
                          )}

                        {configuratorType !== "frame_kit" &&
                          configuratorType !== "build_kit" &&
                          configuratorData.groupset &&
                          configuratorData.groupset["items"] &&
                          configuratorData.groupset["items"].length > 0 && (
                            <GroupSet
                              onChangeGroup={onChangeGroup}
                              stepId={6}
                              groupId={groupId}
                              configuratorData={configuratorData}
                              setCassette={setCassette}
                              cassette={cassette}
                              setCrankArm={setCrankArm}
                              crankArm={crankArm}
                              setChainRings={setChainRings}
                              chainRings={chainRings}
                              configuratorType={configuratorType}
                              setGroupName={setGroupName}
                              setConfiguratorIdURL={setConfiguratorIdURL}
                            />
                          )}
                        {!isModelLoading && <div>
                          {/*                           {isGroupsetLoader && <>
                            <div className={isGroupsetLoader ? "loader-container" : "loader-container is-loaded"}>
                              <span className="loader"></span>
                              <div className="loader-text">Stiamo preparando la bici...</div>
                            </div>
                          </>} */}
                          {configuratorType !== "frame_kit" &&
                            (groupId === 0 || groupId || groupId == "") &&
                            wheels &&
                            wheels.items.length > 0 && (
                              <>
                                <Wheel
                                  setConfiguratorIdURL={setConfiguratorIdURL}
                                  onChangeWheel={onChangeWheel}
                                  wheel={wheel}
                                  wheels={wheels}
                                  wheelPriceText={wheelPriceText}
                                  stepId={
                                    configuratorType === "frame_kit" ||
                                      configuratorType === "build_kit"
                                      ? "6"
                                      : "7"
                                  }
                                />
                              </>
                            )}
                          {(groupId === 0 || groupId) &&
                            otherSteps &&
                            otherSteps.length > 0 &&
                            otherSteps.map((other, otherIndex) => {
                              if (
                                (!other.is_disable_step ||
                                  other.is_disable_step !== "1") &&
                                disableStep[other.step_id] === false
                              ) {
                                otherstepStart++;
                              }
                              return (
                                Object.keys(disableStep).length > 0
                                  ? !disableStep[other.step_id]
                                  : true
                              ) ? (
                                <OtherSteps
                                  onChangeOtherSteps={onChangeOtherSteps}
                                  other={other}
                                  otherIndex={otherIndex}
                                  productIndex={productIndex}
                                  otherStep={otherStep}
                                  disbaleProductId={disbaleProductId}
                                  productQuantity={productQuantity}
                                  onChangeOtherOptions={onChangeOtherOptions}
                                  options={options}
                                  optionalQuantity={optionalQuantity}
                                  onChangesOptionalQuantity={
                                    onChangesOptionalQuantity
                                  }
                                  onChangeOtherOptionsChecbox={
                                    onChangeOtherOptionsChecbox
                                  }
                                  onChangeOtherStepsCheckbox={
                                    onChangeOtherStepsCheckbox
                                  }
                                  onChangesQantity={onChangesQantity}
                                  stepId={otherstepStart}
                                  stepTitle={other.step_label}
                                  style={
                                    other.is_disable_step === "1"
                                      ? { display: "none" }
                                      : {}
                                  }
                                  onChangesQantityForOptions={
                                    onChangesQantityForOptions
                                  }
                                  otherStepDisplayPrice={otherStepDisplayPrice}
                                />
                              ) : (
                                <></>
                              );
                            })}
                        </div>}
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="configurator-summary">
                <Sticky
                  containerSelectorFocus="#configurator-inner"
                  offsetTop={0}
                  stickyEnableRange={[768, Infinity]}
                >
                  <div className="configurator-summary__wrapper">
                    <div className="configurator-summary__wrapper__details">
                      <div className="title">
                        <h3>Riepilogo</h3>
                      </div>
                      <div className="configurator-summary__content">
                        <div className="summary-item summary-model">
                          <div className="summary-item__title">
                            <h3>Model</h3>
                          </div>
                          <div className="summary-item__value">
                            <p>{modelName ? modelName : i18next.t('Pages.Configurator.NotSelected')}</p>
                          </div>
                        </div>
                        <div className="summary-item summary-groupset">
                          <div className="summary-item__title">
                            <h3>Type</h3>
                          </div>
                          <div className="summary-item__value">
                            <p>
                              {CONFIGURATOR_TYPE[configuratorType]
                                ? CONFIGURATOR_TYPE[configuratorType]
                                : i18next.t('Pages.Configurator.NotSelected')}
                            </p>
                          </div>
                        </div>
                        <div className="summary-item summary-groupset">
                          <div className="summary-item__title">
                            <h3>Groupset</h3>
                          </div>
                          <div className="summary-item__value">
                            <p>{groupName ? groupName : i18next.t('Pages.Configurator.NotSelected')}</p>
                          </div>
                        </div>
                        <div className="summary-item summary-color">
                          <div className="summary-item__title">
                            <h3>Color</h3>
                          </div>
                          <div className="summary-item__value">
                            <p>{colorName ? colorName : i18next.t('Pages.Configurator.NotSelected')}</p>
                          </div>
                        </div>
                        <div className="summary-item summary-size">
                          <div className="summary-item__title">
                            <h3>Size</h3>
                          </div>
                          <div className="summary-item__value">
                            <p>{sizeName ? sizeName : i18next.t('Pages.Configurator.NotSelected')}</p>
                          </div>
                        </div>
                        <div className="summary-item summary-size">
                          <div className="summary-item__title">
                            <h3>Wheel</h3>
                          </div>
                          <div className="summary-item__value">
                            <p>{renderName() ? renderName() : i18next.t('Pages.Configurator.NotSelected')}</p>
                          </div>
                        </div>
                        {(groupId === 0 || groupId) &&
                          otherSteps &&
                          otherSteps.length > 0 &&
                          otherSteps.map((other, otherIndex) => {
                            // if (
                            //   !other.is_disable_step ||
                            //   other.is_disable_step !== "1"
                            // ) {
                            //   otherstepStart++;
                            // }

                            return (
                              (

                                (Object.keys(disableStep).length == 0 || (Object.keys(disableStep).length > 0 && !disableStep[other.step_id])) &&
                                (!other.is_disable_step ||
                                  other.is_disable_step !== "1")
                              ) ? (
                                <OtherStepsDataSummary
                                  other={other}
                                  stepTitle={other.step_label}
                                  otherStep={otherStep}
                                  quantity={productQuantity}
                                  optionalQuantity={optionalQuantity}
                                  key={otherIndex}
                                />
                              ) : (
                                <React.Fragment key={otherIndex}></React.Fragment>
                              ))

                          })}
                      </div>
                    </div>
                    <div className="configurator-summary__wrapper__details">
                      <div className="title">
                        <h3>Preview</h3>
                      </div>
                      <div
                        className={
                          isModelLoading || isGroupsetLoader
                            ? "configurator-image-wrap is-loaded"
                            : "configurator-image-wrap is-loaded"
                        }

                        ref={configuratorImageWrap}

                      >
                        <div className="image-loader">
                          <div className={isModelLoading || isGroupsetLoader ? "loader-container" : "loader-container is-loaded"}>
                            <span className="loader"></span>
                            <div className="loader-text">{i18next.t('Pages.Configurator.LoaderLabel')}</div>
                          </div>
                        </div>
                        {configuratorViewImages && configuratorViewImages.view && (
                          <>
                            <ConfiguratorView
                              configuratorViewImages={configuratorViewImages}
                              color={color}
                              wheel={wheel}
                              otherImages={otherImages}
                              otherOptionalImages={otherOptionalImages}
                              previewOrder={previewOrder}
                              groupsetImages={groupsetImages}
                              canvasWidth={canvasWidth}
                              setGroupLoader={setGroupLoader}
                              activeView={activeView}
                              setActiveView={setActiveView}
                              setViewModel={setViewModel}
                            />
                            <Modal
                              visible={isViewModel}
                              width={1200}
                              footer={null}
                              centered
                              closeIcon={<svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.99 222.99"><path d="M461.5,192.25l-23.74-23.74L350,256.26l-87.75-87.75-23.74,23.74L326.26,280l-87.75,87.76,23.74,23.74L350,303.75l87.76,87.75,23.74-23.74L373.75,280Z" transform="translate(-238.51 -168.51)" /></svg>}
                              onCancel={() => {
                                setViewModel(false);
                              }}>
                              <ConfiguratorView
                                configuratorViewImages={configuratorViewImages}
                                color={color}
                                wheel={wheel}
                                otherImages={otherImages}
                                otherOptionalImages={otherOptionalImages}
                                previewOrder={previewOrder}
                                groupsetImages={groupsetImages}
                                canvasWidth={canvasWidthPopup}
                                setGroupLoader={setGroupLoader}
                                activeView={activeView}
                                setActiveView={setActiveView}
                                setViewModel={setViewModel}
                              />
                            </Modal>
                          </>
                        )}

                      </div>
                    </div>
                  </div>
                </Sticky>
              </div>
              <ActionsBar
                price={price}
                salePrice={salePrice}
                nonLoggedInPrice={nonLoggedInPrice}
                productId={productId}
                wheel={wheel}
                configuratorId={(configuratorDetail.configurator_id) ? configuratorDetail.configurator_id : ""}
                modalId={configuratorData.id}
                color={color}
                size={size}
                groupId={groupset}
                cassette={cassette}
                crankArm={crankArm}
                chainRings={chainRings}
                otherStep={otherStep}
                options={options}
                configuratorType={configuratorType}
                productQuantity={productQuantity}
                optionalQuantity={optionalQuantity}
                setGroupLoader={setGroupLoader}
                isGroupsetLoader={isGroupsetLoader}
                disableStep={disableStep}
                otherStepLength={productdata && productdata.other_steps && productdata.other_steps.length ? productdata.other_steps.length : 0}
              />
            </div>
          </div>
        </div>
      </ConfiguratorContextProvider>
    </>
  );
};

export default ConfiguratorContainer;
