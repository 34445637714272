import { request } from "../services/apiService";
import {
    FETCH_ADDRESS_PENDING,
    FETCH_ADDRESS_SUCCESS,
    FETCH_ADDRESS_FAILURE,
    ADD_ADDRESS_PENDING,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_FAILURE,
    UPDATE_ADDRESS_PENDING,
    UPDATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_FAILURE,    
    DELETE_ADDRESS_PENDING,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_FAILURE,
    ADDRESS_POPUP
} from "./actionTypes";




export const getAddress = (type) => dispatch => {
    dispatch({
        type: FETCH_ADDRESS_PENDING
    });
    const url = `v1/address/get_address/?type=${type}`;
    request("get", url, {}, true).then(
        (res) => {
            dispatch({
                type: FETCH_ADDRESS_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: FETCH_ADDRESS_FAILURE,
                data: err.response,
            });
        }
    );
}

export const createAddress = (data) => dispatch => {
    dispatch({
        type: ADD_ADDRESS_PENDING
    });
    const url = `v1/address/add_address/`;
    request("post", url, data, true).then(
        (res) => {
            dispatch({
                type: ADD_ADDRESS_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: ADD_ADDRESS_FAILURE,
                data: err.response,
            });
        }
    );
}

export const updateAddress = (data) => dispatch => {
    dispatch({
        type: UPDATE_ADDRESS_PENDING
    });
    const url = `v1/address/update_address/`;
    request("post", url, data, true).then(
        (res) => {
            dispatch({
                type: UPDATE_ADDRESS_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: UPDATE_ADDRESS_FAILURE,
                data: err.response,
            });
        }
    );
}


export const deleteAddress = (data) => dispatch => {
    dispatch({
        type: DELETE_ADDRESS_PENDING
    });
    const url = `v1/address/delete_address/`;
    request("post", url, data, true).then(
        (res) => {            
            dispatch(getAddress(data.type));
            dispatch({
                type: DELETE_ADDRESS_SUCCESS,
                data: res,
            });
        },
        (err) => {
            dispatch({
                type: DELETE_ADDRESS_FAILURE,
                data: err.response,
            });
        }
    );
}

export const openAddressPopup = (data) => dispatch => {
    dispatch({
        type: ADDRESS_POPUP,
        data: data
    })
}