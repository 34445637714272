import { request } from "../services/apiService";
import {
    GET_AGENT_DEALER_LIST_PENDING,
    GET_AGENT_DEALER_LIST_SUCCESS,
    GET_AGENT_DEALER_LIST_FAILURE,
    SET_AGENT_SELECTED_DEALER
} from "./actionTypes";
import Cookies from "js-cookie";
import { i18n } from "../translations/i18n";
import history from "../history";


export const getAgentDealerList = () => dispatch => {
    dispatch({
        type: GET_AGENT_DEALER_LIST_PENDING
    });
    const url = `v1/dealer/agent-list/`;
    request("get", url, '', true).then(
        (res) => {
            dispatch({
                type: GET_AGENT_DEALER_LIST_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: GET_AGENT_DEALER_LIST_FAILURE,
                error: err.response.data.message,
            });
        }
    );
}

export const setAgentDealer = (dealer, countryDataLocal) => dispatch => {
    localStorage.setItem(
        "platform-access",
        JSON.stringify(dealer.platform)
    );
    localStorage.setItem("agent-selected-user-roles", JSON.stringify(dealer.roles));
    localStorage.setItem("agent-selected-user", JSON.stringify(dealer));
    Cookies.set("platformkey", '')
    if (countryDataLocal === undefined) {
        alert("something went wrong");
    }
    Cookies.set("stardue-carthash", '');
    Cookies.set("stardue-cartcount", 0);
    Object.keys(countryDataLocal).map((key, index) => {
        var country_data = countryDataLocal[key]['country_list'];
        Object.keys(country_data).map((country_key, index_country) => {
            
            let country_data_single = country_data[country_key];
            if (country_data_single.country_code == dealer.country_code) {
                localStorage.setItem('stardue_country', dealer.country_code)
                localStorage.setItem('stardue_flat_discount', dealer.flat_discount)
                localStorage.setItem('stardue_flat_discount_frame_kit', dealer.flat_discount_frame_kit)
                localStorage.setItem('stardue_price_list', country_data_single.price_list);
                localStorage.setItem('stardue_currency', country_data_single.currency);
            }
        })
    })

    history.push("/" + i18n.language + '/' + i18n.t('routes.SwitchBrand'), true);
    dispatch({
        type: SET_AGENT_SELECTED_DEALER
    });
}