import React from "react";

function LoginLockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.943"
      height="30.384"
      viewBox="0 0 28.943 30.384"
    >
      <g transform="translate(-21.637 -10.382)">
        <path
          d="M36.11 10.382a7.971 7.971 0 00-7.957 7.957v3.622h-2.168a4.365 4.365 0 00-4.348 4.339v10.131a4.365 4.365 0 004.349 4.334h20.248a4.363 4.363 0 004.346-4.334V26.3a4.363 4.363 0 00-4.346-4.334h-2.17v-3.628a7.969 7.969 0 00-7.954-7.957zm0 2.9a5.016 5.016 0 015.061 5.061v3.622H31.046v-3.627a5.019 5.019 0 015.063-5.061zM25.985 24.854h20.249a1.413 1.413 0 011.452 1.446v10.131a1.413 1.413 0 01-1.452 1.441H25.985a1.413 1.413 0 01-1.452-1.441V26.3a1.413 1.413 0 011.452-1.441z"
          data-name="Path 5"
        ></path>
        <path
          d="M33.962 25.973a1.447 1.447 0 11-1.447-1.447 1.447 1.447 0 011.447 1.447z"
          data-name="Path 6"
          transform="translate(3.594 5.389)"
        ></path>
        <path
          d="M29.772 25.973a1.447 1.447 0 11-1.447-1.447 1.447 1.447 0 011.447 1.447z"
          data-name="Path 7"
          transform="translate(1.997 5.389)"
        ></path>
        <path
          d="M38.153 25.973a1.447 1.447 0 11-1.447-1.447 1.447 1.447 0 011.447 1.447z"
          data-name="Path 8"
          transform="translate(5.19 5.389)"
        ></path>
      </g>
    </svg>
  );
}

export default LoginLockIcon;
