import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Checkbox, Row, Col, Modal } from "antd";
import i18next from "i18next";
import countryData from "../../utils/country";

const { Option } = Select;

const DealerShopPopup = ({
  formData,
  setFormData,
  onCreate,
  onUpdate,
  setPopupOpen,
  popupOpen,
  defaultCountry = "",
  isLoadingDealerShop,
  isPopupButtonLoading
}) => {
  const [form] = Form.useForm();
  const [id, setDealsShopId] = useState(null);
  const [is_default, setDefault] = useState(false);
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  useEffect(() => {
    if (formData) {
      console.log(formData);
      let let_formdata = {
        ID:formData.ID,
        company_name: formData.company_name,
        address_1: formData.street,
        postcode: formData.postal_code,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        country_code: formData.company_name,
        phone: formData.phone,
        vat: formData.vat,
        coordinates_latitude: formData.latitude,
        coordinates_longitude: formData.longitude,
        dealer_id: userData.id,
      }

      if (formData.ID) setDealsShopId(formData.ID);
      form.setFieldsValue(let_formdata);
    } else {
    }
  }, [formData]);

  const onCloseAddressPoup = () => {
    let data = {
      company_name: "",
      street: "",
      postal_code: "",
      city: "",
      state: "",
      country: "",
      country_code: "",
      phone: "",
      vat: "",
      coordinates_latitude: "22.307156",
      coordinates_longitude: "73.181218",
      dealer_id: userData.id,
      dealer_code: "",
    };
    form.setFieldsValue(data);
    setFormData(data);
    form.resetFields();
    setPopupOpen(false);
  };

  const onFinishFailed = (error) => {
    console.error(error);
  };

  const addOrUpdateDealersShop = async (values) => {
  
    if (id) {
      onUpdate(values);
    } else {
      onCreate(values);
    }
  };

  return (
    <>
      <Modal
        className="modal-s2 modal-address"
        centered
        width={1000}
        footer={null}
        visible={popupOpen}
        onCancel={() => {
          onCloseAddressPoup();
        }}
      >
        <div className="modal-header">
          <h2>{i18next.t("Common.NewAddress")}</h2>
        </div>
        <div className="modal-content">
          <div className="form-wrapper">
            <p className="Note">{i18next.t("Common.RequiredFields")}</p>
            {isLoadingDealerShop ? <div> We are Loading data ... </div> : <div>
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{
                  remember: true,
                  country: defaultCountry ? defaultCountry : "",
                }}
                onFinish={addOrUpdateDealersShop}
                onFinishFailed={onFinishFailed}
              >
                <Row
                  gutter={[
                    { xs: 8, sm: 16, lg: 24 },
                    { xs: 0, sm: 16, lg: 24 },
                  ]}
                >
                  <Form.Item name="ID" noStyle>
                      <Input type="hidden" />
                  </Form.Item>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                    <label className="Form-label">
                      {i18next.t("Common.Form.companyname")}
                    </label>
                    <Form.Item
                      name="company_name"
                      rules={[
                        {
                          required: true,
                          message: i18next.t("Common.Form.require_companyname"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                    <label className="Form-label">
                      {i18next.t("Common.Form.vat")}
                    </label>
                    <Form.Item
                      name="vat"
                      rules={[
                        {
                          required: true,
                          message: i18next.t("Common.Form.require_name"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <h3>{i18next.t("Common.Form.address")}</h3>
                <Row
                  gutter={[
                    { xs: 8, sm: 16, lg: 24 },
                    { xs: 0, sm: 16, lg: 24 },
                  ]}
                >
                  <Col xs={{ span: 24 }} sm={{ span: 10 }} lg={{ span: 10 }}>
                    <label className="Form-label">
                      {i18next.t("Common.Form.country")}
                    </label>
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: i18next.t("Common.Form.require_country"),
                        },
                      ]}
                    >
                      <Select
                        // placeholder={(i18next.t('Common.Form.country')) }
                        defaultValue=""
                        disabled={defaultCountry ? true : false}
                      >
                        <Option key="-1" value="">
                          Select Country
                        </Option>
                        {Object.keys(countryData).map((data, index) => {
                          return (
                            <Option key={index} value={data}>
                              {countryData[data]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 16 }} sm={{ span: 10 }} lg={{ span: 10 }}>
                    <label className="Form-label">
                      {i18next.t("Common.Form.city")}
                    </label>
                    <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: i18next.t("Common.Form.require_city"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 8 }} sm={{ span: 4 }} lg={{ span: 4 }}>
                    <label className="Form-label">
                      {i18next.t("Common.Form.province")}
                    </label>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: i18next.t("Common.Form.require_province"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[
                    { xs: 8, sm: 16, lg: 24 },
                    { xs: 0, sm: 16, lg: 24 },
                  ]}
                >
                  <Col xs={{ span: 16 }} sm={{ span: 20 }} lg={{ span: 20 }}>
                    <label className="Form-label">
                      {i18next.t("Common.Form.street")}
                    </label>
                    <Form.Item
                      name="address_1"
                      rules={[
                        {
                          required: true,
                          message: i18next.t("Common.Form.require_street"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 8 }} sm={{ span: 4 }} lg={{ span: 4 }}>
                    <label className="Form-label">
                      {i18next.t("Common.Form.postalcode")}
                    </label>
                    <Form.Item
                      name="postcode"
                      rules={[
                        {
                          required: true,
                          message: i18next.t("Common.Form.require_postalcode"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  align="top"
                  gutter={[
                    { xs: 8, sm: 16, lg: 24 },
                    { xs: 0, sm: 16, lg: 24 },
                  ]}
                >
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
                    <h3>{i18next.t("Common.Form.contact")}</h3>
                    <label className="Form-label">
                      {i18next.t("Common.Form.phone")}
                    </label>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: i18next.t("Common.Form.require_province"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className="text-center text-lg-end form-confirm">
                  <Button
                    type="primary"
                    // loading={isLoading}
                    htmlType={"submit"}
                    loading={isPopupButtonLoading}
                  >
                    <>{i18next.t("Common.Confirm")}</>
                  </Button>
                </Form.Item>
              </Form>
            </div>}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DealerShopPopup;
