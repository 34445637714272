import React from "react";

function LoginUserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.804"
      height="30.384"
      viewBox="0 0 27.804 30.384"
    >
      <g transform="translate(-17.75 -14.75)">
        <path
          d="M36.065 31a8.729 8.729 0 10-8.825 0 13.917 13.917 0 00-9.49 13.172.97.97 0 001.94 0 11.962 11.962 0 0123.925 0 .97.97 0 001.94 0A13.917 13.917 0 0036.065 31zm-11.2-7.519a6.789 6.789 0 116.789 6.789 6.8 6.8 0 01-6.791-6.791z"
          data-name="Path 1"
        ></path>
      </g>
    </svg>
  );
}

export default LoginUserIcon;
