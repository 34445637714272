import {
  FETCH_PRODUCT_LIST_PENDING,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILURE,
  FETCH_PRODUCT_FILTER_PENDING,
  FETCH_PRODUCT_FILTER_SUCCESS,
  FETCH_PRODUCT_FILTER_FAILURE,
  GET_PRODUCT_SIZE_PENDING ,
  GET_PRODUCT_SIZE_SUCCESS,
  GET_PRODUCT_SIZE_FAILURE
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  message: '',
  productList: [],
  total: 0,
  filterList: [],
  filter: {},
  isFilterLoading: false,
  isSizeLoading: false,
  sizeList: [],
  sizeNotFound:false
};

export default function productList(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case FETCH_PRODUCT_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true
      });
    case FETCH_PRODUCT_LIST_SUCCESS:
      return Object.assign({}, state, {
        productList: action.data.data.products,
        total: action.data.data.total,
        isLoading: false
      });
    case FETCH_PRODUCT_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isLoading: false
      });
    case FETCH_PRODUCT_FILTER_PENDING:
      return Object.assign({}, state, {
        isFilterLoading: true
      });
    case FETCH_PRODUCT_FILTER_SUCCESS:
      return Object.assign({}, state, {
        filterList: action.data.data,
        isFilterLoading: false
      });
    case FETCH_PRODUCT_FILTER_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isFilterLoading: false
      });
    case GET_PRODUCT_SIZE_PENDING:
      return Object.assign({}, state, {
        isSizeLoading: true,
        sizeNotFound:false
      });
    case GET_PRODUCT_SIZE_SUCCESS:
      return Object.assign({}, state, {
        sizeList: action.data.data,
        isSizeLoading: false,
        sizeNotFound:false
      });
    case GET_PRODUCT_SIZE_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isSizeLoading: false,
        sizeNotFound:true
      });
    default:
      return state;
  }
}
