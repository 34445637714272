import {
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTH_LOGOUT,
  GET_COUNTRY_DATA
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  message: '',
  countryData: {}
};

export default function LoginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false,
      });
    case LOGIN_SUCCESS:
      const user = action.payload.user;
      const countryDataLocal = state.countryData;
      Object.keys(state.countryData).map((key, index) => {
        var country_data = countryDataLocal[key]['country_list'];
        Object.keys(country_data).map((country_key, index_country) => {
          let country_data_single = country_data[country_key];
          if (country_data_single.country_code == user.dealer.country_code) {
            localStorage.setItem('stardue_country', user.dealer.country_code)
            localStorage.setItem('stardue_flat_discount', user.dealer.flat_discount)
            localStorage.setItem('stardue_flat_discount_frame_kit', user.dealer.flat_discount_frame_kit)
            localStorage.setItem('stardue_price_list', country_data_single.price_list);
            localStorage.setItem('stardue_currency', country_data_single.currency);
          }
        })
      })
      return Object.assign({}, state, {
        user: action.payload,
        isError: false,
        isLoading: false,
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        message: action.message,
        isError: true,
        isLoading: false
      });
    case AUTH_LOGOUT:
      return Object.assign({}, state, {
        authToken: ''
      });
    case GET_COUNTRY_DATA:
      try {
        let userData = JSON.parse(localStorage.getItem('user'));
        if (localStorage.getItem('agent-selected-user')) {
          try {
            userData = JSON.parse(localStorage.getItem('agent-selected-user'));
          } catch (e) {

          }
        }
        let country_code = '';
        if (userData.country_code) {
          country_code = userData.country_code;
        } else {
          country_code = userData.dealer.country_code;
        }
        if (country_code) {
          let countryDataLocal = action.payload.data;
          Object.keys(action.payload.data).map((key, index) => {
            var country_data = countryDataLocal[key]['country_list'];
            Object.keys(country_data).map((country_key, index_country) => {
              let country_data_single = country_data[country_key];
              if (country_data_single.country_code == country_code) {
                localStorage.setItem('stardue_country', country_code);
                localStorage.setItem('stardue_price_list', country_data_single.price_list);
                localStorage.setItem('stardue_currency', country_data_single.currency);
              }
            })
          })
        }
      } catch (e) {
        console.log("GET_COUNTRY_DATA", e)
      }


      return Object.assign({}, state, {
        countryData: action.payload.data
      })
    default:
      return state;
  }
}
