import React from 'react';
import { useState } from 'react';

export const ConfiguratorContext = React.createContext();


const ConfiguratorContextProvider = (props) => {
    const [imageData, setImageData] = useState();
    const updateImageState = (state) => {
        setImageData(state);
    };

    return (
        <ConfiguratorContext.Provider
            value={{
                imageData,
                updateImageState
            }}
        >
            {props.children}
        </ConfiguratorContext.Provider>
    );
};

export default ConfiguratorContextProvider;