import React from "react";
import BassoSizeGuide from "../basso/BassoSizeGuide";
import Cookies from "js-cookie";

const SizeGuide = ({ module }) => {
  return (
    <>
      {Cookies.get('platformkey') == "7368496012" && (
        <BassoSizeGuide
          module={module}
        />
      )}
      {process.env.platform == "leecougan" && (
        <></>
      )}
    </>
  );
};
export default SizeGuide;
