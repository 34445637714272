import { request } from "../services/apiService";
import {
  FETCH_ORDER_DETAIL_PENDING,
  FETCH_ORDER_DETAIL_SUCCESS,
  FETCH_ORDER_DETAIL_FAILURE,
  ORDER_RETURN_PENDING,
  ORDER_RETURN_SUCCESS,
  ORDER_RETURN_FAILURE
} from "./actionTypes";

export const getOrderDetail = (id) => (dispatch) => {
  dispatch({
    type: FETCH_ORDER_DETAIL_PENDING
  });
  var url = "v1/db/orders/" + id;
  request("get", url, "", true).then(
    (orderResponse) => {
      dispatch({
        type: FETCH_ORDER_DETAIL_SUCCESS,
        data: orderResponse.data
      });
    },
    (error) => {
      dispatch({
        type: FETCH_ORDER_DETAIL_FAILURE,
        data: error.response,
      }
      );
    }
  );
};


export const orderReturn = (data) => (dispatch) => {
  dispatch({
    type: ORDER_RETURN_PENDING
  });
  var url = "v1/order/return";
  request("post", url, data, true).then(
    (orderResponse) => {
      dispatch({
        type: ORDER_RETURN_SUCCESS,
        data: orderResponse.data
      });
    },
    (error) => {
      dispatch({
        type: ORDER_RETURN_FAILURE,
        data: error.response,
      }
      );
    }
  );
};
