import {
  CREATE_TICKET_PENDING,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_FAILURE,

  TICKET_LIST_PENDING,
  TICKET_LIST_SUCCESS,
  TICKET_LIST_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  total: 0,
  tickets: [],
  type: '',
  isCreateLoading: false,
  ticketCreated: false
};

export default function TrainingReducer(state = initialState, action) {
  switch (action.type) {
    case TICKET_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        ticketCreated: false,
        isCreateLoading: false,
      });
    case TICKET_LIST_SUCCESS:
      return Object.assign({}, state, {
        tickets: action.data.tickets,
        isLoading: false,
        total: action.data.total_ticket
      });
    case TICKET_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.error.data.message,
        isLoading: false
      });
    case CREATE_TICKET_PENDING:
      return Object.assign({}, state, {
        isCreateLoading: true,
        ticketCreated: false,
      });
    case CREATE_TICKET_SUCCESS:
      return Object.assign({}, state, {
        ticketCreated: true,
        isCreateLoading: false
      });
    case CREATE_TICKET_FAILURE:
      return Object.assign({}, state, {
        message: action.error.data.message,
        isCreateLoading: false
      });
    default:
      return state;
  }
}