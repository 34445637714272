import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ItemsDetail from '../../components/ItemsDetail';
import { getCart } from "../../actions/cartActions";
import { createOrder, resetCreateOrderNotification } from "../../actions/createOrderActions";
import { Button, Skeleton, notification, Checkbox, Row, Col, Input } from 'antd';
import Cookies from 'js-cookie';
import AddressListComponent from "../../components/AddressListComponent";
import { ReactTitle } from 'react-meta-tags';
import { getSearchUser } from "../../actions/registerBike";
import PriceFormat from '../../components/PriceFormat';
import i18next from 'i18next';

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const [dealerData, setDealerData] = useState(JSON.parse(localStorage.getItem('user')));
  const [orderForDealer, setOrderforDealer] = useState();
  const [isShippingsameasBilling, setShippingSameasBilling] = useState(0);
  const [isOrderForCustomer, setIsOrderForCustomer] = useState(false);
  const [userFieldDisabled, setUserFieldDisabled] = useState(true);
  const [iscreateOrderDisable, setCreateOrderDisable] = useState(true);
  const { cart, isLoading, totalItem } = useSelector(state => {
    return state.cart
  });
  const { isCreatingOrder, createOrderMessage, isCreateOrderError, isCreateOrderSuccess } = useSelector(state => {
    return state.createOrder
  });
  const { isUserSuccess, isUserError, isUserLoading, userList } = useSelector(state => {
    return state.registerBike
  });
  const [selectAddressId, setSelecteAdressId] = useState(Cookies.get('shipping_address_id'));
  const [isFreeShipping, setFreeShipping] = useState(false);

  const [customerName, setCustomerName] = useState('');
  const [surName, setSurname] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false)


  useEffect(() => {
    dispatch(getCart('yes', true));
  }, []);

  const onSetSelecteAdress = (data) => {
    console.log("onSetSelecteAdress", data);
    setSelecteAdressId(data.id);
    Cookies.set('shipping_address_id', data.id);
  }

  useEffect(() => {
    if (dealerData.roles.includes('agent')) {
      setOrderforDealer(JSON.parse(localStorage.getItem('agent-selected-user')));
    } else {
      setOrderforDealer(dealerData.dealer)
    }
  }, [dealerData])

  const createOrderB2b = () => {
    let orderData = {};
    orderData['carthash'] = cart.carthash;
    orderData['payment_method'] = 'basc';
    orderData['b2b_shipping_as_billing'] = isShippingsameasBilling;
    orderData['order_created_from'] = 'b2b';
    orderData['order_for_dealer'] = orderForDealer.id;
    orderData['shipping_address'] = Cookies.get('shipping_address_id');
    orderData['is_order_for_customer'] = isOrderForCustomer;
    orderData['last_name'] = surName;
    orderData['first_name'] = customerName;
    orderData['order_for_customer_email'] = userEmail;
    orderData['self_shipping'] = isFreeShipping;
    dispatch(createOrder(orderData));
  }

  useEffect(() => {
    if (isCreateOrderError) {
      notification['error']({
        message: 'Error in creating order',
        description: createOrderMessage,
      });
      dispatch(resetCreateOrderNotification());
    }
    if (isCreateOrderSuccess) {
      notification['success']({
        message: 'Order Created Successfully',
        description: createOrderMessage,
      });
      dispatch(resetCreateOrderNotification());
    }

  }, [isCreateOrderError, createOrderMessage, isCreateOrderSuccess]);

  useEffect(() => {
    isUserError && setUserFieldDisabled(false)
  }, [isUserError]);

  const onSearch = (searchText) => {
    setUserEmail(searchText);
    let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(searchText)) {
      dispatch(getSearchUser(searchText));
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  }

  useEffect(() => {
    if (userList.id) {
      setSurname(userList.firstName);
      setCustomerName(userList.lastName);
    } else {
      setSurname('')
      setCustomerName('')
    }
  }, [userList]);

  useEffect(() => {
    if (!isFreeShipping && isShippingsameasBilling != 1 && (selectAddressId != '' && selectAddressId != undefined)) {
      setCreateOrderDisable(false);
    } else if (isShippingsameasBilling == 1) {
      setCreateOrderDisable(false);
    } else if (isFreeShipping) {
      setCreateOrderDisable(false);
    } else {
      setCreateOrderDisable(true);
    }
  }, [isFreeShipping, selectAddressId, isShippingsameasBilling]);
  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.Checkout')} />
      <Header />
      <div className="checkout-page">
        <div className="wrap">
          <div className="checkout-heading">
            <h1>{i18next.t('Pages.Checkout.CartTitle')}</h1>
          </div>
          {totalItem == '0' && !isLoading && <>
            <div className="checkout-container">

              <div className="checkout-box no-product-in-cart">
                <p>{i18next.t('Pages.Checkout.CartTitle')}</p>
                <button className="back-to">{i18next.t('Pages.Checkout.NoProducts')}</button>
              </div>

            </div> </>}
          {(totalItem > '0' || isLoading) && <>
            <div className="checkout-container">
              <div className="checkout-box">
                <Skeleton loading={isLoading}>
                  <div className="table rel checkout-table">
                    <div className="hdr sticky">
                      <Row gutter={[24, 24]}>
                        <Col
                          span={3}
                          xs={{ span: 3 }}
                          sm={{ span: 3 }}
                          lg={{ span: 3 }}
                        >
                          <div className="table-header-item">{i18next.t('Pages.Checkout.Table.img')}</div>
                        </Col>
                        <Col
                          span={5}
                          xs={{ span: 5 }}
                          sm={{ span: 5 }}
                          lg={{ span: 5 }}
                        >
                          <div className="table-header-item">{i18next.t('Pages.Checkout.Table.Product')}</div>
                        </Col>
                        <Col
                          span={4}
                          xs={{ span: 4 }}
                          sm={{ span: 4 }}
                          lg={{ span: 4 }}
                        >
                          <div className="table-header-item">{i18next.t('Pages.Checkout.Table.Sku')}</div>
                        </Col>
                        <Col
                          span={3}
                          xs={{ span: 3 }}
                          sm={{ span: 3 }}
                          lg={{ span: 3 }}
                        >
                          <div className="table-header-item">{i18next.t('Pages.Checkout.Table.Qty')}</div>
                        </Col>
                        <Col
                          span={3}
                          xs={{ span: 3 }}
                          sm={{ span: 3 }}
                          lg={{ span: 3 }}
                        >
                          <div className="table-header-item">{i18next.t('Pages.Checkout.Table.Price')}</div>
                        </Col>
                        <Col
                          span={3}
                          xs={{ span: 3 }}
                          sm={{ span: 3 }}
                          lg={{ span: 3 }}
                        >
                          <div className="table-header-item">{i18next.t('Pages.Checkout.Table.Total')}</div>
                        </Col>
                        <Col
                          span={3}
                          xs={{ span: 3 }}
                          sm={{ span: 3 }}
                          lg={{ span: 3 }}
                        >
                          <div className="table-header-item"></div>
                        </Col>
                      </Row>
                    </div>
                    <div className="table-container-wrapper">
                      <div className="table-wrap">
                        {cart && cart.items && cart.items.length > 0 && cart.items.map((item, idx) => (
                          <>
                            <ItemsDetail item={item} key={idx} />
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </Skeleton>
              </div>

              <div className="checkout-payment-shipping-data">
                <div className="checkout-heading">
                  <h1>{i18next.t('Pages.Checkout.ShipmentPaymentTitle')}</h1>
                </div>
                <div className="checkout-payment-shipping-data__wrapper">
                  <div className="checkout-box__container">
                    <div className="checkout-box__container__inner">
                      <div className="checkout-box">
                        <div className="checkout-box__title">
                          <h3>{i18next.t('Pages.Checkout.Shipment.Title')}</h3>
                        </div>
                        <div className="checkout-box-option organize-shipping">
                          <div className="title">
                            <h4>{i18next.t('Pages.Checkout.Shipment.ShipmentByClientLabel')}</h4>
                            <p>{i18next.t('Pages.Checkout.Shipment.ShipmentByClientTxt')}</p>
                          </div>
                          <div className="form-wrapper">
                            <Checkbox className="checkbox-stardue" checked={isFreeShipping} onChange={(e) => { setFreeShipping(e.target.checked) }}><span className="info-checkbox">{i18next.t('Pages.Checkout.Shipment.ShipmentByClientCheckbox')}</span></Checkbox>
                          </div>
                        </div>
                        {(!isFreeShipping) && <>
                          <div className="checkout-box-option details-shipping">
                            <div className="title"><h4>{i18next.t('Pages.Checkout.Shipment.ShipmentAddress')}</h4></div>
                            <div className="form-wrapper">
                              <Checkbox className="checkbox-stardue" defaultChecked={true} checked={isShippingsameasBilling} onChange={(e) => { setShippingSameasBilling(e.target.checked) }}><span className="info-checkbox">{i18next.t('Pages.Checkout.Shipment.SameAsBilling')}</span></Checkbox>
                            </div>

                            {!isShippingsameasBilling && <div className="userAddressContainer">
                              <div className="User-address-row">
                                <AddressListComponent isAddButton="true" isSelectable="true" type="shipping" defaultAddressId={selectAddressId} selectedAddress={onSetSelecteAdress} defaultCountry={orderForDealer ? orderForDealer.country_code : ''} isDelete={false} isEdit={false} />
                              </div>
                            </div>}

                          </div>
                        </>}
                      </div>
                    </div>

                    <div className="checkout-box__container__inner">
                      <div className="checkout-box">
                        <div className="checkout-box__title">
                          <h3>{i18next.t('Pages.Checkout.Billing.Title')}</h3>
                        </div>
                        <div className="checkout-box-option organize-shipping">
                          <div className="title">
                            <h4>{i18next.t('Pages.Checkout.Billing.YourBillingData')}</h4>
                            <p>{i18next.t('Pages.Checkout.Billing.YourBillingDataDescription')}</p>
                          </div>
                          <div className="billing-details">
                            {orderForDealer && orderForDealer && <>
                              <div className="details-info">
                                <div className="title">
                                  <p>{i18next.t('Pages.Checkout.Billing.CompanyDetails')}</p>
                                </div>
                                <div className="value">
                                  <p>{orderForDealer.company_name}</p>
                                  <p>{i18next.t('Pages.Checkout.Billing.Vat')}: {orderForDealer.vat}</p>
                                </div>
                              </div>
                              <div className="details-info">
                                <div className="title">
                                  <p>{i18next.t('Pages.Checkout.Billing.Address')}</p>
                                </div>
                                <div className="value">
                                  <p>{orderForDealer.street} {orderForDealer.street_number}, {orderForDealer.postal_code}</p>
                                  <p>{orderForDealer.city} - {orderForDealer.state} - {orderForDealer.country}</p>
                                </div>
                              </div>
                              <div className="details-info">
                                <div className="title">
                                  <p>{i18next.t('Pages.Checkout.Billing.Contacts')}</p>
                                </div>
                                <div className="value">
                                  <p>{orderForDealer.phone}</p>
                                  <p>{orderForDealer.email}</p>
                                </div>
                              </div>
                            </>}
                          </div>
                        </div>
                        <div className="checkout-box-option organize-shipping">
                          <div className="title">
                            <h4>{i18next.t('Pages.Checkout.Billing.OrderForCustomer.Title')}</h4>
                            <p>{i18next.t('Pages.Checkout.Billing.OrderForCustomer.Description')}</p>
                          </div>
                          <div className="form-wrapper">
                            <Checkbox className="checkbox-stardue" checked={isOrderForCustomer} onChange={(e) => { setIsOrderForCustomer(e.target.checked) }}>
                              <span className="info-checkbox">{i18next.t('Pages.Checkout.Billing.OrderForCustomer.Checkbox')}</span>
                            </Checkbox>
                            {isOrderForCustomer && <>
                              <div className="orderForCustomer">
                                <Row gutter={[{ xs: 8, sm: 16, lg: 24 }, { xs: 0, sm: 16, lg: 0 }]}>
                                  <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                                    <div className="form-group">
                                      <div className="form-label">
                                        <label>{i18next.t('Pages.Checkout.Billing.OrderForCustomer.Email')}</label>
                                      </div>

                                      <Input.Search name="email" type='email' onChange={(e) => onSearch(e.target.value)} loading={isUserLoading} />
                                      <div className="validation-user">
                                        {validEmail && <div className="error">{i18next.t('Pages.Checkout.Billing.OrderForCustomer.EmailNotValid')}</div>}
                                        {isUserError && <div className="not-found">{i18next.t('Pages.Checkout.Billing.OrderForCustomer.MessageUserNotFound')}</div>}
                                        {isUserSuccess && <div className="success">{i18next.t('Pages.Checkout.Billing.OrderForCustomer.MessageUserFound')}</div>}
                                      </div>
                                    </div>
                                  </Col>

                                  <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="form-group">
                                      <div className="form-label">
                                        <label>{i18next.t('Pages.Checkout.Billing.OrderForCustomer.Name')}</label>
                                      </div>
                                      <Input disabled={userFieldDisabled} value={customerName} onChange={(e) => {
                                        setCustomerName(e.target.value)
                                      }} name="customer_name" />
                                    </div>
                                  </Col>

                                  <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 12 }}>
                                    <div className="form-group">
                                      <div className="form-label">
                                        <label>{i18next.t('Pages.Checkout.Billing.OrderForCustomer.Surname')}</label>
                                      </div>
                                      <Input disabled={userFieldDisabled} value={surName} onChange={(e) => {
                                        setSurname(e.target.value)
                                      }} />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkout-box__container__inner other-options">
                      <div className="checkout-box">
                        <div className="checkout-box__title">
                          <h3>{i18next.t('Pages.Checkout.Payment.Title')}</h3>
                        </div>
                        <div className="checkout-box-option organize-shipping">
                          <div className="title mb-0">
                            <h4>{i18next.t('Pages.Checkout.Payment.TypeLabel')}</h4>
                            <p>{i18next.t('Pages.Checkout.Payment.Bacs')}</p>
                            {orderForDealer && <p>{orderForDealer.payment_terms_and_condition}</p>}
                          </div>
                        </div>
                        <div className="checkout-box-option organize-shipping">
                          <div className="title">
                            <h4>{i18next.t('Pages.Checkout.Payment.BankTitle')}</h4>
                            <p><strong>UNICREDIT SPA</strong><br />
                              Piazza 26, 31010 FONTE (TV) Italy<br />
                              <i>{i18next.t('Pages.Checkout.Payment.BeneficiaryName')}:</i> <strong>STARDUE SRL</strong><br />
                              <i>IBAN:</i> <strong>IT 46 E 02008 61700 000102822820</strong><br />
                              <i>BIC:</i> <strong>UNCRITM1A56</strong></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div className="summary-order">
              <div className="summary-order__wrapper">
                <div className='summary-details'>

                  <div className="summary-order__row">
                    <div className="summary-col summary-total-product">
                      <div className="label">
                        <p>{i18next.t('Pages.Checkout.BottomBar.TotalProducts')}</p>
                      </div>
                      <div className="value">
                        {isLoading && <Skeleton.Input style={{ width: 100 }} active={isLoading} />}
                        {(!isLoading && cart && cart.totals) && <p><PriceFormat price={cart.totals.subtotal} /></p>}
                      </div>
                    </div>
                    <div className="summary-col summary-shipment-product">
                      <div className="label">
                        <p>{i18next.t('Pages.Checkout.BottomBar.Shipment')}</p>
                      </div>
                      <div className="value">
                        {isLoading && <Skeleton.Input style={{ width: 100 }} active={isLoading} />}
                        {(!isLoading && cart && cart.totals) && cart.totals.shipping_total > 0 &&
                          <p><PriceFormat price={cart.totals.shipping_total} /></p>
                        }
                        {(!isLoading && cart && cart.totals) && cart.totals.shipping_total == 0 &&
                          <p>{i18next.t('Pages.Checkout.BottomBar.ShipmentTxt')}</p>
                        }
                      </div>
                    </div>
                    <div className="summary-col summary-total-order">
                      <div className="label">
                        <p>{i18next.t('Pages.Checkout.BottomBar.Total')}</p>
                      </div>
                      <div className="value">
                        {isLoading && <Skeleton.Input style={{ width: 100 }} active={isLoading} />}
                        {(!isLoading && cart && cart.totals) && <p><PriceFormat price={cart.totals.total} /></p>}
                      </div>
                    </div>
                  </div>
                  <div className="summary-order-now">
                    <div className="submit-order">
                      <Button loading={isCreatingOrder} onClick={createOrderB2b}
                        disabled={iscreateOrderDisable}
                        className="btn-primary btn-placeOrder">{i18next.t('Pages.Checkout.BottomBar.Submit')}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>}
        </div>
      </div>
      <Footer />
    </>
  )
}
export default CheckoutPage