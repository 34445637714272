import React, { useState, useEffect } from "react";
import StarDueCollapse from "../../components/StarDueCollapse/StarDueCollapse";
import { Steps, Collapse, Radio } from "antd";
import Link from "react-router-dom";
import i18next from "i18next";

const { Step } = Steps;

const { Panel } = Collapse;

const Models = ({
  categoryData,
  categorySlug,
  slug,
  modelName,
  stepId,
  onChangeModel,
  setSlugModel,
  setcategorySlug,
  setModelName,
  setIsDefaultData,
  setConfiguratorIdURL
}) => {
  const [currentCateogry, setcurrentCateogry] = useState(0);
  const onChangeCategory = (e) => {
    setIsDefaultData(false);
    setcategorySlug(e.target.slug);
    setcurrentCateogry(e.target.value);
  };

  useEffect(() => {
    categoryData &&
      categoryData.forEach((categoryDataobj, index) => {
        if (categoryDataobj.slug === categorySlug) {
          setcurrentCateogry(index);
        }
      });
  }, [categorySlug]);
  console.log("currentCateogry", currentCateogry)
  return (
    <>
      <StarDueCollapse stepId={1} title={i18next.t("Pages.Configurator.Step.Header.Categories")} active={true}>
        <div className="step-body">
          <div className="categories-box">
            <Radio.Group
              key="0"
              onChange={onChangeCategory}
              value={currentCateogry}
            >
              {categoryData &&
                categoryData.length &&
                categoryData.map((category, index) => {
                  return (
                    <Radio value={index} slug={category.slug} key={"model-" + index}>
                      <div className="category-item">
                        <div className="category-inner">
                          <div className="category-image">
                            {category &&
                              category.preview_data &&
                              category.preview_data.images &&
                              category.preview_data.images
                                .img_horizzontal && (
                                <img
                                  src={
                                    category.preview_data.images
                                      .img_horizzontal
                                  }
                                />
                              )}
                          </div>
                          <div className="category-name">
                            <h3>{category.name}</h3>
                          </div>
                        </div>
                      </div>
                    </Radio>
                  );
                })}
            </Radio.Group>
          </div>
        </div>
      </StarDueCollapse>

      <StarDueCollapse stepId={2} title={i18next.t("Pages.Configurator.Step.Header.Models")} active={true}>
        <div className="step-body">
          <div className="models-box">
            {categoryData &&
              categoryData[currentCateogry] &&
              categoryData[currentCateogry].models &&
              categoryData[currentCateogry].models.length && (
                <>
                  <Radio.Group
                    key="model-radio-group"
                    onChange={(e) => {                      
                      setConfiguratorIdURL();
                      setIsDefaultData(false);
                      setSlugModel(e.target.slug);
                      onChangeModel(e.target.value);
                      setModelName(e.target.modelName);                      
                    }}
                    value={slug}
                  >
                    {categoryData[currentCateogry].models.map(
                      (modelsData, index) => {
                        return (
                          <Radio
                            value={modelsData.display_slug}
                            key={"model-radio" + index}
                            modelName={modelsData.name}
                            slug={modelsData.slug}
                          >
                            <div
                              className={
                                modelsData.display_slug === slug
                                  ? "model-item__select active"
                                  : "model-item__select"
                              }
                              key={index}
                            >
                              <div className="modelImage">
                                <img src={modelsData.image} />
                              </div>
                              <div className="modelName">
                                <h6>{modelsData.name}</h6>
                              </div>
                            </div>
                          </Radio>
                        );
                      }
                    )}
                  </Radio.Group>
                </>
              )}
          </div>
        </div>
      </StarDueCollapse>
    </>
  );
};

export default Models;
