import React from "react";
import { Route, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { i18n } from "./../translations/i18n";

const ApplicationRouter = ({ isProtected, fallbackRoute, ...route }) => {
  if (!isProtected) {
    const user = window.localStorage.getItem("user");
    return (
      <Route
        path={route.path}
        render={(props) => {
          return (
            user ? <Redirect to={"/" + i18n.language + "/dashboard"} /> : <route.component {...props} routes={route.routes} />
          )
        }}
      />
    );
  }
  return <ProtectedRoute fallbackRoute={fallbackRoute} {...route} />;
};
export default ApplicationRouter;
