import React from "react";
import StarDueCollapse from "../../components/StarDueCollapse/StarDueCollapse";
import { Space, Radio } from "antd";
import i18next from "i18next";
const Size = ({
  size,
  setSize,
  stepId,
  configuratorData,
  setSizePopup,
  setSizeName,
}) => {
  const renderName = (id) => {
    let data = configuratorData.size["items"].filter(
      (item) => item.id === size
    );
    if (data && data[0]) {
      return data[0].name;
    }
  };

  return (
    <>
      <StarDueCollapse
        title={i18next.t("Pages.Configurator.Step.Header.Size")}
        stepId={stepId}
        active={true}
        value={renderName(size)}
      >
        <div className="step-body">
          <div className="step-info">
            <div className="link">
              <a onClick={() => setSizePopup(true)}>
                <span className="icon">
                  <svg
                    id="Livello_1"
                    data-name="Livello 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15.52 14.93"
                  >
                    <path
                      d="M5.69,20.48H20.77L6,6.23,5.69,6ZM20,20.18H18.15v-.87h-.3v.87H16.63v-.87h-.3v.87H15.11v-.87H14.8v.87H13.59v-.87h-.3v.87H12.06v-.87h-.3v.87H10.55v-.87h-.31v.87H9v-.87h-.3v.87H7.5v-.87H7.19v.87H6V18.05h.86v-.3H6V16.53h.86v-.31H6V15h.86V14.7H6V13.48h.86v-.3H6V12h.86v-.3H6V10.44h.86v-.3H6V8.92h.86V8.61H6V6.69ZM8.8,17.29h4.95l-4.7-4.48-.25-.24ZM13,17H9.1V13.28Z"
                      transform="translate(-5.56 -5.68)"
                    />
                    <path
                      d="M21.08,20.61H5.56V5.68L6,6.14ZM5.81,20.36H20.46L5.81,6.27Zm14.52-.06H18v-.86H18v.86H16.5v-.86h-.05v.86H15v-.86h-.05v.86H13.46v-.86h-.05v.86H11.94v-.86h0v.86H10.42v-.86h-.06v.86H8.9v-.86H8.84v.86H7.38v-.86H7.32v.86H5.87V17.93h.86v-.06H5.87V16.4h.86v0H5.87V14.88h.86v-.05H5.87V13.36h.86v0H5.87V11.83h.86v-.05H5.87V10.32h.86v-.06H5.87V8.79h.86v0H5.87V6.4Zm-2-.25h1.43L6.12,7v1.5H7V9H6.12v1H7v.56H6.12v1H7v.55H6.12v1H7v.55H6.12v1H7v.55H6.12v1H7v.55H6.12v1H7v.56H6.12v1.87h1v-.86h.56v.86h1v-.86h.56v.86h1v-.86h.56v.86h1v-.86h.55v.86h1v-.86h.55v.86h1v-.86h.55v.86h1v-.86h.55v.86h1v-.86h.56Zm-4.22-2.63H8.68V12.27l.46.45Zm-5.13-.25h4.51L8.93,12.86Zm4.38-.05H9V13Zm-4.08-.25h3.45l-3.45-3.3Z"
                      transform="translate(-5.56 -5.68)"
                    />
                  </svg>
                </span>
                <span className="text">
                  {i18next.t("Pages.Configurator.Geometry")}
                </span>
              </a>
            </div>
          </div>
          <Radio.Group
            onChange={(e) => {
              setSize(e.target.value);
              setSizeName(e.target.sizeName);
            }}
            value={size}
            optionType="button"
            className={"Radio-subtext ColumnGroup SizeRadio"}
          >
            <Space direction="vertical">
              {configuratorData.size["items"].map((siz) => {
                return (
                  <Radio sizeName={siz.name} key={siz.id} value={siz.id}>
                    {siz.name}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </div>
      </StarDueCollapse>
    </>
  );
};

export default Size;
