export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const GET_COUNTRY_DATA = "GET_COUNTRY_DATA";

export const SET_PLATFORM = "SET_PLATFORM";

export const RESET_CONFIGURATOR_DETAILS = "RESET_CONFIGURATOR_DETAILS";

export const FETCH_CLIENT_LISTING_SUCCESS = "FETCH_CLIENT_LISTING_SUCCESS";
export const FETCH_CLIENT_LISTING_PENDING = "FETCH_CLIENT_LISTING_PENDING";
export const FETCH_CLIENT_LISTING_FAILURE = "FETCH_CLIENT_LISTING_FAILURE";

export const FETCH_CLIENT_DETAIL_SUCCESS = "FETCH_CLIENT_DETAIL_SUCCESS";
export const FETCH_CLIENT_DETAIL_PENDING = "FETCH_CLIENT_DETAIL_PENDING";
export const FETCH_CLIENT_DETAIL_FAILURE = "FETCH_CLIENT_DETAIL_FAILURE";

export const FETCH_BANK_LISTING_SUCCESS = "FETCH_BANK_LISTING_SUCCESS";
export const FETCH_BANK_LISTING_PENDING = "FETCH_BANK_LISTING_PENDING";
export const FETCH_BANK_LISTING_FAILURE = "FETCH_BANK_LISTING_FAILURE";

export const FETCH_BANK_DETAIL_SUCCESS = "FETCH_BANK_DETAIL_SUCCESS";
export const FETCH_BANK_DETAIL_PENDING = "FETCH_BANK_DETAIL_PENDING";
export const FETCH_BANK_DETAIL_FAILURE = "FETCH_BANK_DETAIL_FAILURE";

export const FETCH_ORDER_DETAIL_SUCCESS = "FETCH_ORDER_DETAIL_SUCCESS";
export const FETCH_ORDER_DETAIL_PENDING = "FETCH_ORDER_DETAIL_PENDING";
export const FETCH_ORDER_DETAIL_FAILURE = "FETCH_ORDER_DETAIL_FAILURE";

export const ORDER_RETURN_PENDING = "ORDER_RETURN_PENDING";
export const ORDER_RETURN_SUCCESS = "ORDER_RETURN_SUCCESS";
export const ORDER_RETURN_FAILURE = "ORDER_RETURN_FAILURE";

export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS";
export const FETCH_ORDER_LIST_PENDING = "FETCH_ORDER_LIST_PENDING";
export const FETCH_ORDER_LIST_FAILURE = "FETCH_ORDER_LIST_FAILURE";

export const FETCH_STATUS_LIST_SUCCESS = "FETCH_STATUS_LIST_SUCCESS";
export const FETCH_STATUS_LIST_PENDING = "FETCH_STATUS_LIST_PENDING";
export const FETCH_STATUS_LIST_FAILURE = "FETCH_STATUS_LIST_FAILURE";

export const FETCH_PRODUCT_LIST_PENDING = "FETCH_PRODUCT_LIST_PENDING";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_FAILURE = "FETCH_PRODUCT_LIST_FAILURE";

export const FETCH_PRODUCT_FILTER_PENDING = "FETCH_PRODUCT_FILTER_PENDING";
export const FETCH_PRODUCT_FILTER_SUCCESS = "FETCH_PRODUCT_FILTER_SUCCESS";
export const FETCH_PRODUCT_FILTER_FAILURE = "FETCH_PRODUCT_FILTER_FAILURE";

export const FETCH_MODELS_PENDING = "FETCH_MODELS_PENDING";
export const FETCH_MODELS_SUCCESS = "FETCH_MODELS_SUCCESS";
export const FETCH_MODELS_FAILURE = "FETCH_MODELS_FAILURE";

export const FETCH_OTHER_STEP_PENDING = "FETCH_OTHER_STEP_PENDING";
export const FETCH_OTHER_STEP_SUCCESS = "FETCH_OTHER_STEP_SUCCESS";
export const FETCH_OTHER_STEP_FAILURE = "FETCH_OTHER_STEP_FAILURE";

export const FORGOTPASSWORD_PENDING = "FORGOTPASSWORD_PENDING";
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export const FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE";

export const RESETPASSWORD_PENDING = "RESETPASSWORD_PENDING";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";

export const AREAFTP_PENDING = "AREAFTP_PENDING";
export const AREAFTP_SUCCESS = "AREAFTP_SUCCESS";
export const AREAFTP_FAILURE = "AREAFTP_FAILURE";

export const NEWS_LIST_PENDING = "NEWS_LIST_PENDING";
export const NEWS_LIST_SUCCESS = "NEWS_LIST_SUCCESS";
export const NEWS_LIST_FAILURE = "NEWS_LIST_FAILURE";

export const NEWS_DETAILS_PENDING = "NEWS_DETAILS_PENDING";
export const NEWS_DETAILS_SUCCESS = "NEWS_DETAILS_SUCCESS";
export const NEWS_DETAILS_FAILURE = "NEWS_DETAILS_FAILURE";

export const TRAINING_LIST_PENDING = "TRAINING_LIST_PENDING";
export const TRAINING_LIST_SUCCESS = "TRAINING_LIST_SUCCESS";
export const TRAINING_LIST_FAILURE = "TRAINING_LIST_FAILURE";

export const TRAINING_TYPE_PENDING = "TRAINING_TYPE_PENDING";
export const TRAINING_TYPE_SUCCESS = "TRAINING_TYPE_SUCCESS";
export const TRAINING_TYPE_FAILURE = "TRAINING_TYPE_FAILURE";

export const FETCH_CART_PENDING = "FETCH_CART_PENDING";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";
export const FETCH_CART_FAILURE = "FETCH_CART_FAILURE";

export const ADD_CART_PENDING = "ADD_CART_PENDING";
export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const ADD_CART_FAILURE = "ADD_CART_FAILURE";
export const ADD_CART_NOTIFICATION_DONE = "ADD_CART_NOTIFICATION_DONE";

export const CREATE_ORDER_PENDING = "CREATE_ORDER_PENDING";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";
export const CREATE_ORDER_NOTIFICATION_DONE = "CREATE_ORDER_NOTIFICATION_DONE";

export const FETCH_ADDRESS_PENDING = "FETCH_ADDRESS_PENDING";
export const FETCH_ADDRESS_SUCCESS = "FETCH_ADDRESS_SUCCESS";
export const FETCH_ADDRESS_FAILURE = "FETCH_ADDRESS_FAILURE";

export const ADD_ADDRESS_PENDING = "ADD_ADDRESS_PENDING";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE";

export const DELETE_ADDRESS_PENDING = "DELETE_ADDRESS_PENDING";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILURE = "DELETE_ADDRESS_FAILURE";

export const UPDATE_ADDRESS_PENDING = "UPDATE_ADDRESS_PENDING";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILURE = "UPDATE_ADDRESS_FAILURE";

export const FETCH_CONFIGURATOR_DETAILS_PENDING =
  "FETCH_CONFIGURATOR_DETAILS_PENDING";
export const FETCH_CONFIGURATOR_DETAILS_SUCCESS =
  "FETCH_CONFIGURATOR_DETAILS_SUCCESS";
export const FETCH_CONFIGURATOR_DETAILS_FAILURE =
  "FETCH_CONFIGURATOR_DETAILS_FAILURE";

export const FETCH_CONFIGURATOR_CATEGORY_PENDING =
  "FETCH_CONFIGURATOR_CATEGORY_PENDING";
export const FETCH_CONFIGURATOR_CATEGORY_SUCCESS =
  "FETCH_CONFIGURATOR_CATEGORY_SUCCESS";
export const FETCH_CONFIGURATOR_CATEGORY_FAILURE =
  "FETCH_CONFIGURATOR_CATEGORY_FAILURE";

export const DOWNLOAD_CONFIGURATOR_PDF_PENDING =
  "DOWNLOAD_CONFIGURATOR_PDF_PENDING";
export const DOWNLOAD_CONFIGURATOR_PDF_SUCCESS =
  "DOWNLOAD_CONFIGURATOR_PDF_SUCCESS";
export const DOWNLOAD_CONFIGURATOR_PDF_FAILURE =
  "DOWNLOAD_CONFIGURATOR_PDF_FAILURE";

export const CONFIGURATOR_LIST_PENDING = "CONFIGURATOR_LIST_PENDING";
export const CONFIGURATOR_LIST_SUCCESS = "CONFIGURATOR_LIST_SUCCESS";
export const CONFIGURATOR_LIST_FAILURE = "CONFIGURATOR_LIST_FAILURE";

export const CONFIGURATOR_SUMMARY_PENDING = "CONFIGURATOR_SUMMARY_PENDING";
export const CONFIGURATOR_SUMMARY_SUCCESS = "CONFIGURATOR_SUMMARY_SUCCESS";
export const CONFIGURATOR_SUMMARY_FAILURE = "CONFIGURATOR_SUMMARY_FAILURE";

export const UPDATE_DEALER_DATA_PENDING = "UPDATE_DEALER_DATA_PENDING";
export const UPDATE_DEALER_DATA_SUCCESS = "UPDATE_DEALER_DATA_SUCCESS";
export const UPDATE_DEALER_DATA_FAILURE = "UPDATE_DEALER_DATA_FAILURE";

export const GET_DEALER_DATA_PENDING = "GET_DEALER_DATA_PENDING";
export const GET_DEALER_DATA_SUCCESS = "GET_DEALER_DATA_SUCCESS";
export const GET_DEALER_DATA_FAILURE = "GET_DEALER_DATA_FAILURE";

export const SET_DEALER_DATA = "SET_DEALER_DATA";

export const ADDRESS_POPUP = "ADDRESS_POPUP";

export const CREATE_TICKET_PENDING = "CREATE_TICKET_PENDING";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "CREATE_TICKET_FAILURE";

export const TICKET_LIST_PENDING = "TICKET_LIST_PENDING";
export const TICKET_LIST_SUCCESS = "TICKET_LIST_SUCCESS";
export const TICKET_LIST_FAILURE = "TICKET_LIST_FAILURE";

export const GET_AGENT_DEALER_LIST_PENDING = "GET_AGENT_DEALER_LIST_PENDING";
export const GET_AGENT_DEALER_LIST_SUCCESS = "GET_AGENT_DEALER_LIST_SUCCESS";
export const GET_AGENT_DEALER_LIST_FAILURE = "GET_AGENT_DEALER_LIST_FAILURE";
export const SET_AGENT_SELECTED_DEALER = "SET_AGENT_SELECTED_DEALER";

export const FETCH_BACKORDER_LIST_PENDING = "FETCH_BACKORDER_LIST_PENDING";
export const FETCH_BACKORDER_LIST_SUCCESS = "FETCH_BACKORDER_LIST_SUCCESS";
export const FETCH_BACKORDER_LIST_FAILURE = "FETCH_BACKORDER_LIST_FAILURE";

export const GET_MODEL_FE_PENDING = "GET_MODEL_FE_PENDING";
export const GET_MODEL_FE_SUCCESS = "GET_MODEL_FE_SUCCESS";
export const GET_MODEL_FE_FAILURE = "GET_MODEL_FE_FAILURE";

export const GET_SEARCH_USER_PENDING = "GET_SEARCH_USER_PENDING";
export const GET_SEARCH_USER_SUCCESS = "GET_SEARCH_USER_SUCCESS";
export const GET_SEARCH_USER_FAILURE = "GET_SEARCH_USER_FAILURE";

export const CREATE_REGISTER_BIKE_PENDING = "CREATE_REGISTER_BIKE_PENDING";
export const CREATE_REGISTER_BIKE_SUCESS = "CREATE_REGISTER_BIKE_SUCESS";
export const CREATE_REGISTER_BIKE_FAILURE = "CREATE_REGISTER_BIKE_FAILURE";

export const FETCH_SUPPORT_DEALER_ORDER_LIST_PENDING =
  "FETCH_SUPPORT_DEALER_ORDER_LIST_PENDING";
export const FETCH_SUPPORT_DEALER_ORDER_LIST_SUCCESS =
  "FETCH_SUPPORT_DEALER_ORDER_LIST_SUCCESS";
export const FETCH_SUPPORT_DEALER_ORDER_LIST_FAILURE =
  "FETCH_SUPPORT_DEALER_ORDER_LIST_FAILURE";

export const GET_DEALER_REGISTER_BIKE_PENDING =
  "GET_DEALER_REGISTER_BIKE_PENDING";
export const GET_DEALER_REGISTER_BIKE_SUCCESS =
  "GET_DEALER_REGISTER_BIKE_SUCCESS";
export const GET_DEALER_REGISTER_BIKE_FAILURE =
  "GET_DEALER_REGISTER_BIKE_FAILURE";

export const UPDATE_DEALER_PERSONAL_DATA_PENDING =
  "UPDATE_DEALER_PERSONAL_DATA_PENDING";
export const UPDATE_DEALER_PERSONAL_DATA_SUCCESS =
  "UPDATE_DEALER_PERSONAL_DATA_SUCCESS";
export const UPDATE_DEALER_PERSONAL_DATA_FAILURE =
  "UPDATE_DEALER_PERSONAL_DATA_FAILURE";

export const REMOVE_CART_PENDING = "REMOVE_CART_PENDING";
export const REMOVE_CART_SUCCESS = "REMOVE_CART_SUCCESS";
export const REMOVE_CART_FAILURE = "REMOVE_CART_FAILURE";

export const CHANGE_QUANTITY_PENDING = "CHANGE_QUANTITY_PENDING";
export const CHANGE_QUANTITY_SUCCESS = "CHANGE_QUANTITY_SUCCESS";
export const CHANGE_QUANTITY_FAILURE = "CHANGE_QUANTITY_FAILURE";

export const CONFIGURATOR_SAVE_PENDING = "CONFIGURATOR_SAVE_PENDING";
export const CONFIGURATOR_SAVE_SUCCESS = "CONFIGURATOR_SAVE_SUCCESS";
export const CONFIGURATOR_SAVE_FAILURE = "CONFIGURATOR_SAVE_FAILURE";

export const GET_GROUPSET_FE_PENDING = "GET_GROUPSET_FE_PENDING";
export const GET_GROUPSET_FE_SUCCESS = "GET_GROUPSET_FE_SUCCESS";
export const GET_GROUPSET_FE_FAILURE = "GET_GROUPSET_FE_FAILURE";

export const GET_PRODUCT_SIZE_PENDING = "GET_PRODUCT_SIZE_PENDING";
export const GET_PRODUCT_SIZE_SUCCESS = "GET_PRODUCT_SIZE_SUCCESS";
export const GET_PRODUCT_SIZE_FAILURE = "GET_PRODUCT_SIZE_FAILURE";

export const GET_CONTENT_PENDING = "GET_CONTENT_PENDING";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";
export const GET_CONTENT_FAILURE = "GET_CONTENT_FAILURE";

export const CREATE_DEALERS_SHOP_PENDING = "CREATE_DEALERS_SHOP_PENDING";
export const CREATE_DEALERS_SHOP_SUCCESS = "CREATE_DEALERS_SHOP_SUCCESS";
export const CREATE_DEALERS_SHOP_FAILURE = "CREATE_DEALERS_SHOP_FAILURE";

export const DEALERS_SHOP_LIST_PENDING = "DEALERS_SHOP_LIST_PENDING";
export const DEALERS_SHOP_LIST_SUCCESS = "DEALERS_SHOP_LIST_SUCCESS";
export const DEALERS_SHOP_LIST_FAILURE = "DEALERS_SHOP_LIST_FAILURE";

export const GET_DEALER_SHOP_PENDING = "GET_DEALER_SHOP_PENDING";
export const GET_DEALER_SHOP_SUCCESS = "GET_DEALER_SHOP_SUCCESS";
export const GET_DEALER_SHOP_FAILURE = "GET_DEALER_SHOP_FAILURE";

export const UPDATE_DEALERS_SHOP_PENDING = "UPDATE_DEALERS_SHOP_PENDING";
export const UPDATE_DEALERS_SHOP_SUCCESS = "UPDATE_DEALERS_SHOP_SUCCESS";
export const UPDATE_DEALERS_SHOP_FAILURE = "UPDATE_DEALERS_SHOP_FAILURE";

export const DELETE_DEALERS_SHOP_PENDING = "DELETE_DEALERS_SHOP_PENDING";
export const DELETE_DEALERS_SHOP_SUCCESS = "DELETE_DEALERS_SHOP_SUCCESS";
export const DELETE_DEALERS_SHOP_FAILURE = "DELETE_DEALERS_SHOP_FAILURE";



export const FETCH_PRODUCT_STOCK_LIST_PENDING = "FETCH_PRODUCT_STOCK_LIST_PENDING";
export const FETCH_PRODUCT_STOCK_LIST_SUCCESS = "FETCH_PRODUCT_STOCK_LIST_SUCCESS";
export const FETCH_PRODUCT_STOCK_LIST_FAILURE = "FETCH_PRODUCT_STOCK_LIST_FAILURE";
