import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Spin, Skeleton } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { getConfiguratorSummary } from "../../actions/configuratorActions";
import i18next from "i18next";
const ConfiguratorSummary = ({
  isSummaryPopup,
  configurator_id,
  setisSummaryPopup
}) => {
  const dispatch = useDispatch();
  const { isSummaryLoading, cofiguratorSummary } = useSelector(state => {
    return state.configure
  });



  useEffect(() => {
    if (configurator_id !== '' && configurator_id !== undefined) {
      dispatch(getConfiguratorSummary(configurator_id));
    }
  }, [configurator_id]);


  if (!configurator_id) {
    return <></>;
  }
  return (
    <>
      <Modal
        //title="Basic Modal"
        visible={isSummaryPopup}
        width={1580}
        footer={null}
        centered
        onClose={(e) => {
          setisSummaryPopup(false);
        }}
        onCancel={(e) => {
          setisSummaryPopup(false);
        }}
        destroyOnClose={true}
        closable={true}
        className="modal-s2 modal-configsummary"
      >
        <Skeleton loading={isSummaryLoading} active>
          <div className="modal-header">
            <h2>{i18next.t('Pages.Configurator.ConfiguratorSummary.Title')}</h2>
          </div>
          {cofiguratorSummary && cofiguratorSummary.model_assembly_list != undefined && (
            <div className="modal-content">
              <section className="Config-detail">
                <div className="Config-detail-list">
                  <div className="Config-del-row">
                    <h2>{i18next.t('Pages.Configurator.ConfiguratorSummary.FrameSet')}</h2>
                    <Row gutter={[24, 0]}>
                      <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                        <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Frame')}</h3>
                        <span>
                          {cofiguratorSummary.model_assembly_list.frame_set.frame}
                        </span>
                      </Col>

                      {cofiguratorSummary.model_assembly_list
                        .frame_set.fork !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Fork')}</h3>
                            <span>
                              {cofiguratorSummary.model_assembly_list.frame_set.fork}
                            </span>
                          </Col>
                        )}

                      {cofiguratorSummary.model_assembly_list.frame_set
                        .seat_post_clamp !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.SeatPostClamp')}</h3>
                            <span>
                              {
                                cofiguratorSummary.model_assembly_list.frame_set
                                  .seat_post_clamp
                              }
                            </span>
                          </Col>
                        )}

                      {cofiguratorSummary.model_assembly_list.frame_set
                        .thru_axle_fornt !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.ThruAxleFornt')}</h3>
                            <span>
                              {
                                cofiguratorSummary.model_assembly_list.frame_set
                                  .thru_axle_fornt
                              }
                            </span>
                          </Col>
                        )}
                      {cofiguratorSummary.model_assembly_list.frame_set
                        .thru_axle_rear !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.ThruAxleRear')}</h3>
                            <span>
                              {
                                cofiguratorSummary.model_assembly_list.frame_set
                                  .thru_axle_rear
                              }
                            </span>
                          </Col>
                        )}
                      {cofiguratorSummary.model_assembly_list.frame_set
                        .hanger !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Hanger')}</h3>
                            <span>
                              {
                                cofiguratorSummary.model_assembly_list.frame_set
                                  .hanger
                              }
                            </span>
                          </Col>
                        )}
                      {cofiguratorSummary.model_assembly_list.frame_set
                        .headset !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Headset')}</h3>
                            <span>
                              {
                                cofiguratorSummary.model_assembly_list.frame_set
                                  .headset
                              }
                            </span>
                          </Col>
                        )}
                      {cofiguratorSummary["data_frame-set"] &&
                        cofiguratorSummary["data_frame-set"].map(
                          (data, index) => {
                            if (!data) {
                              return <></>;
                            }
                            return (
                              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }} key={index}>
                                <h3>{data.label}</h3>
                                <span>{data.title}</span>
                              </Col>
                            );
                          }
                        )}
                    </Row>
                  </div>
                  {(cofiguratorSummary.type != 'build_kit' && cofiguratorSummary.type != 'frame_kit') && <div className="Config-del-row">
                    <h2>{i18next.t('Pages.Configurator.ConfiguratorSummary.Groupset')}</h2>
                    <Row gutter={[24, 0]}>
                      {<Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                        <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Groupset')}</h3>
                        <span>{cofiguratorSummary.groupset_name}</span>
                      </Col>}

                      {cofiguratorSummary.groupset_assembly_list
                        .crank !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Crank')}</h3>
                            <span>
                              {cofiguratorSummary.groupset_assembly_list.crank}
                            </span>
                          </Col>
                        )}

                      <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                        <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.CrankArm')}</h3>
                        <span>
                          {cofiguratorSummary.groupset_data_name.crankArm}
                        </span>
                      </Col>

                      <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                        <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.ChainRings')}</h3>
                        <span>
                          {cofiguratorSummary.groupset_data_name.chainRings}
                        </span>
                      </Col>

                      {cofiguratorSummary.groupset_assembly_list
                        .chain !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Chain')}</h3>
                            <span>
                              {cofiguratorSummary.groupset_assembly_list.chain}
                            </span>
                          </Col>
                        )}

                      {cofiguratorSummary.groupset_assembly_list
                        .bottom_braket !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.BottomBraket')}</h3>
                            <span>
                              {
                                cofiguratorSummary.groupset_assembly_list
                                  .bottom_braket
                              }
                            </span>
                          </Col>
                        )}

                      {cofiguratorSummary.groupset_assembly_list
                        .cassette !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Cassette')}</h3>
                            <span>
                              {cofiguratorSummary.groupset_assembly_list.cassette}
                            </span>
                          </Col>
                        )}

                      <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                        <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.CassetteGears')}</h3>
                        <span>
                          {cofiguratorSummary.groupset_data_name.cassette}
                        </span>
                      </Col>

                      {cofiguratorSummary.groupset_assembly_list
                        .front_derailleur !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.FrontDerailleur')}</h3>
                            <span>
                              {
                                cofiguratorSummary.groupset_assembly_list
                                  .front_derailleur
                              }
                            </span>
                          </Col>
                        )}

                      {cofiguratorSummary.groupset_assembly_list
                        .rear_derailleur !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.RearDerailleur')}</h3>
                            <span>
                              {
                                cofiguratorSummary.groupset_assembly_list
                                  .rear_derailleur
                              }
                            </span>
                          </Col>
                        )}

                      {cofiguratorSummary.groupset_assembly_list
                        .shifters !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Shifters')}</h3>
                            <span>
                              {cofiguratorSummary.groupset_assembly_list.shifters}
                            </span>
                          </Col>
                        )}

                      {cofiguratorSummary.groupset_assembly_list
                        .brakes !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Brakes')}</h3>
                            <span>
                              {cofiguratorSummary.groupset_assembly_list.brakes}
                            </span>
                          </Col>
                        )}

                      {cofiguratorSummary.groupset_assembly_list.
                        rotors !== '' && (
                          <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                            <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Rotors')}</h3>
                            <span>
                              {cofiguratorSummary.groupset_assembly_list.rotors}
                            </span>
                          </Col>
                        )}
                      {cofiguratorSummary["data_groupset"] &&
                        cofiguratorSummary["data_groupset"].map((data, index) => {
                          if (!data) {
                            return <></>;
                          }
                          return (
                            <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }} key={index}>
                              <h3>{data.label}</h3>
                              <span>{data.title}</span>
                            </Col>
                          );
                        })}
                    </Row>
                  </div>}
                  {((cofiguratorSummary["data_cockpit"] && cofiguratorSummary["data_cockpit"].length > 0) || cofiguratorSummary.model_assembly_list && cofiguratorSummary.model_assembly_list.cockpit && cofiguratorSummary.model_assembly_list.cockpit.handlebar_tape) && <div className="Config-del-row">
                    <h2>{i18next.t('Pages.Configurator.ConfiguratorSummary.Cockpit')}</h2>
                    <Row gutter={[24, 0]}>
                      {cofiguratorSummary["data_cockpit"] &&
                        cofiguratorSummary["data_cockpit"].map((data, index) => {
                          if (!data) {
                            return <></>;
                          }
                          return (
                            <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }} key={index}>
                              <h3>{data.label}</h3>
                              {data.description && <span>{data.description}<br /></span>}
                              {Array.isArray(data.title) && <><span><b>{i18next.t('Pages.Configurator.ConfiguratorSummary.Upgrade')}:</b></span><br />{data.title.map((titleData, index) => {
                                return <><span key={index}>{titleData}</span><br /></>
                              })}</>}
                              {!Array.isArray(data.title) && <span>{data.title}</span>}
                            </Col>
                          );
                        })}
                      {/* <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                        <h3>Head unit mount</h3>
                        <span>Static Modal</span>
                      </Col> */}

                      {cofiguratorSummary.model_assembly_list.cockpit.handlebar_tape !== '' &&
                        <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                          <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.HandlebarTap')}</h3>
                          <span>
                            {
                              cofiguratorSummary.model_assembly_list.cockpit
                                .handlebar_tape
                            }
                          </span>
                        </Col>
                      }
                    </Row>
                  </div>}
                  {(cofiguratorSummary["data_seatpost-saddle"] && cofiguratorSummary["data_seatpost-saddle"].length > 0) && <div className="Config-del-row">
                    <h2>{i18next.t('Pages.Configurator.ConfiguratorSummary.SeatpostAndSaddle')}</h2>
                    <Row gutter={[24, 0]}>
                      {cofiguratorSummary["data_seatpost-saddle"] &&
                        cofiguratorSummary["data_seatpost-saddle"].map(
                          (data, index) => {
                            if (!data) {
                              return <></>;
                            }
                            return (
                              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }} key={index}>
                                <h3>{data.label}</h3>
                                <span>{data.title}</span>
                              </Col>
                            );
                          }
                        )}
                    </Row>
                  </div>}
                  {(cofiguratorSummary.wheel_name || cofiguratorSummary["data_wheels-tires"]) && <div className="Config-del-row">
                    <h2>{i18next.t('Pages.Configurator.ConfiguratorSummary.WheelsAndTires')}</h2>
                    <Row gutter={[24, 0]}>
                      {cofiguratorSummary.wheel_name && <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                        <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Wheels')}</h3>
                        <span>{cofiguratorSummary.wheel_name}</span>
                      </Col>}
                      {cofiguratorSummary["data_wheels-tires"] &&
                        cofiguratorSummary["data_wheels-tires"].map(
                          (data, index) => {
                            if (!data) {
                              return <></>;
                            }
                            return (
                              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }} key={index}>
                                <h3>{data.label}</h3>
                                {data.description && <span>{data.description}<br /></span>}
                                {Array.isArray(data.title) && <><span><b>{i18next.t('Pages.Configurator.ConfiguratorSummary.Upgrade')}:</b></span><br />{data.title.map((titleData, index) => {
                                  return <><span key={index}>{titleData}</span><br /></>
                                })}</>}
                                {!Array.isArray(data.title) && <span>{data.title}</span>}
                              </Col>

                            );
                          }
                        )}
                    </Row>
                  </div>}
                  {cofiguratorSummary.model_assembly_list.power_unit.motor !==
                    "" &&
                    cofiguratorSummary.model_assembly_list.power_unit.display !==
                    "" &&
                    cofiguratorSummary.model_assembly_list.power_unit.battery !==
                    "" && (
                      <div className="Config-del-row">
                        <h2>{i18next.t('Pages.Configurator.ConfiguratorSummary.PowerUnit')}</h2>
                        <Row gutter={[24, 0]}>
                          {cofiguratorSummary.model_assembly_list.power_unit
                            .motor !== "" && (
                              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                                <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Motor')}</h3>
                                <span>
                                  {
                                    cofiguratorSummary.model_assembly_list
                                      .power_unit.motor
                                  }
                                </span>
                              </Col>
                            )}
                          {cofiguratorSummary.model_assembly_list.power_unit
                            .display !== "" && (
                              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                                <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Display')}</h3>
                                <span>
                                  {
                                    cofiguratorSummary.model_assembly_list
                                      .power_unit.display
                                  }
                                </span>
                              </Col>
                            )}
                          {cofiguratorSummary.model_assembly_list.power_unit
                            .battery !== "" && (
                              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }}>
                                <h3>{i18next.t('Pages.Configurator.ConfiguratorSummary.Battery')}</h3>
                                <span>
                                  {
                                    cofiguratorSummary.model_assembly_list
                                      .power_unit.battery
                                  }
                                </span>
                              </Col>
                            )}
                          {cofiguratorSummary["data_power-unit"] &&
                            cofiguratorSummary["data_power-unit"].map(
                              (data, index) => {
                                if (!data) {
                                  return <></>;
                                }
                                return (
                                  <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }} key={index}>
                                    <h3>{data.label}</h3>
                                    <span>{data.title}</span>
                                  </Col>
                                );
                              }
                            )}
                        </Row>
                      </div>
                    )}
                  {cofiguratorSummary.data_other &&
                    cofiguratorSummary.data_other.length > 0 && (
                      <div className="Config-del-row">
                        <h2>{i18next.t('Pages.Configurator.ConfiguratorSummary.Other')}</h2>
                        <Row gutter={[24, 0]}>
                          {cofiguratorSummary.data_other &&
                            cofiguratorSummary.data_other.map((data, index) => {
                              if (!data) {
                                return <></>;
                              }
                              return (
                                <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 6 }} key={index}>
                                  <h3>{data.label}</h3>
                                  <span>{data.title}</span>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    )}
                </div>
                {/* <div className="Config-detail-footer Flex-box center-align center-align">
                  {isHideEdit && (
                    <a
                      onClick={() => {
                        setisSummaryPopup(false);
                        setAddtoCartLoaderProps(false);
                      }}
                      className="ant-btn-primary btn-bordred"
                    >
                      <span>{i18next.t('Pages.Configurator.ConfiguratorSummary.Editing')}</span>
                    </a>
                  )}
                  {isHideEdit && <><a
                    onClick={() => {
                      downLoadConfiguratorPDF(cofiguratorSummary.configurator_id);
                    }}
                    className="ant-btn-primary btn-bordred"
                  >
                    <span>{i18next.t('Pages.Configurator.ConfiguratorSummary.DownloadPDF')}</span>
                  </a>
                  </>}
                  {onClickAddCart && (
                    <Button
                      onClick={() => {
                        setAddTocartLoader(true);
                        onClickAddCart();
                      }}
                      loading={isAddtoCartLoader}
                      className="ant-btn-primary"
                    >
                      <span>{i18next.t('Pages.Configurator.ConfiguratorSummary.ConfirmAndProceed')}</span>
                    </Button>
                  )}
                </div> */}
              </section>
            </div>
          )}{" "}
        </Skeleton>
      </Modal>
    </>
  );
};

export default ConfiguratorSummary;
