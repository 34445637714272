import {
  NEWS_LIST_PENDING,
  NEWS_LIST_SUCCESS,
  NEWS_LIST_FAILURE,
  NEWS_DETAILS_PENDING,
  NEWS_DETAILS_SUCCESS,
  NEWS_DETAILS_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  total: 0,
  news: [],
  isError: false,
  message: '',
  newsDetail: undefined
};

export default function NewsReducer(state = initialState, action) {
  switch (action.type) {
    case NEWS_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case NEWS_LIST_SUCCESS:
      return Object.assign({}, state, {
        news: action.data.news,
        isLoading: false,
        total: action.data.total_news
      });
    case NEWS_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.data.data.message,
        isLoading: false,
        isError: true
      });
    case NEWS_DETAILS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isError: false
      });
    case NEWS_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        newsDetail: action.data,
        isLoading: false,
        isError: false
      });
    case NEWS_DETAILS_FAILURE:
      return Object.assign({}, state, {
        message: action.data.data.message,
        isLoading: false,
        isError: true
      });
    default:
      return state;
  }
}