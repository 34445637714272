import { request } from "../services/apiService";
import {
    CREATE_TICKET_PENDING,
    CREATE_TICKET_SUCCESS,
    CREATE_TICKET_FAILURE,

    TICKET_LIST_PENDING,
    TICKET_LIST_SUCCESS,
    TICKET_LIST_FAILURE,
} from "./actionTypes";


export const getTickets = (page, size, status, dealer_id) => dispatch => {
    dispatch({
        type: TICKET_LIST_PENDING
    });
    const url = `v1/ticket/list/?page=` + page + `&size=` + size + `&status=` + status + `&dealer_id=` + dealer_id;
    request("get", url, '', true).then(
        (res) => {
            dispatch({
                type: TICKET_LIST_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: TICKET_LIST_FAILURE,
                error: err.response,
            });
        }
    );
}

export const createTicket = (data) => dispatch => {
    dispatch({
        type: CREATE_TICKET_PENDING,
    });
    const url = `v1/ticket/create/`;
    request("post", url, data, true).then(
        (res) => {
            dispatch({
                type: CREATE_TICKET_SUCCESS,
                data: res.data,
            });
        },
        (err) => {
            dispatch({
                type: CREATE_TICKET_FAILURE,
                error: err.response,
            });
        }
    );
}