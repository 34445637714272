import { request } from "../services/apiService";
import {
  FETCH_CONFIGURATOR_DETAILS_PENDING,
  FETCH_CONFIGURATOR_DETAILS_SUCCESS,
  FETCH_CONFIGURATOR_DETAILS_FAILURE,
  DOWNLOAD_CONFIGURATOR_PDF_PENDING,
  DOWNLOAD_CONFIGURATOR_PDF_SUCCESS,
  DOWNLOAD_CONFIGURATOR_PDF_FAILURE,
  CONFIGURATOR_SUMMARY_PENDING,
  CONFIGURATOR_SUMMARY_SUCCESS,
  CONFIGURATOR_SUMMARY_FAILURE,
  CONFIGURATOR_LIST_PENDING,
  CONFIGURATOR_LIST_SUCCESS,
  CONFIGURATOR_LIST_FAILURE,
  CONFIGURATOR_SAVE_PENDING,
  CONFIGURATOR_SAVE_SUCCESS,
  CONFIGURATOR_SAVE_FAILURE,
  GET_GROUPSET_FE_PENDING,
  GET_GROUPSET_FE_SUCCESS,
  GET_GROUPSET_FE_FAILURE,
  RESET_CONFIGURATOR_DETAILS
} from "./actionTypes";

export const getSingleConfigurator = (id) => (dispatch) => {
  dispatch({ type: FETCH_CONFIGURATOR_DETAILS_PENDING });
  const url = `v1/configurator/` + id;
  request("get", url, "", true).then(
    (res) => {
      dispatch({
        type: FETCH_CONFIGURATOR_DETAILS_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: FETCH_CONFIGURATOR_DETAILS_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};

export const resetOldConfigurator = () => (dispatch) => {
  dispatch({ type: RESET_CONFIGURATOR_DETAILS });
}

export const downloadPDF = (id) => (dispatch) => {
  dispatch({ type: DOWNLOAD_CONFIGURATOR_PDF_PENDING });
  const url = `v1/configurator/pdf/` + id;
  request("get", url, "", true).then(
    (res) => {
      dispatch({
        type: DOWNLOAD_CONFIGURATOR_PDF_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: DOWNLOAD_CONFIGURATOR_PDF_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};

export const getConfiguratorSummary = (id) => (dispatch) => {
  dispatch({ type: CONFIGURATOR_SUMMARY_PENDING });
  const url = `v1/configurator/preview/` + id;
  request("get", url, "", true).then(
    (res) => {
      dispatch({
        type: CONFIGURATOR_SUMMARY_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: CONFIGURATOR_SUMMARY_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};

export const getConfiguratorList = (page, size, model, order, configurator, groupset) => (dispatch) => {
  dispatch({ type: CONFIGURATOR_LIST_PENDING });
  const url = `v1/configurator/list/?page=${page}&size=${size}&model=${model}&order=${order}&configurator_id=${configurator}&groupset=${groupset}`;
  request("get", url, "", true).then(
    (res) => {
      dispatch({
        type: CONFIGURATOR_LIST_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: CONFIGURATOR_LIST_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};

export const displaySaveLoader = () => (dispatch) => {
  dispatch({ type: CONFIGURATOR_SAVE_PENDING });
}

export const saveConfigurator = (data) => (dispatch) => {
  dispatch({ type: CONFIGURATOR_SAVE_PENDING });
  const url = `v1/configurator/save`;
  request("post", url, data, true).then(
    (res) => {
      dispatch({
        type: CONFIGURATOR_SAVE_SUCCESS,
        data: res.data,
      });
    },
    (err) => {
      dispatch({
        type: CONFIGURATOR_SAVE_FAILURE,
        message: err.response.data.message,
      });
    }
  );
};


export const getGroupsetFE = () => dispatch => {
  dispatch({ type: GET_GROUPSET_FE_PENDING });
  var url = "v1/groupset/list-fe"
  request("get", url, '', true).then(
    (res) => {
      dispatch({
        type: GET_GROUPSET_FE_SUCCESS,
        data: res,
      });
    },
    (err) => {
      dispatch({
        type: GET_GROUPSET_FE_FAILURE,
        message: err.response.data.message,
      });
    }
  );
}