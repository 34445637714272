import React, { useState, useEffect } from 'react'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ConfiguratorSummary from "../../components/ConfiguratorPopup";
import { useDispatch, useSelector } from 'react-redux';
import { getSingleConfigurator, downloadPDF } from "../../actions/configuratorActions";
import { Row, Col, Button, Dropdown, Menu, Skeleton } from "antd";
import { saveAs } from "file-saver";
import { ReactTitle } from 'react-meta-tags';
import i18next from 'i18next';
import history from "../../history";

const ConfigSearch = () => {
  const dispatch = useDispatch();

  const [configuratorPopupData, setConfiguratorPopupData] = useState('')
  const [downloadPdfId, setDownloadPdfId] = useState('')
  const [searchVal, setSearchVal] = useState('')
  const [isSummaryPopup, setisSummaryPopup] = useState(false)
  const configuratorPopup = (data) => {
    setConfiguratorPopupData(data.configurator_id);
    setisSummaryPopup(true);
  }
  const { isLoading, configuratorDetail, cofiguratorPDF } = useSelector(state => {
    return state.configure
  });

  const downloadConfiguratorPDF = (id) => {
    setDownloadPdfId(id);
    dispatch(downloadPDF(id));
  }

  useEffect(() => {
    if (cofiguratorPDF != undefined && cofiguratorPDF.url) {
      saveAs(cofiguratorPDF.url, "your-new-basso-bike-" + downloadPdfId + ".pdf")
    }
  }, [cofiguratorPDF]);

  const searchConfigurator = () => {
    if (searchVal != '') {
      dispatch(getSingleConfigurator(searchVal));
    }
  }


  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.ConfigurationsSearch')} />
      <Header />
      <div className="dashboard-content config-search">
        <div className="wrap">
          <div className="page-content no-filter">
            <div className="right-side">
              <div className="left-side">
                <div className="filter-side-container">
                  <div className="filter-blk">
                    <div className="page-title">
                      <h3>{i18next.t("Pages.SearchConfiguration.Title")}</h3>
                      <div className="subtitle">
                        <p>{i18next.t("Pages.SearchConfiguration.Info")}</p>
                      </div>
                    </div>
                    <div className="form-wrapper">
                      <div className="form-group group-search">
                        <div className="group-search__inner">
                          <input
                            type="text"
                            className="search-box"
                            placeholder={i18next.t("Pages.SearchConfiguration.ConfigCode")}
                            value={searchVal}
                            onChange={(e) => setSearchVal(e.target.value)}
                          />
                          <button className="btn btn-primary btn-submit" onClick={searchConfigurator}>{i18next.t("Pages.SearchConfiguration.Search")}</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className={isLoading ? "loader-container" : "loader-container is-loaded"}>
                <span className="loader"></span>
                <div className="loader-text">{i18next.t("Pages.SearchConfiguration.Loader")}</div>
              </div>
              {!isLoading &&
                <>
                  {configuratorDetail.model && configuratorDetail.model != 0 &&
                    <div className="table rel">
                      <div className="hdr sticky">
                        <Row gutter={[24, 24]}>
                          {/* <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            lg={{ span: 4 }}
                          >
                            <div className="table-header-item">ID
                              
                            </div>
                          </Col> */}
                          <Col
                            span={3}
                            xs={{ span: 3 }}
                            sm={{ span: 3 }}
                            lg={{ span: 3 }}
                          >
                            <div className="table-header-item">Modello

                            </div>
                          </Col>
                          <Col
                            span={2}
                            xs={{ span: 2 }}
                            sm={{ span: 2 }}
                            lg={{ span: 2 }}
                          >
                            <div className="table-header-item">Taglia

                            </div>
                          </Col>
                          <Col
                            span={2}
                            xs={{ span: 2 }}
                            sm={{ span: 2 }}
                            lg={{ span: 2 }}
                          >
                            <div className="table-header-item">Colore

                            </div>
                          </Col>
                          <Col
                            span={5}
                            xs={{ span: 5 }}
                            sm={{ span: 5 }}
                            lg={{ span: 5 }}
                          >
                            <div className="table-header-item">Gruppo</div>
                          </Col>
                          <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            lg={{ span: 4 }}
                          >
                            <div className="table-header-item">
                              Ruote
                            </div>
                          </Col>
                          <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            lg={{ span: 4 }}
                          >
                            <div className="table-header-item">
                              Cliente
                            </div>
                          </Col>
                          <Col
                            span={2}
                            xs={{ span: 2 }}
                            sm={{ span: 2 }}
                            lg={{ span: 2 }}
                          >
                            <div className="table-header-item">Prezzo

                            </div>
                          </Col>
                          <Col
                            span={2}
                            xs={{ span: 2 }}
                            sm={{ span: 2 }}
                            lg={{ span: 2 }}
                          >
                            <div className="table-header-item"></div>
                          </Col>
                        </Row>
                      </div>
                      <div className="table-wrap">
                        <div className="table-item">

                          <Row gutter={[24, 24]}>
                            {/* <Col
                              span={4}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              <div className="table-item__content">{configuratorDetail.configurator_id}</div>
                            </Col> */}
                            <Col
                              span={3}
                              xs={{ span: 3 }}
                              sm={{ span: 3 }}
                              lg={{ span: 3 }}
                            >
                              <div className="table-item__content">{configuratorDetail.model_name}</div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content">{configuratorDetail.size_name}</div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content">{configuratorDetail.color_name}</div>
                            </Col>
                            <Col
                              span={5}
                              xs={{ span: 5 }}
                              sm={{ span: 5 }}
                              lg={{ span: 5 }}
                            >
                              <div className="table-item__content">{configuratorDetail.groupset_name}</div>
                            </Col>
                            <Col
                              span={4}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              <div className="table-item__content">{configuratorDetail.wheel_name}</div>
                            </Col>
                            <Col
                              span={4}
                              xs={{ span: 4 }}
                              sm={{ span: 4 }}
                              lg={{ span: 4 }}
                            >
                              <div className="table-item__content">{configuratorDetail.user_data.firstName} {configuratorDetail.user_data.lastName}</div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content">{configuratorDetail.regular_price}€</div>
                            </Col>
                            <Col
                              span={2}
                              xs={{ span: 2 }}
                              sm={{ span: 2 }}
                              lg={{ span: 2 }}
                            >
                              <div className="table-item__content rel">
                                <Dropdown overlay={
                                  <Menu>
                                    <Menu.Item onClick={() => configuratorPopup(configuratorDetail)}>{i18next.t("Pages.SearchConfiguration.Table.Summary")}</Menu.Item>
                                    <Menu.Item onClick={() => downloadConfiguratorPDF(configuratorDetail.configurator_id)} >{i18next.t("Pages.SearchConfiguration.Table.DownloadPDF")}</Menu.Item>
                                    {configuratorDetail.is_order_created == 0 && <Menu.Item onClick={() => {
                                      window.open(`/${configuratorDetail.language}` + "/" + i18next.t('routes.Configuration', { lng: configuratorDetail.language }) + `/?configurator_id=${configuratorDetail.configurator_id}`, "_blank");
                                    }}>{i18next.t("Pages.SearchConfiguration.Table.Edit")}</Menu.Item>}
                                    {configuratorDetail.is_order_created != 0 && <Menu.Item onClick={() => {
                                      window.open(`/${i18next.language}` + "/" + i18next.t('routes.YourOrders') + `/${configuratorDetail.is_order_created}`, "_blank");
                                    }}>
                                      {i18next.t("Pages.SearchConfiguration.Table.OrderDetails")}
                                    </Menu.Item>}
                                  </Menu>
                                } placement="bottomLeft" arrow>
                                  <Button>
                                    <svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 56"><path d="M350,308a28,28,0,1,0-28-28A28,28,0,0,0,350,308Zm112,0a28,28,0,1,0-28-28A28,28,0,0,0,462,308Zm-224,0a28,28,0,1,0-28-28A28,28,0,0,0,238,308Z" transform="translate(-210 -252)" style={{ fillRule: 'evenodd' }} /></svg>
                                  </Button>
                                </Dropdown>
                              </div>
                            </Col>
                          </Row>

                        </div>
                      </div>
                    </div>
                  }
                </>
              }
              {configuratorDetail && configuratorDetail.model == 0 &&
                <div className="no-configuration-found">Nessuna configurazione trovata...</div>
              }
            </div>
          </div>
        </div>
      </div>
      {configuratorPopupData && isSummaryPopup && <ConfiguratorSummary isSummaryPopup={isSummaryPopup} configurator_id={configuratorPopupData} setisSummaryPopup={setisSummaryPopup} />}
      <Footer />
    </>
  )
}

export default ConfigSearch
