import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Input, Space, Row, Col } from 'antd';
import { ReactTitle } from 'react-meta-tags';
import { i18n } from "../../translations/i18n";
import history from "../../history";
import Cookie from "js-cookie";
import i18next from "i18next";
import {
  DashboardAnalyticIcon,
  DashboardFilterIcon,
  DashboardTraningIcon,
  DashboardWarrentyIcon,
  DashboardFTPIcon,
  DashboardNewsIcon,
  DashboardSearchIcon,
  DashboardOrderIcon,
  DashboardConfiguratorIcon,
  DashboardConfiguratorationIcon
} from "../../Icons";

const DashboardContainer = (props) => {
  const { Search } = Input;
  const onSearch = value => {
    if (value.length > 0) {
      history.push("/" + i18n.language + "/" + i18next.t('routes.NewOrder') + "/?search=" + value);
    }
  }
  const listOne = [
    {
      label: i18next.t('Dashboard.configuration'),
      icon: <DashboardConfiguratorIcon />,
      txt:  i18next.t('Dashboard.configurationText'),
      btn: i18next.t('Dashboard.button'),
      slug: i18next.t('routes.Configuration'),
      isNewPage: false
    },
    {
      label: i18next.t('Dashboard.ProductStock'),
      icon: <DashboardConfiguratorIcon />,
      txt:  i18next.t('Dashboard.ProductStockText'),
      btn: i18next.t('Dashboard.button'),
      slug: i18next.t('routes.StockProduct'),
      isNewPage: true
    },
    {
      label: i18next.t('Dashboard.order'),
      icon: <DashboardOrderIcon />,
      txt: i18next.t('Dashboard.orderText'),
      btn: i18next.t('Dashboard.button'),
      slug: i18next.t('routes.backOrders'),
      isNewPage: false
    },
    // {
    //   label: "Dashboard analitica",
    //   icon: <DashboardAnalyticIcon />,
    //   btn: i18next.t('Dashboard.button'),
    //   slug: "/",
    // },
  ];

  const listTow = [
    {
      label: i18next.t('Dashboard.warranty'),
      icon: <DashboardWarrentyIcon />,
      txt: i18next.t('Dashboard.warrantyText'),
      btn: i18next.t('Dashboard.button'),
      slug: i18next.t('routes.RegisterBike'),
    },
    {
      label: i18next.t('Dashboard.configurationSaved'),
      icon: <DashboardConfiguratorationIcon />,
      txt: i18next.t('Dashboard.configurationSavedText'),
      btn: i18next.t('Dashboard.button'),
      slug: i18next.t('routes.ConfigurationsSaved'),
    },
    {
      label: "Support Area",
      icon: <DashboardTraningIcon />,
      btn: i18next.t('Dashboard.button'),
      txt: "",
      slug: i18next.t('routes.SupportArea')
    },
    {
      label: i18next.t('Dashboard.trainingArea'),
      icon: <DashboardTraningIcon />,
      btn: i18next.t('Dashboard.button'),
      txt: i18next.t('Dashboard.trainingAreaText'),
      slug: i18next.t('routes.TrainingArea')
    },
  ];
  const listThree = [
    {
      label: i18next.t('Dashboard.newsArea'),
      icon: <DashboardNewsIcon />,
      btn: i18next.t('Dashboard.button'),
      txt: i18next.t('Dashboard.newsAreaText'),
      slug: i18next.t('routes.B2BNews'),
    },
    {
      label: i18next.t('Dashboard.ftpArea'),
      icon: <DashboardFTPIcon />,
      btn: i18next.t('Dashboard.button'),
      txt: i18next.t('Dashboard.ftpAreaText'),
      slug: i18next.t('routes.FTPArea'),
    },
  ];
  const monthSummary = [
    { value: "13", label: "Ordini ricevuti" },
    { value: "12", label: "Ordini emessi" },
    { value: "6", label: "Richieste ricevute" },
    { value: "6", label: "Richieste effettuate" },
    { value: "0", label: "Richieste garanzie" },
    { value: "0", label: "Garanzie effettuate" },
    { value: "43", label: "Prodotti in magazzino" },
    { value: "1", label: "Personalizzazioni richieste" },
    { value: "1", label: "Personalizzazioni richieste" },
  ];

  return (
    <>
      <ReactTitle title={i18next.t('PageTitle.Dashboard')} />
      <Header />
      <div className="dashboard-home">

        <div className="wrap">
          <div className="dashboard-inner">
            <Row gutter={[24, 0]}>
              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 8 }}>
                <div className="card">
                  <div className="card-inner">
                    <div className="meta flex aic">
                      <div className="ico">
                        <DashboardSearchIcon />
                      </div>
                      <div className="title">{i18next.t('Dashboard.equipmentSearch')}</div>
                    </div>
                    <div className="form-wrapper">
                      <Search placeholder={i18next.t('Dashboard.searchPlaceholder')} onSearch={onSearch} enterButton />
                    </div>
                  </div>
                </div>
                {listOne.map((item, idx) => (
                  <div className="card">
                    {item.isNewPage ? (
                      <Link
                        to={{ pathname: "https://stardue-stock-list.vercel.app/basso/stock-list/0" }} target="_blank" 
                        className="flex-link"
                      >
                        <div className="meta flex aic">
                          <div className="ico">{item.icon}</div>
                          <div className="title">{item.label}</div>
                        </div>
                        {item.txt && (
                          <div className="txt">{item.txt}</div>
                        )}
                        <div className="ftr">
                          <Link
                            to={{ pathname: "https://stardue-stock-list.vercel.app/basso/stock-list/0" }} target="_blank" 
                          >
                            {item.btn}
                          </Link>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        to={"/" + i18n.language + '/' + item.slug}
                        className="flex-link"
                      >
                        <div className="meta flex aic">
                          <div className="ico">{item.icon}</div>
                          <div className="title">{item.label}</div>
                        </div>
                        {item.txt && (
                          <div className="txt">{item.txt}</div>
                        )}
                        <div className="ftr">
                          <Link
                            to={"/" + i18n.language + '/' + item.slug}
                          >
                            {item.btn}
                          </Link>
                        </div>
                      </Link>
                    )}
                  </div>
                ))}
              </Col>

              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 8 }}>
                {listTow.map((item, idx) => (
                  <div className="card">
                    <Link
                      to={"/" + i18n.language + '/' + item.slug}
                      className="flex-link"
                    >
                      <div className="meta flex aic">
                        <div className="ico">{item.icon}</div>
                        <div className="title">{item.label}</div>
                      </div>
                      {item.txt && (
                        <div className="txt">{item.txt}</div>
                      )}
                      <div className="ftr">
                        <Link
                          to={"/" + i18n.language + '/' + item.slug}
                        >
                          {item.btn}
                        </Link>
                      </div>
                    </Link>
                  </div>
                ))}
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 8 }} lg={{ span: 8 }}>
                {listThree.map((item, idx) => (
                  <div className="card">
                    <Link
                      to={"/" + i18n.language + '/' + item.slug}
                      className="flex-link"
                    >
                      <div className="meta flex aic">
                        <div className="ico">{item.icon}</div>
                        <div className="title">{item.label}</div>
                      </div>
                      {item.txt && (
                        <div className="txt">{item.txt}</div>
                      )}
                      <div className="ftr">
                        <Link
                          to={"/" + i18n.language + '/' + item.slug}
                        >
                          {item.btn}
                        </Link>
                      </div>
                    </Link>
                  </div>
                ))}

                {(Cookie.get('platformkey') == '7368496012') && <div className="card">
                  <a
                    href={i18next.t('routes.BassoFrontend')}
                    className="flex-link"
                    target={"_blank"}
                  >
                    <div className="meta flex aic">
                      <div className="ico">{<DashboardWarrentyIcon />}</div>
                      <div className="title">{i18next.t('Dashboard.webSite')}</div>
                    </div>
                    <div className="txt">{i18next.t('Dashboard.webSiteText')}</div>
                    <div className="ftr">
                      <a
                        href={i18next.t('routes.BassoFrontend')}
                        target={"_blank"}
                      >
                        {i18next.t('Dashboard.goToWebsite')}
                      </a>
                    </div>
                  </a>
                </div>}
                {(Cookie.get('platformkey') == '7894210751') && <div className="card">
                  <a
                    href={i18next.t('routes.LeeCouganFrontend')}
                    className="flex-link"
                    target={"_blank"}
                  >
                    <div className="meta flex aic">
                      <div className="ico">{<DashboardWarrentyIcon />}</div>
                      <div className="title">{i18next.t('Dashboard.webSite')}</div>
                    </div>
                    <div className="txt">{i18next.t('Dashboard.webSiteText')}</div>
                    <div className="ftr">
                      <a
                        href={i18next.t('routes.LeeCouganFrontend')}
                        target={"_blank"}
                      >
                       {i18next.t('Dashboard.goToWebsite')}
                      </a>
                    </div>
                  </a>
                </div>}
                {/* 
                <div className="card">
                  <div className="card-inner">
                    <div className="meta flex aic">
                      <div className="ico">
                        <DashboardOrderIcon />
                      </div>
                      <div className="title">Riepilogo del mese</div>
                    </div>
                    <div className="cont">
                      {monthSummary.map((item, idx) => (
                        <div key={idx} className="itm flex aic">
                          <div className="no font s15 b5">{item.value}</div>
                          <div className="nam font">{item.label}</div>
                        </div>
                      ))}
                      <div className="itm total flex aic">
                        <div className="no font s15 b5">12.543,89€</div>
                        <div className="nam font">Totale fatturato</div>
                      </div>
                    </div>
                    <div className="ftr flex aic">
                      <Link
                        to={"/" + i18n.language}
                        className=""
                      >
                        Vai al riepilogo ordini
                      </Link>
                    </div>
                  </div>
                </div> */}
              </Col>
            </Row>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};
export default DashboardContainer;
