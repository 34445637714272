import { ERROR_MESSAGE_FOR_INVALID_FIELD } from "./constant";


export const findPrice = (price, type, discount, isOriginalPrice, nonLoggedInPrice) => {
  const priceList = localStorage.getItem('stardue_price_list');
  const user_roles = JSON.parse(localStorage.getItem('user-roles'));
  let agent_dealer_user_roles;
  try {
    agent_dealer_user_roles = JSON.parse(localStorage.getItem('agent-selected-user-roles'));
  } catch (e) {

  }
  try {
    let priceFinal;
    if (user_roles[0] == 'agent' && !nonLoggedInPrice) {
      priceFinal = parseFloat(price[priceList][agent_dealer_user_roles[0]][type]);
    } else if (nonLoggedInPrice === undefined || !nonLoggedInPrice) {
      priceFinal = parseFloat(price[priceList][user_roles[0]][type]);
    } else {
      priceFinal = parseFloat(price[priceList]['non_loggedin'][type]);
    }

    if (discount && !isOriginalPrice) {
      let discountPer = 0;
      /*if (discount == 'product' && localStorage.getItem('stardue_flat_discount')) {
        discountPer = localStorage.getItem('stardue_flat_discount')
      } else */

      if (discount == 'frame' && localStorage.getItem('stardue_flat_discount')) {
        discountPer = localStorage.getItem('stardue_flat_discount')
      }
      priceFinal = priceFinal - (priceFinal * discountPer / 100);
    }
    return priceFinal.toFixed(2)
  } catch (e) {
    console.log(price)
    console.log("findPrice", e)
    return 0;
  }
}

export const priceFormatter = (value) => {
  const language = 'en';
  const currency = localStorage.getItem('stardue_currency');

  let numberFormatData =
    currency === "EUR" ? "de-DE" : language + "-" + language.toUpperCase();
  return new Intl.NumberFormat(numberFormatData, {
    style: "currency",
    minimumFractionDigits: 2,
    currency: currency ? currency : 'USD',
  }).format(value);
};

export const getValue = (obj, expression) => {
  try {
    return expression
      .split(".")
      .reduce((parentObject, currentObjectKey, index) => {
        if (index === 0) {
          return parentObject;
        }
        return typeof parentObject === "undefined" || parentObject === null
          ? undefined
          : parentObject[currentObjectKey];
      }, obj);
  } catch (e) {
    console.error("Failed to getValue => ", e);
    return undefined;
  }
};

export const isEmpty = (value) => {
  return (
    !value ||
    value === null ||
    (typeof value === "string" && value.trim() === "")
  );
};

export const isValueValid = (value, regExp) => {
  const reg = new RegExp(regExp);
  return reg.test(value);
};

export const fieldValidation = ({ field, value, isRequired, regex }) => {
  if (isRequired && !value && value === "")
    return {
      status: "error",
      errorMessage: ERROR_MESSAGE_FOR_INVALID_FIELD["blank"],
    };
  if (regex && value && !value.toString().match(regex)) {
    return {
      status: "error",
      errorMessage: ERROR_MESSAGE_FOR_INVALID_FIELD[field],
    };
  }
};


export const arrayRemove = (arr, value) => {
  return arr.filter(function (ele) {
    return ele != value;
  });
};

