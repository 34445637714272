export const TRANSLATIONS_EN = {
  routes: {
    Dashboard: "dashboard",
    Configuration: "configure",
    ConfigurationsSaved: "your-configurations",
    ConfigurationsSearch: "upload-configuration",
    NewOrder: "new-equipment-order",
    YourOrders: "your-orders",
    backOrders: "confirmed-orders",
    RegisterBike: "register-customer-bike",
    SupportArea: "support-area",
    TrainingArea: "training-area",
    FTPArea: "download-area",
    B2BNews: "news",
    AccountUser: "account-data",
    SwitchBrand: "switch-brand",
    ShopSelectorAgent: "shop-selector",
    Checkout: "checkout",
    Login: "login",
    ResetPassword: "reset-password",
    NewPassword: "new-password",
    PrivacyPolicy: "privacy-policy",
    TermsConditions: "terms-conditions",
    BassoFrontend: "https://bassobikes.com",
    LeeCouganFrontend: "https://www.leecougan.com",
    StockProduct:"products-in-stock",
  },
  PageTitle: {
    Dashboard: "Dashboard | Stardue B2B",
    Configurator: "Configure | Stardue B2B",
    ConfigurationsSearch: "Upload configuration | Stardue B2B",
    ConfigurationsSaved: " Your Configurations | Stardue B2B",
    NewOrder: "New equipment order | Stardue B2B",
    YourOrders: "{{orderId}} Your orders | Stardue B2B",
    OrderList: "Your orders | Stardue B2B",
    BackOrders: "Confirmed orders | Stardue B2B",
    RegisterBike: "Register customer bike | Stardue B2B",
    SupportArea: "Support area | Stardue B2B",
    B2BNews: "News | Stardue B2B",
    TrainingArea: " Training area | Stardue B2B",
    FTPArea: "Download area | Stardue B2B",
    AccountUser: "Account data | Stardue B2B",
    SwitchBrand: "Switch brand | Stardue B2B",
    ShopSelectorAgent: " Shop selector | Stardue B2B",
    Checkout: "Checkout | Stardue B2B",
    Login: "Login | Stardue B2B",
    ResetPassword: "Reset password | Stardue B2B",
    NewPassword: "New password | Stardue B2B",
    PrivacyPolicy: "Privacy & Policy | Stardue B2B",
    TermsConditions: " Terms & Conditions | Stardue B2B",
    ProductsStock: "Products in stock | Stardue B2B",
  },
  Footer: {
    Coopyright: "© 2022 - STARDUE S.R.L. - P.IVA / C.F. 02109950267",
    TermsConditions: "Terms & Conditions",
    PrivacyPolicy: "Privacy & Policy",
  },
  Header: {
    Option: "Select an option",
    BikesGroup: {
      Bikes: "Bikes",
      ProductsStock: "Products in stock",
      ConfigurationsSaved: "My configurations",
      ConfigurationsSearch: "Find customer configuration",
      Configuration: "Create new configuration",
    },
    Equipments: "Equipment",
    OrdersGroup: {
      Orders: "Orders",
      OrdersWaiting: "Waiting confirmation",
      BackOrders: "Confirmed",
    },
    ServicesGroup: {
      Services: "Services",
      RegisterBike: "Register customer's new bike",
      SupportArea: "Assistance area",
      FTPArea: "Download area",
      TrainingArea: "Training area",
      B2BNews: "Latest news",
    },
    UserGroup: {
      YourData: "Your date",
      Logout: "Logout",
      ChangePlatform: "Switch brand",
      ChangeShop: "Switch shop",
    },
  },
  Dashboard: {
    
    ftpArea: "Download area",
    ftpAreaText: "Login to your Download area to downloada reserved content.",
    ticketArea: "Assistance area",
    ticketAreaText: "",
    trainingArea: "Training area",
    trainingAreaText:
      "Discover all the video tutorials reserved for our resellers.",
    warranty: "Register customer's new bike",
    warrantyText: "",
    ticketArea: "Assistance area",
    ticketAreaText: "",
    configuration: "Create a new configuration",
    configurationText: "",
    configurationSaved: "Your configurations",
    configurationSavedText: "",
    equipmentSearch: "Find Equipment",
    searchPlaceholder: "SKU or product name",
    order: "Confirmed orders",
    orderText:"Track the progress of your confirmed orders",
    newsArea: "Latest news",
    newsAreaText:
      "The lastest news from our world so you can be the first to know.",
    webSite: "Website",
    webSiteText: "Visit B2C website",
    button: "Enter",
    goToWebsite: "Go to website",
    ProductStock:"Products in stock",
    ProductStockText: "Prodotti nel nostro magazzino pronti per essere spediti.",
  },
  Common: {
    ServiceNotAvailable: "Data not available due to ERP migration. We are sorry for the inconvenient. The service will be back soon.",
    NoMobileView:
      "Dashboard is not available on mobile screen. Please continue to use your Laptop.",
    PasswordMatchError: "The password isn't correct",
    hi: "Hello",
    Back: "Back",
    Logout: "Logout",
    Address: "Address",
    Contacts: "Contacts",
    FiscalCode: "Fiscal code",
    AddNewAddress: "Add new address",
    NewAddress: "New address",
    RequiredFields: "All fields marked with an asterisk* are mandatory. ",
    Confirm: "Confirm",
    Nominative: "Nominative",
    okText: "Yes",
    CancelText: "No",
    Proceed: "Proceed",
    DiscoverButton: "Discover",
    FiltersSidebar: {
      Title: "Filter",
      Submit: "Apply",
      Reset: "Remove filters",
      All1: "All",
      All2: "All",
      Model: "Model",
      Groupset: "Groupset",
      idOrder: "Order ID",
      IDConfiguration: "Configuration ID",
      SKU: "Search SKU or product name",
      SKUADMIN: "Search by SKU, product name, dealer name or code",
      Category: "Choose a category",
      RelatedModel: "Find spare parts related to:",
      State: "Filter by status",
      FromDate: "From",
      ToDate: "To",
      SerialNumber: "Serial number",
      ChooseOption: "Select option",
      RegisterNewBike: "Register a new bike",
      OpenTicket: "Open new ticket",
      Ready_to_ship: 'Ready to ship',
      order_confirmed: 'Order confirmed',
      in_production: 'In production',
      on_hold: 'On hold',
    },
    Pagination: {
      Prev: "Prev",
      Next: "Next",
    },
    ConfirmationMessage: {
      Products: {
        Title: "Product added to your'order",
        Label: "Thanks, the product has been added to your order",
      },
    },
    Form: {
      SelectOption: "Choose",
      companyname: "Company Name",
      requireField: "This field is required. Please enter a value.",
      name: "Name",
      PublicEmail: "Public email",
      require_name: "Please insert your Name.",
      surname: "Surname",
      require_surname: "Please insert your Surname.",
      address: "Address",
      country: "Country",
      require_country: "Please insert your Country.",
      city: "City",
      require_city: "Please insert your City.",
      province: "Province",
      require_province: "Please insert the Province.",
      street: "Street and Number",
      require_street: "Please insert your Street Name and Number.",
      postalcode: "Postal code",
      require_postalcode: "Please enter the Postal Code.",
      contact: "Contact information",
      phone: "Phone",
      require_phone: "Please enter your Phone number.",
      defaultAddressSettings: "Settings",
      is_default_address: "Mark this address as default.",
      edit: "Edit",
      email: "E-mail",
      valid_email:
        "This E-mail address is not valid, please enter a valid E-mail address.",
      require_email: "Please insert your email",
      first_name: "First Name",
      require_first_name: "Please insert your First Name",
      last_name: "Last Name",
      require_last_name: "Please insert your Last Name",
      password: "Password",
      require_password: "Please insert your password",
      confirmPassword: "Confirm Password",
      require_confirmPassword: "Please confirm your Password",
      model: "Model",
      serial_number: "Serial Number",
      require_serial_number: "Please add your Serial Number",
      purchase_date: "Purchase date",
      proof_purchase: "Proof of Purchase",
      store: "Dealer",
      require_store: "Please, use this field to select your Dealer",
      save: "Save",
      sendrequest: "Send Request",
      otherInformation: "Other Information",
      generic_required:
        "This field is required. Please add the requested information.",
      couopon: "Coupon",
      request: "Request",
      query: "Additional information",
      topic: "Topic",
      Update: "Update",
    },
    Bikes: {
      SummaryBike: {
        ConfigurationID: "Configuration ID",
        Sku: "Sku",
        Groupset: "Groupset",
        Size: "Size",
        Wheels: "Wheels",
        Color: "Color",
      },
    },
  },
  Pages: {
    ChangeBrand: {
      RequestAccess: "Request access",
      Form: {
        Title: "Request access",
        Label: "Describe your request",
        Submit: "Send"
      }
    },
    Login: {
      Title: "Welcome",
      Subtitle: "Log in to your dedicated dashboard",
      Email: "Your email",
      Password: "Your password",
      Submit: "Login",
      LostPassword: "Have you forgotten your password?",
    },
    ForgotPassword: {
      Title: "Have you forgotten your password?",
      Subtitle: "Reset it now and login",
      Email: "Your email",
      Submit: "Proceed",
    },
    NewPassword: {
      Title: "Forgot your Password?",
      Subtitle: "Reset it now and login",
      Email: "Your email",
      Password: "Nuova password",
      ConfirmPassword: "Confirm your Password",
      Message: "",
      Submit: "Confirm",
    },
    AgentPage: {
      Loader: "We are loading your customers",
      Title: "Login on behalf of a customer.",
      NoClient: "No associated customers. Please contact our support.",
    },
    UserDetails: {
      YourData: "Your profile",
      Anagraphic: "Your ",
      Company: "Company name",
      VAT: "VAT Number",
      EmailAccount: "Account email",
      StoreLocatorTitle: "Store locator informations",
      TimeTable: {
        ShopTime: "Store hours",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
        morning: "Morning",
        afternoon: "Afternoon",
        start: "Start",
        end: "End",
        closed: "Closed",
        nonStop: "non-stop",
      },
    },
    Configurator: {
      PageTitle: "Bike configurator",
      ConfigureNow: "Configure yours",
      LoaderLabel: "We are loading the configurator.",
      NotSelected: "Not selected",
      AvailableFrom: "From",
      Quantity: "Quantity",
      Geometry: "Geometry",
      OutOfStock: "Out of stock",
      Type: {
        Bike: "The complete Basso bike ready to ridden.",
        FrameKit: "The frame, fork and all the specific Basso components.",
        BuildKit: "Frame kit and all components without groupset.",
      },
      Step: {
        Header: {
          Categories: "Categories",
          Models: "Model",
          Type: "Type",
          Wheels: "Wheels",
          Color: "Color",
          Size: "Size",
          Groupset: "Groupset",
          Options: "Options",
          Quantity: "Quantity",
        },
        Price: {
          Included: "Included",
          Upgrade: "Upgrade selected",
        },
      },
      BottomBar: {
        ProceedToCart: "Add to cart",
        FindYourDealer: "Find your dealer",
        Save: "Save",
        DownloadPDF: "Download PDF",
        YourPrice: "Reserved price",
        PublicPrice: "Suggested retail price",
        DiscountLabel: "Your markup",
      },
      ConfiguratorSummary: {
        Title: "Configuration Summary",
        FrameSet: "Frame set",
        Frame: "Frame",
        Fork: "Fork",
        SeatPostClamp: "Seat post clamp",
        ThruAxleFornt: "Front Thru Axle",
        ThruAxleRear: "Rear Thru Axle",
        Hanger: "Hanger",
        Headset: "Headset",
        Groupset: "Groupset",
        Crank: "Crank",
        CrankArm: "Crank arm",
        ChainRings: "Chain rings",
        Chain: "Chain",
        BottomBraket: "Bottom bracket",
        Cassette: "Cassette",
        CassetteGears: "Cassette gears",
        FrontDerailleur: "Front derailleur",
        RearDerailleur: "Rear derailleur",
        Shifters: "Shifters",
        Brakes: "Brakes",
        Rotors: "Rotors",
        Cockpit: "Cockpit",
        SeatpostAndSaddle: "Seatpost and saddle",
        WheelsAndTires: "Wheels and Tires",
        Wheels: "Wheels",
        Upgrade: "Upgrade",
        HeadUnitMount: "Head unit mount",
        HandlebarTap: "Handlebar tape",
        PowerUnit: "Power Unit",
        Motor: "Motor",
        Display: "Display",
        Battery: "Battery",
        Other: "Other",
        Editing: "Keep on editing",
        DownloadPDF: "Download PDF",
        ConfirmAndProceed: "Proceed to cart",
      },
      SaveConfigurator: {
        Popup: {
          Loader: "We are saving your configuration...",
          Header: {
            Title: "Save your configuration",
            Subtitle: "Congratulations, your configuration has been saved.",
          },
          DiscoverButton: "Discover",
        },
      },
      AddToCart: {
        Popup: {
          Loader: "We are adding your configuration to the cart...",
          Header: {
            Title: "Configuration added",
            Subtitle:
              "Congratulations, your configuration has been added to the cart.",
          },
        },
      },
      DowloadPDF: {
        Popup: {
          Loader: "We are preparing the PDF of your configuration...",
          Header: {
            Title: "Download PDF",
            Subtitle: "Congratulations, your PDF has been generated.",
          },
        },
      },
      SummarySmall: {
        Title: "Summary",
        Categories: "Categories",
        Models: "Model",
        Type: "Type",
        Wheels: "Wheels",
        Color: "Color",
        Size: "Size",
        Groupset: "Groupset",
      },
      PreviewImage: {
        Title: "Preview",
        LargeImage: "Click to enlarge the image",
      },
    },
    ConfigurationSaved: {
      PageTitle: "Saved configurations",
      Loader: "We are loading your configuration...",
      Table: {
        ID: "ID",
        Model: "Model",
        Groupset: "Groupset",
        Size: "Size",
        Color: "Color",
        Wheels: "Wheels",
        Price: "Price",
        Edit: "Edit",
        Summary: "Summary",
        DownloadPDF: "Download PDF",
        OrderDetails: "View order",
        Delete: "Delete",
      },
    },
    SearchConfiguration: {
      Title: "Search existing configuration",
      Info:
        "Insert a configuration ID code to recall an existing configuration. Configuration ID can be found on “Your configurations” page on both B2B and B2C website and on all PDF configuration documents.",
      Search: "Search now",
      ConfigCode: "Insert Configuration ID code",
      Loader: "We are looking for the requested configuration...",
      Table: {
        Edit: "Edit",
        Summary: "Summary",
        DownloadPDF: "Download PDF",
        OrderDetails: "View order",
      },
    },
    Address: {
      Header: {
        Title: "Your Addresses",
        Subtitle: "Manage your shipping and billing addresses.",
      },
      ShippingAddress: {
        Label: "Shipping address",
      },
      BillingAddress: {
        Label: "Billing address",
      },
    },
    Equipments: {
      PageTitle: "Online order",
      Loader: "We are uploading the products",
      NotFound: "No products found. Please use another keyword.",
      Table: {
        Image: "Image",
        SKU: "SKU",
        Name: "Name",
        Categories: "Categories",
        Stock: "Stock",
        Price: "Price",
        OrderNow: "Add to order",
      },
      Details: {
        RecomandedPrice: "Suggested retail price",
        YourDiscount: "Your markup",
        Description: "Description",
        Features: "Features",
        BuyNow: "Add to my order",
        ConfirmationAddToCart: "",
      },
    },
    Orders: {
      PageTitle: "Your orders",
      Loader: "We are loading your orders...",
      Table: {
        OrderNumber: "ID",
        OrderDate: "Order date",
        ExpectiveDate: "Expected Delivery",
        Destination: "Delivered to",
        State: "Status",
        Products: "Products",
        ProductIncluded: "Products",
        Total: "Total",
        Details: "Details",
        ChildOrderLabel: "Related orders",
      },
      Single: {
        Order: "Order",
        EstimatedDate: "Estimated date",
        NotAvailableEstimateDate: "Not available",
        OpenTicket: "Open a ticket",
        OrderDate: "Order date",
        ShipmentTo: "Shipment to",
        OrderStatus: "Status",
        DownloadReceipt: "Download receipt",
        ReturnOrder: "Return order",
        ReturnRequests: "Return request",
        ReturnRequestsInfo: "Your request has been submitted successfully",
        OrderSummary: "Order summary",
        Sku: "SKU",
        Groupset: "Groupset",
        ConfiguratorID: "Configurator ID",
        Size: "Size",
        Color: "Color",
        Wheels: "Wheels",
        DownloarConf: "Download your configuration",
        Quantity: "Quantity",
        Price: "Price",
        Subtotal: "Subtotal",
        Shipment: "Shipment",
        PaymentMethod: "Payment Method",
        Total: "Total",
        BillingInformation: "Billing information",
        ShippingData: "Shipping data",
        FiscalCode: "Fiscal code",
        IncludeOption: "Options included",
        Upgrade: "Upgrade selected",
        Bacs: "As agreed with Stardue Srl",
      },
      TicketOrder: {
        Common: {
          Title: "Order {{orderId}} - Assistance ticket",
          Request: "Request",
          Order: "Order",
          StepTitle1: "Request",
          StepTitle2: "Products",
          StepTitle3: "Tell us more",
          StepTitle4: "Confirm",
          Next: "Next",
          Proceed: "Proceed",
          Back: "Back",
          SuccessTitle: "Your request has been submitted successfully.",
          SuccessSubtitle:
            "You will be contacted by the Basso Bikes team within the next 24/48 hours.",
          SuccessButton: "Back to the order",
          FileUploadTitle: "Attach a file",
          FileUploadContent:
            "Import pictures representing the problem encountered: .pdf, .png and .jpeg are accepted. Maximum file size: 10MB",
        },
        Step1: {
          Content: "Select the subject of your requested",
          ReturnReplacement: "Replacement / Return",
          Tracking: "Tracking information",
        },
        Step2: {
          Content: "Select the product with issues:",
        },
        Step3ReturnProducts: {
          Content:
            "What kind of problems have you encountered? Help the Basso staff understand what went wrong.",
        },
        Step3Tracking: {
          Content:
            "What kind of problems have you encountered? Help the Basso staff understand what went wrong.",
        },
        Step4ReturnProducts: {
          Content:
            "If you choose to confirm, your replacement/return request will be taken over by the Basso Bikes Team that will get in touch as soon as possible.",
          Title:
            "Are you sure you want open a replace/return request for these products?",
        },
        Step4Tracking: {
          Content:
            "If you choose to confirm, your replacement/return request will be taken over by the Basso Bikes Team that will get in touch as soon as possible.",
          Title: "Are you sure you want to replace/return the products?",
        },
        Step5: {
          Content:
            "You will be contacted by the Basso Bikes team within the next 24/48 hours.",
          Title: "Your request has been submitted successfully.",
        },
      },
    },
    BackOrders: {
      Title: "Confirmed orders",
      Loader: "We are loading your orders...",
      Table: {
        OrderNumber: "Order n°",
        NumberRow: "Row number",
        OrderDate: "Order date",
        ExpectiveDate: "Expected Delivery",
        Client: "Customer",
        Destin: "Destination",
        CodArt: "SKU",
        Groupset: "Groupset",
        TG: "Size",
        ArtDescription: "Description",
        ClientData: "Order Reqested date",
        QRes: "Quantity",
        State: "Status",
        Note: "Note",
      },
    },
    ProductsStock: {
      Title: "Products in stock",
      Loader: "We are loading your orders...",
      Table: {
        CodArt: "SKU",
        Groupset: "Groupset",
        TG: "Size",
        ArtDescription: "Description",
        QRes: "Quantity",
      },
    },
    RegisterBike: {
      PageTitle: "Register a new bike",
      Loader: "We are loading your registered bikes...",
      Table: {
        Client: "Customer",
        Email: "Email",
        PurchaseDate: "Purchase date",
        Model: "Model",
        SerialNumber: "Serial Number",
        ProofOfPurchase: "Proof of purchase",
      },
      FormPage: {
        Header: {
          Title: "Register a new bike",
        },
        Form: {
          MessageUserNotFound:
            "There is no account associated with this mail. Enter your first and last name to create one.",
          MessageUserFound:
            "This user is already registered in our database. You can continue with the registration.",
          EmailNotValid: "The email entered is not valid.",
          Email: "Email",
          Name: "Name",
          Surname: "Surname",
          TitleStep1: "Customer data",
          TitleStep2: "Bike's data",
          TitleStep3: "Summary",
          TitleStepInner1: "Enter the owner's details.",
          TitleStepInner2: "Insert the informations requested of the bike",
          requireField: "This field is required. Please enter a value.",
          Shop: "Shop",
          SerialNumber: "Where to find the serial number?",
          SelectModel: "Select your model",
          PurchaseDate: "Purchase date",
          Model: "Model",
          SerialNumber: "Serial Number",
          ProofOfPurchase: "Proof of purchase",
          importFIle:
            "Import a file with the bike’s receipt: .pdf, .png and .jpeg are accepted. Maximum file size: 10MB",
        },
      },
      ListPage: {
        Header: {
          Title: "Your registered bikes",
          Subtitle: "See your registered bikes or add a new one.",
        },
        Form: {
          MessageUserNotFound:
            "There is no account associated with this mail. Enter your first and last name to create one.",
          MessageUserFound:
            "This user is already registered in our database. You can continue with the registration.",
          EmailNotValid: "The email entered is not valid.",
          Email: "Email",
          Name: "Name",
          Surname: "Surname",
          TitleStep1: "Customer data",
          TitleStep2: "Bike's data",
          TitleStep3: "Summary",
          TitleStepInner1: "Enter the owner's details.",
          TitleStepInner2: "Insert the informations requested of the bike",
          requireField: "This field is required. Please enter a value.",
          Shop: "Shop",
          SerialNumber: "Where to find the serial number?",
          SelectModel: "Select your model",
          PurchaseDate: "Purchase date",
          Model: "Model",
          SerialNumber: "Serial Number",
          ProofOfPurchase: "Proof of purchase",
          importFIle:
            "Import a file with the bike’s receipt: .pdf, .png and .jpeg are accepted. Maximum file size: 10MB",
        },
      },
    },
    SupportArea: {
      PageTitle: "Assistance Area",
      Loader: "We are loading your tickets ...",
      Table: {
        ID: "ID",
        State: "Status",
        Topic: "Topic",
        Description: "Description",
        File: "Attacment",
        Data: "Date",
        Product: "Products",
        YourRequest: "Your request",
      },
      Popup: {
        Common: {
          Title: "Open a assistence request",
          Step1: "Request",
          Step2: "Topic",
          Step3: "Tell us more",
          Next: "Next",
          Proceed: "Proceed",
          Back: "Back",
          FileUploadTitle: "Attachment",
          FileUploadContent:
            "Import pictures representing the problem encountered; .pdf, .png and .jpeg are accepted. Maximum file size: 10MB",
        },
        Step1: {
          Content: "How can we help you?",
        },
        Step2: {
          Content: "What topic is it about?",
        },
        Step3: {
          Content:
            "Please, give us all the information that can to help us to better assist you. Please add images that can help us address the ticket:",
        },
        Options: {
          information: "Information",
          assisted_purchase: "Assisted Purchace",
          leecougan: "Lee Cougan",
          microtech: "Microtech",
          basso: "Basso",
          other: "Other",
          e_commerce_assistance: "E-commerce assistance",
          availability: "Availability",
          leecougan_product_assistance: "Lee Cougan Product assistance",
          on_microtech_product_assistance: "Microtech Product assistance",
          other: "Other",
          warranty_request: "Warranty request",
          support_dealer: "Dealer technical support",
          basso_product_assistance: "Basso Product assistance",
          rider_weight: "Rider weight (with gear)",
          rider_weight_placeholder: "80 kg",
          discipline: "Discipline",
          level: "Level",
          amateur: "Amateur",
          agonist: "Agonist",
          pro: "Pro",
          weekly_training_hours: "Weekly training hours",
          rampage_Innova_frame_size: "Rampage Innova frame size"
        },
      },
    },
    DownloadArea: {
      PageTitle: "Download area",
      Loader: "We are loading all the files...",
      Table: {
        FileName: "File name",
        Category: "Category",
        Description: "Description",
      },
    },
    TraningArea: {
      PageTitle: "Area Training",
      Loader: "We are loading all tutorials...",
      Table: {
        Title: "Title",
        Model: "Model",
        Description: "Description",
        Date: "Description",
      },
    },
    News: {
      PageTitle: "News",
      Loader: "We are loading all the lastest news...",
      LoaderSingle: "We are loading all the lastest news...",
      FindOutMore: "Discover more",
    },
    ShopSelectorAgent: {
      Hello: "Hello",
      Loader: "We are loading your customers",
      Subtitle: "Login on behalf of your customer",
      Proceed: "Proceed",
    },
    Checkout: {
      CartTitle: "Please check your order",
      NoProducts: "No products in the cart",
      Table: {
        img: "Immage",
        Product: "Product",
        Sku: "SKU",
        Qty: "Quantity",
        Price: "Price",
        Total: "Total",
      },
      Item: {
        Qty: "Quantity",
        viewConfiguration: "View configuration details",
        ViewDetails: "View details",
        EditConfiguration: "Edit configuration",
        RemoveProduct: "Remove the product",
        Price: "Price",
      },
      ShipmentPaymentTitle: "Shipping and payments",
      Shipment: {
        Title: "Shipping",
        ShipmentByClientLabel: "Do you want to organize your own shipment?",
        ShipmentByClientTxt: "Shipping costs will be removed from your order",
        ShipmentByClientCheckbox:
          "Yes, I want to organize the shipment with my courier.",
        ShipmentAddress: "Shipping address",
        SameAsBilling: "Same as billing",
      },
      Billing: {
        Title: "Billing",
        YourBillingData: "Your Billing data",
        YourBillingDataDescription:
          "To change your Billing information, please contact customer service.",
        CompanyDetails: "Company details",
        Vat: "VAT",
        Address: "Address",
        Contacts: "Contacts",
        OrderForCustomer: {
          Title: "Are you placing an order for your customer?",
          Description:
            "If you are placing an order for one of your customers, enter it here and the order will also be loaded in their reserved area.",
          Checkbox: "Yes I'm placing the order for my customer",
          MessageUserNotFound:
            "There is no account associated with this mail. Enter your first and last name to create one.",
          MessageUserFound:
            "This user is already registered in our database. You can continue with the registration.",
          EmailNotValid: "The email entered is not valid.",
          Email: "Email",
          Name: "Name",
          Surname: "Surname",
        },
      },
      Payment: {
        Title: "Payment",
        TypeLabel: "Payment methods and conditions",
        Bacs: "As agreed with Stardue Srl",
        BankTitle: "Banks details",
        BeneficiaryName: "Beneficiary name",
      },
      BottomBar: {
        TotalProducts: "Product total",
        Shipment: "Shipment",
        ShipmentTxt: "Quoted separately",
        Total: "Order total",
        Submit: "Submit",
      },
    },
    DealersShop: {
      PageTitle: "Dealers Shop",
      Loader: "Wir laden Ihre Tickets...",
      Table: {
        ID: "ID",
        CompanyName: "Company Name",
        Vat: "Vat",
        Phone: "Phone",
      },
    },
  },
  PopupConfirmation: {
    TicketAdded: {
      Title: "Title",
      Content: "Content",
    },
    ProductAddedToCart: {
      Title: "Title",
      Content: "Content",
    },
    OrderPlaced: {
      Title: "Title",
      Content: "Content",
    },
    RegisteredBike: {
      Title: "Title",
      Content: "Content",
    },
  },
};
