import React, { useEffect, useState, useContext } from 'react';
import CanvasComponent from "../../components/CanvasComponent";
import i18next from 'i18next';
import { ConfiguratorContext } from "../../context/configurator-loader";



const ConfiguratorView = (props) => {
    // const [activeView, setActiveView] = useState(props.activeView ? props.activeView : 0)
    const { updateImageState, imageData } = useContext(ConfiguratorContext);
    const [imageArray, setImageArray] = useState({})
    const configuratorData = props.configuratorViewImages;
    const viewImages = props.configuratorViewImages.view;
    const colorName = props.color;
    const wheelID = props.wheel;
    const groupsetImages = props.groupsetImages;
    const canvasWidth = props.canvasWidth;
    const imageWidth = 4904;
    const imageHeight = 3678;
    const canvasHeight = (imageHeight * canvasWidth / imageWidth);
    const setViewModel = props.setViewModel
    const setImageLoaded = (key) => {
        checkLoader();
    }

    useEffect(() => {
        if (Object.keys(imageArray).length > 0) {
            checkLoader();
        }
    }, [imageData, imageArray]);

    function checkLoader() {
        let isTrue = true;
        Object.keys(imageArray).map((key, data) => {
            let obj = document.getElementById(key);
            if (obj) {
                let dataSet = obj.dataset;
                if (dataSet.image != '' && dataSet.imageLoaded != 'yes') {
                    isTrue = false;
                }
            }
        })
        // console.log("checkLoader", isTrue, imageArray)
        if (Object.keys(imageArray).length > 2 && props.setGroupLoader && isTrue) {

            setTimeout(function () { props.setGroupLoader(false) }, 2000);
        } else if (Object.keys(imageArray).length == 2) {

            props.setGroupLoader(false);
        } else {
            /*temp code*/
            props.setGroupLoader(false);
            if (Object.keys(imageArray)) {
                // checkLoader();
            }
        }
    }

    useEffect(() => {
        let imageArrayTemp = [];
        colorName && viewImages && props.previewOrder && props.previewOrder.length > 0 && props.previewOrder.map(order => {
            if (order === "frmae") {
                imageArrayTemp["main_view_0"] = false;
            } else if (order === "wheels") {
                imageArrayTemp["wheel_0"] = false;
            } else if (props.groupsetImages && props.groupsetImages.map(image => image.name).includes(order)) {
                let data = props.groupsetImages.filter(image => image.name === order);
                let key = order.replace(/[^a-zA-Z0-9]/g, "");
                imageArrayTemp[key] = false;
            }
            else if (props.otherImages && Object.keys(props.otherImages).length > 0 && Object.keys(props.otherImages) && Object.keys(props.otherImages).includes(order)) {
                let key = order.replace(/[^a-zA-Z0-9]/g, "");
                imageArrayTemp[key] = false;
            }
        })
        updateImageState(imageArrayTemp);
        setImageArray(imageArrayTemp);
    }, [configuratorData, viewImages, colorName, wheelID, props.previewOrder, groupsetImages]);

    useEffect(() => { checkLoader() }, [imageArray])

    return (
        <>
            <div key={Math.random()} className="viewSwitcher">
                <div className="viewSwitcherContainer">
                    {colorName && viewImages &&
                        viewImages.length > 0 &&
                        viewImages.map((configuratorViewData, index) => {
                            if (!configuratorViewData.color || !configuratorViewData.color[colorName]) {
                                return <></>
                            }
                            var id = "view_switcher_" + index;
                            var className = (props.activeView === index) ? id + " view_switcher active" : id + " view_switcher";
                            if (configuratorViewData.color[colorName].preview_image == '' || configuratorViewData.color[colorName].main_image == '') {
                                return <></>
                            }
                            return (
                                <>
                                    <div key={id} id={id} className={className} onClick={() => props.setActiveView(index)}>
                                        {configuratorViewData.color && configuratorViewData.color[colorName] && <img src={configuratorViewData.color[colorName].preview_image} />}
                                    </div>
                                </>
                            );
                        })
                    }
                </div>
            </div>
            {colorName && viewImages &&
                viewImages.length > 0 &&
                viewImages.map((configuratorViewData, index) => {
                    var id = "view_" + index;
                    var className = (props.activeView === index) ? id + " viewCanvase active" : id + " viewCanvase";
                    var wheel_id = "wheel_" + index;
                    var main_canvas_id = "main_view_" + index;
                    return (
                        <>
                            <div key={id} className={className} id={id} style={{ "height": canvasHeight + "px", "width": canvasWidth + "px" }}>

                                {index === 0 ? props.previewOrder && props.previewOrder.length > 0 && props.previewOrder.map(order => {
                                    if (order === "frmae") {
                                        if (!configuratorViewData.color || !configuratorViewData.color[colorName] || !configuratorViewData.color[colorName].main_image) {
                                            return <></>
                                        }
                                        return <CanvasComponent key={main_canvas_id} id={main_canvas_id} image={configuratorViewData.color[colorName].main_image}
                                            canvasWidth={canvasWidth}
                                            canvasHeight={canvasHeight}
                                            imageWidth={imageWidth}
                                            imageHeight={imageHeight}
                                            setImageLoaded={setImageLoaded}
                                            isImageLoaded={0}
                                        />
                                    } else if (order === "wheels") {
                                        return configuratorData.wheel[wheelID] && <CanvasComponent key={wheel_id} id={wheel_id} image={configuratorData.wheel[wheelID]} canvasWidth={canvasWidth}
                                            canvasHeight={canvasHeight}
                                            imageWidth={imageWidth}
                                            imageHeight={imageHeight} setImageLoaded={setImageLoaded}
                                            isImageLoaded={0}
                                        />
                                    } else if (props.groupsetImages && props.groupsetImages.map(image => image.name).includes(order)) {
                                        let id = order.replace(/[^a-zA-Z0-9]/g, "");
                                        let data = props.groupsetImages.filter(image => image.name === order)
                                        return <CanvasComponent key={order} id={id} image={data[0].image} canvasWidth={canvasWidth}
                                            canvasHeight={canvasHeight}
                                            imageWidth={imageWidth}
                                            imageHeight={imageHeight} setImageLoaded={setImageLoaded}
                                            isImageLoaded={0}
                                        />
                                    } else if (props.otherImages && Object.keys(props.otherImages).length > 0 && Object.keys(props.otherImages) && Object.keys(props.otherImages).includes(order)) {
                                        let id = order.replace(/[^a-zA-Z0-9]/g, "");
                                        return <CanvasComponent key={order} id={id} image={props.otherImages[order]} canvasWidth={canvasWidth}
                                            canvasHeight={canvasHeight}
                                            imageWidth={imageWidth}
                                            imageHeight={imageHeight} setImageLoaded={setImageLoaded}
                                            isImageLoaded={0}
                                        />
                                    } else if (props.otherOptionalImages && Object.keys(props.otherOptionalImages).length > 0 && Object.keys(props.otherOptionalImages) && Object.keys(props.otherOptionalImages).includes(order)) {
                                        let id = order.replace(/[^a-zA-Z0-9]/g, "");
                                        return props.otherOptionalImages[order].length > 0 && props.otherOptionalImages[order].map((image, index) => {
                                            return (<>
                                                <CanvasComponent key={order + '-' + index} id={id + '-' + index} image={image} canvasWidth={canvasWidth}
                                                    canvasHeight={canvasHeight}
                                                    imageWidth={imageWidth}
                                                    imageHeight={imageHeight} setImageLoaded={setImageLoaded}
                                                    isImageLoaded={0}
                                                />
                                            </>)
                                        })

                                    }
                                }) :
                                    <>
                                        {configuratorViewData.color && configuratorViewData.color[colorName] && <CanvasComponent key={id + index} id={id + index} image={configuratorViewData.color[colorName].main_image} canvasWidth={canvasWidth}
                                            canvasHeight={canvasHeight}
                                            imageWidth={imageWidth}
                                            imageHeight={imageHeight} isImageLoaded={0} />}
                                    </>
                                }
                            </div>
                        </>
                    );
                })}
            <div className="view-configuration__open">
                <a onClick={() => setViewModel(true)}>{i18next.t('Pages.Configurator.PreviewImage.LargeImage')}</a>
            </div>
        </>
    )
}
export default ConfiguratorView;