import Cookies from "js-cookie";
import {
  CREATE_DEALERS_SHOP_PENDING,
  CREATE_DEALERS_SHOP_SUCCESS,
  CREATE_DEALERS_SHOP_FAILURE,
  DEALERS_SHOP_LIST_PENDING,
  DEALERS_SHOP_LIST_SUCCESS,
  DEALERS_SHOP_LIST_FAILURE,
  GET_DEALER_SHOP_PENDING,
  GET_DEALER_SHOP_SUCCESS,
  GET_DEALER_SHOP_FAILURE,
  UPDATE_DEALERS_SHOP_PENDING,
  UPDATE_DEALERS_SHOP_SUCCESS,
  UPDATE_DEALERS_SHOP_FAILURE,
  DELETE_DEALERS_SHOP_PENDING,
  DELETE_DEALERS_SHOP_SUCCESS,
  DELETE_DEALERS_SHOP_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  message: "",
  dealersShop: [],
  dealerShop: {},
  isCreate: false,
  isCreateError: false,
  isUpdate: false,
  isUpdateError: false,
  isDelete: false,
  isDeleteError: false,
  isLoadingDealerShop: false,
  isErrorDealerShop: false,
  isPopupButtonLoading: false,
  isDeletePopupButtonLoading: false,
};

export default function dealersShop(state = initialState, action) {
  if (!state) state = {};
  switch (action.type) {
    case DEALERS_SHOP_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case DEALERS_SHOP_LIST_SUCCESS:
      return Object.assign({}, state, {
        dealersShop: action.data,
        isLoading: false,
      });
    case DEALERS_SHOP_LIST_FAILURE:
      return Object.assign({}, state, {
        message: action.data.message,
        isError: true,
        isLoading: false,
      });
    case CREATE_DEALERS_SHOP_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        isCreate: false,
        isCreateError: false,
        isDelete: false,
        isDeleteError: false,
        isUpdate: false,
        isUpdateError: false,
        isPopupButtonLoading: true,
      });
    case CREATE_DEALERS_SHOP_SUCCESS:
      return Object.assign({}, state, {
        dealerShop: action.data,
        isLoading: false,
        isCreate: true,
        isCreateError: false,
        isDelete: false,
        isDeleteError: false,
        isUpdate: false,
        isUpdateError: false,
        isPopupButtonLoading: false,
      });
    case CREATE_DEALERS_SHOP_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isCreate: false,
        isCreateError: true,
        isDelete: false,
        isDeleteError: false,
        isUpdate: false,
        isUpdateError: false,
        isPopupButtonLoading: false,
      });
    case UPDATE_DEALERS_SHOP_PENDING:
      return Object.assign({}, state, {
        isUpdate: false,
        isUpdateError: false,
        isCreate: false,
        isDelete: false,
        isDeleteError: false,
        isCreateError: false,
        isPopupButtonLoading: true,
      });
    case UPDATE_DEALERS_SHOP_SUCCESS:
      return Object.assign({}, state, {
        dealerShop: action.data,
        isUpdate: true,
        isUpdateError: false,
        isDelete: false,
        isDeleteError: false,
        isCreate: false,
        isCreateError: false,
        isPopupButtonLoading: false,
      });
    case UPDATE_DEALERS_SHOP_FAILURE:
      return Object.assign({}, state, {
        isUpdate: false,
        isUpdateError: true,
        isDelete: false,
        isDeleteError: false,
        isCreate: false,
        isCreateError: false,
        isPopupButtonLoading: false,
      });
    case DELETE_DEALERS_SHOP_PENDING:
      return Object.assign({}, state, {
        isUpdate: false,
        isUpdateError: false,
        isCreate: false,
        isCreateError: false,
        isDeletePopupButtonLoading: true,
        isDelete: false,
        isDeleteError: false,
      });
    case DELETE_DEALERS_SHOP_SUCCESS:
      return Object.assign({}, state, {
        isUpdate: false,
        isUpdateError: false,
        isCreate: false,
        isCreateError: false,
        isPopupButtonLoading: false,
        isDeletePopupButtonLoading: false,
        isDelete: true,
        isDeleteError: false,
      });
    case DELETE_DEALERS_SHOP_FAILURE:
      return Object.assign({}, state, {
        isUpdate: false,
        isUpdateError: false,
        isCreate: false,
        isCreateError: false,
        isPopupButtonLoading: false,
        isDeletePopupButtonLoading: false,
        isDelete: false,
        isDeleteError: true,
      });
    case GET_DEALER_SHOP_PENDING:
      return Object.assign({}, state, {
        isLoadingDealerShop: true,
      });
    case GET_DEALER_SHOP_SUCCESS:
      return Object.assign({}, state, {
        dealerShop: action.data,
        isLoadingDealerShop: false,
      });
    case GET_DEALER_SHOP_FAILURE:
      return Object.assign({}, state, {
        isErrorDealerShop: true,
        isLoadingDealerShop: false,
      });
    default:
      return state;
  }
}
