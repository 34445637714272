import React, { useState } from "react";
import Sticky from "wil-react-sticky";
const BassoGeometrics = ({ module }) => {

  const [activeRow, setActiveRow] = useState("");
  const [activeColumn, setActiveColumn] = useState("");
  const [isSizePopup, setSizePopup] = useState(false);
  const [styleObj, setStyleObj] = useState()
  const rowMouseOver = (element) => {
    let svgTag = document.getElementById(element.currentTarget.dataset.id);
    if (svgTag) {
      svgTag.classList.add("active");
    }
  };
  const rowMouseOut = (element) => {
    let svgTag = document.getElementById(element.currentTarget.dataset.id);
    if (svgTag) {
      svgTag.classList.remove("active");
    }
  };

  const columnHeadMouseOver = (element) => {
    setActiveColumn(element)
  }

  const columnHeadMouseOut = (element) => {
    setActiveColumn('')
  }


  const rowMouseOverSVG = (element) => {
    // console.log("element rowMouseOverSVG", element.target.nodeName)
    // console.log("element rowMouseOverSVG", element)
    if (element && element.target && element.target.nodeName) {
      let target_element = element.target.nodeName;
      if (
        target_element == "path" ||
        target_element == "polyline" ||
        target_element == "circle" ||
        target_element == "polygon"
      ) {

        if (element.nativeEvent.path) {
          setActiveRow(element.nativeEvent.path[1].getAttribute("id"));
        }
        if (
          element.nativeEvent.composedPath &&
          element.nativeEvent.composedPath()
        ) {
          setActiveRow(
            element.nativeEvent.composedPath()[1].getAttribute("id")
          );
        }
      } else {
        // console.log(element.target)
      }
    }
  };
  const rowMouseOutSVG = () => {
    //setActiveRow("")
  };

  return (
    <div id="detailed-geometry-section" className="detailed-geometry-section">
      <div className="productDetailsHeader">
        <div className="productDetailsHeader__headingWrapper">

        </div>
      </div>
      <div id="geometryRow" className="geometryRow ">
        <div id="geometryTable" className="geometryTable">
          <table className="table-geometry">
            <thead className="table-geometry__heading">
              <tr className="geometryTable__headingRow">
                {module &&
                  module.data_sheet &&
                  module.data_sheet.header &&
                  module.data_sheet.header.map((content, key) => {
                    if (key !== 0) {
                      return (
                        <th className="geometryTable__heading" key={key}
                          onMouseOver={() => columnHeadMouseOver(key)}
                          onMouseOut={() => columnHeadMouseOut(key)}
                        >
                          {content.c}
                        </th>
                      );
                    }
                  })}
              </tr>
            </thead>
            <tbody>
              {module &&
                module.data_sheet &&
                module.data_sheet.body &&
                module.data_sheet.body.map((content, key) => {
                  return (
                    <tr
                      key={key}
                      className={activeRow == content[0].c ? "active" : ""}
                      data-id={content[0].c}
                      onMouseOver={rowMouseOver}
                      onMouseOut={rowMouseOut}
                    >
                      {content.map((rowData, index) => {
                        if (index !== 0) {
                          return (
                            <td
                              className={
                                (index == 0
                                  ? "geometryTable__title"
                                  : "geometryTableData") + ' ' + ((index != 1) && (activeColumn == index) ? 'active' : '')
                              }
                              key={key + index}
                            >
                              <span>{rowData.c}</span>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div id="geometryImage" style={styleObj} className="geometryImage" >
          <Sticky
            containerSelectorFocus="#geometryRow"
            offsetTop={0}
            stickyEnableRange={[768, Infinity]}
          >
            <div className="geometryImage__inner">
              <div className="geometrySvgInner">
                <div
                  onMouseOver={rowMouseOverSVG}
                  onMouseOut={rowMouseOutSVG}
                  dangerouslySetInnerHTML={{
                    __html: module.svg_file,
                  }}
                ></div>
              </div>
            </div>
          </Sticky>
        </div>
      </div>
    </div>
  );
};
export default BassoGeometrics;
