import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Checkbox, Row, Col, Modal } from "antd";
import i18next from "i18next";
import countryData from '../../utils/country';
import { useDispatch, useSelector } from 'react-redux';
import { createAddress, openAddressPopup, updateAddress } from "../../actions/addressActions";

const { Option } = Select;

const AddressPopup = ({
  type,
  addressData,
  defaultCountry
}) => {
  const dispatch = useDispatch();
  const { isLoading, isAddressPopup } = useSelector(state => {
    return state.address
  });
  const [form] = Form.useForm();
  const [id, setAddressId] = useState(null);
  const [is_default, setDefault] = useState(false);

  useEffect(() => {

    if (addressData) {
      setAddressId(addressData.id);
      setDefault(addressData.is_default == "1" ? true : false);
      form.setFieldsValue({
        firstname: addressData.firstname,
        lastname: addressData.lastname,
        country: addressData.country,
        state: addressData.state,
        postcode: addressData.postcode,
        phone: addressData.phone,
        address_1: addressData.address_1,
        city: addressData.city,
        company_name: addressData.company_name,
      });
    } else {
    }
  });

  const onCloseAddressPoup = () => {
    form.setFieldsValue({
      firstname: "",
      lastname: "",
      country: defaultCountry ? defaultCountry : '',
      state: "",
      postcode: "",
      phone: "",
      address_1: "",
      city: "",
      company_name: ""
    });
    form.resetFields();
    dispatch(openAddressPopup(false));
    setDefault(false);
  };

  const onFinishFailed = (error) => {
    console.error(error);
  };

  const handleOnCheked = (e) => {
    setDefault(e.target.checked);
  };

  const saveAddressData = async (values) => {
    let data = {
      type: type,
      firstname: values.firstname,
      lastname: values.lastname,
      country: values.country,
      state: values.state,
      postcode: values.postcode,
      phone: values.phone,
      address_1: values.address_1,
      city: values.city,
      is_default: is_default ? 1 : 0,
      company_name: values.company_name,
    };
    if (id) {
      data["id"] = id;
      dispatch(updateAddress(data));
      onCloseAddressPoup();
    } else {
      dispatch(createAddress(data));
      onCloseAddressPoup();
    }
  };

  return (
    <>
      <Modal
        className="modal-s2 modal-address"
        centered
        width={1000}
        footer={null}
        visible={isAddressPopup}
        onCancel={() => {
          onCloseAddressPoup();
        }}
      >
        <div className="modal-header">
          <h2>{i18next.t("Common.NewAddress")}</h2>
        </div>
        <div className="modal-content">
          <div className="form-wrapper">
            <p className="Note">
              {i18next.t("Common.RequiredFields")}
            </p>
            <div>
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true, country: defaultCountry ? defaultCountry : '' }}
                onFinish={saveAddressData}
                onFinishFailed={onFinishFailed}
              >
                <Row
                  gutter={[
                    { xs: 8, sm: 16, lg: 24 },
                    { xs: 0, sm: 16, lg: 24 },
                  ]}
                >
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                  <label className="Form-label">{i18next.t("Common.Form.companyname")}</label>
                    <Form.Item
                      name="company_name"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_companyname"
                          ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                    <label className="Form-label">{i18next.t("Common.Form.name")}</label>
                    <Form.Item
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_name"
                          ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 8 }}>
                    <label className="Form-label">{i18next.t("Common.Form.surname")}</label>
                    <Form.Item
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_surname"
                          ),
                        },
                      ]}
                    >
                      
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <h3>{i18next.t("Common.Form.address")}</h3>
                <Row
                  gutter={[
                    { xs: 8, sm: 16, lg: 24 },
                    { xs: 0, sm: 16, lg: 24 },
                  ]}
                >
                  <Col xs={{ span: 24 }} sm={{ span: 10 }} lg={{ span: 10 }}>
                    <label className="Form-label">{i18next.t("Common.Form.country")}</label>
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_country"
                          ),
                        },
                      ]}
                    >
                      
                      <Select
                        // placeholder={(i18next.t('Common.Form.country')) }
                        defaultValue=""
                        disabled={defaultCountry ? true : false}
                      >
                        <Option key="-1" value="">
                          Select Country
                        </Option>
                        {Object.keys(countryData).map((data, index) => {
                          return <Option key={index} value={data}>
                            {countryData[data]}
                          </Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 16 }} sm={{ span: 10 }} lg={{ span: 10 }}>
                    <label className="Form-label">{i18next.t("Common.Form.city")}</label>
                    <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_city"
                          ),
                        },
                      ]}
                    >
                       
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 8 }} sm={{ span: 4 }} lg={{ span: 4 }}>
                    <label className="Form-label">{i18next.t("Common.Form.province")}</label>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_province"
                          ),
                        },
                      ]}
                    >
                       
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  gutter={[
                    { xs: 8, sm: 16, lg: 24 },
                    { xs: 0, sm: 16, lg: 24 },
                  ]}
                >
                  <Col xs={{ span: 16 }} sm={{ span: 20 }} lg={{ span: 20 }}>
                    <label className="Form-label">{i18next.t("Common.Form.street")}</label>
                    <Form.Item
                      name="address_1"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_street"
                          ),
                        },
                      ]}
                    >
                      
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 8 }} sm={{ span: 4 }} lg={{ span: 4 }}>
                    <label className="Form-label">{i18next.t("Common.Form.postalcode")}</label>
                    <Form.Item
                      name="postcode"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_postalcode"
                          ),
                        },
                      ]}
                    >
                     
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  align="top"
                  gutter={[
                    { xs: 8, sm: 16, lg: 24 },
                    { xs: 0, sm: 16, lg: 24 },
                  ]}
                >
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
                    <h3>{i18next.t("Common.Form.contact")}</h3>
                    <label className="Form-label">{i18next.t("Common.Form.phone")}</label>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: i18next.t(
                            "Common.Form.require_province"
                          ),
                        },
                      ]}
                    >
                     
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
                    <h3>
                      {i18next.t(
                        "Common.Form.defaultAddressSettings"
                      )}
                    </h3>
                    <div className="checkbox-wrapper">
                      <Checkbox
                        className="checkbox-stardue"
                        value={is_default}
                        type="checkbox"
                        id="isDefault"
                        checked={is_default}
                        onChange={(e) => handleOnCheked(e)}
                      >
                        <span className="info-checkbox">
                          {i18next.t(
                            "Common.Form.is_default_address"
                          )}
                        </span>
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
                <Form.Item className="text-center text-lg-end form-confirm">
                  <Button type="primary" loading={isLoading} htmlType={"submit"}>
                    <>
                      {i18next.t("Common.Confirm")}
                    </>
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddressPopup;
