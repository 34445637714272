import React from "react";
import StarDueCollapse from "../../components/StarDueCollapse/StarDueCollapse";
import { Space, Radio, Row, Col } from "antd";
import PriceFormat from "../../components/PriceFormat";
import i18next from "i18next";

const Wheel = ({ onChangeWheel, wheel, wheels, wheelPriceText, stepId, setConfiguratorIdURL }) => {
  const renderName = (id) => {
    let data = wheels.items.filter((item) => item.id === wheel);
    if (data && data[0]) {
      return data[0].name;
    }
  };

  return (
    <>
      <StarDueCollapse
        title={i18next.t('Pages.Configurator.Step.Header.Wheels')}
        stepId={stepId}
        active={true}
        value={renderName(wheel)}
      >
        <div className="step-body">
          <div className="RadioGroupWithImage">
            <Radio.Group
              onChange={(e) => {onChangeWheel(e);setConfiguratorIdURL();}}
              value={wheel}
              className="Radio-img WheelsRadio">
              <Space direction="vertical">

                {wheels.items &&
                  wheels.items.map((wheel) => {
                    return (

                      <Radio key={wheel.id} value={wheel.id} wheel={wheel}>
                        <div className="Flex-box boxWithPrice">
                          <h4>{wheel.name}</h4>
                          {wheelPriceText &&
                            wheelPriceText[wheel.id] &&
                            wheelPriceText[wheel.id]["text"] == 0 && (
                              <span className="RadioWheelsPrice">
                                {wheels.default === wheel.id
                                  ? (i18next.t('Pages.Configurator.Step.Price.Included'))
                                  : (i18next.t('Pages.Configurator.Step.Price.Upgrade'))}
                              </span>
                            )}
                          {wheelPriceText &&
                            wheelPriceText[wheel.id] &&
                            wheelPriceText[wheel.id]["text"] != 0 && (
                              <>

                                <span className="RadioWheelsPrice">
                                  <span>{wheelPriceText[wheel.id]["text"] > 0 ? '+' : ''}</span>
                                  <PriceFormat
                                    price={wheelPriceText[wheel.id]["text"]}
                                  />
                                </span>
                              </>
                            )}
                        </div>
                        <div className="Radio-custom-img RadioImgWheels">
                          {wheel.image && wheel.image.url && <img src={wheel.image.url} alt="" />}
                        </div>
                      </Radio>

                    );
                  })}

              </Space>
            </Radio.Group>
          </div>
        </div>
      </StarDueCollapse>
    </>
  );
};

export default Wheel;
