import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from 'react-redux';
import { getTraining, getTrainingType } from "../../actions/trainingActions";
import { Row, Col, Pagination, Skeleton, Modal, Select } from "antd";
import paginationLabel from "../../components/PaginationRender";
import { ReactTitle } from 'react-meta-tags';
import DateFormat from "../../components/DateFormat";
import Footer from "../../components/Footer";
import i18next from "i18next";

const { Option } = Select;

const AreaTraining = () => {
  const dispatch = useDispatch();
  const [current, setcurrent] = useState(1);
  const [pageSize, setpageSize] = useState(9);
  const [selectedType, setSelectedType] = useState("");
  const [videoContent, setvideoContent] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [modelVisibal, setmodelVisibal] = useState(false);

  const { isLoading, training, total, type } = useSelector(state => {
    return state.training
  });

  useEffect(() => {
    dispatch(getTraining(current, pageSize, selectedType));
  }, [current]);
  useEffect(() => {
    dispatch(getTrainingType());
  }, []);

  //-----------reset filter ---------//
  const [filterButton, setFilterButton] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const makeResetFilter = () => {
    setSelectedType('');
    setResetFilter(true);
    setFilterButton(false);
  }
  useEffect(() => {
    if (resetFilter) {
      dispatch(getTraining(current, pageSize, selectedType));
    }
  }, [resetFilter]);
  //-----------reset filter ---------//

  const onFilterApply = () => {
    dispatch(getTraining(current, pageSize, selectedType));
  };
  return (
    <>
      <ReactTitle title={i18next.t("PageTitle.TrainingArea")} />
      <Header />
      <div className="dashboard-content prd-list">
        <div className="wrap">

          <div className="page-content flex">
            <div className="left-side">
              <div className="filter-side-container">
                <div className="filter-blk">
                  <div className="title">{i18next.t("Common.FiltersSidebar.Title")}</div>

                  <div className="form-wrapper">
                    <div className="form-group">
                      <div className="form-label">
                        <label>{i18next.t("Common.FiltersSidebar.Model")}</label>
                      </div>
                      <Select
                        value={selectedType}
                        onChange={(value) => {
                          setSelectedType(value);
                          setResetFilter(false);
                          setFilterButton(true);
                        }}
                      >
                        <Option value="">{i18next.t("Common.FiltersSidebar.All1")}</Option>
                        {type.length > 0 && type.map((item) => (
                          <Option value={item.slug}>{item.name}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>

                </div>
                <div className="filter-submit">
                  <button className="btn btn-primary btn-submit" onClick={onFilterApply}>
                    {i18next.t("Common.FiltersSidebar.Submit")}
                  </button>
                  {resetFilter && <button
                    className="btn btn-only-icon"
                    onClick={makeResetFilter}
                  >
                    <div className="icon">
                      <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1219.76 1087.17"><path d="M1192.6,422.91a18.73,18.73,0,0,0-26.25,3.66L1066.13,558.85a533.06,533.06,0,1,0-15.28,175.31,18.75,18.75,0,1,0-36.28-9.37,496.85,496.85,0,1,1,14.82-154.69L890.64,464.63A18.77,18.77,0,1,0,868,494.54L1040.07,625a18.76,18.76,0,0,0,11.34,3.75,11.89,11.89,0,0,0,2.53,0,18.75,18.75,0,0,0,12.38-7.22l130.31-172a18.76,18.76,0,0,0-4-26.53Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /><path d="M534.78,1141a540.07,540.07,0,1,1,536.61-600.64l89.38-118a25.75,25.75,0,0,1,36-5l3.51,2.65-.08.37a25.75,25.75,0,0,1,2,33.33l-130.32,172a25.54,25.54,0,0,1-17,9.92l-.17,0a18.66,18.66,0,0,1-3.66,0,25.93,25.93,0,0,1-15.19-5.15L863.72,500.12a25.77,25.77,0,0,1,31.14-41.07l126.35,96a490,490,0,1,0-13.45,168.07A25.75,25.75,0,1,1,1057.6,736,540.33,540.33,0,0,1,534.78,1141Zm-.1-1066.17a526.07,526.07,0,1,0,509.39,657.63l.07-.26a11.75,11.75,0,1,0-22.73-5.87l-.06.26a503.9,503.9,0,1,1,15-156.87l.94,15.25L886.4,470.21A11.77,11.77,0,0,0,872.18,489L1044.3,619.37a11.55,11.55,0,0,0,7.08,2.33h.41l.4,0a3.87,3.87,0,0,0,.9,0,11.66,11.66,0,0,0,7.66-4.51l130.3-172a11.75,11.75,0,0,0-2.53-16.61l-.84-.6a11.75,11.75,0,0,0-15.75,2.78L1060.6,577.73l-1.45-18.33A526,526,0,0,0,536.85,74.79Z" transform="translate(8.85 -57.28)" style={{ stroke: '#000', strokeMiterlimit: 10, strokeWidth: '7px' }} /></svg>
                    </div>{i18next.t("Common.FiltersSidebar.Reset")}
                  </button>}
                </div>
              </div>
            </div>
            <div className="right-side flex flex-col rel">
              <div className="page-title">
                <h3>{i18next.t("Pages.TraningArea.PageTitle")}</h3>
              </div>
              <div className="table rel with-pagination">
                <div className="hdr">

                  <Row gutter={[24, 24]}>
                    <Col
                      span={5}
                      xs={{ span: 5 }}
                      sm={{ span: 5 }}
                      lg={{ span: 5 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.TraningArea.Table.Title")}</div>
                    </Col>
                    <Col
                      span={8}
                      xs={{ span: 8 }}
                      sm={{ span: 8 }}
                      lg={{ span: 8 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.TraningArea.Table.Description")}</div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.TraningArea.Table.Model")}</div>
                    </Col>
                    <Col
                      span={3}
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      lg={{ span: 3 }}
                    >
                      <div className="table-header-item">{i18next.t("Pages.TraningArea.Table.Date")}</div>
                    </Col>
                    <Col
                      span={4}
                      xs={{ span: 4 }}
                      sm={{ span: 4 }}
                      lg={{ span: 4 }}
                    >
                      <div className="table-header-item"></div>
                    </Col>
                  </Row>

                </div>

                <div className="table-container-wrapper">
                  <div className={isLoading ? "loader-container" : "loader-container is-loaded"}>
                    <span className="loader"></span>
                    <div className="loader-text">{i18next.t("Pages.TraningArea.Loader")}</div>
                  </div>
                  <div className="table-wrap">
                    {training.length > 0 && training.map((item, idx) => (
                      <div className="table-item">
                        <Row gutter={[24, 24]}>
                          <Col
                            span={5}
                            xs={{ span: 5 }}
                            sm={{ span: 5 }}
                            lg={{ span: 5 }}
                          >
                            <div className="table-item__content">{item.title}</div>
                          </Col>
                          <Col
                            span={8}
                            xs={{ span: 8 }}
                            sm={{ span: 8 }}
                            lg={{ span: 8 }}
                          >
                            <div className="table-item__content">{item.description}</div>
                          </Col>
                          <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            lg={{ span: 4 }}
                          >
                            <div className="table-item__content">{(item.type.name && item.type.name.length && item.type.name.join(', '))}</div>
                          </Col>
                          <Col
                            span={3}
                            xs={{ span: 3 }}
                            sm={{ span: 3 }}
                            lg={{ span: 3 }}
                          >
                            <div className="table-item__content">
                              <DateFormat date={item.date} />
                            </div>
                          </Col>
                          <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            lg={{ span: 4 }}
                          >
                            <div className="table-item__content align-end">
                              <button className="btn btn-icon" onClick={() => { setvideoContent(item.video); setmodelVisibal(true); setVideoTitle(item.title) }}>
                              <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 364.32 445.32"><path d="M.15,341.03L1.79,98.24C2.33,17.08,95.81-28.92,160.38,20.22l165.02,125.64c53.14,40.46,51.48,121.14-2.64,159.12l-165.5,121.17C90.12,475.32-4.46,423.78,.16,341.03h-.01Zm304.23-61.14l-165.49,121.17c-46.83,34.33-111.33-2.57-107.66-59l1.64-243.62c.22-56.41,65.02-86.88,108.67-53.49l165.02,125.63c36.65,27.98,34.97,83.23-2.17,109.3h0Z" style={{fillRule: 'evenodd'}} /></svg>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Modal
                      className="modal-s2 modal-training"
                      visible={modelVisibal}
                      footer={null}
                      onCancel={() => { setmodelVisibal(false) }}
                      closeIcon={<svg id="Modalità_Isolamento" data-name="Modalità Isolamento" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.99 222.99"><path d="M461.5,192.25l-23.74-23.74L350,256.26l-87.75-87.75-23.74,23.74L326.26,280l-87.75,87.76,23.74,23.74L350,303.75l87.76,87.75,23.74-23.74L373.75,280Z" transform="translate(-238.51 -168.51)" /></svg>}
                    >
                      <div className="modal-header">
                        <h2>
                          {videoTitle}
                        </h2>
                      </div>
                      <div className="video-training__wrapper">
                        <div dangerouslySetInnerHTML={{
                          __html: videoContent
                        }}></div>
                      </div>
                    </Modal>
                  </div>
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    showSizeChanger={false}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} video`}
                    onChange={(page) => setcurrent(page)}
                    itemRender={paginationLabel}
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default AreaTraining;
