import React, { useState, useLayoutEffect } from "react";
import FindYourSize from "../../FindYourSize";
import { Modal } from "antd";
import Sticky from "wil-react-sticky";
import i18next from "i18next";
const BassoGeometrics = ({ module, isStep, setCurrentSizeStep, modelName, modalId, test }) => {

  const [activeRow, setActiveRow] = useState("");
  const [activeColumn, setActiveColumn] = useState("");
  const [isSizePopup, setSizePopup] = useState(false);
  const [styleObj, setStyleObj] = useState()
  const rowMouseOver = (element) => {
    let svgTag = document.getElementById(element.currentTarget.dataset.id);
    if (svgTag) {
      svgTag.classList.add("active");
    }
  };
  const rowMouseOut = (element) => {
    let svgTag = document.getElementById(element.currentTarget.dataset.id);
    if (svgTag) {
      svgTag.classList.remove("active");
    }
  };

  const columnHeadMouseOver = (element) => {
    setActiveColumn(element)
  }

  const columnHeadMouseOut = (element) => {
    setActiveColumn('')
  }


  const rowMouseOverSVG = (element) => {
    //console.log("element rowMouseOverSVG", element.target.nodeName)
    //console.log("element rowMouseOverSVG", element)
    if (element && element.target && element.target.nodeName) {
      let target_element = element.target.nodeName;
      if (
        target_element == "path" ||
        target_element == "polyline" ||
        target_element == "circle" ||
        target_element == "polygon"
      ) {
        if (element.nativeEvent.path) {
          setActiveRow(element.nativeEvent.path[1].getAttribute("id"));
        }
        if (
          element.nativeEvent.composedPath &&
          element.nativeEvent.composedPath()
        ) {
          setActiveRow(
            element.nativeEvent.composedPath()[1].getAttribute("id")
          );
        }
      } else {
        // console.log(element.target)
      }
    }
  };
  const rowMouseOutSVG = () => {
    //setActiveRow("")
  };
  useLayoutEffect(() => {
    window.addEventListener('scroll', (event) => {


      // const rect = document.getElementById('geometryTable').getBoundingClientRect();
      // let inViewport = (
      //   rect.top >= 0 &&
      //   rect.left >= 0 &&
      //   rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      //   rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      // );

      //   console.log(inViewport)

      //   return false;
      // var docViewTop = window.pageYOffset;
      // var docViewBottom = docViewTop + window.innerHeight;

      // var elemTop = document.getElementById("geometryImage").offsetTop;
      // console.log(elemTop)
      // var getElementDetails = document.getElementById("geometryImage").getBoundingClientRect()
      // var elemBottom = elemTop + getElementDetails.height;
      // console.log(((elemBottom <= docViewBottom) && (elemTop >= docViewTop)))
      // return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));

    })
  }, [])
  return (
    <div id="detailed-geometry-section" className="detailed-geometry-section">
      {/* <div className="detailedGeometry__wrapper"> */}

      <div className="productDetailsHeader">
        <div className="productDetailsHeader__headingWrapper">
          {/* <div className="headline-section align-horizzontal-left-flex align-vertical-top importance-small type-normal order-title-subtitle spacing-large">
                <div className="headline-inner">
                  <div className="headline-wrap">
                    <div className="title">
                      <h2>Tabella Geometrie</h2>
                    </div>
                    <div className="paragraph">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </div>
                </div>
              </div> */}
        </div>
      </div>
      {/* <div className="geometryContent productDetailsContent" > */}
      <div id="geometryRow" className="geometryRow ">

        <div id="geometryTable" className="geometryTable">
          <div className="geometryTableInner">
            <table className="table-geometry">
              <thead className="table-geometry__heading">
                <tr className="geometryTable__headingRow">
                  {module &&
                    module.table &&
                    module.table.header &&
                    module.table.header.map((content, key) => {
                      if (key !== 0) {
                        return (
                          <th className="geometryTable__heading" key={key}
                            onMouseOver={() => columnHeadMouseOver(key)}
                            onMouseOut={() => columnHeadMouseOut(key)}
                          >
                            {content.c}
                          </th>
                        );
                      }
                    })}
                </tr>
              </thead>
              <tbody>
                {module &&
                  module.table &&
                  module.table.body &&
                  module.table.body.map((content, key) => {
                    return (
                      <tr
                        key={key}
                        className={activeRow == content[0].c ? "active" : ""}
                        data-id={content[0].c}
                        onMouseOver={rowMouseOver}
                        onMouseOut={rowMouseOut}
                      >
                        {content.map((rowData, index) => {
                          if (index !== 0) {
                            return (
                              <td
                                className={
                                  (index == 0
                                    ? "geometryTable__title"
                                    : "geometryTableData") + ' ' + ((index != 1) && (activeColumn == index) ? 'active' : '')
                                }
                                key={key + index}
                              >
                                <span>{rowData.c}</span>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div id="geometryImage" style={styleObj} className="geometryImage" >
          <Sticky
            containerSelectorFocus="#geometryRow"
            offsetTop={90}
            stickyEnableRange={[768, Infinity]}
          >
            <div className="geometryImage__inner">
              <div className="geometryImageTitle">
                {/* <h3>{module.model ? module.model.name : modelName} - Model View</h3> */}
                <div className="FindYourSizeLink">
                  <a onClick={() => isStep ? setCurrentSizeStep(1) : setSizePopup(true)}>
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="82.3px"
                      height="41px"
                      viewBox="0 0 82.3 41"
                      style={{
                        overflow: "visible",
                        enableBackground: "new 0 0 82.3 41",
                      }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <path
                          d="M80.8,0H1.5C0.7,0,0,0.7,0,1.5v38C0,40.3,0.7,41,1.5,41h79.3c0.8,0,1.5-0.7,1.5-1.5v-38C82.3,0.7,81.7,0,80.8,0z M79.3,38
                          h-9V26.2c0-0.6-0.4-1-1-1s-1,0.4-1,1V38h-9.8V16.7c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2V38H42.9V27c0-0.5-0.5-1-1-1c-0.6,0-1,0.5-1,1
                          v11h-9.7V16.7c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2V38H15.5V27c0-0.5-0.5-1-1-1c-0.6,0-1,0.5-1,1v11H3V3h76.3V38z"
                        />
                      </g>
                    </svg>
                    <span className="text">{i18next.t('Components.Geometry.Header.label')}</span>
                  </a>
                </div>
              </div>
              <div className="geometrySvgInner">
                <div
                  onMouseOver={rowMouseOverSVG}
                  onMouseOut={rowMouseOutSVG}
                  dangerouslySetInnerHTML={{
                    __html: module.geometries,
                  }}
                ></div>
              </div>
            </div>
          </Sticky>
        </div>

      </div>


      {/* <div className="row align-items-start">
        <div className="col-12 col-md-6">
          <div style={{ height: 2000, border: '3px solid blue' }}>Main Content</div>
        </div>
        <div className="col-12 col-md-6">
          <StickyBox offsetTop={20} offsetBottom={20} style={{ border: '3px solid green' }}>
            <div style={{ height: 200, border: '3px solid blue' }}>Main Content</div>
          </StickyBox>
        </div>
      </div> */}

      {/* </div> */}

      {/* </div> */}

      {isSizePopup && (
        <Modal
          className="findYourSizePopup"
          //title="Basic Modal"
          visible={isSizePopup}
          width={768}
          footer={null}
          centered
          onCancel={() => {
            setSizePopup(false);
          }}
        >
          <FindYourSize modalId={module.id ? module.id : modalId} />
        </Modal>
      )}
    </div>
  );
};
export default BassoGeometrics;
